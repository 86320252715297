<template>
  <section id="form-wizard-subscriptions">
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("subscriptions.create") }}</h1>
        <h5 class="text-primary">
          {{ $t("subscriptions.configurationSubsc") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-row class="align-items-center">
          <b-col>
            <b-button
              variant="danger"
              @click="goToBackPage"
            >
              {{
                $t("dataGeneric.goBack")
              }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        title=""
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.create')"
        :back-button-text="$t('dataGeneric.goBack')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- Gestión de pago tab -->
        <tab-content :title="$t('subscriptions.wizardForm')">
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t("subscriptions.wizardForm") }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group :label="$t('subscriptions.gestion')">
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="isStripeGestioned"
                  button-variant="outline-primary"
                  size="lg"
                  name="radios-btn-default"
                  buttons
                >
                  <b-form-radio :value="false">
                    {{ $t('dataGeneric.internal') }}
                  </b-form-radio>
                  <b-form-radio
                    :disabled="externalPayMethod == null ? true : false"
                    :value="true"
                  >
                    {{ $t('dataGeneric.external') }}
                  </b-form-radio>
                </b-form-radio-group>
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('crSus')
                  "
                  class="ml-2"
                  icon="InfoIcon"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <!-- Información general tab -->
        <tab-content
          v-if="isStripeGestioned == false"
          :title="$t('purchases.label2')"
          :before-change="validationForm"
        >
          <validation-observer
            ref="info"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("purchases.label3") }}
                </h5>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-group :label="$t('dataGeneric.name') + ' *'">
                    <b-form-input
                      id="name"
                      v-model="name"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('subscriptions.name')"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('subscriptions.timeline')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Periodo"
                    rules="required"
                  >
                    <b-form-select
                      id="Periodo"
                      v-model="interval"
                      label="text"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option
                        v-for="s in intervalOptions"
                        :key="s.value"
                        :disabled="s.value == 'LIFETIME' && isStripeGestioned ? true : false"
                        :value="s.value"
                      >
                        {{ s.text }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :label="$t('dataGeneric.description')">
                  <b-form-textarea
                    id="textarea"
                    v-model="description"
                    :placeholder="$t('dataGeneric.description')"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="maxDevices"
                  rules="required"
                >
                  <b-form-group
                    class="mb-2 "
                    :label="$t('subscriptions.max') + ' *'"
                    :invalid-feedback="$t('subscriptions.numberDevices')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="maxDevices"
                      type="number"
                      :min="3"
                      :max="5"
                      autocomplete="new-password"
                      class="mr-1"
                      :placeholder="$t('subscriptions.max')"
                      name="maxDevices"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <b-form-group
                  class="mb-2"
                  :label="$t('subscriptions.freeDays')"
                >
                  <b-form-input
                    v-model="freeDays"
                    type="number"
                    autocomplete="new-password"
                    :placeholder="$t('freeDays')"
                    name="freeDays"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="subgroups"
                md="4"
              >
                <b-form-group
                  v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                  :label="$t('Subgroup')"
                >
                  <v-select
                    id="subgroup"
                    v-model="subgroup"
                    :options="subgroups"
                    label="name"
                    :placeholder="$t('SinGrupoUsuario')"
                    aria-autocomplete="new-password"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col md="2">
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isActive"
                    v-model="isActive"
                    name="isActive"
                    :switch="true"
                  >
                    {{ $t("subscriptions.active") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isAdFree"
                    v-model="isAdFree"
                    name="isAdFree"
                    :switch="true"
                  >
                    {{ $t("isAdFree") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isUnlock4k"
                    v-model="isUnlock4k"
                    name="isUnlock4k"
                    :switch="true"
                  >
                    {{ $t("isUnlock4k") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isUnlockDownload"
                    v-model="isUnlockDownload"
                    name="isUnlockDownload"
                    :switch="true"
                  >
                    {{ $t("isUnlockDownload") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          v-else
          :title="$t('purchases.label5')"
          :before-change="validationForm"
        >
          <validation-observer
            v-if="externalPayMethod"
            ref="info"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("purchases.label3") }}
                </h5>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-group :label="$t('dataGeneric.name') + ' *'">
                    <b-form-input
                      id="name"
                      v-model="name"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('subscriptions.name')"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('subscriptions.timeline')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Periodo"
                    rules="required"
                  >
                    <b-form-select
                      id="Periodo"
                      v-model="interval"
                      label="text"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option
                        v-for="s in intervalOptions"
                        :key="s.value"
                        :disabled="s.value == 'LIFETIME' && isStripeGestioned ? true : false"
                        :value="s.value"
                      >
                        {{ s.text
                        }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group :label="$t('dataGeneric.description')">
                  <b-form-textarea
                    id="textarea"
                    v-model="description"
                    :placeholder="$t('dataGeneric.description')"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="devices"
                  rules="required"
                >
                  <b-form-group
                    class="mb-2 "
                    :label="$t('subscriptions.max') + ' *'"
                    :invalid-feedback="$t('subscriptions.numberDevices')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="devices"
                      v-model="maxDevices"
                      type="number"
                      :min="3"
                      :max="5"
                      autocomplete="new-password"
                      :placeholder="$t('subscriptions.max')"
                      class="mr-1"
                      name="maxDevices"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <b-form-group
                  class="mb-2 "
                  :label="$t('subscriptions.freeDays')"
                >
                  <b-form-input
                    v-model="freeDays"
                    type="number"
                    autocomplete="new-password"
                    :placeholder="$t('freeDays')"
                    name="freeDays"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="price"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('purchases.price') + '  ( ' + currencyType[currency] + ' )' + ' *'"
                    :invalid-feedback="$t('purchases.priceFormValid')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="price"
                      v-model="price"
                      type="number"
                      :min="0"
                      step="any"
                      :placeholder="currencyType[currency]"
                      autocomplete="new-password"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                  <!--small class="text-danger">{{ errors[0] }}</small-->
                </validation-provider>
              </b-col>

              <b-col v-if="externalPayMethod == 'STR'">
                <b-form-group :label="$t('subscriptions.spi')">
                  <b-form-input
                    id="StripePI"
                    v-model="subscriptionId"
                    autocomplete="new-password"
                    :placeholder="$t('subscriptions.spi')"
                  />
                </b-form-group>
              </b-col>
              <b-col v-else />
              <b-col v-if="externalPayMethod == 'STR'">
                <b-form-group :label="externalPayMethod == 'STR' ? $t('subscriptions.sip') : 'Epoch PiCode'">
                  <b-form-input
                    id="StripePC"
                    v-model="planId"
                    autocomplete="new-password"
                    :placeholder="externalPayMethod == 'STR' ? $t('subscriptions.sip') : 'Epoch PiCode'"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="externalPayMethod == 'STR'">
                <b-form-group :label="$t('subscriptions.app')">
                  <b-form-input
                    id="StripeAM"
                    v-model="subscriptionAppId"
                    autocomplete="new-password"
                    :placeholder="$t('subscriptions.app')"
                  />
                </b-form-group>
              </b-col>
              <b-col v-else>
                <b-form-group :label="$t('subscriptions.app')">
                  <b-form-input
                    id="EpochAM"
                    v-model="subscriptionAppId"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="subgroups"
                md="4"
              >
                <b-form-group
                  v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                  :label="$t('Subgroup')"
                >
                  <v-select
                    id="subgroup"
                    v-model="subgroup"
                    :options="subgroups"
                    label="name"
                    :placeholder="$t('SinGrupoUsuario')"
                    aria-autocomplete="new-password"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col md="2">
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isActive"
                    v-model="isActive"
                    name="isActive"
                    :switch="true"
                  >
                    {{ $t("subscriptions.active") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isAdFree"
                    v-model="isAdFree"
                    name="isAdFree"
                    :switch="true"
                  >
                    {{ $t("isAdFree") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isUnlock4k"
                    v-model="isUnlock4k"
                    name="isUnlock4k"
                    :switch="true"
                  >
                    {{ $t("isUnlock4k") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isUnlockDownload"
                    v-model="isUnlockDownload"
                    name="isUnlockDownload"
                    :switch="true"
                  >
                    {{ $t("isUnlockDownload") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
          <validation-observer
            v-else
            ref="info"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("purchases.label3") }}
                </h5>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('dataGeneric.name') + ' *'">
                  <validation-provider
                    v-slot="validationContext"
                    name="name"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('subscriptions.name')"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('subscriptions.timeline')"
                  label-for="Periodo"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Periodo"
                    rules="required"
                  >
                    <b-form-select
                      id="Periodo"
                      v-model="interval"
                      label="text"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option
                        v-for="s in intervalOptions"
                        :key="s.value"
                        :disabled="s.value == 'LIFETIME' && isStripeGestioned ? true : false"
                        :value="s.value"
                      >
                        {{ s.text
                        }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('dataGeneric.description')"
                  label-for="textarea"
                >
                  <b-form-textarea
                    id="textarea"
                    v-model="description"
                    :placeholder="$t('dataGeneric.descriptionSubsc')"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  class="mb-2 "
                  :label="$t('subscriptions.max')"
                  label-for="maxDevices"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="maxDevices"
                    rules="required"
                  >
                    <b-form-input
                      v-model="maxDevices"
                      type="number"
                      :min="3"
                      :max="5"
                      autocomplete="new-password"
                      class="mr-1"
                      :placeholder="$t('subscriptions.max')"
                      name="maxDevices"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('purchases.price')"
                  label-for="price"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="price"
                    rules="required"
                  >
                    <b-col style="display: flex; align-items: center">
                      <b-form-input
                        id="price"
                        v-model="price"
                        type="number"
                        :min="0"
                        step="any"
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                      />

                      <strong>
                        {{ currencyType[currency] }}
                      </strong>
                    </b-col>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('subscriptions.product')"
                  label-for="StripePI"
                >
                  <b-form-input
                    id="StripePI"
                    v-model="subscriptionId"
                    autocomplete="new-password"
                    :placeholder="$t('subscriptions.product')"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('subscriptions.price')"
                  label-for="StripePC"
                >
                  <b-form-input
                    id="StripePC"
                    v-model="planId"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  :label="$t('subscriptions.app')"
                  label-for="StripeAM"
                >
                  <b-form-input
                    id="StripeAM"
                    v-model="subscriptionAppId"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col md="2">
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isActive"
                    v-model="isActive"
                    name="isActive"
                    :switch="true"
                  >
                    {{ $t("subscriptions.active") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isAdFree"
                    v-model="isAdFree"
                    name="isAdFree"
                    :switch="true"
                  >
                    {{ $t("isAdFree") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isUnlock4k"
                    v-model="isUnlock4k"
                    name="isUnlock4k"
                    :switch="true"
                  >
                    {{ $t("isUnlock4k") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-2 mr-1">
                  <b-form-checkbox
                    id="isUnlockDownload"
                    v-model="isUnlockDownload"
                    name="isUnlockDownload"
                    :switch="true"
                  >
                    {{ $t("isUnlockDownload") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content :title="$t('subscriptions.category')">
          <b-row>
            <b-col>
              <b-card>
                <b-card-header class="head">
                  <b-card-title>{{ $t('contents.categories') }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col md="6">
                      <b-row>
                        <b-col md="5">
                          <h6 class="text-primary font-weight-bold mb-2">
                            {{ $t("code.allC") }}
                            <feather-icon
                              icon="PlusCircleIcon"
                              size="20"
                              class="text-success cursor-pointer"
                              @click="AddCat()"
                            />
                          </h6>
                        </b-col>
                        <b-col md="4">
                          <b-form-input
                            v-model="searchCategories"
                            :placeholder="$t('subscriptions.minCharact')"
                            minlength="3"
                            @keyup="searchCategorieByName"
                          />
                        </b-col>
                        <b-col>
                          <b-button
                            variant="primary"
                            @click="searchCategorieByName(null, true)"
                          >
                            <feather-icon
                              icon="SearchIcon"
                              size="16"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                      <draggable
                        ref="catlist"
                        :list="resCategories"
                        tag="ul"
                        :handle="resCategories.length == 0 ? '.handle' : false"
                        group="categorias"
                        class="scroll-list list-group list-group-flush cursor-move"
                      >
                        <b-list-group-item
                          v-for="c in resCategories"
                          :key="c.node.id"
                          tag="li"
                          @dragend="updateResCategories"
                        >
                          <div class="d-flex">
                            <div class="ml-25">
                              <b-card-text class="mb-0 font-weight-bold">
                                {{ c.node.name }}
                              </b-card-text>
                            </div>
                          </div>
                        </b-list-group-item>
                        <div
                          v-if="resCategories.length == 0"
                          class="mt-2"
                        >
                          {{ emptySearchCat }}
                        </div>
                      </draggable>
                    </b-col>
                    <b-col
                      md="6"
                      class="mt-sm-2 mt-md-0"
                    >
                      <h6 class="text-primary font-weight-bold mb-2">
                        {{ $t("subscriptions.selectedCategory") }}
                        <feather-icon
                          icon="XCircleIcon"
                          size="20"
                          class="text-danger cursor-pointer"
                          @click="QuitarCat()"
                        />
                      </h6>

                      <draggable
                        ref="catsel"
                        :list="categorySelected"
                        tag="ul"
                        group="categorias"
                        class="scroll-list list-group list-group-flush cursor-move"
                      >
                        <b-list-group-item
                          v-for="value in categorySelected"
                          :key="value.node.id"
                          tag="li"
                          @dragend="updateCategorySelected"
                        >
                          <div class="d-flex">
                            <div class="ml-25">
                              <b-card-text class="mb-0 font-weight-bold">
                                {{ value.node.name }}
                              </b-card-text>
                            </div>
                          </div>
                        </b-list-group-item>
                      </draggable>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </section>
</template>
<script>
import {
  VBTooltip,
  BFormSelectOption,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormRadio,
  BFormRadioGroup,
  BCardText,
  BOverlay,
  BListGroupItem,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, showToast, getSubgroups, checkPermissions,
} from '@/store/functions'

import draggable from 'vuedraggable'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  components: {
    BCardText,
    draggable,
    BListGroupItem,
    BOverlay,
    BFormRadio,
    BFormRadioGroup,
    FormWizard,
    TabContent,
    BFormSelectOption,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,

  },
  data() {
    return {
      subscriptionAppId: null,
      subscriptionId: null,
      searchCategories: '',
      required,
      planId: null,
      userData: getUserData(),
      name: null,
      interval: 'YEAR',
      price: null,
      intervalOptions: [
        { value: 'DAY', text: this.$t('code.Daily') },
        { value: 'WEEK', text: this.$t('code.Weekly') },
        { value: 'MONTH', text: this.$t('code.Monthly') },
        { value: 'YEAR', text: this.$t('code.Annual') },
        { value: 'LIFETIME', text: this.$t('code.Vitalicia'), disabled: this.isStripeGestioned },
      ],
      freeDays: 0,
      maxDevices: null,
      allCountry: null,
      country: null,
      isActive: false,
      isAdFree: false,
      isUnlockDownload: false,
      isUnlock4k: false,
      isStripeGestioned: false,
      categorySelected: [],
      allCats: [],
      resCategories: [],
      emptySearchCat: this.$t('subscriptions.nameCategory'),
      description: null,
      externalPayMethod: null,
      categorySuscriptions: [],
      allCat: [],
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
      },
      currency: null,
      show: false,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      checkPermissions,
    }
  },
  watch: {
    isStripeGestioned() {
      if (this.isStripeGestioned) this.interval = 'YEAR'
    },
  },

  mounted() {
    this.allCountryData()
    this.getPayMethod()
    this.allCategories()

    this.hasSubgroups = this.userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
      this.subgroup = this.userData.profile.subgroup
    }
  },
  methods: {
    fix(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'required') {
        return this.$t('purchases.nameFormValid')
      }
      if (keyName === 'min') {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    getRandomString(length) {
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
      return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('')
    },
    formSubmitted() {
      this.show = true
      const { id } = this.userData.profile.client
      if (this.planId === null && this.subscriptionId === null) {
        this.planId = this.getRandomString(16)
        this.subscriptionId = this.getRandomString(16)
      }
      axios
        .post('', {
          variables: {
            name: this.name,
            interval: this.interval,
            maxDevices: this.maxDevices,
            freeDays: this.freeDays,
            categorySuscriptions: this.getIdArray(this.categorySelected),
            description: this.description,
            isActive: this.isActive,
            isAdFree: this.isAdFree,
            isUnlock4k: this.isUnlock4k,
            isUnlockDownload: this.isUnlockDownload,
            isStripeGestioned: this.isStripeGestioned,
            subgroup: this.subgroup?.id,
          },
          query: `
            mutation($name: String,$interval: SubscriptionIntervalInput,$maxDevices: Int,$freeDays: Int
            ,$isStripeGestioned: Boolean,$isActive: Boolean,$isAdFree: Boolean,$isUnlock4k: Boolean,$isUnlockDownload: Boolean
            ,$description: String,$categorySuscriptions: [ID], $subgroup: ID){
               createSubscription(input:{
                    client: "${id}"
                    name: $name,
                    description: $description,
                    interval: $interval,
                    maxDevices: $maxDevices,
                    freeDays: $freeDays,
                    categorySuscriptions: $categorySuscriptions,
                    isStripeGestioned: $isStripeGestioned,
                    isActive: $isActive,
                    isAdFree: $isAdFree,
                    isUnlock4k: $isUnlock4k,
                    isUnlockDownload: $isUnlockDownload,
                    subgroup: $subgroup,
                    ${this.planId == null ? '' : `planId: "${this.planId}",`}
                    ${this.subscriptionAppId == null
    ? ''
    : `subscriptionAppId: "${this.subscriptionAppId}",`
}
                    ${this.subscriptionId == null
    ? ''
    : `subscriptionId: "${this.subscriptionId}",`
}
                   
                }){
                    subscription{
                    id
                    name
                    }
                  }
                }`,

        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.createsubscrip'), 1, this)
          this.$router.push('/platform/subscriptions')
        })
        .catch(() => {
          this.show = false
          showToast(this.$t('code.errorcreatesubscrip'), 2, this)
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.info.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormData() {
      return new Promise((resolve, reject) => {
        this.$refs.info2.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    allCountryData() {
      axios
        .post('', {
          query: `
          query{
            allCountry{
                edges{
                    node{
                    id
                    country
                    }
                }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.allCountry = res.data.data.allCountry.edges
        })
    },
    getPayMethod() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
              query{
                client(id:"${id}"){
                  externalPayMethod
                  currency
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.externalPayMethod = res.data.data.client.externalPayMethod
          this.currency = res.data.data.client.currency
        })
        .catch(() => {
          this.externalPayMethod = null
        })
    },
    QuitarCat() {
      if (this.categorySelected.length !== 0) {
        this.allCats = [...this.categorySelected, ...this.allCats]

        this.categorySelected = []

        // eslint-disable-next-line no-restricted-globals
        this.searchCategorieByName(event, true)
      }
    },
    AddCat() {
      if (this.resCategories.length !== 0) {
        this.categorySelected = [...this.categorySelected, ...this.resCategories]

        this.resCategories.forEach(element => {
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })

        this.resCategories = []
      }
    },
    getCategories() {
      this.showCategories = true

      axios
        .post('', {
          query: `
             query{
              allCategories(client:"${this.userData.profile.client.id}",name:"${this.searchCategories}"){
                  edges {
                      node {
                      id
                      name                    
                      }
                  }
              }                
          }   
          `,
        })
        .then(response => {
          messageError(response, this)

          const arr2 = response.data.data.allCategories.edges
          const array = []
          for (let i = 0; i < arr2.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.categorySelected.length; j += 1) {
              if (this.categorySelected[j].node.id === arr2[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              array.push(arr2[i])
            }
          }
          this.categories = arr2.slice()
          this.allCats = array.slice()
          this.resCategories = this.allCats

          this.emptySearchCat = this.$t('subscriptions.notFound')
          this.showCategories = false
        })
    },
    updateResCategories() {
      this.categorySelected.forEach(element => {
        this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
      })
    },
    updateCategorySelected() {
      const difference = this.resCategories.filter(
        x => !this.allCats.includes(x),
      )

      this.allCats = [...difference, ...this.allCats]
    },
    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    searchCategorieByName(event = null, pass = false) {
      let keycode = 0
      if (event) { keycode = event.keyCode || event.which }

      if (keycode === 13 || pass) {
        if (this.searchCategories.trim().length >= 3) {
          this.getCategories()
        } else {
          this.resCategories = []
          this.emptySearchCat = this.$t('subscriptions.nameCategory')
        }
      }
    },
    allCategories() {
      axios
        .post('', {
          query: `
            query{
              allCategories(client:"${this.userData.profile.client.id}"){
                edges{
                  node{
                    id
                    name
                    image
                  }
                }
              }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.allCat = res.data.data.allCategories.edges
        })
    },
    goToBackPage() {
      window.history.back()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#form-wizard-subscriptions .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}
</style>
