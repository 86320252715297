<template>
  <section id="wizard-vendor">
    <b-row class="mb-2">
      <b-col
        md="8"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('createVend') }}</h1>
        <h5 class="text-primary">
          {{ $t('confg') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-img
          v-if="languageService"
          height="20px"
          width="30px"
          :src="languageFlags(defaultLanguage)"
          class="mr-2"
        />
        <b-button
          variant="danger"
          @click="goBack()"
        >
          {{ $t("dataGeneric.goBack") }}
        </b-button>
      </b-col>
    </b-row>

    <b-overlay
      variant="transparent"
      :show="showData"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.create')"
        :back-button-text="$t('dataGeneric.goBack')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted()"
      >
        <tab-content
          :title="$t('contents.categoryData')"
          :before-change="validationFormInfo"
        >
          <!-- DATA VENDOR -->
          <validation-observer
            ref="dataRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("contents.categoryData") }}
                </h5>
                <small class="text-muted">
                  {{ $t('infoSeller') }}
                </small>
              </b-col>

              <b-col
                md="6"
                sm="12"
              >
                <b-form-group :label="$t('nameShop')+' *'">
                  <validation-provider
                    v-slot="validationContext"
                    name="vendorName"
                    :rules="{ required: true, min: 3 }"
                  >
                    <b-form-input
                      id="vendorName"
                      v-model="vendorName"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('nameShop')"
                      maxlength="150"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                sm="12"
              >
                <b-form-group :label="$t('firstName')+' *'">
                  <validation-provider
                    v-slot="validationContext"
                    name="name"
                    :rules="{ required: true, min: 3 }"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('firstName')"
                      maxlength="150"
                    />
                    <small class="text-danger">{{ fixName(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('administrators.lname')">
                  <b-form-input
                    id="lastName"
                    v-model="lastName"
                    :placeholder="$t('administrators.lname')"
                    maxlength="150"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('finalUsers.Phone')">
                  <validation-provider
                    v-slot="validationContext"
                    name="phone"
                    :rules="{ regex: /^\d{9}$/ }"
                  >
                    <b-form-input
                      id="phone"
                      v-model="phone"
                      :placeholder="$t('finalUsers.Phone')"
                      type="text"
                      :state="validationContext.errors.length > 0 ? false : null"
                      maxlength="9"
                      autocomplete="new-password"
                      @input="validationPhone(phone)"
                    />
                    <small class="text-danger">{{ msgPhone }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="'E-mail'">
                  <b-form-input
                    id="email"
                    v-model="email"
                    :placeholder="'E-mail'"
                    maxlength="100"
                    type="email"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="nif"
                >
                  <b-form-group label="NIF/ CIF/ NIE">
                    <b-form-input
                      id="nif"
                      v-model="nif"
                      placeholder="nif/ cif/ nie"
                      :state="validationContext.errors.length > 0 ? false : null"
                      type="text"
                      maxlength="9"
                      autocomplete="new-password"
                      @input="validationDni(nif)"
                    />
                    <small class="text-danger">{{ msgDni }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('externalRef')">
                  <b-form-input
                    id="reference"
                    v-model="reference"
                    :placeholder="$t('externalRefVend')"
                    maxlength="150"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="'Packlink api key'">
                  <b-form-input
                    id="packlinkApiKey"
                    v-model="packlinkApiKey"
                    placeholder="Packlink api key"
                    maxlength="150"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                class="align-self-center"
              >
                <b-form-checkbox
                  v-model="isActive"
                  switch
                >
                  {{ $t('dataGeneric.activeQ') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          :title="$t('locationVend')"
          :before-change="validationFormAddress"
        >
          <!-- ADDRESS VENDOR -->
          <validation-observer
            ref="addressRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t('finalUsers.Address') }}
                </h5>
                <small class="text-muted">
                  {{ $t('locationVend') }}
                </small>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('finalUsers.Address')">
                  <b-form-input
                    id="address"
                    v-model="address"
                    autocomplete="new-password"
                    maxlength="150"
                    :placeholder="$t('finalUsers.Address')"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('localidad')">
                  <b-form-input
                    id="city"
                    v-model="city"
                    :placeholder="$t('localidad')"
                    maxlength="150"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('finalUsers.Province')">
                  <b-form-input
                    id="province"
                    v-model="province"
                    :placeholder="$t('finalUsers.Province')"
                    maxlength="150"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="postal"
                  :rules="{ regex: /^\d{5}$/ }"
                >
                  <b-form-group :label="$t('finalUsers.Code')">
                    <b-form-input
                      id="postalCode"
                      v-model="postalCode"
                      :placeholder="$t('finalUsers.Code')"
                      :state="validationContext.errors.length > 0 ? false : null"
                      type="text"
                      maxlength="5"
                      autocomplete="new-password"
                      @input="validationPostal(postalCode)"
                    />
                    <small class="text-danger">{{ msgPostal }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('finalUsers.Country')">
                  <b-form-select
                    v-model="country"
                    class="mb-3"
                  >
                    <b-form-select-option
                      :value="null"
                      disabled
                    >
                      {{ $t('Seleccione un país') }}
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="coun in allCountry"
                      :key="coun.node.id"
                      :value="coun.node.id"
                    >
                      {{ coun.node.country }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          :title="$t('contents.categoryFiles')"
          :before-change="validationFormFiles"
        >
          <!-- ARCHIVOS DEL VENDOR -->
          <validation-observer
            ref="filesRules"
            tag="form"
          >
            <b-row>
              <b-col
                lg="11"
                class="ml-2 mb-2"
              >
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('resources.originMedia')"
                >
                  <b-form-select
                    v-model="mediaOriginSelected"
                    class="mr-1"
                    :options="mediaOriginOptions"
                    @change="onChange()"
                  />
                </b-form-group>
                <b-form-group class="m-1">
                  <label><strong>TV/Web</strong></label>
                  <b-row
                    style="justify-content: space-around;;"
                    align-v="stretch"
                  >
                    <image-element-vue
                      :image-origin="mediaOriginSelected == 'BUK' ? imageVendor : imageVendorUrl"
                      :media-origin-selected="mediaOriginSelected"
                      :image-type="'imageVendor'"
                      :is-required="true"
                      :can-delete="true"
                      :label="$t('contents.imgInternal')"
                      @saveImage="loadImage"
                    />
                    <image-element-vue
                      :image-origin="mediaOriginSelected == 'BUK' ? banner : bannerUrl"
                      :media-origin-selected="mediaOriginSelected"
                      :image-type="'banner'"
                      :label="$t('banner')"
                      :label-info="$t('vendorWebBannerSize')"
                      :can-delete="true"
                      @saveImage="loadImage"
                    />
                    <b-col cols="3" />
                  </b-row>
                </b-form-group>
                <b-form-group class="m-1">
                  <label><strong>{{ $t('Movil') }}</strong></label>
                  <b-row
                    style="justify-content: space-around;;"
                    align-v="stretch"
                  >
                    <image-element-vue
                      :image-origin="mediaOriginSelected == 'BUK' ? bannerMobile : bannerMobileUrl"
                      :media-origin-selected="mediaOriginSelected"
                      :image-type="'bannerMobile'"
                      :label="$t('bannerMobile')"
                      :label-info="$t('vendorMobileBannerSize')"
                      :can-delete="true"
                      @saveImage="loadImage"
                    />
                    <b-col cols="3" />
                    <b-col cols="3" />
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          :title="$t('Datos económicos')"
          :before-change="validationFormPrice"
        >
          <!-- PRICE DATA VENDOR -->
          <validation-observer
            ref="priceRules"
            tag="form"
          >
            <b-card>
              <b-form-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    {{ $t("Datos bancarios") }}
                  </h5>
                  <small class="text-muted">
                    {{ $t('DatosBancarios') }}
                  </small>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="bankName"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Nombre del banco')+' *'"
                      :invalid-feedback="$t('nameField')"
                    >
                      <b-form-input
                        id="bankName"
                        v-model="bankName"
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                        maxlength="150"
                        :placeholder="$t('Nombre del banco')"
                        required
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="iban"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('IBAN')+' *'"
                      :invalid-feedback="$t('ibanField')"
                    >
                      <b-form-input
                        id="iban"
                        v-model="iban"
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                        maxlength="150"
                        :placeholder="$t('IBAN')"
                        required
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group :label="$t('userExternal')">
                    <b-form-input
                      id="stripeUser"
                      v-model="stripeUser"
                      autocomplete="new-password"
                      maxlength="150"
                      :placeholder="$t('userExternal')"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>
            <b-card>
              <b-form-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    {{ $t('impuestos') }}
                  </h5>
                  <small class="text-muted">
                    {{ $t('impVendor') }}
                  </small>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="commissionValue"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('valor')+' *'"
                      :invalid-feedback="$t('commissionValue')"
                    >
                      <b-form-input
                        id="commissionValue"
                        v-model="commissionValue"
                        type="number"
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                        maxlength="150"
                        :placeholder="$t('valor')"
                        required
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  class="d-none"
                >
                  <b-form-group :label="$t('Tipo de comisión')">
                    <b-form-select
                      v-model="commissionType"
                      :options="[
                        { value: 'NON', text: $t('Ninguno') },
                        { value: 'FLA', text: $t('Fija') },
                        { value: 'PER', text: $t('Porcentaje') },
                      ]"
                      class="mr-1"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="vatNumber"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Impuestos')+' (%) *'"
                      :invalid-feedback="$t('taxes')"
                    >
                      <b-form-input
                        id="vatNumber"
                        v-model="vatNumber"
                        required
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        :placeholder="$t('Impuestos')+' (%)'"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  class="align-self-center"
                >
                  <b-form-checkbox
                    v-model="isFeatured"
                    switch
                  >
                    {{ $t("Es presentado") }}
                  </b-form-checkbox>
                </b-col>
              </b-form-row>
            </b-card>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </section>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  showToast, messageError, phoneNumber, identityDocument, postal,
} from '@/store/functions'
import {
  BFormCheckbox,
  BFormInput,
  BFormSelectOption,
  BFormGroup,
  BFormRow,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BImg,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'

import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import languageFlags from '@/@core/utils/languageFlags'
import ImageElementVue from '@/views/components/ImageElement.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    FormWizard,
    TabContent,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormSelectOption,
    BFormRow,
    BImg,
    BOverlay,
    ImageElementVue,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      vendorName: null,
      lastName: null,
      packlinkApiKey: '',
      phone: null,
      email: null,
      nif: null,
      bankName: null,
      iban: null,
      stripeUser: null,
      address: null,
      province: null,
      city: null,
      postalCode: null,
      country: null,
      allCountry: [],
      isActive: true,
      commissionValue: 0,
      commissionType: 'NON',
      isFeatured: false,
      vatNumber: 21,
      userData: getUserData(),
      id: this.$route.params.id,
      idSelectCard: null,
      imageVendor: null,
      name: null,
      reference: '',
      mediaOriginSelected: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      showData: false,
      msgDni: '',
      msgPhone: '',
      msgPostal: '',
      nifValidation: false,
      boolPhone: false,
      boolPostal: false,
      defaultLanguage: '',
      languageService: false,
      banner: null,
      bannerUrl: null,
      bannerMobile: null,
      bannerMobileUrl: null,
      imageVendorUrl: null,
    }
  },
  mounted() {
    this.countryList()
    this.getDefaultLanguage()
  },
  methods: {
    languageFlags,
    validationDni(dni) {
      identityDocument(dni).then(result => {
        this.nifValidation = result
        this.msgDni = ''
      }).catch(err => {
        this.nifValidation = true
        this.msgDni = err
      })
    },
    validationPhone(phone) {
      phoneNumber(phone).then(result => {
        this.boolPhone = result
        this.msgPhone = ''
      }).catch(err => {
        this.boolPhone = true
        this.msgPhone = err
      })
    },
    validationPostal(post) {
      postal(post).then(result => {
        this.boolPostal = result
        this.msgPostal = ''
      }).catch(err => {
        this.boolPostal = true
        this.msgPostal = err
      })
    },
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('purchases.nameFormValid')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    fixName(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('purchases.nameFormValid')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    countryList() {
      const query = `
                {
                    allCountry {
                    edges {
                        node {
                        id
                        country
                        }
                    }
                }
            }  
            `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)

          this.allCountry = result.data.data.allCountry.edges
        })
        .catch(err => { console.log(err) })
    },

    goBack() {
      window.history.back()
    },
    onChange() {
      if (this.mediaOriginSelected === 'BUK') {
        this.imageVendorUrl = null
        this.bannerUrl = null
        this.bannerMobileUrl = null
      } else {
        this.imageVendor = null
        this.banner = null
        this.bannerMobile = null
      }
    },
    formSubmitted() {
      this.showData = true

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
                mutation($imageVendorUrl: String,$bannerUrl: String, $banner:Upload!, $bannerMobile:Upload! ,$bannerMobileUrl: String) {
                    createVendors(input:{
                        name:"${this.name}",
                        vendorName:"${this.vendorName}",
                        packlinkApiKey:"${this.packlinkApiKey}",
                        client:"${this.userData.profile.client.id}",
                        ${this.lastName != null ? `lastName:"${this.lastName}",` : ''}
                        ${this.phone != null ? `phone:"${this.phone}",` : ''}
                        ${this.email != null ? `email:"${this.email}",` : ''}
                        ${this.nif != null ? `nif:"${this.nif}",` : ''}
                        ${this.province != null ? `province:"${this.province}",` : ''}
                        ${this.city != null ? `city:"${this.city}",` : ''}
                        ${this.address != null ? `address:"${this.address}",` : ''}
                        ${this.postalCode != null ? `postalCode:"${this.postalCode}",` : ''}
                        ${this.stripeUser != null ? `stripeUser:"${this.stripeUser}",` : ''}
                        ${this.country != null ? `country:"${this.country}",` : ''}
                        ${this.reference != null ? `reference:"${this.reference}",` : ''}
                        mediaLocation:${this.mediaOriginSelected},
                        imageVendorUrl: $imageVendorUrl,
                        bankName:"${this.bankName}",
                        iban:"${this.iban}",
                        vatNumber:${this.vatNumber},
                        isFeatured:${this.isFeatured},
                        commissionType:${this.commissionType},
                        commissionValue:${this.commissionValue},
                        isActive:${this.isActive},
                        bannerUrl: $bannerUrl,
                        bannerMobileUrl: $bannerMobileUrl,
                        banner: $banner,
                        bannerMobile: $bannerMobile,
                    }){
                        vendor{
                                id
                                name
                                
                            }
                        }
                    }
                `
      const variables = {
        imageVendorUrl: this.imageVendorUrl,
        bannerUrl: this.bannerUrl,
        bannerMobileUrl: this.bannerMobileUrl,
        banner: '',
        bannerMobile: '',
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      if (this.imageVendor) data.append('vendor_image', this.imageVendor)
      if (this.banner) data.append('banner', this.banner)
      if (this.bannerMobile) data.append('banner_mobile', this.bannerMobile)
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      axios.post('', data, { headers }, config).then(res => {
        messageError(res, this)

        showToast(
          this.$t('success'),
          1, this,
        )
        this.$router.push({ name: 'vendor' })
        this.showData = false
      })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.dataRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormFiles() {
      return new Promise((resolve, reject) => {
        this.$refs.filesRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormPrice() {
      return new Promise((resolve, reject) => {
        this.$refs.priceRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
              query{
              client(id:"${this.userData.profile.client.id}"){
                  defaultLanguage
                  availableLanguages
              }
          }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          try {
            const arr = availableLanguages.split('|')
            this.languageService = arr.length > 1
            // eslint-disable-next-line no-empty
          } catch (error) {

          }

          this.defaultLanguage = res.data.data.client.defaultLanguage
        })
        .catch(() => {
        })
    },
    loadImage(item) {
      const { data, type } = item
      console.log(type)
      switch (type) {
        case 'imageVendor':
          if (this.mediaOriginSelected === 'BUK') this.imageVendor = data
          else this.imageVendorUrl = data
          break
        case 'banner':
          if (this.mediaOriginSelected === 'BUK') this.banner = data
          else this.bannerUrl = data
          break
        case 'bannerMobile':
          if (this.mediaOriginSelected === 'BUK') this.bannerMobile = data
          else this.bannerMobileUrl = data
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "vue-context/src/sass/vue-context";

#wizard-vendor .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#wizard-vendor #add-new-category-sidebar {
  #wizard-vendor .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#wizard-vendor .base-image-input {
  display: block;
  /* width: 200px;
      height: 200px; */
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#wizard-vendor .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#wizard-vendor .placeholder:hover {
  background: #e0e0e0;
}

#wizard-vendor .file-input {
  display: none;
}

#wizard-vendor .final-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

#wizard-vendor .tamanio {
  width: 50%;
}
</style>
