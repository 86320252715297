<template>
  <section id="purchases">
    <b-overlay
      variant="transparent"
      :show="overlay"
    >
      <b-row>
        <b-col
          v-for="r in response"
          :key="r.node.id"
          class="mt-2"
          md="6"
          lg="3"
          xl="3"
          sm="12"
        >
          <b-card
            header-classes="bg-transparent"
            footer-classes="bg-transparent"
            body-classes="px-lg-7"
            class="border-0 text-center mb-2 custom-height cursor-pointer"
            @click="routeToEditView(r.node.id)"
          >
            <h4 class="ls-1 text-white py-2 mb-1 mt-1 text-center">
              {{ r.node.name }}
            </h4>

            <b-card-body>
              <div class="actions">
                <div class="action">
                  <br>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="20"
                      />
                    </template>

                    <b-dropdown-item @click.stop.prevent="routeToEditView(r.node.id)">
                      <feather-icon
                        icon="Edit2Icon"
                        size="18"
                      />
                      {{ $t("purchases.edit") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop.prevent="deletePurchases(r.node.id)">
                      <feather-icon
                        icon="DeleteIcon"
                        size="18"
                      />
                      {{ $t("purchases.delete") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <p v-if="!r.node.isManual">
                {{ r.node.price }}
                <strong>
                  {{ currencyType[currency] }}
                </strong>
              </p>
              <div
                v-if="!r.node.isManual"
                class="display-4 text-white"
              >
                {{ r.node.price }}{{ currencyType[currency] }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col v-if="vacio">
          {{ $t("purchases.empty") }}
        </b-col>
      </b-row>
    </b-overlay>

    <b-row>
      <b-col>
        <h3
          v-if="msg == true"
          class="text-center"
        >
          {{ $t("purchases.paidInternal") }}
        </h3>

        <h3
          v-else
          class="text-center"
        >
          {{ $t("purchases.paidExternal") }}
        </h3>
        <pay-content :is-manual="msg" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BOverlay,

} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import PayContent from './TablePay/PayPurchases.vue'

export default {
  components: {
    BCardBody,
    PayContent,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    msg: Boolean,
  },
  data() {
    return {
      response: null,
      vacio: false,
      overlay: false,
      currency: null,
      userData: getUserData(),
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
      },
    }
  },
  mounted() {
    this.getData()
    this.getPayMethod()
  },
  methods: {

    getData() {
      this.overlay = true
      axios
        .post('', {
          query: `
         query{
            allPurchases(client:"${this.userData.profile.client.id}", isManual:${this.msg}){
              edges{
                node{
                  id
                  name
                  price
                  isManual
                    inapppurchaseCategory{
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  inapppurchaseContent{
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }        
        }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.response = res.data.data.allPurchases.edges
          this.vacio = this.response.length === 0
          this.overlay = false
        })
    },
    routeToEditView(purchId) {
      this.$router.push({ name: 'purchases-edit', params: { id: purchId } })
    },
    deletePurchases(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),

        cancelButtonText: this.$t('dataGeneric.cancel'),

        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
          mutation{
            deletePurchases(id:"${id}"){
              found
              deletedId
            }
          }
        `,
            })
            .then(res => {
              messageError(res, this)

              showToast(this.$t('code.deletePur'), 1, this)

              this.getData()
            })
            .catch(() => {
              showToast(this.$t('code.deletePurErr'), 2, this)
            })
        }
      })
    },
    getPayMethod() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
              query{
                client(id:"${id}"){
                  externalPayMethod
                  currency
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.currency = res.data.data.client.currency
        })
        .catch(() => {
          this.currency = null
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-height {
  height: 290px;
}

#purchases .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#purchases .card-body h4 {
  font-size: 1.2rem !important;
}

.actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
</style>
