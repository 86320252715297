<template>
  <b-overlay
    variant="transparent"
    class="d-flex"
    :show="overlay"
    spinner-variant="primary"
    :opacity="1.00"
    :blur="'2px'"
    rounded="lg"
  >
    <b-card
      id="platformSelector"
      style="min-width: -webkit-fill-available"
    >
      <b-row class="mb-2">
        <b-col md="4">
          <h1>{{ $t('Plataformas') }}</h1>
          <h5 class="text-primary">
            {{ $t('selectPlat') }}
          </h5>
        </b-col>
        <b-col
          v-if="manualOrder"
          class="d-flex ml-4"
        >
          <b-button
            variant="outline-warning"
            disabled
          >
            {{ $t("contents.manualSortMode") }}
          </b-button>
        </b-col>
        <b-col>
          <div class="mt-1 float-right">
            <b-button
              v-if="!manualOrder"
              variant="primary"
              @click.stop.prevent="activateManualOrder()"
            >
              <span class="text-nowrap">
                <feather-icon
                  icon="FolderPlusIcon"
                  class="mr-50"
                />
                {{ $t("contents.sortManual") }}</span>
            </b-button>
            <b-button
              v-else
              variant="success"
              @click.stop.prevent="activateManualOrder()"
            >
              <span class="text-nowrap">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                {{ $t("contents.finalize") }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row
        cols="12"
        class="mb-2"
      >
        <b-col>
          <b-form-input
            id="search_platform"
            :placeholder="$t('Search')"
            autofocus
            @change="busqueda"
          />
        </b-col>
      </b-row>
      <b-row>
        <template v-if="manualOrder">
          <div
            ref="infiniteScroll"
            class="manualOrder body-scrollable"
            @scroll="handleScroll"
          >
            <draggable
              v-model="platforms"
              :disabled="buscar != null"
              class="row rounded"
              style="min-width: -webkit-fill-available"
            >
              <b-col
                v-for="platform in platforms"
                :id="platform.node.id + ' ' + platform.node.order"
                :key="platform.node.id"
                v-b-tooltip.hover.top="platform.node.name"
                cols="3"
                @dragend="updateOrder($event)"
              >
                <b-card
                  id="img"
                  class="cursor-pointer text-center embed-responsive embed-responsive-16by9"
                >
                  <b-img
                    rounded="lg"
                    :src="buildImageUrl(platform)"
                    style="object-fit: contain;"
                    class="embed-responsive-item"
                  />
                  <b-card-body>
                    <div class="actions">
                      <feather-icon
                        v-if="platform.node.order < maxOrder"
                        class="cursor-pointer"
                        icon="ArrowUpIcon"
                        size="20"
                        @click.stop.prevent="movePlatformToTop(platform)"
                      />
                    </div>
                  </b-card-body>
                </b-card>
                {{ platform.node.displayName }}
              </b-col>
            </draggable>
          </div>
        </template>
        <template v-else>
          <div
            ref="infiniteScroll"
            class="body-scrollable"
            @scroll="handleScroll"
          >
            <b-col
              v-for="platform in platforms"
              :id="platform.node.id + ' ' + platform.node.order"
              :key="platform.node.id"
              v-b-tooltip.hover.top="platform.node.name"
              cols="3"
            >
              <b-card
                id="img"
                class="cursor-pointer text-center mb-2 embed-responsive embed-responsive-16by9"
                @click="onRowSelected(platform)"
              >
                <b-img
                  rounded="lg"
                  :src="buildImageUrl(platform)"
                  style="object-fit: contain;"
                  class="embed-responsive-item"
                />
              </b-card>
              {{ platform.node.displayName }}
            </b-col>
          </div>
        </template>
      </b-row>
      <div
        v-if="hasMore"
        class="text-center"
      >
        <small>{{ $t('downToSeePlat') }}</small><feather-icon
          icon="ArrowDownIcon"
          size="20"
        />
      </div>
    </b-card>
  </b-overlay>
</template>

<script>

import {
  BCard,
  BCol,
  BRow,
  BImg,
  VBTooltip,
  BFormInput,
  BOverlay,
  BButton,
  BCardBody,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { clientNode } from '@/auth/utils'
import draggable from 'vuedraggable'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BFormInput,
    BImg,
    BCard,
    BCardBody,
    BCol,
    BRow,
    BOverlay,
    BButton,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      videojs: null,
      video: false,
      fileUrl: null,
      name: null,
      selected: null,
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      idOrig: null,
      platforms: [],
      currentParentFolder: null,
      tableColumns: [
        { key: 'logo', label: 'Logo' },
        { key: 'platform', label: 'Plataforma' },
      ],
      scrollPage: 1,
      perPage: 15,
      currentPage: 1,
      rows: null,
      buscar: null,
      manualOrder: false,
      hasMore: true,
      overlay: false,
      maxOrder: 0,
    }
  },

  mounted() {
    const scrollContainer = this.$refs.infiniteScroll
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', this.handleScroll)
    }
    this.handleScroll()
  },
  destroyed() {
    const scrollContainer = this.$refs.infiniteScroll
    if (scrollContainer) {
      scrollContainer.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    handleScroll() {
      const element = this.$refs.infiniteScroll

      const containerHeight = element.clientHeight // Height of the scroll container
      const { scrollHeight } = element // Total height of the scrollable content
      const { scrollTop } = element

      if (scrollTop + containerHeight + 1 >= scrollHeight && this.hasMore && !this.overlay) {
        this.fetchData()
        this.overlay = true
      }
    },
    seleccionar() {
      this.$emit('client', this.selected)
    },
    onRowSelected(data) {
      this.selected = data.node
      this.seleccionar()
    },
    buildImageUrl(folder) {
      return folder.node.logo == null || folder.node.logo.length === 0
        ? noCover
        : folder.node.logo
    },

    fechasSplit(value) {
      try {
        const fecha = value
        const nueva = fecha.split(' ')[0]
        const format = nueva.split('-')
        return `${format[2].split('T')[0]}-${format[1]}-${format[0]}`
      } catch (error) {
        return null
      }
    },
    fetchData(reload = false) {
      this.overlay = true
      const { scrollPage } = this

      const element = this.$refs.infiniteScroll
      const containerHeight = element.clientHeight // Height of the scroll container
      const { scrollTop } = element
      const scrollY = containerHeight + scrollTop

      let actualPage = scrollPage
      if (reload) {
        this.platforms = []
        actualPage = 1
      }
      this.getRootOffset(reload, scrollPage, actualPage, scrollY)
    },
    getRootOffset(reload, scrollPage, actualPage, scroll) {
      axios
        .post('', {
          query: `
         query {
                allClients(
                  orderBy:"-order",
                  first:${this.perPage},
                  offset:${this.perPage * (actualPage - 1)},
                  ${this.buscar != null ? `name_Icontains: "${this.buscar}",` : ''}                 
                  
                  ){
                pageInfo{
                  hasPreviousPage
                  hasNextPage
                }
                totalCount
                edgeCount
                edges{                  
                    node{
                      ${clientNode()}
                      name
                      order
                    }
                  }
                  }
                }
        `,
        })
        .then(response => {
          messageError(response, this)

          const clients = response.data.data.allClients.edges
          this.platforms = [...this.platforms, ...clients]

          this.rows += response.data.data.allClients.totalCount

          if (scrollPage === 1) this.maxOrder = this.platforms[0].node.order
          if (!reload) this.scrollPage += 1
          this.hasMore = response.data.data.allClients.pageInfo.hasNextPage

          if (actualPage < (scrollPage - 1)) {
            this.getRootOffset(reload, scrollPage, actualPage + 1, scroll)
          } else {
            if (scroll !== null) {
              this.$refs.infiniteScroll.scrollTo(0, scroll)
            }
            this.overlay = false
          }
        }).catch(() => {
        })
    },

    busqueda(data) {
      this.buscar = data || null
      this.fetchData(true)
    },
    activateManualOrder() {
      this.manualOrder = !this.manualOrder
    },
    async movePlatformToTop(platform) {
      try {
        this.overlay = true

        const { order } = platform.node

        let allPlatforms = []
        if (this.buscar) {
          await this.fetchAllClients(this.maxOrder - order, 0, []).then(result => {
            allPlatforms = [...result]
          })
        } else {
          allPlatforms = this.platforms
        }
        const index = allPlatforms.findIndex(obj => obj.node.order === order)
        if (index > -1) {
          allPlatforms.splice(index, 1)
          allPlatforms.unshift(platform)
        } else {
          throw new Error(this.$t('platformOrderError'))
        }

        const arrayNum = []
        const arrayId = []
        allPlatforms.forEach((item, i) => {
          arrayId.push(item.node.id)
          arrayNum.push(i)
        })

        const batchSize = 100 // Max elements per batch
        const batches = []
        for (let i = 0; i < arrayId.length; i += batchSize) {
          batches.push({
            ids: arrayId.slice(i, i + batchSize),
            orders: arrayNum.slice(i, i + batchSize),
          })
        }

        await this.updateClientsBatch(batches, 0).then(() => {
          showToast(this.$t('clientOrderSuccess'), 1, this)
          this.fetchData(true)
        }).catch(error => {
          console.error(error)
          showToast(this.$t('error'), 0, this)
        })
      } catch (error) {
        this.overlay = false
        console.error(error)
        showToast(this.$t('error'), 0, this)
      }
    },

    async updateClientsBatch(batches, currentBatchIndex) {
      if (currentBatchIndex >= batches.length) return

      const batch = batches[currentBatchIndex]
      let query = 'mutation {'
      batch.ids.forEach((id, i) => {
        query += `
      m${i + 1}: updateClient(id: "${id}", input: { order: ${this.maxOrder - batch.orders[i]} }) {
        client {
          id
          name
          order
        }
      }
    `
      })
      query += '}'

      try {
        await axios
          .post('', {
            query,
          }).then(res => {
            messageError(res, this)
          })
      } catch (error) {
        console.error(`Error processing batch ${currentBatchIndex}:`, error)
        showToast(this.$t('error'), 0, this)
        return
      }

      await this.updateClientsBatch(batches, currentBatchIndex + 1)
    },
    updateOrder(event) {
      if (this.buscar != null) return
      this.overlay = true
      const arrayNum = []
      const arrayId = []
      const evento = event.target.parentNode.childNodes
      for (let i = 0; i < evento.length; i += 1) {
        if (evento[i]) {
          arrayId.push(evento[i].id.split(' ')[0])
          arrayNum.push(evento[i].id.split(' ')[1])
        }
      }
      arrayNum.sort((a, b) => a - b)

      const dataArr = new Set(arrayNum)

      const result = [...dataArr]
      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }
      result.reverse()
      let query = ` mutation
      {`

      for (let i = 0; i < result.length; i += 1) {
        query += `
        m${i + 1}: updateClient(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
                    client{
                      id
                      name
                      order
                    }
                  }
                `
      }
      query += `
      }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.overlay = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.overlay = false
        })
    },
    async fetchAllClients(platformIndex = 0, offset = 0, allPlatforms = []) {
      const batchSize = 300
      let currentIndex = platformIndex
      let currentPlatforms = []

      try {
        const elementsToFetch = Math.min(batchSize, currentIndex + 1)
        // Fetch the current batch
        const response = await axios.post('', {
          query: `
      query {
        allClients(
          orderBy: "-order",
          first: ${elementsToFetch},
          offset: ${offset},
        ) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              ${clientNode()}
              name
              order
            }
          }
        }
      }
    `,
        })

        // Error handling
        messageError(response, this)

        // Extract the current batch of clients
        const data = response.data.data.allClients
        const clients = data.edges

        // Add the current batch to the cumulative list
        currentPlatforms = [...allPlatforms, ...clients]
        currentIndex -= batchSize

        // Check if there are more pages
        if (data.pageInfo.hasNextPage && currentIndex > 0) {
          // Recursive call with updated offset
          return this.fetchAllClients(currentIndex, offset + batchSize, currentPlatforms)
        }
        return currentPlatforms
      } catch (error) {
        console.error('Error fetching clients:', error)
        showToast(this.$t('error'), 0, this)
        return [] // Return an empty array on error
      }
    },
  },

}
</script>

<style lang="scss">
.manualOrder {
  border: 5px dashed #e69d5d;
  background: repeating-linear-gradient(-55deg,
      #283046,
      #283046 10px,
      #161d31 10px,
      #161d31 20px);
}

#avatar {
  width: 10rem;
  height: 5.5rem;
}

#contentsSelector .td {
  width: 50px;
  height: 100px;
}

#contentsSelector .folder {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

#contentsSelector .dropContainer {
  border: 3px dashed;
}

#contentsSelector .card-width {
  width: 200px;
}

#contentsSelector .list-group-item {
  transition: all 1s;
}

.body-scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 550px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.overlay-container {
  position: fixed;
  /* Posición fija en la ventana del navegador */
  top: 0;
  /* Coloca el contenedor en la parte superior */
  left: 130px;
  /* Coloca el contenedor en el lado izquierdo */
  width: 100%;
  /* Ancho completo */
  height: 100%;
  /* Altura completa */
  display: flex;
  /* Activa el modelo de caja flexible */
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  z-index: 9999;
  /* Z-index alto para asegurar que esté por encima de otros elementos */
}

 .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  //z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  /*  -webkit-justify-content: flex-end;
  justify-content: flex-end; */
  -webkit-justify-content: space-between;
  justify-content: right;
}

</style>
