<template>
  <section>
    <b-row class="justify-content-md-end m-2">
      <b-col
        md="11"
        class=""
      >
        <b-link
          class="brand-logo"
          @click="goInit()"
        >
          <b-img
            :src="require('@/assets/images/logo/interactvty-logo.png')"
            fluid
            style="height: 50px; opacity: 50%;"
            alt="placeholder"
          />
        </b-link>
      </b-col>
      <b-col md="1">
        <b-button
          variant="outline-danger"
          @click="goInit()"
        >
          {{ $t('dataGeneric.cancel') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row
      class="justify-content-md-center"
      align-v="center"
    >
      <b-col
        md="8"
        class="m-5"
      >
        <b-card
          id="wizardCategory"
          no-body
        >
          <b-card-header>
            <b-card-title>
              {{ $t("createPlatform") }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-overlay
              variant="transparent"
              :show="show"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
            >
              <form-wizard
                color="#7367F0"
                :title="null"
                :subtitle="null"
                shape="square"
                :finish-button-text="$t('dataGeneric.save')"
                :back-button-text="$t('dataGeneric.previous')"
                :next-button-text="$t('next')"
                class="mb-3"
                @on-complete="formSubmitted"
              >
                <tab-content
                  :title="$t('purchases.label3')"
                  :before-change="validationForm"
                >
                  <validation-observer
                    ref="accountRules"
                    tag="form"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        class="mb-2"
                      >
                        <h5 class="mb-0">
                          {{ $t("settingTabs.general") }}
                        </h5>
                        <small class="text-muted">
                          {{ $t("infoPlatform") }}
                        </small>
                      </b-col>
                    </b-row>
                    <b-row>
                      <image-element-vue
                        :image-origin="imageLogoPr"
                        :image-type="'imageLogoPr'"
                        :can-delete="true"
                        md-length="4"
                        :label="$t('branding.span')"
                        @deleteImage="deleteImage"
                        @saveImage="loadImage"
                      />
                      <b-col>
                        <b-row>
                          <b-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="displayName"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('dataGeneric.name') + ' *'"
                                :invalid-feedback="$t('required')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <b-form-input
                                  id="displayName"
                                  v-model="displayName"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="$t('dataplatform.name')"
                                  maxlength="150"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('idPlatform') + ' *'">
                              <validation-provider
                                v-slot="validationContext"
                                name="name"
                                rules="required|existPlatform"
                              >
                                <b-form-input
                                  id="name"
                                  v-model="name"
                                  :state="validationContext.errors.length > 0 ? false : null"
                                  :placeholder="$t('dataplatform.id')"
                                  maxlength="150"
                                  @input="checkNamePlatform()"
                                />
                                <small class="text-danger">{{ fix(validationContext) }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('dataplatform.type')">
                              <b-form-select
                                v-model="selectType"
                                :options="types"
                              />
                            </b-form-group>
                            <!-- <b-form-group :label="$t('apiDomainName') + ' *'">
                              <validation-provider
                                v-slot="validationContext"
                                name="name"
                                rules="required"
                              >
                                <b-form-input
                                  id="apiName"
                                  v-model="apiName"
                                  :state="validationContext.errors.length > 0 ? false : null"
                                  :placeholder="$t('apiDomainName')"
                                  maxlength="150"
                                />
                                <small class="text-danger">{{ fix(validationContext) }}</small>
                              </validation-provider>
                            </b-form-group> -->
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="ml-0">
                        <b-form-group :label="$t('Moneda')">
                          <b-form-select
                            v-model="currency"
                            :options="currencyType"
                          />
                        </b-form-group>
                        <b-form-group :label="$t('countryPlatf')">
                          <b-form-select
                            v-model="country"
                          >
                            <b-form-select-option
                              v-for="cnt in countryOptions"
                              :key="cnt.id"
                              :value="cnt.id"
                            >
                              {{ cnt.country }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                        <b-form-group :label="$t('idiomaDefe')">
                          <b-form-select
                            v-model="defaultLanguage"
                            :placeholder="$t('idiomaDefe')"
                          >
                            <b-form-select-option
                              v-for=" loc in locales "
                              :key="loc.locale"
                              :value="formatToSaveDB(loc.locale)"
                            >
                              {{ loc.name }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                        <b-form-group :label="$t('idiomaPlat')">
                          <b-form-tags
                            v-model="value"
                            size="lg"
                            add-on-change
                            no-outer-focus
                          >
                            <template
                              #default="{
                                tags,
                                inputAttrs,
                                inputHandlers,
                                removeTag
                              }"
                            >
                              <ul
                                v-if="tags.length > 0"
                                class="list-inline d-inline-block mb-1"
                              >
                                <li
                                  v-for=" tag in tags "
                                  :key="tag"
                                  class="list-inline-item"
                                >
                                  <b-form-tag
                                    :title="tag"
                                    :disabled="disabledDefault(tag)"
                                    variant="primary"
                                    @remove="removeTag(tag)"
                                  >
                                    {{ tag }}
                                  </b-form-tag>
                                </li>
                              </ul>
                              <b-form-select
                                v-bind="inputAttrs"
                                size="lg"
                                :options="availableOptions"
                                v-on="inputHandlers"
                              >
                                <template #first>
                                  <option
                                    disabled
                                    value=""
                                  >
                                    {{ $t('disponibleLang') }}...
                                  </option>
                                </template>
                              </b-form-select>
                            </template>
                          </b-form-tags>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!--  <b-row class="match-height">
                      <b-col>
                          <b-card no-body>
                            <b-card-header>
                              <b-card-title>{{ $t("dataplatform.countryList")
                              }}
                                <feather-icon v-b-tooltip.hover.top.v-primary="'Paises donde está disponible la plataforma'
                                  " icon="InfoIcon" />
                              </b-card-title>
                            </b-card-header>
                            <b-col class="ml-0">
                              <b-form-group>

                                <b-form-tags v-model="value" size="lg" add-on-change no-outer-focus @input="newCountry">
                                  <template v-slot="{
                                    tags,
                                    inputAttrs,
                                    inputHandlers,
                                    disabled,
                                    removeTag
                                  }">
                                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-1">
                                      <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                        <b-form-tag :title="tag" :disabled="disabled" variant="primary"
                                          @remove="removeTag(tag)">
                                          {{ tag }}
                                        </b-form-tag>
                                      </li>
                                    </ul>
                                    <b-form-select v-bind="inputAttrs" size="lg"
                                      :disabled="disabled || availableOptions.length === 0"
                                      :options="availableOptions" v-on="inputHandlers">
                                      <template v-slot:first>

                                        <option disabled value="">
                                          {{ $t("dataplatform.countryAva") }}
                                        </option>
                                      </template>
                                    </b-form-select>
                                  </template>
                                </b-form-tags>
                              </b-form-group>
                            </b-col>
                          </b-card>
                      </b-col>
                    </b-row> -->
                  </validation-observer>
                </tab-content>
                <tab-content :title="$t('dataplatform.description')">
                  <b-row class="justify-content-center">
                    <b-col
                      v-for="( funcionalidad, index ) in funcionalidades "
                      :key="index + '_' + funcionalidad.id"
                      lg="3"
                      class="card-platform-feature cursor-pointer m-1"
                    >
                      <b-media
                        no-body
                        @click="funcionalidad.value = !funcionalidad.value"
                      >
                        <b-media-aside class="mr-90">
                          <b-avatar
                            size="70"
                            :variant="funcionalidad.value ? 'light-success' : 'light-danger'
                            "
                          >
                            <feather-icon
                              size="30"
                              :icon="funcionalidad.icon"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h6 class="">
                            {{ funcionalidad.titulo }}
                            <feather-icon
                              v-b-tooltip.hover.top.v-primary
                              :title="funcionalidad.descripcion"
                              icon="InfoIcon"
                            />
                          </h6>
                          <small>{{ funcionalidad.descripcion }}</small>
                        </b-media-body>
                      </b-media>
                    </b-col>
                  </b-row>
                </tab-content>
                <tab-content
                  :title="$t('settingTabs.data')"
                  :before-change="validationFormBucket"
                >
                  <b-tabs v-model="tabIndex">
                    <b-tab
                      id="s3"
                      title-item-class="w-25"
                    >
                      <template #title>
                        <b-card
                          :img-src="require('@/assets/images/backend/buckets/s3.png')"
                          class="bg-light"
                          overlay
                        />
                      </template>
                      <b-tabs
                        content-class="mt-3"
                        justified
                        :pills="productionEnviroment"
                      >
                        <validation-observer
                          ref="bucketRulesS3"
                          tag="form"
                        >
                          <b-tab
                            id="fill"
                            :title="$t('rellenarBucket')"
                          >
                            <b-row>
                              <b-col
                                md="12"
                                class="mt-2"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="bucketName"
                                  rules="required"
                                >
                                  <b-form-group
                                    class="mb-2 mr-1"
                                    :label="$t('almacenamiento.nameBuck') + ' *'"
                                    :invalid-feedback="$t('required')"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <b-form-input
                                      v-model="bucketName"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('almacenamiento.nameBuck')"
                                      class="mr-1"
                                      maxlength="150"
                                      autocomplete="new-password"
                                    />
                                  </b-form-group>
                                </validation-provider>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="bucketAccessKey"
                                  rules="required"
                                >
                                  <b-form-group
                                    class="mb-2 mr-1"
                                    :label="$t('almacenamiento.buckA3') + ' *'"
                                    :invalid-feedback="$t('required')"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <b-form-input
                                      v-model="bucketAccessKey"
                                      :placeholder="$t('almacenamiento.buckA3')"
                                      maxlength="150"
                                      :state="errors.length > 0 ? false : null"
                                      class="mr-1"
                                      autocomplete="new-password"
                                    />
                                  </b-form-group>
                                </validation-provider>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="bucketSecretKey"
                                  rules="required"
                                >
                                  <b-form-group
                                    class="mb-2 mr-1"
                                    :state="errors.length > 0 ? false : null"
                                    :invalid-feedback="$t('required')"
                                    :label="$t('almacenamiento.buckS3') + ' *'"
                                  >
                                    <b-input-group class="mr-1">
                                      <b-form-input
                                        v-model="bucketSecretKey"
                                        :placeholder="$t('almacenamiento.buckS3')"
                                        maxlength="150"
                                        :state="errors.length > 0 ? false : null"
                                        :type="passwordFieldType"
                                        autocomplete="new-password"
                                      />
                                      <b-input-group-append is-text>
                                        <feather-icon
                                          :icon="passwordToggleIcon"
                                          class="cursor-pointer"
                                          @click="togglePasswordVisibility"
                                        />
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>
                                </validation-provider>

                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('almacenamiento.buckReg')"
                                >
                                  <b-form-select
                                    v-model="bucketRegion"
                                    class="mr-1"
                                    :options="bucketRegionOptions"
                                    :placeholder="$t('almacenamiento.selectReg')"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  label="CloudFront"
                                >
                                  <b-form-checkbox
                                    v-model="isCloudfront"
                                    switch
                                  />
                                </b-form-group>
                                <validation-provider
                                  v-if="isCloudfront"
                                  v-slot="{ errors }"
                                  name="cloudfrontUrl"
                                  rules="required"
                                >
                                  <b-form-group
                                    class="mb-2 mr-1"
                                    label="URL cloudFront *"
                                    :state="errors.length > 0 ? false : null"
                                    :invalid-feedback="$t('required')"
                                  >
                                    <b-form-input
                                      v-model="cloudfrontUrl"
                                      maxlength="150"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="'URL cloudFront'"
                                      autocomplete="new-password"
                                    />
                                  </b-form-group>
                                </validation-provider>

                                <b-form-group
                                  v-if="isCloudfront"
                                  class="mb-2 mr-1"
                                  :label="$t('distributionId')"
                                >
                                  <b-form-input
                                    v-model="distributionId"
                                    :placeholder="$t('distributionId')"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-tab>
                          <b-tab
                            v-if="productionEnviroment"
                            id="create"
                            :title="$t('crearBucket')"
                          >
                            <b-row>
                              <b-col>
                                <b-form-group :label="$t('apiDomainName') + ' *'">
                                  <validation-provider
                                    v-slot="validationContext"
                                    name="name"
                                    rules="required"
                                  >
                                    <b-form-input
                                      id="apiName"
                                      v-model="apiName"
                                      :state="validationContext.errors.length > 0 ? false : null"
                                      :placeholder="$t('apiDomainName')"
                                      maxlength="150"
                                    />
                                    <small class="text-danger">{{ fix(validationContext) }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="bucketNameCreate"
                                  rules="required"
                                >
                                  <b-form-group
                                    class="mb-2 mr-1"
                                    :invalid-feedback="$t('required')"
                                    :state="errors.length > 0 ? false : null"
                                    :label="$t('almacenamiento.nameBuck') + ' *'"
                                  >
                                    <b-form-input
                                      v-model="bucketName"
                                      disabled
                                      :state="errors.length > 0 ? false : null"

                                      :placeholder="$t('almacenamiento.nameBuck')"
                                      class="mr-1"
                                      maxlength="150"
                                      autocomplete="new-password"
                                    />
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="bucketAccessKeyCreate"
                                  rules="required"
                                >
                                  <b-form-group
                                    class="mb-2 mr-1"
                                    :invalid-feedback="$t('required')"
                                    :state="errors.length > 0 ? false : null"
                                    :label="$t('almacenamiento.buckA3') + ' *'"
                                  >
                                    <b-form-input
                                      v-model="bucketAccessKey"
                                      :placeholder="$t('almacenamiento.buckA3')"
                                      maxlength="150"
                                      class="mr-1"
                                      :state="errors.length > 0 ? false : null"
                                      disabled
                                      autocomplete="new-password"
                                    />
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="bucketSecretKeyCreate"
                                  rules="required"
                                >
                                  <b-form-group
                                    class="mb-2 mr-1"
                                    :label="$t('almacenamiento.buckS3') + ' *'"
                                    :state="errors.length > 0 ? false : null"
                                    :invalid-feedback="$t('required')"
                                  >
                                    <b-input-group class="mr-1">
                                      <b-form-input
                                        v-model="bucketSecretKey"
                                        :placeholder="$t('almacenamiento.buckS3')"
                                        maxlength="150"
                                        disabled
                                        :state="errors.length > 0 ? false : null"

                                        :type="passwordFieldType"
                                        autocomplete="new-password"
                                      />
                                      <b-input-group-append is-text>
                                        <feather-icon
                                          :icon="passwordToggleIcon"
                                          class="cursor-pointer"
                                          @click="togglePasswordVisibility"
                                        />
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('almacenamiento.buckReg')"
                                >
                                  <b-form-select
                                    v-model="bucketRegion"
                                    class="mr-1"
                                    disabled
                                    :options="bucketRegionOptions"
                                    :placeholder="$t('almacenamiento.selectReg')"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="cloudfrontUrlCreate"
                                  rules="required"
                                >
                                  <b-form-group
                                    class="mb-2 mr-1"
                                    :invalid-feedback="$t('required')"
                                    label="URL cloudFront *"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <b-form-input
                                      v-model="cloudfrontUrl"
                                      maxlength="150"
                                      :state="errors.length > 0 ? false : null"

                                      disabled
                                      :placeholder="'URL cloudFront'"
                                      autocomplete="new-password"
                                    />
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('distributionId')"
                                >
                                  <b-form-input
                                    v-model="distributionId"
                                    disabled
                                    :placeholder="$t('distributionId')"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row class="mt-1 mb-2">
                              <b-col>
                                <b-button
                                  block
                                  variant="success"
                                  :disabled="isEmpty(apiName)"
                                  @click="createBucket()"
                                >
                                  {{ $t('crearBucket') }}
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-tab>
                        </validation-observer>
                      </b-tabs>
                    </b-tab>
                    <b-tab
                      id="azure"
                      title-item-class="w-25"
                    >
                      <template #title>
                        <b-card
                          class="bg-light"
                          :img-src="require('@/assets/images/backend/buckets/azure.png')"
                          overlay
                        />
                      </template>
                      <validation-observer
                        ref="bucketRulesAzure"
                        tag="form"
                      >
                        <b-row>
                          <b-col
                            md="12"
                            class="mt-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="azureClientId"
                              rules="required"
                            >
                              <b-form-group
                                class="mb-2 mr-1"
                                :invalid-feedback="$t('required')"
                                :state="errors.length > 0 ? false : null"
                                :label="$t('almacenamiento.clientId') + ' *'"
                              >
                                <b-input-group class="mr-1">
                                  <b-form-input
                                    v-model="azureClientId"
                                    maxlength="250"
                                    :type="passwordFieldType"
                                    :state="errors.length > 0 ? false : null"
                                    autocomplete="new-password"
                                    :placeholder="$t('almacenamiento.clientId')"
                                  />
                                  <b-input-group-append is-text>
                                    <feather-icon
                                      :icon="passwordToggleIcon"
                                      class="cursor-pointer"
                                      @click="togglePasswordVisibility"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              name="azureAccount"
                              rules="required"
                            >
                              <b-form-group
                                class="mb-2 mr-1"
                                :label="$t('almacenamiento.azure') + ' *'"
                                :invalid-feedback="$t('required')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <b-form-input
                                  id="azureAccount"
                                  v-model="azureAccount"
                                  :state="errors.length > 0 ? false : null"
                                  class="mr-1"
                                  maxlength="150"
                                  name="azureAccount"
                                  :placeholder="$t('almacenamiento.azure')"
                                  autocomplete="new-password"
                                />
                              </b-form-group>
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              name="azureContainer"
                              rules="required"
                            >
                              <b-form-group
                                class="mb-2 mr-1"
                                :invalid-feedback="$t('required')"
                                :state="errors.length > 0 ? false : null"
                                :label="$t('almacenamiento.containtAz') + ' *'"
                              >
                                <b-form-input
                                  id="azureContainer"
                                  v-model="azureContainer"
                                  maxlength="150"
                                  :state="errors.length > 0 ? false : null"
                                  class="mr-1"
                                  name="azureContainer"
                                  :placeholder="$t('almacenamiento.containtAz')"
                                  autocomplete="new-password"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </b-tab>
                    <b-tab
                      id="vimeo"
                      title-item-class="w-25"
                    >
                      <template #title>
                        <b-card
                          class="bg-light"
                          :img-src="require('@/assets/images/backend/buckets/vimeo.png')"
                          overlay
                        />
                      </template>
                      <validation-observer
                        ref="bucketRulesVimeo"
                        tag="form"
                      >
                        <b-row>
                          <b-col
                            md="12"
                            class="mt-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="vimeoClientId"
                              rules="required"
                            >
                              <b-form-group
                                class="mb-2 mr-1"
                                :invalid-feedback="$t('required')"
                                :state="errors.length > 0 ? false : null"
                                :label="$t('almacenamiento.clientIdVimeo') + ' *'"
                              >
                                <b-form-input
                                  v-model="vimeoClientId"
                                  maxlength="150"
                                  class="mr-1"
                                  :state="errors.length > 0 ? false : null"
                                  autocomplete="new-password"
                                  :placeholder="$t('almacenamiento.clientIdVimeo')"
                                />
                              </b-form-group>
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              name="vimeoClientSecret"
                              rules="required"
                            >
                              <b-form-group
                                class="mb-2 mr-1"
                                :invalid-feedback="$t('required')"
                                :state="errors.length > 0 ? false : null"
                                :label="$t('almacenamiento.secretVimeo') + ' *'"
                              >
                                <b-input-group class="mr-1">
                                  <b-form-input
                                    v-model="vimeoClientSecret"
                                    :state="errors.length > 0 ? false : null"
                                    maxlength="150"
                                    :type="passwordFieldType"
                                    :placeholder="$t('almacenamiento.secretVimeo')"
                                    autocomplete="new-password"
                                  />
                                  <b-input-group-append is-text>
                                    <feather-icon
                                      :icon="passwordToggleIcon"
                                      class="cursor-pointer"
                                      @click="togglePasswordVisibility"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              name="vimeoAccessToken"
                              rules="required"
                            >
                              <b-form-group
                                class="mb-2 mr-1"
                                :invalid-feedback="$t('required')"
                                :state="errors.length > 0 ? false : null"
                                :label="$t('almacenamiento.accessVimeo') + ' *'"
                              >
                                <b-form-input
                                  v-model="vimeoAccessToken"
                                  class="mr-1"
                                  :state="errors.length > 0 ? false : null"
                                  autocomplete="new-password"
                                  maxlength="150"
                                  :placeholder="$t('almacenamiento.accessVimeo')"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </b-tab>
                    <b-tab
                      id="odmedia"
                      title-item-class="w-25"
                    >
                      <template #title>
                        <b-card
                          class="bg-light"
                          :img-src="require('@/assets/images/backend/buckets/odmedia.png')"
                          overlay
                        />
                      </template>
                      <validation-observer
                        ref="bucketRulesODM"
                        tag="form"
                      >
                        <b-row>
                          <b-col
                            md="12"
                            class="mt-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="odUser"
                              rules="required"
                            >
                              <b-form-group
                                class="mb-2 mr-1"
                                :invalid-feedback="$t('required')"
                                :state="errors.length > 0 ? false : null"
                                :label="$t('almacenamiento.userCDN') + ' *'"
                              >
                                <b-form-input
                                  id="odUser"
                                  v-model="odUser"
                                  :state="errors.length > 0 ? false : null"
                                  maxlength="150"
                                  class="mr-1"
                                  autocomplete="new-password"
                                  :placeholder="$t('almacenamiento.userCDN')"
                                  name="odUser"
                                />
                              </b-form-group>
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              name="odPassword"
                              rules="required"
                            >
                              <b-form-group
                                class="mb-2 mr-1"
                                :label="$t('almacenamiento.claveCDN') + ' *'"
                                :invalid-feedback="$t('required')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <b-input-group class="mr-1">
                                  <b-form-input
                                    id="odPassword"
                                    v-model="odPassword"
                                    :state="errors.length > 0 ? false : null"
                                    maxlength="150"
                                    :type="passwordFieldType"
                                    :placeholder="$t('almacenamiento.claveCDN')"
                                    autocomplete="new-password"
                                    name="odPassword"
                                  />
                                  <b-input-group-append is-text>
                                    <feather-icon
                                      :icon="passwordToggleIcon"
                                      class="cursor-pointer"
                                      @click="togglePasswordVisibility"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </b-tab>
                  </b-tabs>
                </tab-content>
              </form-wizard>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <p class="clearfix mb-2 mr-2 ml-2">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        COPYRIGHT © {{ new Date().getFullYear() }}
        <b-link
          class="ml-25"
          href="https://interactvty.com"
          target="_blank"
        >Interactvty</b-link>
        <span class="d-none d-sm-inline-block">, All rights Reserved</span>
      </span>
      <span
        name="Beta"
        class="float-md-right d-none d-md-block"
      >{{ version }}
      </span>
    </p>
  </section>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  showToast, messageError, isEmpty,
} from '@/store/functions'
import localesLanguages from '@core/utils/languageModels'
import { clientNode, getUserData, setUserData } from '@/auth/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'

import {
  BImg,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BCardTitle,
  BCardHeader,
  BRow,
  BCardBody,
  BAvatar,
  BLink,
  BCol,
  BFormTags,
  BFormTag,
  BFormGroup,
  BInputGroupAppend,
  BMedia,
  VBTooltip,
  BTab,
  BMediaAside,
  BTabs,
  BMediaBody,
  BInputGroup,
  BCard,
  BFormInput,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BImg,
    BOverlay,
    BFormSelectOption,
    BButton,
    BFormCheckbox,
    BTab,
    BCardBody,
    ValidationProvider,
    ImageElementVue,
    BTabs,
    BMedia,
    BFormTags,
    BFormTag,
    BMediaAside,
    BInputGroup,
    BMediaBody,
    BAvatar,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormSelect,
    BCardTitle,
    BCardHeader,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroupAppend,
    BLink,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      totalClients: 0,
      version: '',
      imageLogoPr: '',
      apiName: '',
      isEmpty,
      value: [],
      options: [],
      selectType: 'NOR',
      productionEnviroment: false,
      showCountries: true,
      azureClientId: '',
      azureAccount: '',
      azureContainer: '',
      languagesValue: [],
      locales: localesLanguages,
      defaultLanguage: '',
      isCloudfront: false,
      cloudfrontUrl: '',
      distributionId: '',
      bucketName: '',
      bucketAccessKey: '',
      bucketSecretKey: '',
      bucketRegion: 'S3_EU_WEST_1_AMAZONAWS_COM',
      bucketRegionOptions: [
        { value: 'S3_EU_WEST_1_AMAZONAWS_COM', text: 'Irlanda (EU)' },
        { value: 'S3_US_EAST_1_AMAZONAWS_COM', text: 'Virgina (EEUU)' },
        { value: 'S3_US_WEST_1_AMAZONAWS_COM', text: 'California (EEUU)' },
        { value: 'S3_US_WEST_2_AMAZONAWS_COM', text: 'Oregón (EEUU)' },
        { value: 'S3_EU_CENTRAL_1_AMAZONAWS_COM', text: 'Frankfurt (EU)' },
        { value: 'S3_AP_NORTHEAST_1_AMAZONAWS_COM', text: 'Tokio' },
        { value: 'S3_AP_NORTHEAST_2_AMAZONAWS_COM', text: 'Seúl' },
        { value: 'S3_AP_SOUTHEAST_1_AMAZONAWS_COM', text: 'Singapur' },
        { value: 'S3_AP_SOUTHEAST_2_AMAZONAWS_COM', text: 'Sidney' },
        { value: 'S3_SA_EAST_1_AMAZONAWS_COM', text: 'Sao Pablo' },
      ],
      country: null,
      countryOptions: [],
      currency: 'EUR',
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Peso argentino',
      },
      odUser: '',
      odPassword: '',
      vimeoAccessToken: '',
      vimeoClientSecret: '',
      vimeoClientId: '',
      types: {
        NOR: 'Normal',
        OTT: 'OTT',
        SPO: this.$t('sport'),
        EDU: this.$t('EDUCATION'),
      },
      displayName: '',
      tabIndex: 0,
      required,
      name: '',
      show: false,
      funcionalidades: [
        {
          value: false,
          titulo: this.$t('resFunciones.isClose.title'),
          descripcion: this.$t('resFunciones.isClose.description'),
          icon: 'LockIcon',
          id: 'isClose',
        },
        {
          value: false,
          titulo: this.$t('Perfiles'),
          descripcion: this.$t('platPerf'),
          icon: 'UsersIcon',
          id: 'hasProfiles',
        },

        {
          value: false,
          titulo: this.$t('contDownlable'),
          descripcion: this.$t('canDownloadCont'),
          icon: 'DownloadIcon',
          id: 'isDownload',
        },
        {
          value: false,
          titulo: this.$t('learning'),
          descripcion: this.$t('learningInfo'),
          icon: 'BookOpenIcon',
          id: 'isElearningPlatform',
        },
        {
          value: false,
          titulo: this.$t('myChannel'),
          descripcion: this.$t('myChannelplat'),
          icon: 'TvIcon',
          id: 'hasMyChannel',
        },

        {
          value: false,
          titulo: this.$t('Códigos promocionales'),
          descripcion: this.$t('hasCod'),
          icon: 'PercentIcon',
          id: 'isPromotionalCodes',
        },
        {
          value: true,
          titulo: this.$t('settingTabs.ads'),
          descripcion: this.$t('existAds'),
          icon: 'MonitorIcon',
          id: 'isAds',
        },
        {
          value: false,
          titulo: this.$t('Shop'),
          descripcion: this.$t('resFunciones.isShop.description'),
          icon: 'ShoppingBagIcon',
          id: 'isShop',
        },
        {
          value: false,
          titulo: this.$t('resFunciones.isBuy.title'),
          descripcion: this.$t('resFunciones.isBuy.description'),
          icon: 'ShoppingCartIcon',
          id: 'isBuy',
        },
        {
          value: false,
          titulo: this.$t('resFunciones.hasRating.title'),
          descripcion: this.$t('resFunciones.hasRating.description'),
          icon: 'StarIcon',
          id: 'hasRating',
        },
        {
          value: false,
          titulo: this.$t('resFunciones.hasAdSlides.title'),
          descripcion: this.$t('resFunciones.hasAdSlides.description'),
          icon: 'MonitorIcon',
          id: 'hasAdSlides',
        },
        {
          value: false,
          titulo: this.$t('resFunciones.hasAdCuePoints.title'),
          descripcion: this.$t('resFunciones.hasAdCuePoints.description'),
          icon: 'AirplayIcon',
          id: 'hasAdCuePoints',
        },
        {
          value: false,
          titulo: this.$t('resFunciones.isLive.title'),
          descripcion: this.$t('resFunciones.isLive.description'),
          icon: 'RadioIcon',
          id: 'isLive',
        },
        {
          value: false,
          titulo: this.$t('transcoding.transcoding'),
          descripcion: this.$t('transcoding.transcodingAllowed'),
          icon: 'FilmIcon',
          id: 'isTranscoding',
        },
        {
          value: false,
          titulo: this.$t('geoblocking'),
          descripcion: this.$t('geoipbloking'),
          icon: 'MapPinIcon',
          id: 'geoipbloking',
        },
        {
          value: true,
          titulo: this.$t('interactivities.subtitle'),
          descripcion: this.$t('interactivities.inf'),
          icon: 'TvIcon',
          id: 'isInteractivity',
        },
        {
          value: false,
          titulo: this.$t('adsDisplay.title'),
          descripcion: this.$t('adsDisplay.description'),
          icon: 'ExternalLinkIcon',
          id: 'hasDisplayAds',
        },
        {
          value: false,
          titulo: this.$t('resFunciones.isHbbtv.title'),
          descripcion: this.$t('resFunciones.isHbbtv.description'),
          icon: 'ImageIcon',
          id: 'isHbbtv',
        },
        /*   {
          value: false,
          titulo: this.$t('appMobile'),
          descripcion: this.$t('appMobileInfo'),
          icon: 'SmartphoneIcon',
          id: 'isAppMobile',
        }, */
        {
          value: false,
          titulo: this.$t('resFunciones.isFavorite.title'),
          descripcion: this.$t('resFunciones.isFavorite.description'),
          icon: 'HeartIcon',
          id: 'isFavorite',
        },
        {
          value: false,
          titulo: this.$t('checkLanguageSegmentation'),
          descripcion: this.$t('checkLanguageSegmentationInfo'),
          icon: 'GridIcon',
          id: 'languageSegmentation',
        },
        {
          value: false,
          titulo: this.$t('audioPlayer.title'),
          descripcion: this.$t('audioPlayer.description'),
          icon: 'HeadphonesIcon',
          id: 'hasAudioPlayer',
        },
        {
          value: false,
          titulo: this.$t('resFunciones.hasLiveEvents.title'),
          descripcion: this.$t('resFunciones.hasLiveEvents.description'),
          icon: 'ZapIcon',
          id: 'hasLiveEvents',
        },
        /*   {
            value: false,
            titulo: this.$t('parentalCheck'),
            descripcion: this.$t('infoParental'),
            icon: 'ShieldIcon',
            id: 'isParentalControl',
          }, */
      ],
    }
  },

  computed: {
    availableOptions() {
      const selectOptions = this.options.filter(opt => this.value.indexOf(opt.name) === -1)
      return selectOptions.map(a => a.name)
    },

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  watch: {
    defaultLanguage() {
      localesLanguages.forEach(element => {
        if (element.locale === this.getLocaleFormat(this.defaultLanguage)[0]) {
          if (!this.value.includes(element.name)) {
            this.value.push(element.name)
          }
        }
      })
    },
  },
  mounted() {
    const userData = getUserData()
    this.getCountries()
    if (!userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
    if (mode === 'production') {
      this.productionEnviroment = true
    }
    localesLanguages.forEach(item => {
      this.options.push({ id: item.locale, name: item.name })
    })
    this.defaultLanguage = 'es'
    this.funcionalidades = this.funcionalidades.sort((a, b) => {
      const tituloA = a.titulo.toUpperCase()
      const tituloB = b.titulo.toUpperCase()

      if (tituloA < tituloB) {
        return -1
      }
      if (tituloA > tituloB) {
        return 1
      }
      return 0
    })
    this.version = process.env.VUE_APP_VERSION

    extend('existPlatform', {
      validate: () => this.totalClients === 0,
      message: this.$t('existPlatform'),
    })
  },

  methods: {
    getCountries() {
      const query = `{
        allCountry {
          edges {
            node {
              id
              country
            }
          }
        }
      }`

      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          const countriesData = result.data.data.allCountry.edges.map(edge => ({
            id: edge.node.id,
            country: edge.node.country,
          }))

          this.countryOptions = [{ id: null, country: this.$t('Seleccione un país') }, ...countriesData]
        })
        .catch(err => {
          showToast(this.$t('error'), 2, this)

          console.log(err)
        })
    },
    checkNamePlatform() {
      const query = `{
        allClients(name:"${this.name.trim()}") {
        totalCount
        }
      }`
      axios
        .post('', { query }).then(result => {
          this.totalClients = result.data.data.allClients.totalCount
        }).catch(() => {
        })
    },
    deleteImage() {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.imageLogoPr = null
        }
      }).catch(() => {

      })
    },
    getLocaleFormat(languagesString) {
      const idiomas = languagesString.split('|')
      const idiomasFormateados = idiomas.map(idioma => {
        const partes = idioma.split('-')
        const idiomaFormateado = partes
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1))
          .join('')
        return idiomaFormateado
      })
      return idiomasFormateados
    },
    formatToSaveDB(languagesString) {
      const partes = []
      for (let i = 0; i < languagesString.length; i += 2) {
        partes.push(languagesString.slice(i, i + 2).toLowerCase())
      }
      return partes.join('-')
    },
    disabledDefault(value) {
      const defLang = this.getLocaleFormat(this.defaultLanguage)[0]
      const matchingLocale = this.locales.find(element => element.locale === defLang)
      if (matchingLocale && matchingLocale.name === value) {
        return true
      }
      return false
    },
    loadImage(item) {
      const { data } = item
      this.imageLogoPr = data
    },
    getIdLanguages(array) {
      const languagesSelected = this.options.filter(opt => array.indexOf(opt.name) !== -1)
      const languages = languagesSelected.map(a => this.formatToSaveDB(a.id)).join('|')
      return languages
    },
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave[0] === 'required') {
        return this.$t('required')
      }
      if (existeClave[0] === 'existPlatform') {
        return data.failedRules[existeClave[0]]
      }
      return ''
    },
    createBucket() {
      const bucketName = this.formatBucketName(this.name)
      this.$swal({
        title: this.$t('crearBucket'),
        text: `Si continuas se creará un bucket de s3 con el nombre ${bucketName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = !this.show
          axios
            .post('', {
              query: `mutation{
                createBucket(bucketName:"${bucketName}",apiName:"${this.apiName}"){
                  response{
                    code
                    errorMessage
                    body{
                      distributionId
                      distributionDomainName
                      accessKeyId
                      secretAccessKey
                      bucketName
                    }
                    
                  }
                }
              }`,
            }).then(res => {
              const bucketResponse = res.data.data.createBucket.response

              if (bucketResponse.code === 200) {
                this.distributionId = bucketResponse.body.distributionId
                this.cloudfrontUrl = `https://${bucketResponse.body.distributionDomainName}`
                this.bucketAccessKey = bucketResponse.body.accessKeyId
                this.bucketSecretKey = bucketResponse.body.secretAccessKey
                this.bucketName = bucketResponse.body.bucketName
                this.isCloudfront = true
                this.show = !this.show

                showToast(this.$t('success'), 1, this)
              } else {
                showToast(bucketResponse.errorMessage, 2, this)
              }
            }).catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      }).catch(() => {

      })
    },
    formatBucketName(name) {
      let newName = name.toLowerCase()

      // Reemplazar caracteres especiales y espacios por guiones
      newName = newName.replace(/[^\w\s]/g, '') // Eliminar caracteres especiales
      newName = newName.replace(/\s+/g, '-') // Reemplazar espacios por guiones
      newName = newName.replace(/([A-Z])/g, '-$1') // Separar letras mayúsculas con guiones

      // Eliminar guiones duplicados
      newName = newName.replace(/-+/g, '-')

      // Eliminar guiones al principio y al final
      newName = newName.replace(/^-+|-+$/g, '')

      newName = `itvty-${newName}`

      return newName
    },
    formSubmitted() {
      this.show = true
      let functionalities = ''
      this.funcionalidades.forEach(element => {
        functionalities += `
          ${element.id}:${element.value},
        `
      })
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const bucketTypes = ['AS3', 'AZU', 'VIM', 'ODM']
      let bucket = ''
      switch (this.tabIndex) {
        case 0:
          bucket += `bucketName: "${this.bucketName}",
            bucketAccessKey: "${this.bucketAccessKey}",
            bucketSecretKey: "${this.bucketSecretKey}",
            bucketRegion: ${this.bucketRegion},
            isCloudfront: ${this.isCloudfront},
            cloudfromUrl: "${this.cloudfrontUrl}",
            distributionId: "${this.distributionId}"`
          break
        case 1:
          bucket += `azureClientId: "${this.azureClientId}",
            azureAccount: "${this.azureAccount}",
            azureContainer: "${this.azureContainer}",
           `
          break
        case 2:
          bucket += `vimeoClientId: "${this.vimeoClientId}",
            vimeoClientSecret: "${this.vimeoClientSecret}",
            vimeoAccessToken: "${this.vimeoAccessToken}",`
          break
        case 3:
          bucket += `odUser: "${this.odUser}",
            odPassword: "${this.odPassword}",`
          break
        default:
          break
      }
      const query = `
          mutation($logo:Upload!){
            createClient(input:{
              name: "${this.name}",
              googleAdsPublisherId:"",
              displayName: "${this.displayName}",
              type: ${this.selectType},
              defaultLanguage: "${this.defaultLanguage}",
              availableLanguages: "${this.getIdLanguages(this.value)}",
              ${functionalities}
              isSecondScreenInteractivity:true,
              isPushInteractivity:true,
              isBlockInteractivity:false,
              isVirtualUser:false,
              isVirtualCoin:false,
              isControlBar:false,
              isLinkCategories:true,
              isActive:true,
              androidTvUrl:"",
              amazonTvUrl:"",
              logoInteractivities: $logo,
              currency:${this.currency},
              ${!isEmpty(this.country) ? `countries:[${this.country}],` : ''}
              bucketType: ${bucketTypes[this.tabIndex]}
              ${bucket}
            }){
              client {
              ${clientNode()}
            }
          }
          }
        `
      data.append('query', query)
      data.append('variables', JSON.stringify({ logo: '' }))

      axios
        .post('', data, config)
        .then(response => {
          messageError(response, this)
          const dataClient = response.data.data.createClient.client
          const dataUpdate = new FormData()
          const queryUpdate = `mutation{
                updateClient(id:"${dataClient.id}",input:{
                  videotype: CON                 
                }){
                  client{
                    id
                    name
                    videotype
                    video
                    logo
                    logoMono
                  }
                }
              }
            `
          dataUpdate.append('query', queryUpdate)
          dataUpdate.append('logo', this.imageLogoPr)
          axios
            .post('', dataUpdate, config).then(() => {
              this.show = false
              showToast(this.$t('success'), 1, this)
              const dataSaved = getUserData()
              const ability = {
                ability: [
                  {
                    action: 'manage',
                    subject: 'all',
                  },
                ],
              }
              if (dataSaved.profile) {
                dataSaved.profile.client = dataClient
              }
              const userData = { ...dataSaved, ...ability }
              setUserData(userData)
              this.$ability.update(userData.ability)
              this.$router.push({ name: 'dashboard' })
            }).catch(() => {

            })
        })
        .catch(() => {
          this.show = false
          showToast(this.$t('error'), 2, this)
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormBucket() {
      switch (this.tabIndex) {
        case 1:
          // AZU
          return new Promise((resolve, reject) => {
            this.$refs.bucketRulesAzure.validate().then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
          })

        case 2:
          // VIM
          return new Promise((resolve, reject) => {
            this.$refs.bucketRulesVimeo.validate().then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
          })
        case 3:
          // ODM
          return new Promise((resolve, reject) => {
            this.$refs.bucketRulesODM.validate().then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
          })
        case 0:
        default:
          // AS3
          return new Promise((resolve, reject) => {
            this.$refs.bucketRulesS3.validate().then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
          })
      }
    },
    goInit() {
      this.$router.push({ name: 'dashboard' })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
