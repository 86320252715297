<template>
  <section id="hbbtv">
    <h1>{{ $t("hbbtv.title") }}</h1>
    <h5 class="text-primary">
      {{ $t("hbbtv.subtitle") }}
    </h5>
    <b-container>
      <b-row class="mt-2">
        <b-col md="6">
          <b-form @submit.prevent="updateHbbtvImage">
            <b-overlay
              variant="transparent"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
              :show="uploading"
            >
              <b-card>
                <b-card-header class="justify-content-between">
                  <b-card-title>{{ $t("hbbtv.label") }}</b-card-title>
                  <b-button
                    class="save-button ml-2"
                    type="submit"
                    variant="success"
                    :disabled="!hbbtvImage || uploading"
                  >
                    {{ $t("dataGeneric.save") }}
                  </b-button>
                </b-card-header>
                <b-card-body class="justify-content-center">
                  <b-form-group>
                    <div class="d-flex justify-content-center">
                      <image-element
                        class="d-flex justify-content-center"
                        :image-origin="hbbtvImage"
                        :image-type="'hbbtvImage'"
                        :can-delete="true"
                        :hbbtv-image-size="true"
                        :label="$t('hbbtv.span')"
                        md-length="6"
                        fixed-width="350px"
                        fixed-height="600px"
                        @saveImage="loadImage"
                      />
                    </div>
                  </b-form-group>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-form>
        </b-col>
        <b-col md="6">
          <b-card>
            <b-card-header class="justify-content-between mb-3">
              <b-card-title>
                {{ $t("hbbtv.preview") }}
              </b-card-title>
            </b-card-header>
            <b-card-body
              class="text-center mb-3"
            >
              <b-img
                v-if="urlHbbtvImage"
                :src="urlHbbtvImage"
                fluid
                alt="Preview Image"
                class="img-fluid"
              />
              <h5 v-else>
                {{ $t("hbbtv.noImage") }}
              </h5>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  messageError, showToast, stringToSha256,
} from '@/store/functions'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  BForm,
  BCol,
  BCardTitle,
  BCardBody,
  BButton,
  BFormGroup,
  BCard,
  BImg,
  BRow,
  BContainer,
  BCardHeader,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import ImageElement from '@/views/components/ImageElement.vue'

const fileError = require('@/assets/images/backend/file-error.png')

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BCardTitle,
    BCardBody,
    BFormGroup,
    BForm,
    BCol,
    BButton,
    BCard,
    ImageElement,
    BImg,
    BRow,
    BCardHeader,
    BContainer,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showTemplates: false,
      typeCard: null,
      userData: getUserData(),
      inputColor: '#000000',
      backgroundColor: '#000000',
      selected: null,
      hbbtvImage: null,
      isSuperuser: false,
      urlHbbtvImage: null,
      bucketName: null,
      bucketRegion: null,
      uploading: false,
      client: null,
      dimensions: {
        height: 0,
        width: 0,
      },

    }
  },

  mounted() {
    this.isSuperuser = this.userData.isSuperuser
    this.getS3PlatformData()
  },

  methods: {
    getS3PlatformData() {
      const userData = getUserData()
      const query = `
        query {
          client(id: "${userData.profile.client.id}"){
            id
            clientId
            name
            bucketName
            bucketRegion
            hbbtvImage
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        // 'S3_EU_WEST_1_AMAZONAWS_COM' => 's3.eu-west-1.amazonaws.com'
        this.bucketRegion = res.data.data.client.bucketRegion
          .toLowerCase()
          .replace('_', '.')
          .replace('_', '-')
          .replace('_', '-')
          .replace(/_/g, '.')
        this.bucketName = res.data.data.client.bucketName
        this.client = res.data.data.client.clientId
        const hbbtvUrl = res.data.data.client.hbbtvImage
        if (hbbtvUrl) this.urlHbbtvImage = `https://${this.bucketName}.${this.bucketRegion}/${hbbtvUrl}`
      })
    },
    chooseInput(dato) {
      document.getElementById(dato).click()
    },
    hideModal() {
      this.$refs.modalCard.hide()
    },
    loadImage(item) {
      const { data } = item

      if (data) {
        const photoUrl = URL.createObjectURL(data)
        const image = new Image()
        image.onload = () => {
          this.dimensions.height = image.height
          this.dimensions.width = image.width

          URL.revokeObjectURL(photoUrl)
        }
        image.src = photoUrl
      }

      this.hbbtvImage = data
    },
    updateHbbtvImage(event) {
      this.showLogos = true
      event.preventDefault()

      if (this.dimensions.width !== 350 || this.dimensions.height !== 600) {
        showToast(this.$t('code.errorImageSizeHbbTV'), 2, this)
        this.showLogos = false
        return
      }
      this.applyHbbTVMutation()
    },
    async applyHbbTVMutation() {
      const userData = getUserData()
      const { id } = userData.profile.client
      this.showLogos = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
          mutation{
            updateClient(id:"${id}",input:{}) 
            {
              client{
                id
                name
                hbbtvImage
              }
            }
          }
     `
      data.append('query', query)
      if (this.hbbtvImage) {
        this.uploading = true
        data.append('hbbtv_image', this.hbbtvImage)
      }

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)

          const imageUploaded = res.data.data.updateClient.hbbtvImage
          stringToSha256(this.client).then(hash => {
            this.uploading = false

            // Datetime added in order to make VUE detect the change and update the image
            this.urlHbbtvImage = `https://${this.bucketName}.${this.bucketRegion}/hbbtv/img/${hash}/welcome-img.jpg?${new Date().getTime()}`
            if (imageUploaded) showToast(this.$t('code.ImageHbbTVUploaded'), 1, this)
            else showToast(this.$t('code.ImageHbbTVRetrieved'), 1, this)

            this.showLogos = false
          })
        })
        .catch(() => {
          showToast(this.$t('code.errorImageHbbTVUpload'), 2, this)
          this.showLogos = false
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },

  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#hbbtv .card {
  width: auto; /* Ensure the card has no fixed width */
  height: auto; /* Ensure the card has no fixed height */
}

#hbbtv .card-body {
  min-height: 1px;
  text-align: center;
  justify-content: center;
}
#hbbtv .img {
  margin-top: 10px;
}

</style>
