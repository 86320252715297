<template>
  <div id="two-factor">
    <!-- Elegir método de autenticación -->
    <div v-if="!factorRegistred">
      <b-row class="mb-2">
        <b-col>
          <b-alert
            show
            variant="warning"
            class="p-3 alert-message"
          >
            <div
              class="mb-1"
              style="text-align: center; text-align: -webkit-center;"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                size="50"
              />
            </div>
            <strong class="message">{{ $t('twoFactor.message') }}</strong>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col style="justify-items:start">
          <h5 class="">
            {{ $t('twoFactor.chooseMode') }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="flex-column align-items-stretch">
        <b-col class="mb-1">
          <b-card class="border">
            <b-row class="align-items-center">
              <b-col class="d-flex align-items-center">
                <feather-icon
                  icon="SmartphoneIcon"
                  size="40"
                />
                <div class="ml-2">
                  <h6 class="mb-0 font-weight-bold">
                    {{ $t('twoFactor.qrAuth.link') }}
                  </h6>
                  <small class="mb-0 text-muted">
                    {{ $t('twoFactor.qrAuth.send') }}
                  </small>
                </div>
              </b-col>
              <b-col class="text-right">
                <b-button
                  variant="outline-primary"
                  @click="selectMethod('QR')"
                >
                  <div class="d-flex align-items-center">
                    {{ $t('select') }}
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="20"
                    />
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col class="mb-1">
          <b-card class="border">
            <b-row class="align-items-center">
              <b-col class="d-flex align-items-center">
                <feather-icon
                  icon="MailIcon"
                  size="40"
                />
                <div class="ml-2">
                  <h6 class="mb-0 font-weight-bold">
                    {{ $t('twoFactor.emailAuth.link') }}
                  </h6>
                  <small class="mb-0 text-muted">
                    {{ $t('twoFactor.emailAuth.send') }}
                  </small>
                </div>
              </b-col>
              <b-col class="text-right">
                <b-button
                  variant="outline-primary"
                  @click="selectMethod('MAIL')"
                >
                  <div class="d-flex align-items-center">
                    {{ $t('select') }}
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="20"
                    />
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <!-- Establecer código para entrar -->
    <div v-else>
      <b-overlay
        :show="show"
        variant="transparent"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <b-card
          v-if="changeEmail"
          no-body
        >
          <b-row style="flex-direction: column;">
            <b-col>
              <b-alert
                show
                variant="warning"
                class="p-1 alert-message"
              >
                <strong class="">{{ $t('twoFactor.emailAuth.needEmail') }}</strong>
              </b-alert>
            </b-col>
            <b-col style="text-align: justify;">
              <validation-observer
                ref="emailCheck"
                tag="form"
              >
                <label>Email</label>
                <b-form-group>
                  <validation-provider
                    v-slot="validationContext"
                    name="User Email"
                    rules="email"
                  >
                    <b-form-input
                      id="mc-user-email"
                      v-model="newEmail"
                      autocomplete="new-password"
                      :placeholder="$t('administrators.email')"
                      maxlength="254"
                      :state="validationContext.errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      checkEmail(validationContext.failedRules)
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-observer>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              style="
              text-align: right;
              text-align: -webkit-right;"
            >
              <b-button
                class="mt-2"
                variant="success"
                :disabled="isEmpty(newEmail)"
                @click="validationForm()"
              >
                {{ $t("dataGeneric.save") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card v-else>
          <div v-if="factorMethod == 'QR'">
            <b-row>
              <!-- Escáner QR -->
              <b-col
                v-if="qrLink"
                md="5"
                class="mb-3 with-vertical-line"
              >
                <h5 class="text-center mb-3">
                  {{ $t('twoFactor.qrAuth.title') }}
                </h5>
                <div class="qr-scanner-container">
                  <div class="qr-scanner">
                    <vue-qr
                      :text="qrLink"
                      :size="200"
                      :margin="10"
                    />
                  </div>
                  <small class="text-muted d-block mt-2 text-center">
                    {{ $t('twoFactor.qrAuth.helper') }}
                  </small>
                </div>
              </b-col>
              <!-- Campo para Código -->
              <b-col>
                <div>
                  <h5 class="text-center mb-3">
                    {{ $t('twoFactor.code') }}
                  </h5>
                  <b-form>
                    <b-form-group
                      label-for="auth-code"
                      label-class="font-weight-bold"
                      :label="$t('twoFactor.label')"
                    >
                      <div class="otp-container">
                        <b-form-input
                          v-for="(digit, index) in code"
                          :key="index"
                          ref="inputs"
                          v-model="code[index]"
                          maxlength="1"
                          class="otp-input"
                          @paste="handlePaste"
                          @input="handleInput(index, $event)"
                        />
                      </div>
                    </b-form-group>
                    <small
                      v-if="qrLink"
                      class="text-muted d-block mt-2 text-center"
                    >
                      {{ $t('twoFactor.qrAuth.firstMessage') }}
                    </small>
                    <small
                      v-else
                      class="text-muted d-block mt-2 text-center"
                    >
                      {{ $t('twoFactor.qrAuth.message') }}
                    </small>
                  </b-form>
                </div>
                <b-link
                  class="mt-2"
                  @click="selectMethod('MAIL')"
                >
                  <small>{{ $t('twoFactor.emailAuth.link') }}</small>
                </b-link>
              </b-col>
            </b-row>
          </div>
          <div v-if="factorMethod == 'MAIL'">
            <b-row>
              <b-col>
                <div
                  v-if="isEmailSent"
                  class="email-sent-message"
                >
                  <b-alert
                    variant="info"
                    class="p-2"
                    :show="factorMethod == 'MAIL'"
                    style="text-align: justify;"
                  >
                    {{ $t('twoFactor.emailAuth.sentMessage') }}
                  </b-alert>
                  <div
                    v-if="canResend"
                    class="resend-button"
                  >
                    <b-button
                      variant="primary"
                      @click="startSendEmailFunction"
                    >
                      {{ $t('twoFactor.emailAuth.resendButton') }}
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="d-flex"
                    style="flex-direction: column;
    align-items: center;"
                  >
                    <small class="text-muted mb-1">{{ $t('twoFactor.emailAuth.waitMessage') }}</small>
                    <b-button
                      disabled
                      variant="outline-primary"
                    >
                      <div
                        class="d-flex"
                        style="place-items: center;"
                      >
                        <b-spinner
                          small
                          class="mr-1"
                          variant="primary"
                        />
                        <span>
                          {{ countdown }} {{ $t('Segundos') + '...' }}
                        </span>
                      </div>
                    </b-button>
                  </div>
                </div>
                <div v-else>
                  <b-button
                    variant="primary"
                    @click="startSendEmailFunction"
                  >
                    {{ $t('twoFactor.emailAuth.sendButton') }}
                  </b-button>
                </div>
                <b-form-group
                  label-for="auth-code"
                  label-class="font-weight-bold mt-2"
                  :label="$t('twoFactor.label')"
                >
                  <div class="otp-container">
                    <b-form-input
                      v-for="(digit, index) in code"
                      :key="index"
                      ref="inputs"
                      v-model="code[index]"
                      maxlength="1"
                      class="otp-input"
                      @paste="handlePaste"
                      @input="handleInput(index, $event)"
                    />
                  </div>
                </b-form-group>
                <b-link
                  v-if="qrLink"
                  class="mt-2"
                  @click="selectMethod('QR')"
                >
                  <small>{{ $t('twoFactor.qrAuth.link') }}</small>
                </b-link>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
  BFormInput,
  BSpinner,
  BLink, BForm,
  BOverlay,
  BFormGroup,
  BCard,
  BAlert,
} from 'bootstrap-vue'
import axios from '@axios'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueQr from 'vue-qr'
import { isEmpty, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow,
    BSpinner,
    VueQr,
    BOverlay,
    BLink,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCard,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  props: {
    factor: {
      type: String,
      default: 'MAIL',
    },
    registered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: '',
      username: '',
      qrLink: '',
      show: false,
      isEmailSent: false,
      countdown: 30,
      countdownInterval: null,
      userEmail: '',
      password: '',
      scannerReady: false,
      factorMethod: 'MAIL',
      factorRegistred: false,
      authCode: '', // Modelo del código de autenticación
      code: ['', '', '', '', '', ''],
      originalEmail: '',
      changeEmail: false,
      newEmail: '',
      isEmpty,
    }
  },
  computed: {
    canResend() {
      return this.countdown <= 0
    },
    authCodeValid() {
      // Validar que el código tenga exactamente 6 caracteres alfanuméricos
      return /^[a-zA-Z0-9]{6}$/.test(this.authCode) || this.authCode === ''
    },
  },
  watch: {
    code() {
      if (this.code.length === 6 && this.code.every(digit => digit !== '')) {
        this.authCode = this.code.join('')
      }
    },
    authCode() {
      if (this.authCode) {
        this.show = !this.show
        let query = ''
        if (this.factorMethod === 'QR') {
          query = `mutation {
           methodValidate: validate2faCode(code: "${this.authCode}") {
              result
            }
          }`
        } else {
          query = `mutation {
            methodValidate: validate2faHotpCode(code: "${this.authCode}") {
              result
            }
          }`
        }
        axios
          .post('', { query }).then(result => {
            const resultValidation = result.data.data.methodValidate.result
            this.show = !this.show
            if (resultValidation) {
              this.$emit('validate', this.factorMethod)
            } else {
              showToast(this.$t('twoFactor.error'), 2, this)
              this.authCode = ''
              this.code = ['', '', '', '', '', '']
              this.$refs.inputs[0].focus()
            }
          }).catch(() => {

          })
      }
    },
  },
  mounted() {
    this.originalEmail = getUserData()?.email
    this.factorMethod = this.factor
    this.factorRegistred = this.registered

    if (this.factorRegistred) {
      this.selectMethod(this.factorMethod)
    }
  },
  beforeDestroy() {
    // Limpiar el intervalo cuando el componente se destruya
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval)
    }
  },
  methods: {
    validationForm() {
      return new Promise(resolve => {
        this.$refs.emailCheck.validate().then(success => {
          if (success) {
            resolve(true)
            this.show = !this.show

            const query = `mutation{
                updateEmail(newEmail:"${this.newEmail}"){
                  success
                  message
                }
              }`
            axios
              .post('', { query }).then(result => {
                const { message } = result.data.data.updateEmail
                const successChange = result.data.data.updateEmail.success
                this.show = !this.show
                if (successChange) {
                  this.originalEmail = this.newEmail
                  showToast(this.$t('success'), 1, this)
                  this.startSendEmailFunction()
                } else {
                  showToast(message, 2, this)
                }
              }).catch(() => {

              })
          }
        })
      })
    },
    startSendEmailFunction() {
      if (isEmpty(this.originalEmail)) {
        this.changeEmail = true
      } else if (this.canResend || !this.isEmailSent) {
        this.changeEmail = false
        this.isEmailSent = true
        this.startCountdown()
        this.$nextTick(() => {
          this.sendEmail()
        })
      }
    },
    startCountdown() {
      try {
        if (this.countdownInterval) {
          clearInterval(this.countdownInterval)
        }
        this.countdown = 30
        this.countdownInterval = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown -= 1
          } else {
            clearInterval(this.countdownInterval)
          }
        }, 1000)
      } catch (error) {
        console.log(error)
      }
    },
    handleInput(index, event) {
      const value = event
      if (value) {
        // Limitar el input a un único carácter
        this.code[index] = value.slice(0, 1)

        if (index < this.code.length - 1) {
          let nextIndex = index + 1
          // Encuentra el próximo input vacío
          while (nextIndex < this.code.length && !isEmpty(this.code[nextIndex])) {
            nextIndex += 1
          }
          // Mueve el foco al próximo input válido
          if (nextIndex < this.code.length) {
            this.$refs.inputs[nextIndex].focus()
          }
        }
      }
    },
    checkEmail(rules) {
      if (rules.email) {
        return (this.$t('verifiedEmail'))
      }
      return ''
    },
    handlePaste(event) {
      const pasteData = event.clipboardData.getData('text').trim()
      // Dividir el string en caracteres y distribuirlo
      const digits = pasteData.slice(0, this.code.length).split('')
      digits.forEach((digit, idx) => {
        this.code[idx] = digit
      })
    },
    selectMethod(type) {
      this.factorMethod = type
      this.initUser2fa()
    },
    initUser2fa() {
      this.show = !this.show

      const query = `mutation{
            initUser2fa {
              url
            }
          }`
      axios
        .post('', { query }).then(result => {
          this.qrLink = result.data.data.initUser2fa.url
          if (this.factorMethod === 'MAIL') {
            this.startSendEmailFunction()
          }
          this.factorRegistred = true
          this.show = !this.show
        }).catch(() => {

        })
    },
    sendEmail() {
      this.show = !this.show
      const query = `mutation {
          sendHotpUserCode {
            result
            error
          }
        }
        `
      axios
        .post('', { query }).then(result => {
          this.show = !this.show
          if (!result.data.data.sendHotpUserCode.result) {
            console.error(result.data.data.sendHotpUserCode.error)
            showToast(result.data.data.sendHotpUserCode.error, 2, this)
          }
        }).catch(err => {
          console.log(err)
          showToast(this.$t('twoFactor.emailAuth.error'), 2, this)
        })
    },

  },
}
</script>
<style lang="scss">
#two-factor .qr-scanner-container {
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

#two-factor .qr-scanner {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

#two-factor .otp-container {
  display: flex;
  justify-content: center;
}

#two-factor .otp-input {
  margin: 5px;
  width: 50px;
  /* Anchura mayor para acomodar números grandes */
  height: 60px;
  /* Altura mayor */
  text-align: center;
  /* Centra el texto horizontalmente */
  font-size: 1.8rem;
  /* Tamaño del texto más grande */
  font-weight: bold;
  /* Hace los números más legibles */
  border: 1px solid #ccc;
  /* Borde inicial */
  border-radius: 8px;
  /* Bordes redondeados */
  outline: none;
  /* Elimina el borde azul predeterminado */
  padding: 5px;
  /* Espaciado interno para evitar que el texto se corte */
  box-sizing: border-box;
  /* Incluye padding y borde en el tamaño del input */
  transition: border-color 0.3s, box-shadow 0.3s;
  /* Animaciones suaves */
}

#two-factor .otp-input:focus {
  border-color: #007bff;
  /* Borde azul al enfocarse */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Sombra al enfocarse */
}

#two-factor .otp-input::-webkit-inner-spin-button,
#two-factor .otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* Oculta los botones de número en navegadores basados en WebKit */
  margin: 0;
  /* Elimina cualquier margen */
}

#two-factor .otp-input[type="text"] {
  -moz-appearance: textfield;
  /* Oculta los botones de número en Firefox */
}

#two-factor .with-vertical-line {
  border-right: 2px solid #7a7272;
  /* La línea vertical será de 2px y color negro */
}

#two-factor .message {
  text-align: justify;
  color: white;
  line-height: 1.5;
  font-size: 1rem;
}

#two-factor .alert-message {
  color: white;
  border-radius: 8px;
  text-align: justify;
}
</style>
