<template>
  <div id="wizardCategory">
    <b-row class="mb-5">
      <b-col
        md="10"
        class=""
      >
        <h1>{{ $t("category.createCat") }}</h1>
        <h5 class="text-primary">
          {{ $t("contents.addCategory") }}
        </h5>
      </b-col>

      <b-col class="d-flex justify-content-end align-items-center">
        <b-img
          v-if="languageService"
          height="20px"
          width="30px"
          :src="languageFlags(defaultLanguage)"
          class="mr-2"
        />
        <b-button
          variant="danger"
          @click="goBack()"
        >
          {{
            $t("dataGeneric.goBack")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.save')"
        :back-button-text="$t('dataGeneric.previous')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- Información del contenido tab -->
        <tab-content
          :title="$t('category.info')"
          :before-change="validationForm"
        >
          <validation-observer
            ref="accountRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("category.info") }}
                </h5>
                <small class="text-muted">
                  {{ $t("category.small") }}
                </small>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-group :label="$t('dataGeneric.name') + ' *'">
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('category.name')"
                      maxlength="150"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <b-form-group :label="$t('category.type')">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('contents.categoryType')"
                    rules="required"
                  >
                    <h6 v-if="disabledType">
                      {{ $t(categoryTypes[typeCategorySelected]) }}
                    </h6>
                    <b-form-select
                      v-else
                      :key="update"
                      v-model="typeCategorySelected"
                      class="mr-1"
                      @change="changeFinal()"
                    >
                      <b-form-select-option
                        v-for="typ in Object.keys(categoryTypes)"
                        :key="typ"
                        :value="typ"
                      >
                        {{ $t(categoryTypes[typ]) }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="shortDescription"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('category.shortDescription') + ' *'"
                    :invalid-feedback="$t('category.shortDescriptionValidation')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="shortDescription"
                      v-model="shortDescription"
                      maxlength="180"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('category.shortDescription')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :label="$t('dataGeneric.description')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="description"
                  >
                    <b-form-textarea
                      v-model="comment"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('category.description')"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group :label="$t('category.factSheet')">
                  <b-form-textarea
                    v-model="technicalDetails"
                    :placeholder="$t('category.factSheet')"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('contents.template')"
                >
                  <b-form-select
                    v-model="templateCategorySelected"
                    :options="templateCategory"
                    class="mr-1"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('contents.datePublishing')"
                >
                  <flat-pickr
                    id="example-datepicker"
                    v-model="emisionDate"
                    class="form-control"
                    :placeholder="$t('contents.datePublishing')"
                    :config="{
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'Y-m-d H:i:S'
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('durationCat')"
                  class="mb-2 mr-1"
                >
                  <b-form-input
                    id="duration"
                    v-model="duration"
                    autocomplete="new-password"
                    type="number"
                    :min="0"
                    step="any"
                    :placeholder="$t('durationCat')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :label="$t('epg.genre')">
                  <v-select
                    id="genre"
                    v-model="selectedGenre"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allGenre"
                    label="text"
                    :placeholder="$t('epg.genre')"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('epg.ageClasific')">
                  <b-form-select
                    id="clasification"
                    v-model="selectedClasification"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <b-form-select-option :value="null">
                      {{ $t('epg.ageClasific') }}
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="s in allClasification"
                      :key="s.node.id"
                      :value="s.node.id"
                    >
                      <strong :style="'color:' + s.node.color + ';'">
                        {{ s.node.initial }} - {{ s.node.description }}
                      </strong>
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('qualityCat')"
                >
                  <b-form-select
                    v-model="quality"
                    class="mr-1"
                  >
                    <b-form-select-option
                      v-for="typ in Object.keys(qualityOptions)"
                      :key="typ"
                      :value="typ"
                    >
                      {{ qualityOptions[typ] }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="subgroups"
                md="4"
              >
                <b-form-group
                  v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                  :label="$t('Subgroup')"
                >
                  <v-select
                    id="subgroup"
                    v-model="subgroup"
                    :options="subgroups"
                    label="name"
                    :placeholder="$t('SinGrupoUsuario')"
                    aria-autocomplete="new-password"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group :label="$t('category.question')">
                  <b-form-checkbox
                    id="categoriaFinal"
                    v-model="isFinalCategory"
                    name="categoriaFinal"
                    :disabled="disabledFinalOption()"
                    :switch="true"
                  >
                    Final
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                v-if="subCategory"
                md="3"
              >
                <b-form-group :label="$t('category.payCategory')">
                  <b-form-checkbox
                    id="pago"
                    v-model="isPremium"
                    name="pago"
                    :switch="true"
                  >
                    {{ $t('category.paidCategory') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!specialCategory(typeCategorySelected) && (typeCategorySelected != 'POD' || typeCategorySelected != 'AUL')"
                md="3"
              >
                <b-form-group :label="$t('hasSub')">
                  <b-form-checkbox
                    id=" hasSubtitle"
                    v-model="hasSubtitle"
                    name="hasSubtitle"
                    :switch="true"
                  >
                    {{ $t('subt') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                v-if="typeCategorySelected == 'FIL'"
                md="3"
              >
                <b-form-group :label="$t('gridCont')">
                  <b-form-checkbox
                    id="modeGrid"
                    v-model="modeGrid"
                    name="modeGrid"
                    :switch="true"
                  >
                    Grid
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <div
                v-if="$route.name == 'create-podcast'"
                class="d-flex"
              >
                <b-col>
                  <b-form-group>
                    <label>{{ $t('catFrom') }}
                      <feather-icon
                        v-if="idParent != null"
                        class="cursor-pointer text-danger ml-50"
                        icon="XCircleIcon"
                        size="18"
                        @click="(categoryName = null, idParent = null)"
                      />

                    </label>

                    <b-form-input
                      id="categoryId"
                      v-model="categoryName"
                      maxlength="180"
                      disabled
                      :placeholder="$t('contents.start')"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="4"
                  class="align-self-center"
                >
                  <b-button
                    variant="primary"
                    @click="$refs['modal-podcast'].show()"
                  >
                    {{ $t('select') }}
                  </b-button>
                </b-col>
              </div>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Archivos del contenido  tab -->
        <tab-content
          :title="$t('category.categoryFiles')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="rulesCategory"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t('Images') }}
                </h5>
                <small class="text-muted">{{ $t("category.image") }}</small>
              </b-col>
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('resources.originMedia')"
                >
                  <b-form-select
                    v-model="mediaOriginSelected"
                    class="mr-1"
                    :options="mediaOriginOptions"
                  />
                </b-form-group>
                <div>
                  <!-- FILA TV/WEB -->
                  <b-form-group class="m-1">
                    <label><strong>TV/Web</strong></label>
                    <b-row style="justify-content: space-around;;">
                      <!-- TARJETA -->
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? imageCategory : imageCategoryUrlSelected"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'imageCategory'"
                        :label="$t('contents.imgInternal')"
                        @saveImage="loadImage"
                      />
                      <!-- FONDO NORMAL -->
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? backgroundCategory : backgroundCategoryURL"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'backgroundCategory'"
                        :label-info="$t('infoImage.background')"
                        :label="$t('contents.bckgInternal') + ' ' + $t('precontent')"
                        @saveImage="loadImage"
                      />
                      <!-- FONDO TYPE BCK -->
                      <image-element-vue
                        v-if="templateCategorySelected == 'BCK' || templateCategorySelected == 'INF'"
                        :image-origin="mediaOriginSelected == 'BUK' ? backgroundRoot : backgroundRootURL"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'backgroundRoot'"
                        :label="$t('backList')"
                        @saveImage="loadImage"
                      />
                      <b-col
                        v-else
                        cols="3"
                      />
                    </b-row>
                  </b-form-group>
                  <!-- FILA MÓVIL -->
                  <b-form-group class="m-1">
                    <label><strong>{{ $t('Movil') }}</strong></label>
                    <b-row style="justify-content: space-around;;">
                      <!-- TARJETA MÓVIL -->
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? mobileImageCategory : mobileImageCategoryURL"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'mobileImageCategory'"
                        :label="$t('contents.mobileImgInternal')"
                        @saveImage="loadImage"
                      />
                      <!-- FONDO MÓVIL NORMAL -->
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? backgroundMobileCategory : backgroundMobileCategoryURL"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'backgroundMobileCategory'"
                        :label-info="$t('infoImage.backgroundMobile')"
                        :label="$t('backPre')"
                        @saveImage="loadImage"
                      />
                      <!-- FONDO MÓVIL BCK -->
                      <image-element-vue
                        v-if="templateCategorySelected == 'BCK' || templateCategorySelected == 'INF'"
                        :image-origin="mediaOriginSelected == 'BUK' ? backgroundRootMobile : backgroundRootMobileURL"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'backgroundRootMobile'"
                        :label="$t('backListMov')"
                        @saveImage="loadImage"
                      />
                      <b-col
                        v-else
                        cols="3"
                      />
                    </b-row>
                  </b-form-group>
                  <!-- FILA RECURSOS VARIOS -->
                  <b-form-group class="m-1">
                    <label><strong>{{ $t('otherRes') }}</strong></label>
                    <b-row style="justify-content: space-around;;">
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? altImageCategory : altImageCategoryURL"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'altImageCategory'"
                        :label-info="$t('infoImage.alternative')"
                        :label="$t('editContent.image')"
                        @saveImage="loadImage"
                      />
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? titleImageCategory : titleImageCategoryURL"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'titleImageCategory'"
                        :label-info="$t('infoImage.title')"
                        :label="$t('logoTit')"
                        @saveImage="loadImage"
                      />
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? trailerCategory : trailerCategoryURL"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'trailerCategory'"
                        :label="$t('contents.trailerInterno')"
                        :video="true"
                        @saveImage="loadImage"
                      />
                    </b-row>
                  </b-form-group>
                  <b-form-group class="m-1">
                    <b-row style="justify-content: space-around;;">
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? sponsorImage : sponsorImageUrlSelected"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'sponsorImage'"
                        :label-info="$t('infoImage.title')"
                        :label="$t('sponsorImage')"
                        @saveImage="loadImage"
                      />
                      <image-element-vue
                        v-if="typeCategorySelected == 'POD' || typeCategorySelected == 'AUL'"
                        :image-origin="mediaOriginSelected == 'BUK' ? imagePodcast : imagePodcastUrlSelected"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'imagePodcast'"
                        :label-info="$t('infoImage.podcast')"
                        :label="$t('POD')"
                        @saveImage="loadImage"
                      />
                      <b-col
                        v-else
                        cols="3"
                      />
                      <b-col cols="3" />
                    </b-row>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content title="SEO">
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                SEO
              </h5>
              <small class="text-muted">{{ $t('fieldSeo') }}</small>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('title')">
                <b-form-input
                  id="title"
                  v-model="seoTitle"
                  maxlength="80"
                  :placeholder="$t('title')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('dataGeneric.description')">
                <b-form-input
                  id="description"
                  v-model="seoDescription"
                  maxlength="180"
                  :placeholder="$t('dataGeneric.description')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content
          :title="$t('contents.categoryDesign')"
          :before-change="validationFormSocial"
        >
          <validation-observer
            ref="socialRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("contents.categoryDesign") }}
                </h5>

                <small class="text-muted">{{ $t('designCards') }}</small>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <span>{{ $t("contents.categoryCard") }}</span>

                <b-form-group
                  class="
                                                                        mt-2
                                                                                  mb-2
                                                                                  mr-1
                                                                                  embed-responsive embed-responsive-16by9
                                                                                "
                >
                  <div
                    id="imageCatTV"
                    class="base-card-input embed-responsive-item"
                    @click="showModalCard('imageCatTV')"
                  >
                    <span
                      class="placeholder"
                      :style="cardSelected != null
                        ? `background: url('${cardSelected.sampleImage}')center/contain no-repeat;`
                        : ''"
                    >
                      <feather-icon
                        v-if="cardSelected == null"
                        icon="LayoutIcon"
                        size="80"
                      />
                    </span>
                  </div>
                </b-form-group>
                <span>{{
                  cardSelected != null ? cardSelected.name : $t('noSelect')
                }}</span>
              </b-col>
              <b-col cols="3">
                <span>{{ $t("contents.smartphoneCategoryCard") }}</span>

                <b-form-group
                  class="
                                                                        mt-2
                                                                                  mb-2
                                                                                  mr-1
                                                                                  embed-responsive embed-responsive-16by9
                                                                                "
                >
                  <div
                    id="imageContTV"
                    class="base-card-input embed-responsive-item"
                    @click="showModalCard('imageContTV')"
                  >
                    <span
                      class="placeholder"
                      :style="cardMobileSelected != null
                        ? `background: url('${cardMobileSelected.sampleImage}')center/contain no-repeat;`
                        : ''"
                    >
                      <feather-icon
                        v-if="cardMobileSelected == null"
                        icon="LayoutIcon"
                        size="80"
                      />
                    </span>
                  </div>
                </b-form-group>
                <span>{{
                  cardMobileSelected != null
                    ? cardMobileSelected.name
                    : $t('noSelect')
                }}</span>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col
                cols="12"
                class="mb-2"
              >
                <small class="text-muted">{{ $t('desginText') }}</small>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label-for="contentDesign">
                  <b-form-select
                    id="contentDesign"
                    v-model="contentDesign"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="contentDesignTypes"
                    label="text"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('catTit')"
                >
                  <b-form-checkbox
                    id="isTitle"
                    v-model="isTitle"
                    name="isTitle"
                    :switch="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>

    <b-modal
      ref="modalCard"
      size="xl"
      hide-footer
      :title="$t('modal.card')"
    >
      <div class="d-block text-center">
        <card-selector-modal @id="SelectCardId" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="modal-podcast"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :type="false"
          :columns="[
            { key: 'image', label: $t('contents.category') },
            { key: 'name', label: $t('firstName') },
            { key: 'type', label: $t('type') },
          ]"
          :especial="true"
          @confirm-selection="selectCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal(1)"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import configCategoryType from '@core/utils/categoryType'
import {
  utf8ToB64, limitCharacter, specialCategory, isEmpty, messageError,
  showToast, getSubgroups, checkPermissions,
} from '@/store/functions'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import {
  BImg,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import { getQualityOptions } from '@/@core/utils/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'
import languageFlags from '@/@core/utils/languageFlags'
import CardSelectorModal from '@/views/common/CardSelectorModal.vue'
import CategorySelector from '@/views/common/CategorySelector.vue'

export default {
  components: {
    BImg,
    BOverlay,
    BFormSelectOption,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    CategorySelector,
    FormWizard,
    TabContent,
    BFormSelect,
    BFormTextarea,
    flatPickr,
    ImageElementVue,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    CardSelectorModal,
  },
  data() {
    return {
      specialCategory,
      categoryName: this.$t('contents.start'),
      backgroundRoot: null,
      backgroundRootMobile: null,
      backgroundRootURL: '',
      backgroundRootMobileURL: '',
      modeGrid: false,
      quality: 'No_Quality',
      qualityOptions: getQualityOptions(),
      hasSubtitle: false,
      subCategory: false,
      idParent: this.$route.params.id != null ? this.$route.params.id : null,
      seoTitle: null,
      seoDescription: null,
      userData: getUserData(),
      video: null,
      shortDescription: null,
      imageCategoryUrlSelected: null,
      imagePodcastUrlSelected: null,
      sponsorImageUrlSelected: null,
      mobileImageCategoryURL: null,
      backgroundCategoryURL: null,
      titleImageCategoryURL: null,
      backgroundMobileCategoryURL: null,
      trailerCategoryURL: null,
      imageCategory: null,
      imagePodcast: null,
      sponsorImage: null,
      mobileImageCategory: null,
      backgroundCategory: null,
      titleImageCategory: null,
      altImageCategory: null,
      altImageCategoryURL: '',
      trailerCategory: null,
      resources: null,
      technicalDetails: null,
      emisionDate: null,
      duration: 0,
      tipo: '',
      mediaOriginSelected: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      selectedLanguage: null,
      selectedStatus: '',
      isFinalCategory: true,
      isPremium: false,
      name: '',
      comment: '',
      inputs: null,
      required,
      languageName: [
        { value: 1, text: 'Spanish' },
        { value: 2, text: 'English' },
        { value: 3, text: 'Italian' },
      ],
      cardSelected: null,
      cardMobileSelected: null,
      blurEffectStatus: false,
      typeCategorySelected: 'NOR',
      isLinkCategory: true,
      categoryTypes: {
      },
      templateCategorySelected: null,
      templateCategory: [
        { value: null, text: this.$t('designTypes.templ') },
        { value: 'LAT', text: this.$t('designTypes.side') },
        { value: 'BCK', text: 'Background' },
        { value: 'INF', text: this.$t('designTypes.backInfo') },
      ],
      update: 0,
      newOrder: 0,
      show: false,
      typeCard: null,
      allClasification: [],
      final: false,
      disabledType: false,
      allGenre: [],
      selectedGenre: null,
      selectedClasification: null,
      backgroundMobileCategory: null,
      isTitle: true,
      defaultLanguage: '',
      languageService: false,
      contentDesign: 'ONL',
      contentDesignTypes: [
        { value: 'NOT', text: this.$t('designTypes.not') },
        { value: 'ONL', text: this.$t('designTypes.onl') },
        { value: 'CTC', text: this.$t('designTypes.ctc') },
        { value: 'TAD', text: this.$t('designTypes.tad') },
        {
          value: 'TDS',
          text: this.$t('designTypes.tds'),
        },
        { value: 'TID', text: this.$t('designTypes.tid') },
      ],
      filtered: true,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      checkPermissions,
    }
  },
  watch: {

    isFinalCategory() {
      this.isPremium = false
    },

    duration() {
      function checkNumberType(value) {
        if (Number.isFinite(value)) {
          return true
        }
        return false
      }
      try {
        this.duration = checkNumberType(this.duration) ? this.duration.toFixed(2) : this.duration
      } catch (error) {
        console.log(error)
      }
    },

  },
  mounted() {
    if (this.$route.name === 'create-podcast') {
      this.disabledType = true
      this.typeCategorySelected = 'POD'
    }
    if (this.idParent != null) {
      this.getCheckCat(this.$route.params.id)
      this.subCategory = true
    } else {
      this.getCheckClient()
    }

    const userData = getUserData()
    this.hasSubgroups = userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
      this.subgroup = this.userData.profile.subgroup
    }

    this.getDefaultLanguage()
    this.clasification()
    this.genre()
    this.getOrderCategory()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    languageFlags,
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageCategory':
          if (this.mediaOriginSelected === 'BUK') this.imageCategory = data
          else this.imageCategoryUrlSelected = data
          break
        case 'imagePodcast':
          if (this.mediaOriginSelected === 'BUK') this.imagePodcast = data
          else this.imagePodcastUrlSelected = data
          break
        case 'sponsorImage':
          if (this.mediaOriginSelected === 'BUK') this.sponsorImage = data
          else this.sponsorImageUrlSelected = data
          break
        case 'backgroundCategory':
          if (this.mediaOriginSelected === 'BUK') this.backgroundCategory = data
          else this.backgroundCategoryURL = data
          break
        case 'mobileImageCategory':
          if (this.mediaOriginSelected === 'BUK') this.mobileImageCategory = data
          else this.mobileImageCategoryURL = data
          break
        case 'backgroundMobileCategory':

          if (this.mediaOriginSelected === 'BUK') this.backgroundMobileCategory = data
          else this.backgroundMobileCategoryURL = data
          break
        case 'altImageCategory':
          if (this.mediaOriginSelected === 'BUK') this.altImageCategory = data
          else this.altImageCategoryURL = data
          break
        case 'titleImageCategory':
          if (this.mediaOriginSelected === 'BUK') this.titleImageCategory = data
          else this.titleImageCategoryURL = data
          break
        case 'trailerCategory':
          if (this.mediaOriginSelected === 'BUK') this.trailerCategory = data
          else this.trailerCategoryURL = data
          break
        case 'backgroundRoot':
          if (this.mediaOriginSelected === 'BUK') this.backgroundRoot = data
          else this.backgroundRootURL = data
          break
        case 'backgroundRootMobile':
          if (this.mediaOriginSelected === 'BUK') this.backgroundRootMobile = data
          else this.backgroundRootMobileURL = data
          break

        default:
          break
      }
    },
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
              query{
              client(id:"${this.userData.profile.client.id}"){
                  defaultLanguage
                  availableLanguages
              }
          }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          try {
            const arr = availableLanguages.split('|')
            this.languageService = arr.length > 1
            // eslint-disable-next-line no-empty
          } catch (error) {

          }

          this.defaultLanguage = res.data.data.client.defaultLanguage
        })
        .catch(() => {
        })
    },
    fix(data) {
      const keys = Object.keys(data.failedRules)

      if (keys.includes('required')) {
        return this.$t('nameField')
      }

      if (keys.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }

      return ''
    },
    getCheckCat(id) {
      axios
        .post('', {
          query: `
        {
        allCategories(id:"${id}") {
          edges {
            node {
              id
              type
            }
          }
        }
      }
        `,
        })
        .then(result => {
          messageError(result, this)

          const { type } = result.data.data.allCategories.edges[0].node

          if (type === 'FIL') {
            this.filtered = false
          }
          this.getCheckClient()
        })
        .catch(() => { })
    },
    getCheckClient() {
      axios
        .post('', {
          query: `
        {
          allClients(clientId:"${this.userData.profile.client.clientId}") {
            edges {
              node {
                isLinkCategories
               
              }
            }
          }
        }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.categoryTypes = configCategoryType(false, this.subCategory, result.data.data.allClients.edges[0].node.isLinkCategories, this.filtered)
          this.update += 1
        })
        .catch(() => { })
    },

    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
    hideModal() {
      this.$refs.modalCard.hide()
    },
    showModalCard(type) {
      this.$refs.modalCard.show()
      this.typeCard = type
    },
    SelectCardId(file) {
      switch (this.typeCard) {
        case 'imageCatTV':
          this.cardSelected = file
          break
        case 'imageContTV':
          this.cardMobileSelected = file
          break
        default:
          break
      }
      this.hideModal()
    },

    chooseType(input) {
      switch (input) {
        case 'fileInputTr':
          this.tipo = 'VID'
          break
        default:
          this.tipo = 'IMG'
          break
      }
    },
    selectCategory(item) {
      this.$refs['modal-podcast'].hide()

      this.idParent = item.node.id
      this.categoryName = item.node.name
    },
    formSubmitted() {
      this.show = true

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
        mutation( $name: String!, $language: ID, $description: String, $type: CategoryTypeInput,
        $isFinal: Boolean, $isPremium: Boolean,$hasSubtitle: Boolean,$modeGrid: Boolean,$quality: CategoryQualityInput, $isBackgroundBlur: Boolean, $card: ID,
        $cardMobile: ID, $client: ID!, $mediaLocation: CategoryMediaLocationInput,$shortDescription: String,
         $imageUrl: String,$imagePodcastUrl: String,$sponsorImageUrl: String, $imageMobileUrl: String, $backgroundUrl: String, $backgroundRootUrl: String,
          $backgroundRootMobileUrl: String,$backgroundMobileUrl: String,
          $titleImageUrl: String, $trailerUrl: String, $seoTitle: String, $seoDescription: String,
          $alternativeImageUrl: String,$templateCategory:  CategoryTemplateCategoryInput,
          $technicalDetails: String,$genre: ID, $clasification: ID, 
          $isTitle: Boolean, $contentDesign: CategoryContentDesignInput
          ${this.emisionDate != null ? ',$emisionDate: DateTime' : ''} 
          ,$duration: Int
          ,$subgroup: ID 
          ){
          createCategory(input:{
            name: $name,
            client: $client,
            language: $language,
            description: $description,
            type: $type,
            isFinal: $isFinal,
            hasSubtitle: $hasSubtitle,
            ${this.emisionDate != null ? 'emisionDate: $emisionDate,' : ''}
            duration: $duration,
            modeGrid: $modeGrid,
            quality: $quality,
            isActive: false,
            state: RED,
            isPremium: $isPremium,
            templateCategory: $templateCategory,
            isBackgroundBlur: $isBackgroundBlur,
            alternativeImageUrl:  $alternativeImageUrl,
            card: $card,
            order: ${this.newOrder},
            cardMobile: $cardMobile,
            mediaLocation: $mediaLocation,
            imageUrl: $imageUrl,
            imagePodcastUrl: $imagePodcastUrl,
            sponsorImageUrl: $sponsorImageUrl,
            imageMobileUrl: $imageMobileUrl,
            backgroundUrl: $backgroundUrl,
            backgroundRootUrl: $backgroundRootUrl,
            backgroundRootMobileUrl: $backgroundRootMobileUrl,
            backgroundMobileUrl: $backgroundMobileUrl,
            titleImageUrl: $titleImageUrl,
            trailerUrl: $trailerUrl,
            seoTitle: $seoTitle,
            seoDescription: $seoDescription,
            shortDescription:$shortDescription,
            ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}",`}            
            ${this.idParent != null ? `parent: "${this.idParent}"` : ''}
            genre: $genre,
            clasification: $clasification,
            isTitle: $isTitle,
            technicalDetails: $technicalDetails,
            contentDesign: $contentDesign,
            subgroup: $subgroup,
          }){
          category {
            id
            name
            image
            imageUrl
            imageMobile
            imageMobileUrl
          }
        }
        }
      `
      const variables = {
        name: this.name,
        language: this.selectedLanguage ? this.selectedLanguage.value : null,
        description: this.comment,
        type: this.typeCategorySelected,
        emisionDate:
          this.emisionDate != null ? this.fechaFormato(this.emisionDate) : '',
        duration: this.duration * 60,
        templateCategory: this.templateCategorySelected,
        isFinal: this.isFinalCategory,
        shortDescription: this.shortDescription,
        isPremium: this.isPremium,
        isBackgroundBlur: this.blurEffectStatus,
        card: this.cardSelected != null ? this.cardSelected.id : null,
        cardMobile:
          this.cardMobileSelected != null ? this.cardMobileSelected.id : null,
        client: this.userData.profile.client.id,
        mediaLocation: this.mediaOriginSelected,
        hasSubtitle: this.hasSubtitle,
        modeGrid: this.modeGrid,
        quality: this.quality !== 'No_Quality' ? this.quality : null,
        imageUrl: this.imageCategoryUrlSelected,
        imagePodcastUrl: this.imagePodcastUrlSelected,
        sponsorImageUrl: this.sponsorImageUrlSelected,
        imageMobileUrl: this.mobileImageCategoryURL,
        backgroundUrl: this.backgroundCategoryURL,
        backgroundRootUrl: this.backgroundRootURL,
        backgroundRootMobileUrl: this.backgroundRootMobileURL,
        alternativeImageUrl: this.altImageCategoryURL,
        backgroundMobileUrl: this.backgroundMobileCategoryURL,
        titleImageUrl: this.titleImageCategoryURL,
        trailerUrl: this.trailerCategoryURL,
        seoDescription: isEmpty(this.seoDescription) ? limitCharacter(this.shortDescription, 80) : this.seoDescription,
        seoTitle: isEmpty(this.seoTitle) ? limitCharacter(this.name, 80) : this.seoTitle,
        isTitle: this.isTitle,
        contentDesign: this.contentDesign,
        technicalDetails: this.technicalDetails,
        genre: this.selectedGenre ? this.selectedGenre.value : null,
        subgroup: this.subgroup?.id,
        clasification: this.selectedClasification
          ? this.selectedClasification
          : null,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      data.append('image', this.imageCategory)
      data.append('image_podcast', this.imagePodcast)
      data.append('sponsor_image', this.sponsorImage)
      data.append('image_mobile', this.mobileImageCategory)
      data.append('background', this.backgroundCategory)
      data.append('background_mobile', this.backgroundMobileCategory)
      data.append('background_root_mobile', this.backgroundRootMobile)
      data.append('background_root', this.backgroundRoot)
      data.append('title_image', this.titleImageCategory)
      data.append('alternative_image', this.altImageCategory)
      data.append('trailer', this.trailerCategory)
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          this.show = false
          showToast(this.$t('success'), 1, this)
          if (res.status === 200) {
            this.goBack()
          }
        })
        .catch(res => {
          console.log(res)
        })
    },
    getOrderCategory() {
      if (this.$route.params.order !== null && this.$route.params.order !== 0) {
        let query = ''
        if (this.idParent === null) {
          query = `
          query {
            allCategories(
              client:"${this.userData.profile.client.id}",
              orderBy:"-order",
              first:1,
              parent_Isnull: true
              ){
              pageInfo {
                hasNextPage            
            }
            totalCount
                    edges {
                      node {
                        id
                        order      
                      }
                  }
              }
          }
        `
        } else {
          query = `
          query {
            allCategories(id:"${this.idParent}"){              
                edges {
                  node {
                    childCategories(orderBy:"-order",first:1) {
                      edges {
                        node {
                          id
                          order
                          name
                        }
                      }
                    }
                  }
              }
          }
      }
        `
        }
        axios
          .post('', {
            query,
          }).then(result => {
            const category = result.data.data.allCategories.edges[0].node
            if (this.idParent === null) {
              this.newOrder = category.order + 1
            } else {
              this.newOrder = category.childCategories.edges[0].node.order + 1
            }
          }).catch(err => {
            console.log(err)
          })
      } else {
        this.newOrder = 0
      }
    },

    changeFinal() {
      switch (this.typeCategorySelected) {
        case 'NOR':
        case 'COU':
        case 'UNI':
        case 'MOD':
        case 'AUL':
        default:
          break
        case 'SER':
        case 'PRO':
        case 'LIN':
        case 'FIL':
        case 'FET':
        case 'FAC':
          this.isFinalCategory = false
          break
        case 'DIR':
        case 'POD':
        case 'FAV':
        case 'LAS':
        case 'NEW':
        case 'MOS':
        case 'FOY':
        case 'SUM':
        case 'COW':
          this.isFinalCategory = true
          break
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.rulesCategory.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    videoMaker() {
      this.video = videojs('demo')

      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
    genre() {
      axios
        .post('', {
          query: `
            query{
            allGenre(client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  name
                  description
                  isActive
                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allGenre.edges.forEach(element => {
            if (element.node.isActive) {
              this.allGenre.push({
                value: element.node.id,
                text: element.node.name,
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    goBack() {
      window.history.back()
    },
    disabledFinalOption() {
      return (this.typeCategorySelected !== 'NOR' && this.typeCategorySelected !== 'AUL' && this.typeCategorySelected !== 'UNI' && this.typeCategorySelected !== 'COU' && this.typeCategorySelected !== 'MOD')
    },
    clasification() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
          query{
            allClasification {
              edges {
                node {
                  id
                  type
                  initial
                  description
                  color

                   clasificationsClient {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allClasification.edges.forEach(element => {
            if (element.node.clasificationsClient.edges[0] != null) {
              element.node.clasificationsClient.edges.forEach(a => {
                if (a.node.id === id) {
                  this.allClasification.push(element)
                }
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#wizardCategory .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#wizardCategory .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#wizardCategory .placeholder:hover {
  background: #e0e0e0;
}

#wizardCategory .file-input {
  display: none;
}
</style>
