<template>
  <b-overlay
    variant="transparent"
    :show="showData"
  >
    <b-form @submit.prevent="updateData">
      <b-row
        v-if="language == base"
        class="infoContent mb-1"
      >
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="'Mailjet api key'"
          >
            <b-form-input
              id="mailjetApiKey"
              v-model="mailjetApiKey"
              :placeholder="$t('mail.apiMail')"
              autocomplete="new-password"
            />
            <small class="text-primary">{{
              $t('mail.apiMail') }}
            </small>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="'Mailjet api secret'"
          >
            <b-input-group>
              <b-form-input
                id="mailjetApiSecret"
                v-model="mailjetApiSecret"
                :placeholder="$t('mail.secrMail')"
                autocomplete="new-password"
                :type="passwordFieldType"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-primary">{{ $t("mail.secrMail") }}</small>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="infoContent mb-1">
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.label2')"
          >
            <b-form-input
              id="interactvtyEmailTemplate"
              v-model="interactvtyEmailTemplate"
              :placeholder="$t('mail.temt2')"
              :disabled="disabledFields"
              type="number"
              :min="0"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt2") }}</small>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.label3')"
          >
            <b-form-input
              id="welcomeUserEmailTemplate"
              v-model="welcomeUserEmailTemplate"
              :placeholder="$t('mail.temt3')"
              :disabled="disabledFields"
              type="number"
              :min="0"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt3") }}</small>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.label4')"
          >
            <b-form-input
              id="changePassEmailTemplate"
              v-model="changePassEmailTemplate"
              :placeholder="$t('mail.temt4')"
              :disabled="disabledFields"
              type="number"
              :min="0"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt4") }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="infoContent mb-1">
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.label5')"
          >
            <b-form-input
              id="changePassCompleteEmailTemplate"
              v-model="changePassCompleteEmailTemplate"
              :placeholder="$t('mail.temt11')"
              :disabled="disabledFields"
              type="number"
              :min="0"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt11") }}</small>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.label6')"
          >
            <b-form-input
              id="rememberPassEmailTemplate"
              v-model="rememberPassEmailTemplate"
              :placeholder="$t('mail.temt5')"
              :disabled="disabledFields"
              type="number"
              :min="0"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt5") }}</small>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.label7')"
          >
            <b-form-input
              id="rememberPassCompleteEmailTemplate"
              v-model="rememberPassCompleteEmailTemplate"
              :placeholder="$t('mail.temt12')"
              :disabled="disabledFields"
              type="number"
              :min="0"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt12") }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="infoContent mb-1">
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.label8')"
          >
            <b-form-input
              id="newSubEmailTemplate"
              v-model="newSubEmailTemplate"
              :placeholder="$t('mail.temt6')"
              :disabled="disabledFields"
              type="number"
              :min="0"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt6") }}</small>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.label9')"
          >
            <b-form-input
              id="newPaymentEmailTemplate"
              v-model="newPaymentEmailTemplate"
              :placeholder="$t('mail.temt7')"
              :disabled="disabledFields"
              type="number"
              :min="0"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt7") }}</small>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.label10')"
          >
            <b-form-input
              id="cancelSubEmailTemplate"
              v-model="cancelSubEmailTemplate"
              :placeholder="$t('mail.temt8')"
              :disabled="disabledFields"
              type="number"
              :min="0"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt8") }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="language == base"
        class="infoContent mb-1"
      >
        <b-col>
          <b-form-group
            class="mb-2 mr-1"
            :label="$t('mail.temt1')"
          >
            <b-form-input
              id="adminsEmailTemplate"
              v-model="adminsEmailTemplate"
              :disabled="disabledFields"
              :placeholder="$t('mail.temt1')"
              autocomplete="new-password"
            />
            <small class="text-primary">{{ $t("mail.temt1") }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="text-align: end;">
          <b-button
            type="submit"
            variant="success"
          >
            {{
              $t("dataGeneric.save")
            }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast, isEmpty } from '@/store/functions'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      adminsEmailTemplate: null,
      interactvtyEmailTemplate: null,
      welcomeUserEmailTemplate: null,
      changePassEmailTemplate: null,
      changePassCompleteEmailTemplate: null,
      rememberPassEmailTemplate: null,
      rememberPassCompleteEmailTemplate: null,
      newSubEmailTemplate: null,
      newPaymentEmailTemplate: null,
      cancelSubEmailTemplate: null,
      mailjetApiKey: null,
      showData: false,
      id: null,
      disabledFields: true,

      mailjetApiSecret: null,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },

  },
  mounted() {
    this.getClient()
  },
  methods: {
    getClient() {
      const userData = getUserData()

      axios
        .post('', {
          query: `query{
            allClients(clientId:"${userData.profile.client.clientId}"){
              edges {
                node {
                  id
                  mailjetApiKey
                  mailjetApiSecret                  

                  adminsEmailTemplate
                  changePassCompleteEmailTemplate(lang:"${this.language}")
                  rememberPassCompleteEmailTemplate(lang:"${this.language}")
                  interactvtyEmailTemplate(lang:"${this.language}")
                  welcomeUserEmailTemplate(lang:"${this.language}")
                  changePassEmailTemplate(lang:"${this.language}")
                  rememberPassEmailTemplate(lang:"${this.language}")
                  newSubEmailTemplate(lang:"${this.language}")
                  newPaymentEmailTemplate(lang:"${this.language}")
                  cancelSubEmailTemplate(lang:"${this.language}")

                  sendAdminEmails
                  sendUserAccountEmails
                  sendStripePaymentsEmails
                  sendMobilePaymentsEmails
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]
          this.id = response.node.id
          this.mailjetApiKey = response.node.mailjetApiKey
          this.mailjetApiSecret = response.node.mailjetApiSecret
          this.disabledFields = (isEmpty(this.mailjetApiKey) || isEmpty(this.mailjetApiSecret))
          this.adminsEmailTemplate = response.node.adminsEmailTemplate
          this.interactvtyEmailTemplate = response.node.interactvtyEmailTemplate
          this.welcomeUserEmailTemplate = response.node.welcomeUserEmailTemplate
          this.changePassEmailTemplate = response.node.changePassEmailTemplate
          this.changePassCompleteEmailTemplate = response.node.changePassCompleteEmailTemplate
          this.rememberPassEmailTemplate = response.node.rememberPassEmailTemplate
          this.rememberPassCompleteEmailTemplate = response.node.rememberPassCompleteEmailTemplate
          this.newSubEmailTemplate = response.node.newSubEmailTemplate
          this.newPaymentEmailTemplate = response.node.newPaymentEmailTemplate
          this.cancelSubEmailTemplate = response.node.cancelSubEmailTemplate
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateData() {
      this.showData = true
      // eslint-disable-next-line no-restricted-globals
      event.preventDefault()
      let objectVariables = []
      objectVariables = [
        { title: `interactvtyEmailTemplate${this.language}`, value: this.interactvtyEmailTemplate ? this.interactvtyEmailTemplate : null, type: 'Int' },
        { title: `welcomeUserEmailTemplate${this.language}`, value: this.welcomeUserEmailTemplate ? this.welcomeUserEmailTemplate : null, type: 'Int' },
        { title: `changePassEmailTemplate${this.language}`, value: this.changePassEmailTemplate ? this.changePassEmailTemplate : null, type: 'Int' },
        { title: `rememberPassEmailTemplate${this.language}`, value: this.rememberPassEmailTemplate ? this.rememberPassEmailTemplate : null, type: 'Int' },
        { title: `newSubEmailTemplate${this.language}`, value: this.newSubEmailTemplate ? this.newSubEmailTemplate : null, type: 'Int' },
        { title: `newPaymentEmailTemplate${this.language}`, value: this.newPaymentEmailTemplate ? this.newPaymentEmailTemplate : null, type: 'Int' },
        { title: `cancelSubEmailTemplate${this.language}`, value: this.cancelSubEmailTemplate ? this.cancelSubEmailTemplate : null, type: 'Int' },
        { title: `changePassCompleteEmailTemplate${this.language}`, value: this.changePassCompleteEmailTemplate ? this.changePassCompleteEmailTemplate : null, type: 'Int' },
        { title: `rememberPassCompleteEmailTemplate${this.language}`, value: this.rememberPassCompleteEmailTemplate ? this.rememberPassCompleteEmailTemplate : null, type: 'Int' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'adminsEmailTemplate', value: this.adminsEmailTemplate ? this.adminsEmailTemplate : null, type: 'Int' },
          { title: 'mailjetApiKey', value: this.mailjetApiKey, type: 'String' },
          { title: 'mailjetApiSecret', value: this.mailjetApiSecret, type: 'String' },
        )
      }

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateClient(id:"${this.id}",input:{\n
       `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        client{
            id
            name
          }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          query,
          variables,
        })
        .then(res => {
          messageError(res, this)
          showToast(this.$t('code.email'), 1, this)
          this.getClient()

          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('code.errorEmail'), 2, this)
          this.showData = false
        })
    },
  },
}
</script>

<!-- <style lang="scss" scoped>
.nav-link .card {
  background-color: #f6f6f6 !important;
}

.nav-link.active .card {
  background-image: linear-gradient(47deg, #7367f0, #9e95f5) !important;
}
</style> -->
