import contents from '../navigation/vertical/contents/contents'
import podcast from '../navigation/vertical/contents/podcast'
import live from '../navigation/vertical/contents/live'
import resources from '../navigation/vertical/contents/resources'
import media from '../navigation/vertical/contents/media'
import reels from '../navigation/vertical/contents/reels'
import epg from '../navigation/vertical/contents/epg'
import fastChannels from '../navigation/vertical/fastChannels/fastChannels'
import shop from '../navigation/vertical/shop/shop'
import sellers from '../navigation/vertical/shop/sellers'
import orders from '../navigation/vertical/shop/orders'
import refunds from '../navigation/vertical/shop/refunds'
import payments from '../navigation/vertical/shop/payments'
import ads from '../navigation/vertical/ads/advertising'
import displayAds from '../navigation/vertical/ads/displayAds'
import categories from '../navigation/vertical/avatar/categories'
import avatar from '../navigation/vertical/avatar/avatar'
import settingsCharacter from '../navigation/vertical/avatar/settings'
import admin from '../navigation/vertical/users/admin'
import finalUsers from '../navigation/vertical/users/final-users'
import settings from '../navigation/vertical/platform/setting'
import branding from '../navigation/vertical/platform/branding'
import menu from '../navigation/vertical/platform/menu'
import notifications from '../navigation/vertical/platform/notifications'
import slider from '../navigation/vertical/platform/slider'
import myChannel from '../navigation/vertical/platform/myChannel'
import zones from '../navigation/vertical/platform/zones'
import promotionalCode from '../navigation/vertical/platform/promotionalCode'
import purchases from '../navigation/vertical/platform/purchases'
import subscriptions from '../navigation/vertical/platform/subscriptions'
import subgroups from '../navigation/vertical/platform/subgroups'
import hbbtv from '../navigation/vertical/platform/hbbtv'
// Mapa de permisos con sus respectivos contenidos
const permissionMap = {

  // CONTENIDOS
  'contents.menu_cms_category': contents,
  'contents.menu_cms_podcast': podcast,
  'contents.menu_cms_live': live,
  'contents.menu_cms_resource': resources,
  'contents.menu_cms_file': media,
  'contents.menu_cms_reel': reels,
  'contents.menu_cms_epg': epg,

  // FAST CHANNELS
  'contents.menu_cms_fastchannel': fastChannels,

  // TIENDA
  'showroom.menu_cms_category_showroom': shop,
  'showroom.menu_cms_vendor': sellers,
  'shop.menu_cms_order': orders,
  'showroom.menu_cms_refund': refunds,
  'showroom.menu_cms_withdrawal': payments,

  // PUBLICIDAD
  'ads.menu_cms_campaing': ads,
  'ads.view_displayad': displayAds,

  // AVATAR
  'contents.menu_cms_category_character': categories,
  'contents.menu_cms_character': avatar,
  'users.menu_cms_character_settings': settingsCharacter,

  // USUARIOS
  'users.menu_cms_profile': admin,
  'users.menu_cms_vuser': finalUsers,

  // HBBTV
  'users.menu_cms_hbbtv': hbbtv,

  // PLATAFORMA
  'users.menu_cms_settings': settings,
  'users.menu_cms_branding': branding,
  'contents.menu_cms_menu': menu,
  'devices.menu_cms_notification': notifications,
  'contents.menu_cms_slider': slider,
  'contents.menu_cms_mychannel': myChannel,
  'users.add_subgroup': subgroups, // Todos los usuarios deben de tener permiso para ver pero no para crear, y solo los que tienen permisos de crear pueden ver este apartado
  'contents.menu_cms_countrygroup': zones,
  'contents.menu_cms_promotionalcode': promotionalCode,
  'contents.menu_cms_inapppurchase': purchases,
  'users.menu_cms_subscription': subscriptions,
}

export default permissionMap
