<template>
  <b-col
    id="imageElement"
    :cols="colsLength"
    :md="mdLength"
    align-self="stretch"
    :class="!disabled ? 'imageLabels cursor-pointer' : ''"
    @dragover="event => event.preventDefault()"
    @drop="!disabled ? addFiles($event) : ''"
  >
    <label class="d-flex justify-content-between mb-1">
      <div>
        {{ label }}{{ isRequired ? ' *' : '' }}
        <feather-icon
          v-if="!isEmpty(labelInfo)"
          v-b-tooltip.hover.top.v-primary="labelInfo"
          class="ml-1 cursor-pointer"
          icon="InfoIcon"
          size="15"
        />
      </div>
      <div>
        <b-img
          v-if="!isEmpty(aiText)"
          :src="aiColor"
          class="cursor-pointer mr-1"
          style="width: 18px;
          border-radius: 50%;"
          @click="clickAiImage"
        />
        <feather-icon
          v-if="imageDataUrl && isEmpty(imageData)"
          class="text-primary cursor-pointer"
          icon="DownloadIcon"
          size="15"
          @click="downloadFile"
        />
        <feather-icon
          v-if="imageDataUrl && canDelete && !disabled"
          class="text-danger cursor-pointer ml-1"
          icon="XOctagonIcon"
          size="15"
          @click="deleteImage"
        />
      </div>

    </label>
    <validation-observer
      ref="ArchivosRules"
      tag="form"
    >
      <validation-provider
        v-slot="{ errors }"
        name="imageOrigin"
        :rules="isRequired ? 'required' : ''"
      >
        <b-form-group
          v-if="mediaOriginSelected == 'BUK'"
          :state="errors.length > 0 ? false : null"
          class="
          dropContainer
            mb-2
            mr-1
            embed-responsive embed-responsive-16by9
          "
          :style="{ width: fixedWidth, height: fixedHeight }"
          @dragenter="event => event.preventDefault()"
        >
          <div
            v-if="!video"
            class="base-image-input embed-responsive-item"
            :style="{
              background: `${isEmpty(imageDataSrc)
                ? `url(${isEmpty(imageDataUrl)
                  ? imageData
                  : imageDataUrl
                }) center/contain no-repeat,transparent center/contain no-repeat`
                : ''
              }`
            }"
            @click="!disabled ? chooseImage(imageType) : ''"
          >
            <span
              v-if="isEmpty(imageDataUrl)"
              class="placeholder embed-responsive-item"
            >
              <feather-icon
                icon="ImageIcon"
                size="3.5x"
              />
              <small
                v-if="!disabled"
                style="text-align: center;"
              >
                {{ $t("media.dropFilesToCard") }}
              </small>

            </span>
            <b-img
              v-else
              :src="imageDataSrc"
              class="mb-3 embed-responsive-item"
              style="object-fit: contain;"
              fluid
              block
              rounded
            />
            <b-form-file
              :id="imageType"
              v-model="imageData"
              accept="image/*"
              class="mr-1"
              :state="errors.length > 0 ? false : null"
              style="display: none"
            />
          </div>
          <div
            v-else
            class="base-image-input embed-responsive-item"
            @click="!disabled ? chooseImage(imageType) : ''"
          >
            <span
              v-if="isEmpty(imageDataUrl)"
              class="placeholder embed-responsive-item"
            >
              <feather-icon
                icon="FilmIcon"
                size="3.5x"
              />
              <small
                v-if="!disabled"
                style="text-align: center;"
              >
                {{ $t("media.dropFilesToCard") }}
              </small>

            </span>
            <video
              v-else
              ref="fileInputTrVid"
              class="base-image-input embed-responsive-item"
            >
              <source
                :src="isEmpty(imageDataSrc)
                  ? imageDataUrl
                  : imageDataSrc
                "
                type="video/mp4"
              >
            </video>
            <b-form-file
              :id="imageType"
              v-model="imageData"
              accept="video/mp4"
              class="mr-1"
              style="display: none"
            />
          </div>
        </b-form-group>
        <b-form-group
          v-else
          :state="errors.length > 0 ? false : null"
          class="
            mb-2
            mr-1
            embed-responsive embed-responsive-16by9
            "
        >
          <div
            v-if="!video"
            class="base-image-input embed-responsive-item urlContainer"
            :style="{
              background: `url('${imageDataUrl}') center/contain no-repeat,transparent center/contain no-repeat`
            }"
            @click="!disabled ? chooseImage(imageType) : ''"
          >
            <span
              v-if="isEmpty(imageDataUrl)"
              class="placeholder"
            >
              <feather-icon
                icon="ImageIcon"
                size="3.5x"
              />
            </span>
            <input
              v-model="imageDataUrl"
              type="text"
              hidden
              :state="errors.length > 0 ? false : null"
            >
          </div>
          <div
            v-else
            class="base-image-input embed-responsive-item"
            @click="!disabled ? chooseImage(imageType) : ''"
          >
            <span
              v-if="isEmpty(imageDataUrl)"
              class="placeholder"
            >
              <feather-icon
                icon="FilmIcon"
                size="3.5x"
              />
            </span>
            <video
              v-else
              ref="fileInputTrVid"
              class="base-image-input embed-responsive-item"
            >
              <source
                :src="imageDataUrl"
                type="video/mp4"
              >
            </video>
          </div>
        </b-form-group>
        <b-form-invalid-feedback v-if="isEmpty(imageDataUrl)">
          {{ $t('requiere') + ' ' + label }}
        </b-form-invalid-feedback>
      </validation-provider>
    </validation-observer>
  </b-col>
</template>

<script>
import {
  BCol,
  BFormFile,
  BFormInvalidFeedback,
  VBTooltip,
  BFormGroup,
  BImg,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { base64Encode, isEmpty } from '@/store/functions'

const aiColor = require('@/assets/images/icons/ai.png')

export default {
  components: {
    BCol,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormGroup,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    imageOrigin: {
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    mediaOriginSelected: {
      type: String,
      default: 'BUK',
    },
    labelInfo: {
      type: String,
      default: '',
    },
    AiElement: {
      type: String,
      default: '',
    },
    imageType: {
      type: String,
      default: '',
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Boolean,
      default: false,
    },
    colsLength: {
      default: 4,
    },
    mdLength: {
      default: 3,
    },
    fixedWidth: {
      type: String,
      default: '',
    },
    fixedHeight: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      aiColor,
      imageData: null,
      imageDataSrc: null,
      imageDataUrl: '',
      aiText: '',
      hbbtvImageSize: false,
    }
  },
  watch: {
    imageData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          if (this.mediaOriginSelected === 'BUK') {
            base64Encode(newValue)
              .then(value => {
                this.imageDataSrc = value
                this.imageDataUrl = value
                this.$emit('saveImage', { data: this.imageData, type: this.imageType })
              })
              .catch(() => {
                this.imageDataSrc = null
              })
          } else this.$emit('saveImage', { data: this.imageDataUrl, type: this.imageType })
        } else {
          this.imageDataSrc = null
        }
      }
    },
    imageOrigin() {
      this.imageDataUrl = this.imageOrigin
    },
  },
  mounted() {
    this.imageDataUrl = this.imageOrigin
    const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
    if (mode === 'develop' || mode === 'development') {
      this.aiText = this.AiElement
    }
  },
  methods: {
    isEmpty,
    clickAiImage() {
      this.$emit('clickAiImage', this.aiText)
    },
    chooseImage(imageType) {
      if (this.mediaOriginSelected === 'BUK') {
        document.getElementById(imageType).click()
      } else if (this.mediaOriginSelected === 'DIR') {
        this.$swal({
          title: this.$t('code.indicar'),
          input: 'url',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: this.$t('dataGeneric.save'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.value) {
            this.imageDataUrl = result.value
            this.$emit('saveImage', { data: this.imageDataUrl, type: this.imageType })
          }
        })
      }
    },
    downloadFile() {
      if (this.imageDataUrl) {
        const link = document.createElement('a')
        link.href = this.imageDataUrl
        link.setAttribute('download', '')
        link.setAttribute('target', '_blank') // Abre el enlace en una nueva pestaña

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    deleteImage() {
      if (this.imageData) {
        this.imageData = null
        this.imageDataUrl = null
        this.imageDataSrc = null

        this.$emit('saveImage', { data: this.imageData, type: this.imageType })
      } else { this.$emit('deleteImage', this.imageType) }
    },
    addFiles(event) {
      event.dataTransfer.files.forEach(element => {
        this.imageData = element
      })

      event.preventDefault()
    },
  },
}
</script>

<style lang="scss">
#imageElement .base-image-input {
  display: block;
  background-size: contain;
  background-position: center center;
}

#imageElement .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#imageElement .infoBadge {
  width: -webkit-fill-available;
  font-size: initial;
  line-height: inherit;
  white-space: break-spaces;
  text-align: inherit;
}

#imageElement.imageLabels {
  flex-direction: column !important;
}

#imageElement .dropContainer {
  border: 3px dashed rgb(238, 238, 194);
}

#imageElement .urlContainer {
  border: 2px solid rgb(238, 238, 194);
}
</style>
