<template>
  <section id="final-users-v2">
    <b-row>
      <b-col>
        <h1>{{ $t("administrators.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("administrators.edit") }}
        </h5>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-end">
          <router-link to="/users/administrators">
            <b-button variant="danger">
              {{ $t("dataGeneric.goBack") }}
            </b-button>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <div class="mt-2">
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t("User") }}</span>
          </template>

          <user-setting-general />
        </b-tab>
        <b-tab
          v-if="superUser"
          lazy
        >
          <template #title>
            <feather-icon
              icon="SmartphoneIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t('twoFactor.vincular') }}</span>
          </template>
          <account-setting-2fa />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="ToolIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t("GestionPermisos") }}</span>
          </template>

          <user-setting-permissions />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t('changPass') }}</span>
          </template>

          <user-setting-password />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t("administrators.info") }}</span>
          </template>

          <user-setting-information />
        </b-tab>
      </b-tabs>
    </div>
  </section>
</template>

<script>
// import router from '@/router'
import {
  BTabs,
  BTab,
  BCol,
  BRow,
  BButton,

} from 'bootstrap-vue'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import UserSettingGeneral from '../../setting/UserSettingGeneral.vue'
import UserSettingPassword from '../../setting/UserSettingPassword.vue'
import UserSettingInformation from '../../setting/UserSettingInformation.vue'
import UserSettingPermissions from '../../setting/UserSettingPermissions.vue'
import userStoreModule from '../administratorsStoreModule'
import AccountSetting2fa from '../../profile/AccountSetting2fa.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCol,
    BRow,
    BButton,
    UserSettingGeneral,
    UserSettingPassword,
    AccountSetting2fa,
    UserSettingInformation,
    UserSettingPermissions,
  },
  data() {
    return {
      superUser: false,
    }
  },
  mounted() {
    this.superUser = getUserData().isSuperuser
  },
  beforeDestroy() {
    if (store.hasModule('admin-user')) store.unregisterModule('admin-user')
  },
  beforeCreate() {
    if (!store.hasModule('admin-user')) store.registerModule('admin-user', userStoreModule)
  },
}
</script>
