/* eslint-disable no-else-return */
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import { isEmpty } from '@/store/functions'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              {
                allProfiles(
                    first: ${queryParams.perPage},
                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                    username: "${queryParams.q}",
                    client:"${userData.profile.client.id}",
                    #excludeName:${!userData.isSuperuser},
                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                  ) {
                  totalCount
                  edgeCount
                  edges {
                    node {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      profileVendor {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      avatar
                      description
                      subgroup {
                        id
                        name
                      }
                      customuserPtr {
                        groups {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
          })
          .then(response => {
            const allProfiles = response
            const searchName = response.data.data.allProfiles.edges

            axios
              .post('', {
                // params: queryParams
                query: `
                              {
                                allProfiles(
                                    first: ${queryParams.perPage},
                                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                                    email: "${queryParams.q}",
                                    client:"${userData.profile.client.id}",
                                    #excludeName:${!userData.isSuperuser},
                                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                                  ) {
                                  totalCount
                                  edgeCount
                                  edges {
                                    node {
                                      id
                                      username
                                      firstName
                                      lastName
                                      email
                                      isActive
                                      profileVendor {
                                        edges {
                                          node {
                                            id
                                            name
                                          }
                                        }
                                      }
                                      avatar
                                      description
                                      subgroup {
                                        id
                                        name
                                      }
                                      customuserPtr {
                                        groups {
                                          edges {
                                            node {
                                              id
                                              name

                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            `,
              }).then(result => {
                const searchMail = result.data.data.allProfiles.edges
                let nuevo = [...searchName, ...searchMail]

                const hash = {}
                nuevo = nuevo.filter(current => {
                  const exists = !hash[current.node.id]
                  hash[current.node.id] = true
                  return exists
                })

                allProfiles.data.data.allProfiles.edges = nuevo

                resolve(allProfiles)
              }).catch(err => {
                reject(err)
              })
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchUser(ctx, { id }) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              {
                __type(name: "ProfileNode") {
                  fields {
                    name
                    description
                    type {
                      kind
                      ofType {
                        name
                      }
                      name
                      description
                      enumValues {
                        description
                        name
                      }
                    }
                  }
                }
                  allProfiles(id: "${id}",
                  client:"${userData.profile.client.id}"
                  ) {
                    edges {
                      node {
                      id
                        username
                        firstName
                        lastName
                        email
                        isActive
                        dateJoined
                        lastLogin
                        profileVendor {
                          edges {
                            node {
                              id
                              vendorName
                            }
                          }
                        }
                        avatar
                        description
                        subgroup {
                          id
                          name
                        }
                        customuserPtr {
                          id
                          groups {
                            edges {
                              node {
                                id
                                name

                              }
                            }
                          }
                        }
                      }
                    }
                  }
                allGroups {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                allVendors {
                  edges {
                    node {
                      id
                      vendorName
                    }
                  }
                }
              }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroups() {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              query
            {
              allGroups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    mutateUser(ctx, data) {
      const {
        user, groups, vendor, categories, subgroup, description, avatar, querySelect, view,
      } = data
      const permisosString = JSON.stringify(groups)
      const dataAvatar = new FormData()
      const dataAdmin = new FormData()

      const config = {
        timeout: 1000 * 60 * 30,
      }
      function getIdArray(array) {
        const res = []
        for (let i = 0; i < array.length; i += 1) {
          res.push(array[i].node.id)
        }
        return res
      }
      let queryAdmin
      let queryProfile
      /*
        Se realizara en la vista de 'Mi Perfil'(Profile)[Concretamente en el apartado de AccountSettingPassword] y 'Editar Administrador'(EditAdmin)
        ________________________________________________________________
        Datos a actualizar:
        - groupsAdd (Permisos)
        - password  (Cambios de contraseña)
      */
      /* Version con mas datos
       const queryUpdateCustomuser = `
      mutation{
        updateCustomuser(id: "${user.id}" input: {
          groupsAdd: ${permisosString},
          ${user.password != null && user.password.length !== 0 ? `password: "${user.password}",` : ' '}
          firstName: "${user.firstName}",
          lastName: "${user.lastName}",
          ${user.email !== '' ? `email: "${user.email}", ` : ' '}}) {
          customUser {
            id
            username
            firstName
            lastName
            email
            isActive
            dateJoined
            lastLogin

            groups {
              edges {
                node {
                  id
                  name
            }
          }
        }
    }}}
      ` */
      const queryUpdateCustomuser = `
      mutation{
        updateCustomuser(id: "${user.id}"
          input: {
              groupsAdd: ${permisosString},
              ${user.password != null && user.password.length !== 0 ? `password: "${user.password}",` : ' '}
          }
        ) {
            customUser {
              id
              username
              firstName
              lastName
              email
              isActive
              dateJoined
              lastLogin
              groups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `
      // TODO => Añadir valor identificaivo de vista, si esta en mi perfil no añadir el subgurpo
      /*
        Se realizara en la vista de 'Mi Perfil'(Profile)[Concretamente en el apartado de AccountSettingGeneral] y 'Editar Administrador'(EditAdmin)
        ________________________________________________________________
        Datos a actualizar:
        - subgroup => Solo se añade si esta en la vista 'Editar Administrador'(EditAdmin)
        - description
        - firstName
        - lastName
        - email
        - profileVendor
        - sponsorCategory
      */
      // ${description ? '$description:String,' : ' '}
      const queryUpdateProfile = `
        mutation(
        ${view !== 'Profile' ? '$subgroup:ID,' : ' '}
        ${view !== 'Profile' && description ? '$description:String,' : ' '}
        ${view === 'Profile' ? '$description:String,' : ' '}
        ) {
          updateProfile(id:"${user.id}",input:{
            ${view !== 'Profile' ? 'subgroup: $subgroup,' : ' '}
            ${view === 'Profile' ? 'description: $description,' : ' '}
            ${view !== 'Profile' && description ? 'description: $description,' : ' '}
            firstName: "${user.firstName}",
            lastName: "${user.lastName}",
            ${user.email !== '' ? `email: "${user.email}", ` : ' '}
            ${!isEmpty(vendor) ? `profileVendor:"${vendor.id}",` : ''}
            ${view === 'Admin' ? `sponsorCategory: [${getIdArray(categories)}]` : ''}}){
            profile{
              id
              username
              firstName
              lastName
              email
              isActive
              dateJoined
              lastLogin
              description
              avatar
              profileVendor{
                edges{
                  node{
                    id
                    vendorName
                  }
                }
              }
            }
          }
        }
        `
      // Selector dinamico de Query | Seleccionas que query usar a la hora de pasarle los valores al data
      if (querySelect) {
        // console.log(' querySelect => ', querySelect)
        switch (querySelect) {
          // Ac
          case 'UpdateCustomuser':
            queryAdmin = queryUpdateCustomuser
            // console.log(queryUpdateCustomuser)
            dataAdmin.append('query', queryAdmin)
            // console.log('Query empleada => UpdateCustomuser')
            break
          case 'UpdateProfile':
            queryProfile = queryUpdateProfile
            dataAvatar.append('query', queryProfile)
            // console.log('Query empleada => UpdateProfile')
            break
          case 'UpdateAdmin':
            queryProfile = queryUpdateProfile
            queryAdmin = queryUpdateCustomuser
            dataAvatar.append('query', queryProfile)
            dataAdmin.append('query', queryAdmin)
            // console.log('Query empleada => UpdateAdmin')

            break
          default:
            break
        }
      } else {
        console.error('No hay query seleccionada => ', querySelect)
      }

      const variables = {
        subgroup,
        description,
      }
      dataAvatar.append('variables', JSON.stringify(variables))
      if (avatar) {
        // console.log('StoreModule - foto => ', avatar)
        dataAvatar.append('avatar', avatar)
      }
      if (querySelect === 'UpdateAdmin') {
        // console.log('querySelect === UpdateAdmin')
        return new Promise((resolve, reject) => {
          const requests = [
            axios.post('', dataAvatar, config),
            axios.post('', dataAdmin, config),
          ]

          // Usamos Promise.allSettled para esperar que ambas solicitudes se completen
          Promise.allSettled(requests)
            .then(results => {
              const errors = results.filter(result => result.status === 'rejected')
              const successes = results.filter(result => result.status === 'fulfilled')

              // Si hay errores, rechazamos la promesa
              if (errors.length > 0) {
                // console.error('Errores en las solicitudes:', errors.map(e => e.reason))
                reject(errors.map(e => e.reason)) // Rechazar con los errores
              } else {
                // Si todo fue exitoso, resolvemos con los resultados exitosos
                // console.log('Todas las solicitudes fueron exitosas:', successes.map(s => s.value))
                resolve(successes.map(s => s.value)) // Resolver con los valores exitosos
              }
            })
            .catch(error => {
              // Este catch es para cualquier error no controlado fuera de Promise.allSettled
              console.error('Error general al realizar las solicitudes:', error)
              reject(error) // Rechazar con el error general
            })
        })
      } else if (querySelect === 'UpdateCustomuser') {
        // console.log('querySelect: OTRA')
        // console.log('dataAdmin => ', dataAdmin)
        return new Promise((resolve, reject) => {
          axios
            .post('', dataAdmin, config)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      } else {
        // console.log('querySelect: OTRA')
        // console.log('dataAvatar => ', dataAvatar)
        return new Promise((resolve, reject) => {
          axios
            .post('', dataAvatar, config)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
    },
    //
    fetchSubGroups() {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
            {
              allSubgroups(client:"${userData.profile.client.id}",) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateUserGroups(ctx, { customUserId, groups }) {
      const permisosString = JSON.stringify(groups)

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              mutation {
              updateCustomuser(id: "${customUserId}" input: { groupsAdd: ${permisosString} }) {
                              customUser {
                  id
                  username
                  firstName
                                groups {
                                  edges {
                                    node {
                        id
                      }
                    }
                  }
                }
              }
            }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              mutation{
                deleteProfile(id: "${id}") {
                  found
                  deletedId
                }
              }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, data) {
      const json = getUserData()
      const client = json.profile.client.id
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
            mutation {
              createProfile(input: {
                username: "${data.username}",
                password: "${data.password}",
                firstName: "${data.firstName}",
                lastName: "${data.lastName}",
                email: "${data.email}",
                client: "${client}"
              }) {
                profile {
                    id
                  }
                }
              }
              `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
