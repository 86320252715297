export default [
  /* SETTINGS */
  {
    path: '/platform/settings',
    name: 'settings',
    meta: { permission: ['users.view_client', 'users.change_client', 'users.menu_cms_settings'] },
    component: () => import('@/views/platform/settings/Settings.vue'),
  },
  /* MYCHANNEL */
  {
    path: '/platform/my-channel',
    name: 'myChannel',
    meta: { permission: ['contents.view_extendedtag'] },
    component: () => import('@/views/platform/my-channel/MyChannel.vue'),
  },
  {
    path: '/platform/my-channel/:id',
    name: 'myChannel-list',
    meta: { activeItem: 'myChannel', permission: ['contents.view_extendedtag'] },
    component: () => import('@/views/platform/my-channel/MyChannel.vue'),
  },
  {
    path: '/platform/my-channel/edit/:id',
    name: 'myChannel-edit',
    meta: { activeItem: 'myChannel', permission: ['contents.view_extendedtag', 'contents.change_extendedtag'] },
    component: () => import('@/views/platform/my-channel/edit/index.vue'),
  },
  {
    path: '/platform/my-channel/edit/:id/languages',
    meta: { activeItem: 'myChannel', permission: ['contents.view_extendedtag', 'contents.change_extendedtag'] },
    component: () => import('@/views/platform/my-channel/edit/index.vue'),
  },
  {
    path: '/platform/my-channel/wizard/:parent',
    name: 'myChannel-create',
    meta: { activeItem: 'myChannel', permission: ['contents.view_extendedtag', 'contents.add_extendedtag'] },
    component: () => import('@/views/platform/my-channel/FormWizardChannel.vue'),
  },
  /* BRANDING */
  {
    path: '/platform/branding',
    name: 'branding',
    meta: { permission: ['users.view_client', 'users.menu_cms_branding', 'users.change_client'] },
    component: () => import('@/views/platform/branding/Branding.vue'),
  },
  /* HBBTV */
  {
    path: '/platform/hbbtv',
    name: 'hbbtv',
    meta: { permission: ['users.menu_cms_hbbtv'] },
    component: () => import('@/views/platform/hbbtv/Hbbtv.vue'),
  },
  /* MENÚ */
  {
    path: '/platform/menu',
    name: 'menu',
    meta: { permission: ['contents.view_menu'] },
    component: () => import('@/views/platform/menu/menuList.vue'),
  },
  /* NOTIFICATIONS */
  {
    path: '/platform/notifications',
    name: 'notifications',
    meta: { activeItem: 'notifications', permission: ['devices.view_notificationpush'] },
    component: () => import('@/views/platform/notifications/PushNotification.vue'),
  },
  {
    path: '/platform/edit/notifications/:id',
    name: 'edit-notifications',
    meta: { activeItem: 'notifications', permission: ['devices.view_notificationpush', 'devices.change_notificationpush'] },
    component: () => import('@/views/platform/notifications/edit/index.vue'),
  },
  {
    path: '/platform/edit/notifications/:id/languages',
    meta: { activeItem: 'notifications', permission: ['devices.view_notificationpush', 'devices.change_notificationpush'] },
    component: () => import('@/views/platform/notifications/edit/index.vue'),
  },
  {
    path: '/platform/create/notifications',
    name: 'create-notifications',
    meta: { activeItem: 'notifications', permission: ['devices.view_notificationpush', 'devices.add_notificationpush'] },
    component: () => import('@/views/platform/notifications/create/index.vue'),
  },
  {
    path: '/platform/old/notifications',
    name: 'create-notifications',
    meta: { activeItem: 'notifications', permission: ['devices.view_notificationpush'] },
    component: () => import('@/views/platform/notifications/OldPushNotification.vue'),
  },
  /* SLIDER */
  {
    path: '/platform/slider',
    name: 'slider',
    meta: { activeItem: 'slider', permission: ['contents.view_slider'] },
    component: () => import('@/views/platform/slider/slider.vue'),
  },
  {
    path: '/platform/slider/create-slide/:idSlider/:type/:position',
    name: 'slide-create',
    meta: { activeItem: 'slider', permission: ['contents.add_slide'] },
    component: () => import('@/views/platform/slider/WizardSlide.vue'),
  },
  {
    path: '/platform/slider/create-slide/:idSlider/:type/:position',
    name: 'slide-create-sponsor',
    meta: { activeItem: 'slider', permission: ['contents.add_slide'] },
    component: () => import('@/views/platform/slider/WizardSlide.vue'),
  },
  {
    path: '/platform/slider/edit-slide/:id/:type',
    name: 'slide-edit',
    meta: { activeItem: 'slider', permission: ['contents.view_slide', 'contents.change_slide'] },
    component: () => import('@/views/platform/slider/edit/index.vue'),
  },
  {
    path: '/platform/slider/edit-slide/:id/:type/languages',
    meta: { activeItem: 'slider', permission: ['contents.view_slide', 'contents.change_slide'] },
    component: () => import('@/views/platform/slider/edit/index.vue'),
  },

  /* ZONES */
  {
    path: '/platform/zones',
    name: 'zones',
    meta: { activeItem: 'zones', permission: ['contents.view_countrygroup'] },
    component: () => import('@/views/platform/zones/Zones.vue'),
  },

  /* INAPPPURCHASE */
  {
    path: '/platform/purchases',
    name: 'purchases',
    meta: { permission: ['contents.view_inapppurchase'] },

    component: () => import('@/views/platform/purchases/Purchases.vue'),
  },
  {
    path: '/platform/purchases/edit/:id',
    name: 'purchases-edit',
    meta: { activeItem: 'purchases', permission: ['contents.change_inapppurchase', 'contents.view_inapppurchase'] },
    component: () => import('@/views/platform/purchases/EditPurchases.vue'),
  },
  {
    path: '/platform/purchases/create',
    name: 'purchases-create',
    meta: { activeItem: 'purchases', permission: ['contents.add_inapppurchase'] },
    component: () => import('@/views/platform/purchases/FormWizardPurchases.vue'),
  },

  /* SUBSCRIPTIONS */
  {
    path: '/platform/subscriptions',
    name: 'subscriptions',
    meta: { permission: ['users.view_subscription'] },
    component: () => import('@/views/platform/subscriptions/Subscriptions.vue'),
  },
  {
    path: '/platform/subscriptions/edit/:id',
    name: 'subscriptions-edit',
    meta: { activeItem: 'subscriptions', permission: ['users.view_subscription', 'users.change_subscription'] },
    component: () => import('@/views/platform/subscriptions/EditSubscriptions.vue'),
  },

  {
    path: '/platform/subscriptions/create',
    name: 'subscriptions-create',
    meta: { activeItem: 'subscriptions', permission: ['users.add_subscription'] },
    component: () => import('@/views/platform/subscriptions/FormWizardSubscriptions.vue'),
  },
  /* PROMOTIONAL CODES */
  {
    path: '/platform/promotional-codes',
    name: 'promotional-codes',
    meta: { permission: ['contents.view_promotionalcode'] },
    component: () => import('@/views/platform/promotional-codes/index.vue'),
  },
  {
    path: '/platform/promotional-codes/languages',
    meta: { permission: ['contents.view_promotionalcode'] },
    component: () => import('@/views/platform/promotional-codes/index.vue'),
  },
  {
    path: '/platform/use-promotional-codes',
    name: 'use-codes',
    meta: { permission: ['contents.view_promotionalcodeuses'] },
    component: () => import('@/views/platform/promotional-codes/usePromotionalCodes.vue'),
  },

  /* SUBGRUPOS */
  {
    path: '/platform/subgroups',
    name: 'subgroups',
    meta: { permission: ['users.add_subgroup'] },
    component: () => import('@/views/platform/subgroups/subgroups.vue'),
  },
  {
    path: '/platform/subgroups/edit/:id',
    name: 'subgroups-edit',
    meta: { permission: ['users.change_subgroup', 'users.view_subgroup'] },
    component: () => import('@/views/platform/subgroups/subgroups.vue'),
  },

]
