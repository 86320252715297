<template>
  <section id="edit-notifications">
    <b-tabs
      v-model="tabIndex"
      lazy
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>{{ $t("contents.categoryData") }}</span>
        </template>
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t("contents.categoryData") }}</b-card-title>
            <b-button
              :disabled="!editNotification"
              variant="success"
              @click="validationForm"
            >
              {{ $t("dataGeneric.save") }}
            </b-button>
          </b-card-header>
          <b-card-body>
            <validation-observer
              id="modal_notifications"
              ref="notification"
              tag="form"
            >
              <b-overlay
                :show="show"
                variant="transparent"
              >
                <b-row>
                  <b-col v-if="language == base">
                    <validation-provider
                      v-slot="{ errors }"
                      name="nameNotification"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('dataGeneric.name') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="name"
                          v-model="nameNotification"
                          :disabled="!editNotification"
                          :state="errors.length > 0 ? false : null"
                          maxlength="255"
                          autocomplete="new-password"
                          :placeholder="$t('firstName')"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="title"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('title') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="title"
                          v-model="title"
                          :disabled="!editNotification"
                          maxlength="255"
                          :state="errors.length > 0 ? false : null"
                          autocomplete="new-password"
                          :placeholder="$t('title')"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <b-form-group :label="$t('tiposNot')">
                      <b-form-select
                        id="typeSelected"
                        v-model="typeSelected"
                        :disabled="!editNotification"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        :options="typeNotifications"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="typeSelected == 'TEX'">
                  <!-- TEXTO -->
                  <b-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('notificationPush.text') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="description"
                          :disabled="!editNotification"
                          rows="5"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('notificationPush.text')"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="typeSelected == 'URL'">
                  <!-- URL -->
                  <b-col cols="12">
                    <b-form-group :label="$t('notificationPush.url') + ' *'">
                      <validation-provider
                        v-slot="validationContext"
                        name="url"
                        rules="required|url"
                      >
                        <b-form-input
                          id="url"
                          v-model="url"
                          :disabled="!editNotification"
                          maxlength="255"
                          :state="validationContext.errors.length > 0 ? false : null"
                          placeholder="https://www.ejemplo.com"
                        />
                        <small class="text-danger">{{ validationMedia(validationContext) }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('notificationPush.text') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="description"
                          :disabled="!editNotification"
                          rows="5"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('notificationPush.text')"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="typeSelected == 'CON'">
                  <b-col
                    v-if="language == base"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="content"
                      rules="required"
                    >
                      <b-form-group
                        label="Contenido *"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <div class="d-flex">
                          <b-form-input
                            id="content"
                            v-model="contentName"
                            :disabled="true"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Contenido"
                          />
                          <b-button
                            variant="primary"
                            :disabled="!editNotification"
                            class="ml-2"
                            @click="$refs['cont-modal'].show()"
                          >
                            {{ $t('select') }}
                          </b-button>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('notificationPush.text') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="description"
                          :disabled="!editNotification"
                          rows="5"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('notificationPush.text')"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="typeSelected == 'CAC'">
                  <b-col v-if="language == base">
                    <validation-provider
                      v-slot="{ errors }"
                      name="category"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('catCont') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <div class="d-flex">
                          <b-form-input
                            id="category"
                            v-model="categoryName"
                            :disabled="!editNotification"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('catCont')"
                          />
                          <b-button
                            variant="primary"
                            :disabled="!editNotification"
                            class="ml-2"
                            @click="$refs['cat-modal'].show()"
                          >
                            {{ $t('select') }}
                          </b-button>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('notificationPush.text') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="description"
                          :disabled="!editNotification"
                          :state="errors.length > 0 ? false : null"
                          rows="5"
                          :placeholder="$t('notificationPush.text')"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="typeSelected == 'CAP'">
                  <b-col
                    v-if="language == base"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="categoryProd"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('slider.productCategory') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <div class="d-flex">
                          <b-form-input
                            id="categoryProd"
                            v-model="categoryProdName"
                            :disabled="!editNotification"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('slider.productCategory')"
                          />
                          <b-button
                            variant="primary"
                            :disabled="!editNotification"
                            class="ml-2"
                            @click="$refs['cat-product-modal'].show()"
                          >
                            {{ $t('select') }}
                          </b-button>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('notificationPush.text') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="description"
                          :disabled="!editNotification"
                          :state="errors.length > 0 ? false : null"
                          rows="5"
                          :placeholder="$t('notificationPush.text')"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="typeSelected == 'PRO'">
                  <b-col
                    v-if="language == base"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="product"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('producto') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <div class="d-flex">
                          <b-form-input
                            id="product"
                            v-model="productName"
                            :disabled="!editNotification"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('producto')"
                          />
                          <b-button
                            variant="primary"
                            :disabled="!editNotification"
                            class="ml-2"
                            @click="$refs['product-modal'].show()"
                          >
                            {{ $t('select') }}
                          </b-button>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('notificationPush.text') + ' *'"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="description"
                          :disabled="!editNotification"
                          rows="5"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('notificationPush.text')"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <b-form-group :label="$t('dispositivos')">
                      <b-form-select
                        id="target"
                        v-model="target"
                        :disabled="!editNotification"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        :options="typeTarget"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('typeSends')">
                      <b-form-select
                        id="sendType"
                        v-model="sendType"
                        :disabled="!editNotification"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        :options="typeSended"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <b-form-group
                      v-if="sendType === 'CRON'"
                      :label="$t('dateSend')"
                    >
                      <flat-pickr
                        id="example-datepicker"
                        v-model="sendDate"
                        :disabled="!editNotification"
                        class="form-control"
                        :placeholder="getPlaceholder()"
                        :config="{
                          enableTime: true,
                          enableSeconds: true,
                          dateFormat: 'Y-m-d H:i:S',
                          minDate: minDate,
                        }"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group
                  v-if="language == base"
                  :label="$t('sendToUsers')"
                >
                  <b-row>
                    <b-col>
                      <b-form-select
                        v-model="userTarget"
                        :disabled="!editNotification"
                        :options="userTargetOptions"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-row class="mb-2">
                  <image-element-vue
                    :disabled="!editNotification"
                    :md-length="3"
                    :image-origin="imageSelected"
                    :image-type="'imageData' + language"
                    :label="$t('Image')"
                    :can-delete="imageSelected ? true : false"
                    @saveImage="loadImage"
                    @deleteImage="deleteImage"
                  />
                </b-row>
                <b-row v-if="language == base">
                  <b-col cols="2">
                    <p>{{ $t('notification.active') }}</p>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      v-model="isActive"
                      name="is-active"
                      switch
                    />
                  </b-col>
                </b-row>
              </b-overlay>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-tab>
      <b-tab v-if="language == base && !hide">
        <template #title>
          <feather-icon icon="PieChartIcon" />
          <span>{{ $t("datosNotif") }}</span>
        </template>

        <b-row class="match-height">
          <b-col cols="9">
            <b-card>
              <b-overlay
                :variant="'transparent'"
                :opacity="1.00"
                :blur="'2px'"
                :show="showMetrics"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <template
                  v-if="!sended"
                  #overlay
                >
                  <b-row class="text-center mx-0">
                    <b-col
                      cols="12"
                      class="d-flex align-items-between flex-column py-1"
                    >
                      <b-card-text class="mb-0">
                        <feather-icon
                          icon="AlertTriangleIcon"
                          class="text-warning"
                          size="30"
                        />
                      </b-card-text>
                      <h6 class=" mt-2 mb-50">
                        {{ $t('notSend') }}
                      </h6>
                    </b-col>
                  </b-row>
                </template>
                <b-card-header>
                  <b-card-title>
                    {{ $t("devicesSend") }}
                  </b-card-title>
                  <span class="text-nowrap text-muted">
                    {{ $t('totalDevices', { x: totalNotification }) }}</span>
                </b-card-header>
                <b-card-body>
                  <b-table
                    :items="devicesData"
                    responsive
                    :fields="[
                      { key: 'devices', label: $t('dispositivos'), sortable: false },
                      { key: 'total', label: $t('settingTabs'), sortable: false },
                      { key: 'active', label: $t('activados'), sortable: false },
                      { key: 'deactive', label: $t('deactive'), sortable: false },
                      { key: 'pending', label: $t('PENDING'), sortable: false },
                      { key: 'inProccess', label: $t('IN_PROCESS'), sortable: false },
                      { key: 'sended', label: $t('SEND'), sortable: false },
                      { key: 'error', label: $t('Error'), sortable: false },
                    ]"
                    class="text-left"
                  >
                    <template #cell(devices)="data">
                      <div class="d-flex align-items-center">
                        <b-img
                          :src="data.item.image"
                          alt="browser img"
                          class="mr-1"
                          height="30"
                        />
                        {{ data.item.name }}
                      </div>
                    </template>
                    <template #cell(total)="data">
                      <div class="">
                        <ICountUp
                          v-if="data.item.total >= 0"
                          :delay="1000"
                          :end-val="data.item.total"
                          :options="{
                            useEasing: true,
                            useGrouping: true,
                            separator: ',',
                            decimal: '.',
                            prefix: '',
                            suffix: '',
                          }"
                        />
                      </div>
                    </template>
                    <template #cell(actives)="data">
                      <div class="">
                        <ICountUp
                          v-if="data.item.active >= 0"
                          :delay="1000"
                          :end-val="data.item.active"
                          :options="{
                            useEasing: true,
                            useGrouping: true,
                            separator: ',',
                            decimal: '.',
                            prefix: '',
                            suffix: '',
                          }"
                        />
                      </div>
                    </template>
                    <template #cell(deactive)="data">
                      <div class="">
                        <ICountUp
                          v-if="data.item.deactive >= 0"
                          :delay="1000"
                          :end-val="data.item.deactive"
                          :options="{
                            useEasing: true,
                            useGrouping: true,
                            separator: ',',
                            decimal: '.',
                            prefix: '',
                            suffix: '',
                          }"
                        />
                      </div>
                    </template>
                    <template #cell(pending)="data">
                      <div class="">
                        <ICountUp
                          v-if="data.item.pending >= 0"
                          :delay="1000"
                          :end-val="data.item.pending"
                          :options="{
                            useEasing: true,
                            useGrouping: true,
                            separator: ',',
                            decimal: '.',
                            prefix: '',
                            suffix: '',
                          }"
                        />
                      </div>
                    </template>
                    <template #cell(inProccess)="data">
                      <div class="">
                        <ICountUp
                          v-if="data.item.inProccess >= 0"
                          :delay="1000"
                          :end-val="data.item.inProccess"
                          :options="{
                            useEasing: true,
                            useGrouping: true,
                            separator: ',',
                            decimal: '.',
                            prefix: '',
                            suffix: '',
                          }"
                        />
                      </div>
                    </template>
                    <template #cell(sended)="data">
                      <div class="">
                        <ICountUp
                          v-if="data.item.sended >= 0"
                          :delay="1000"
                          :end-val="data.item.sended"
                          :options="{
                            useEasing: true,
                            useGrouping: true,
                            separator: ',',
                            decimal: '.',
                            prefix: '',
                            suffix: '',
                          }"
                        />
                      </div>
                    </template>
                    <template #cell(error)="data">
                      <div class="">
                        <ICountUp
                          v-if="data.item.error >= 0"
                          :delay="1000"
                          :end-val="data.item.error"
                          :options="{
                            useEasing: true,
                            useGrouping: true,
                            separator: ',',
                            decimal: '.',
                            prefix: '',
                            suffix: '',
                          }"
                        />
                      </div>
                    </template>
                  </b-table>
                </b-card-body>
              </b-overlay>
            </b-card>
          </b-col>
          <!-- PERCENTAGE SEND TYPES -->
          <b-col lg="3">
            <b-card>
              <b-overlay
                :variant="'transparent'"
                :opacity="1.00"
                :blur="'2px'"
                :show="showMetrics"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <template
                  v-if="!sended"
                  #overlay
                >
                  <b-row class="text-center mx-0">
                    <b-col
                      cols="12"
                      class="d-flex align-items-between flex-column py-1"
                    >
                      <b-card-text class="mb-0">
                        <feather-icon
                          icon="AlertTriangleIcon"
                          class="text-warning"
                          size="30"
                        />
                      </b-card-text>
                      <h6 class=" mt-2 mb-50">
                        {{ $t('notSend') }}
                      </h6>
                    </b-col>
                  </b-row>
                </template>
                <b-card-header>
                  <h4 class="mb-0">
                    {{ $t('percentSend') }}
                  </h4>
                  <b-card-text class="font-medium-5 mb-0">
                    <feather-icon
                      v-b-tooltip.hover.top.v-secondary="$t('percentInfo')"
                      icon="HelpCircleIcon"
                      size="21"
                      class="text-muted cursor-pointer"
                    />
                  </b-card-text>
                </b-card-header>
                <vue-apex-charts
                  type="radialBar"
                  height="350"
                  :options="goalOverviewRadialBar.chartOptions"
                  :series="goalOverviewRadialBar.series"
                />
              </b-overlay>
            </b-card>
          </b-col>
          <!-- PIE -- ERROR NOTIFICATION -->
          <b-col lg="5">
            <b-card>
              <b-overlay
                :variant="'transparent'"
                :opacity="1.00"
                :blur="'2px'"
                :show="showMetrics"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <template
                  v-if="!sended"
                  #overlay
                >
                  <b-row class="text-center mx-0">
                    <b-col
                      cols="12"
                      class="d-flex align-items-between flex-column py-1"
                    >
                      <b-card-text class="mb-0">
                        <feather-icon
                          icon="AlertTriangleIcon"
                          class="text-warning"
                          size="30"
                        />
                      </b-card-text>
                      <h6 class=" mt-2 mb-50">
                        {{ $t('notSend') }}
                      </h6>
                    </b-col>
                  </b-row>
                </template>
                <b-card-header>
                  <b-card-title>{{ $t("errorTitle") }}</b-card-title>
                  <span class="text-nowrap text-muted">
                    {{ $t('errorBysend', { totalError: totalError }) }}

                  </span>
                </b-card-header>
                <app-echart-doughnut
                  v-if="totalError > 0"
                  :series="dataError"
                  :error-colors="true"
                />
                <div
                  v-else
                  style="text-align: center;"
                  class="m-2"
                >
                  <span class="text-nowrap text-muted">
                    {{ $t('notError') }}
                  </span>
                </div>
              </b-overlay>
            </b-card>
          </b-col>
          <!-- BAR -- DATA NOTIFICATION -->
          <b-col lg="7">
            <b-card>
              <b-overlay
                :variant="'transparent'"
                :opacity="1.00"
                :blur="'2px'"
                :show="showMetrics"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <template
                  v-if="!sended"
                  #overlay
                >
                  <b-row class="text-center mx-0">
                    <b-col
                      cols="12"
                      class="d-flex align-items-between flex-column py-1"
                    >
                      <b-card-text class="mb-0">
                        <feather-icon
                          icon="AlertTriangleIcon"
                          class="text-warning"
                          size="30"
                        />
                      </b-card-text>
                      <h6 class=" mt-2 mb-50">
                        {{ $t('notSend') }}
                      </h6>
                    </b-col>
                  </b-row>
                </template>
                <b-card-header>
                  <b-card-title>{{ $t("datosNotif") }}</b-card-title>
                  <span class="text-nowrap " />
                </b-card-header>
                <vue-apex-charts
                  :key="renderiza"
                  type="bar"
                  height="300"
                  :options="optionsNotification"
                  :series="dataNotification"
                />
              </b-overlay>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

    <b-modal
      ref="modal"
      centered
      size="sm"
      :title="$t('resendProg')"
      hide-footer
      @ok="handleOk"
    >
      <div>
        <b-overlay
          :variant="'transparent'"
          :opacity="1.00"
          :blur="'2px'"
          :show="showMetrics"
        >
          <span class="text-muted">{{ $t('continueResend', { name: nameNotification }) }}</span>
          <form
            ref="form"
            class="mt-1 mb-1"
            @submit.stop.prevent="handleOk"
          >
            <b-form-group
              :label="$t('dateSend')"
              label-for="name-input"
            >
              <flat-pickr
                id="example-datepicker"
                v-model="sendDate"
                class="form-control"
                :placeholder="$t('dateSend')"
                :config="{
                  enableTime: true,
                  enableSeconds: true,
                  dateFormat: 'Y-m-d H:i:S',
                  minDate: minDate,
                }"
              />
            </b-form-group>
          </form>
          <div
            class="d-flex mt-1"
            style="place-content: end;"
          >
            <b-button
              size="md"
              variant="outline-danger"
              @click="$refs.modal.hide()"
            >
              <!-- @click="cancel()" -->
              {{ $t('dataGeneric.cancel') }}
            </b-button>
            <b-button
              size="md"
              variant="success"
              class="ml-1"
              @click="handleOk()"
            >
              {{ $t('dataGeneric.save') }}
            </b-button>
          </div>
        </b-overlay>
      </div>
      <!--   <template #modal-footer="{ ok, cancel }">

      </template> -->
    </b-modal>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BFormSelect,
  BImg,
  BFormTextarea,
  BCardTitle,
  BTabs,
  BTab,
  VBTooltip,
  BFormGroup,
  BCardHeader,
  BFormCheckbox,
  BTable,
  BFormInput,
  BButton,
  BOverlay,
  BRow,
  BCardText,
  BCol,
} from 'bootstrap-vue'
import {
  showToast, messageError, isEmpty,
  camelToSnake,
  camelToKebab,
} from '@/store/functions'
// eslint-disable-next-line import/no-extraneous-dependencies
import ICountUp from 'vue-countup-v2'

import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import { getUserData } from '@/auth/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'
import AppEchartDoughnut from '@/@core/components/charts/echart/AppEchartDoughnut.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const appleIcon = require('@/assets/images/icons/apple_icon.png')
const androidIcon = require('@/assets/images/icons/android_icon.png')
const iosGcmIcon = require('@/assets/images/icons/ios_icon.png')
const allIcon = require('@/assets/images/icons/all_icon.png')

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    ImageElementVue,
    BTabs,
    BTab,
    BCardBody,
    BCardTitle,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    VueApexCharts,
    BFormInput,
    BFormCheckbox,
    BButton,
    BCardHeader,
    AppEchartDoughnut,
    ICountUp,
    BOverlay,
    ValidationProvider,
    flatPickr,
    ValidationObserver,
    BImg,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hide: true, // ocultar temporalmente hasta que se arreglen. Eliminar esta variable y la condición donde se usa
      viewName: this.$t('editNot'),
      viewDetail: this.$t('confNot'),
      sended: false,
      chartData: [],
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      devicesData: [],
      totalDevices: 0,
      totalActives: 0,
      totalDeactive: 0,
      renderiza: 0,
      totalError: 0,
      tabIndex: 0,
      totalNotification: 0,
      dataNotification: [
        {
          name: this.$t('cantidadEnv'),
          data: [],
        },
      ],
      optionsNotification: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: $themeColors.primary,
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%',
            columnWidth: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          position: 'top',
          maxItems: 100,
          hideOverflowingLabels: true,
          total: {
            enabled: true,
            formatter: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#848893',
              fontSize: '12px',
              fontFamily: undefined,
              fontWeight: 600,
            },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: '#848893',
            },
          },
          title: {
            style: {
              color: '#848893',
            },
          },
        },
        yaxis: {
          title: {
            style: {
              color: '#848893',
            },
          },
          labels: {
            style: {
              colors: '#848893',
            },
          },
        },
      },
      dataError: [
        {
          name: this.$t('erroresRegistrados'),
          type: 'pie',
          radius: '70%',
          center: ['45%', '45%'],
          data: [
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
      goalOverviewRadialBar: {
        series: [],
        labels: ['MiSerie'],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,

              opacity: 0.1,
            },
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#848893',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#ebe9f1',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
      /* -------------- */
      disabled: true,
      notificationText: null,
      currentPage: 1,
      perPage: 10,
      showUsers: false,
      urlLink: null,
      rows: 0,
      isLoading: false,
      disabledDemo: true,
      search: '',
      hasMore: false,
      isUrl: false,

      columnsNotifications: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
        { key: 'sendDate', label: this.$t('dateSend') },
        { key: 'target', label: 'Target' },
        { key: 'sended', label: this.$t('Enviado') },
        { key: 'user', label: this.$t('Creador') },
        { key: 'successMessages', label: this.$t('totalDisSend') },
        { key: 'openedMessages', label: this.$t('totalDisOp') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      /* ------- */
      nameNotification: '',
      title: '',
      description: '',
      url: '',
      sendDate: null,
      contentName: null,
      showMetrics: false,
      userTarget: 'ALL',
      isActive: '',
      sendDevices: false,
      contentId: null,
      image: null,
      target: 'ALL',
      categoryName: null,
      categoryId: null,
      categoryProdName: null,
      categoryProdId: null,
      productName: null,
      productId: null,
      required,
      userData: getUserData(),
      imageDataSrc: '',
      imageSelected: false,
      imageData: null,
      minDate: null,
      originalDate: null,
      typeSelected: 'TEX',
      sendType: 'AUTO',
      notificationSend: [],
      typeNotifications: {
        TEX: this.$t('resources.scenarioText'),
        URL: 'URL',
        CON: this.$t('contents.content'),
        CAC: this.$t('purchases.category'),
        CAP: this.$t('slider.productCategory'),
        PRO: this.$t('producto'),
      },
      typeSended: {
        AUTO: this.$t('interactivities.auto'),
        CRON: this.$t('programado'),
      },
      typeTarget: {
        ALL: this.$t('Todos'),
        IOF: 'iOS GCM',
        IOS: 'iOS',
        AND: 'Android',
      },
      userTargetOptions: {
        ALL: this.$t('allUser'),
        REG: this.$t('register'),
        UNR: this.$t('noRegister'),
      },
      platformId: getUserData().profile.client.id,
      categorySelectorTable: [
        { key: 'image', label: this.$t('purchases.category') },
        { key: 'name', label: this.$t('dataGeneric.name') },
      ],
      show: false,
      /* ------ */
      notifications: [
      ],
      editNotification: false,
      items: [],
      selected: [],
      id: this.$route.params.id,
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: '#e9ecef',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
      errorMessagesSummary: [],
    }
  },
  watch: {
    tabIndex() {
      if (this.tabIndex !== 0) {
        this.getNotificationsMetrics()
      }
    },
  },

  mounted() {
    const fechaActual = new Date()
    fechaActual.setTime(fechaActual.getTime() + 5 * 60 * 1000)
    this.minDate = fechaActual
    this.getNotificationsInfo()
  },
  methods: {

    goBack() {
      window.history.back()
    },
    isEmpty,
    validationMedia(data) {
      const keyContent = Object.keys(data.failedRules)
      if (keyContent.includes('required')) {
        return this.$t('required')
      }
      if (keyContent.includes('url')) {
        return this.$t('validUrl')
      }
      return ''
    },
    getNotificationsInfo() {
      this.show = true
      const query = `{
            allNotificationPush(id:"${this.id}") {
              edges {
                node {
                  id
                  notificationType
                  resource
                  sendedAt
                  sendType
                  user {
                    id
                    username
                  }
                  title(lang:"${this.language}")
                  description(lang:"${this.language}")
                  sendDate
                  userTarget
                  name
                  target
                  successMessages
                  openedMessages
                  image(lang:"${this.language}")
                  isActive
                }
              }
            }
          }`

      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          const { node } = result.data.data.allNotificationPush.edges[0]
          this.show = true
          this.nameNotification = node.name
          this.imageSelected = node.image
          this.userTarget = node.userTarget
          this.title = node.title
          this.typeSelected = node.notificationType
          this.description = node.description

          const originalDate = new Date(node.sendDate)
          this.sendDate = originalDate.toISOString()
          this.target = node.target
          this.isActive = node.isActive
          this.originalDate = node.sendDate !== null ? node.sendDate : null

          this.url = node.url
          const { resource } = node
          this.sendType = node.sendType
          this.sended = node.sendedAt
          if (this.sendType !== 'AUTO' && !this.sended) {
            this.editNotification = true
          }
          if (this.typeSelected !== 'TEX' && this.typeSelected !== 'URL' && this.typeSelected !== 'WEB'
            && this.typeSelected !== 'RES' && this.typeSelected !== 'APP') {
            this.getTypeId(this.typeSelected, resource)
          } else {
            this.show = false
          }
          this.$emit('return', {
            name: this.nameNotification,
            viewName: this.viewName,
            viewDetail: this.viewDetail,
            notifications: true,
            sended: this.sended,
            editNotification: this.editNotification,
            sendType: this.sendType,
            originalDate: this.originalDate,
          })
        })
        .catch(() => {
          this.show = false

          showToast(this.$t('serverError'), 2, this)
        })
    },
    setImageByDevice(type) {
      switch (type) {
        case 'AND':
          return androidIcon

        case 'IOS':
          return appleIcon

        case 'IOF':
          return iosGcmIcon
        default:
          return allIcon
      }
    },
    getNotificationsMetrics() {
      this.showMetrics = true
      this.goalOverviewRadialBar.series = []
      this.dataNotification[0].data = []
      this.optionsNotification.xaxis.categories = []
      this.dataError[0].data = []
      this.devicesData = []
      if (this.sended) {
        const query = `
          {
            mobile:devicesPlatform(filter_Mobile:true,client:"${this.userData.profile.client.id}"){
              totalCount
            }
            pushActive:devicesPlatform(filter_Mobile:true,pushActive:true,client:"${this.userData.profile.client.id}"){
              totalCount
            }
            pushActiveFalse:devicesPlatform(filter_Mobile:true,pushActive:false,client:"${this.userData.profile.client.id}"){
              totalCount
            }
          }
        `

        axios
          .post('', { query })
          .then(result => {
            messageError(result, this)

            this.totalDevices = result.data.data.mobile.totalCount
            this.totalActives = result.data.data.pushActive.totalCount
            this.totalDeactive = result.data.data.pushActiveFalse.totalCount
            const nError = 0
            const parte = 0
            if (nError > 0) {
              this.dataNotification[0].data.push(nError)
              this.optionsNotification.xaxis.categories.push('ERROR')
            }
            this.goalOverviewRadialBar.series.push(Math.round((parte / this.totalNotification) * 100))
            const totalData = {
              sended: 0,
              active: 0,
              deactive: 0,
              pending: 0,
              inProccess: 0,
              error: 0,
              total: 0,
            }
            const devicesData = []
            if (devicesData.length > 0) {
              devicesData.push({
                image: this.setImageByDevice(''),
                name: this.typeTarget.ALL,
                sended: totalData.sended,
                active: totalData.active,
                deactive: totalData.deactive,
                pending: totalData.pending,
                inProccess: totalData.inProccess,
                error: totalData.error,
                total: totalData.total,
              })
            }
            this.devicesData = devicesData.slice()
            this.renderiza += 1
            this.showMetrics = false
          })
          .catch(() => {
            this.showMetrics = false
            showToast(this.$t('serverError'), 2, this)
          })
      }
    },
    getFormatDate(date) {
      if (date !== null) {
        const dateDB = new Date(date)
        const year = dateDB.getFullYear()
        const month = String(dateDB.getMonth() + 1).padStart(2, '0')
        const day = String(dateDB.getDate()).padStart(2, '0')
        const hours = String(dateDB.getHours()).padStart(2, '0')
        const minutes = String(dateDB.getMinutes()).padStart(2, '0')
        const seconds = String(dateDB.getSeconds()).padStart(2, '0')
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
        return formattedDate
      }
      return ''
    },
    deleteNotification() {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('remNotification'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
              mutation{
                deleteNotificationPush(id:"${this.id}"){
                      found
                      deletedId
                  }
              }
              `,
            })
            .then(res => {
              messageError(res, this)
              showToast(this.$t('code.updateData'), 1, this)
              this.goBack()
            })
            .catch(() => {
              showToast(this.$t('code.updateDataError'), 2, this)
              this.show = false
            })
        } else {
          this.show = false
        }
      }).catch(err => {
        console.log(err)
      })
    },
    loadImage(item) {
      const { data, type } = item
      switch (type) {
        case `imageData${this.language}`:
          this.imageData = data
          break
        default:
          break
      }
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.show = true

            const { id } = this.$route.params
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const query = `
            mutation{
              updateNotificationPush(id:"${id}",input:{}){
                notificationPush{
                  id
                }
              }
            }`
            data.append('query', query)

            switch (type) {
              case `imageData${this.language}`:
                this.imageSelected = null
                data.append('deleteFile', `image_${camelToSnake(this.language)}`)
                break

              default:
                break
            }
            axios.post('', data, config).then(createResponse => {
              messageError(createResponse, this)
              this.show = false
              this.getNotificationsInfo()

              showToast(this.$t('success'), 1, this)
            }).catch(() => {
              this.show = false

              showToast(this.$t('code.updateDataError'), 2, this)
            })
          }
        })
        .catch(() => { })
    },
    resendNow() {
      this.showMetrics = true
      this.show = true
      this.$swal({
        title: this.$t('dataGeneric.atention'),
        text: this.$t('sureResend'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.sendDate = new Date()
          this.handleOk()
        } else {
          this.showMetrics = false
          this.show = false
        }
      }).catch(() => {

      })
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp)

      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // Sumar 1 porque enero es 0
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const seconds = date.getSeconds().toString().padStart(2, '0')

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`

      return formattedDate
    },
    handleOk() {
      this.show = true
      this.showMetrics = true
      const sendDate = new Date(this.sendDate).getTime()
      const query = `mutation{
          resendNotification(id:"${this.id}",newDate:"${this.formatTimestamp(sendDate)}"){
            notificationPush{
              id
              sendDate
              name
            }
          }
        }
          `

      axios
        .post('', { query })
        .then(res => {
          this.showMetrics = false
          this.show = false
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.goBack()
        }).catch(() => {
          this.showMetrics = false
          this.show = false

          showToast(this.$t('error'), 0, this)
        })
    },
    resendCron() {
      this.$swal({
        title: this.$t('dataGeneric.atention'),
        text: this.$t('sureResendCron'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.sendDate = new Date()
          this.$refs.modal.show()
        }
      }).catch(() => {

      })
    },
    getTypeId(type, id) {
      let query = ''
      switch (type) {
        case 'CON':
          query = `{
            allContents(id:"${id}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`

          break
        case 'CAC':
          query = `{
          allCategories(id:"${id}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`
          break
        case 'CAP':
          query = `{
          allCategoryShop(id:"${id}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`
          break
        case 'PRO':
          query = `{
          allProducts(id:"${id}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`
          break

        default:
          break
      }
      axios
        .post('', { query }).then(result => {
          messageError(result, this)
          let content = null
          let categories = null
          let categoriesShop = null
          let products = null
          switch (type) {
            case 'CON':
              content = result.data.data.allContents.edges[0].node
              this.contentId = content.id
              this.contentName = content.name

              break
            case 'CAC':

              categories = result.data.data.allCategories.edges[0].node
              this.categoryId = categories.id
              this.categoryName = categories.name
              break
            case 'CAP':

              categoriesShop = result.data.data.allCategoryShop.edges[0].node
              this.categoryProdId = categoriesShop.id
              this.categoryName = categoriesShop.name
              break
            case 'PRO':

              products = result.data.data.allProducts.edges[0].node
              this.productId = products.id
              this.productName = products.name
              break

            default:
              break
          }
          this.show = false
        }).catch(() => {

        })
    },
    validationForm() {
      return new Promise(resolve => {
        this.$refs.notification.validate().then(success => {
          if (success) {
            resolve(true)
            this.disabled = false
            this.updatePushNotification()
          } else {
            this.disabled = false
          }
        })
      })
    },
    getPlaceholder() {
      const dateDB = new Date(this.sendDate)
      const dateMin = new Date(this.minDate)
      if (dateDB < dateMin) {
        const year = dateDB.getFullYear()
        const month = String(dateDB.getMonth() + 1).padStart(2, '0')
        const day = String(dateDB.getDate()).padStart(2, '0')
        const hours = String(dateDB.getHours()).padStart(2, '0')
        const minutes = String(dateDB.getMinutes()).padStart(2, '0')
        const seconds = String(dateDB.getSeconds()).padStart(2, '0')
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        return formattedDate
      }
      return this.$t('dateSend')
    },

    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
    updatePushNotification() {
      const headers = {
        'Accept-Language': camelToKebab(this.language),
        'Content-Language': camelToKebab(this.language),
      }
      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      let typeId = ''
      switch (this.typeSelected) {
        case 'CON':
          typeId = this.contentId
          break

        case 'CAC':
          typeId = this.categoryId
          break
        case 'CAP':
          typeId = this.categoryProdId
          break
        case 'PRO':
          typeId = this.productId
          break
        default:
          typeId = undefined
          break
      }
      let objectVariables = []
      objectVariables = [
        { title: `title${this.language}`, value: this.title, type: 'String' },
        { title: `description${this.language}`, value: this.description, type: 'String' },
      ]
      if (this.typeSelected === 'URL') {
        objectVariables.push(
          { title: 'resource', value: this.url, type: 'String' },
        )
      } else if (typeId !== undefined) {
        objectVariables.push(
          { title: 'resource', value: typeId, type: 'String' },
        )
      }
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'user', value: this.userData.id, type: 'ID' },
          { title: 'userTarget', value: this.userTarget, type: 'NotificationPushUserTargetInput' },
          { title: 'sendType', value: this.sendType, type: 'NotificationPushSendTypeInput' },
          { title: 'sendDate', value: this.fechaFormato(this.sendDate), type: 'DateTime' },
          { title: 'target', value: this.target, type: 'NotificationPushTargetInput' },
          { title: 'notificationType', value: this.typeSelected, type: 'NotificationPushNotificationTypeInput' },
          { title: 'name', value: this.nameNotification, type: 'String' },
          { title: 'isActive', value: this.isActive, type: 'Boolean!' },
        )
      }

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateNotificationPush(id:"${this.id}",input:{\n
        `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        notificationPush{
            id
            name
            
          }
        }
      }`
      query = mutation + query

      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      data.append(`image_${camelToSnake(this.language)}`, this.imageData)

      axios
        .post('', data, config, { headers })
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
          this.show = false
          this.getNotificationsInfo()
        })
        .catch(() => {
          showToast(this.$t('error'), 0, this)
        })
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#edit-notifications .base-image-input {
  display: block;
  background-size: cover;
  background-position: center center;
}

#edit-notifications .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#edit-notifications .dropContainer {
  border: 3px dashed;
}

#edit-notifications .file-input {
  display: none;
}

#edit-notifications .modal {
  overflow-y: auto;
}

#edit-notifications .tamanio {

  width: auto;
  height: auto;
}

#edit-notifications .devices-states {
  display: flex;
  justify-content: space-evenly;
}
</style>
