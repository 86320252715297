<template>
  <section id="interactivitiesScreen">
    <b-row class="mb-1">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("interactivities.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("interactivities.listado") }}
        </h5>
      </b-col>

      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-button
          variant="danger"
          @click="$router.go(-1)"
        >
          {{
            $t("dataGeneric.goBack")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-card v-if="lleno">
      <b-row>
        <!-- VIDEO -->
        <b-col>
          <video
            id="demo"
            controls
            class="video-js vjs-default-skin vjs-big-play-centered tamanio"
            data-setup="{&quot;liveui&quot;: true}"
            :poster="file.includes('mp3')
              ? require('@/assets/images/icons/audio.svg')
              : ''
            "
            @loadstart="videoMaker()"
            @loadeddata="videoMaker()"
          >
            <source
              :src="file"
              :type="file.includes('m3u8')
                ? 'application/x-mpegURL'
                : file.includes('mp4')
                  ? 'video/mp4'
                  : 'audio/mp3'
              "
            >
          </video>
        </b-col>
        <!-- INTERACTIVIDADES -->
        <b-col v-show="showData">
          <b-overlay
            :show="show"
            variant="transparent"
            :opacity="1.00"
            :blur="'2px'"
            rounded="lg"
          >
            <b-modal
              v-model="showDateTimePicker"
              :title="$t('interactivities.liveEventTime')"
            >
              <validation-provider
                v-slot="{ errors }"
                ref="liveEventTime"
                name="liveEventTime"
                :rules="{ handlerLiveEventTime: { liveEventDate: liveEventDate } }"
              >
                <b-row
                  cols="12"
                  class="align-items-center "
                >
                  <b-col>
                    <b-form-timepicker
                      id="liveEventTime"
                      v-model="liveEventTime"
                      :locale="locale"
                      show-seconds
                      v-bind="timeLabels"
                      :min="minimumLiveEventTime"
                      :state="errors && errors.length > 0 ? false : null"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-datepicker
                      id="liveEventDate"
                      v-model="liveEventDate"
                      :locale="locale"
                      class="mb-1"
                      v-bind="dateLabels"
                      :min="minimumLiveEventDate"
                      :state="errors && errors.length > 0 ? false : null"
                    />
                  </b-col>
                </b-row>
                <b-row class="ml-25">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-row>
                <b-row class="ml-25">
                  <small class="text-muted">{{ $t('Timezone') }}: {{ timeZone }}</small>
                </b-row>
              </validation-provider>
              <template #modal-footer>
                <b-button
                  variant="secondary"
                  @click="showDateTimePicker = false"
                >
                  {{ $t('dataGeneric.cancel') }}
                </b-button>
                <b-button
                  :disabled="$refs.liveEventTime && $refs.liveEventTime.errors.length > 0"
                  variant="primary"
                  @click="InteractivityLiveEventConfirmation()"
                >
                  {{ $t('confirm') }}
                </b-button>
              </template>
            </b-modal>
            <b-list-group class="list">
              <b-row class="d-flex align-items-center justify-content-between">
                <h4>{{ $t("resFunciones.isInteractivity.title") }}</h4>
                <b-button
                  pill
                  variant="primary"
                  @click="interactivitieContent()"
                >
                  <feather-icon
                    icon="FilePlusIcon"
                    size="18"
                  /> Crear
                </b-button>
              </b-row>
              <b-list-group-item
                v-for="i in interactivities"
                :key="i.node.id"
                class="d-flex justify-content-between"
              >
                <div>
                  <small
                    class="cursor-pointer"
                    @click="markInter(i.node.second)"
                  >{{ i.node.name }}</small>
                </div>
                <div>
                  <b-button
                    v-if="isLiveContent && hasLiveEvents && i.node.timeType == 'LIV'"
                    v-b-tooltip.hover
                    pill
                    variant="outline-success"
                    size="sm"
                    class="mr-1 immediate-event-button"
                    :title="$t('interactivities.sentLiveEvent')"
                    @click="liveEventClicked(i.node)"
                  >
                    <feather-icon
                      icon="ArrowUpIcon"
                      size="16"
                    />
                  </b-button>
                  <span
                    class="mr-1 edit"
                    @click="editInteractivitie(i.node.id)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="16"
                    />
                  </span>
                  <span
                    v-if="isLiveContent && hasLiveEvents && i.node.timeType == 'LIV'"
                    v-b-tooltip.hover
                    :title="$t('interactivities.scheduleLiveEvent')"
                    class="mr-1 event"
                    @click="liveEventClicked(i.node, true)"
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="16"
                    />
                  </span>
                  <span
                    class="trash"
                    @click="deleteInteractivitie(i.node)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="18"
                    />
                  </span>
                </div>
              </b-list-group-item>
              <b-overlay
                :show="busy"
                no-wrap
                variant="transparent"
              >
                <template #overlay>
                  <div
                    v-if="processing"
                    class="text-center p-3 text-light rounded"
                  >
                    <b-icon
                      icon="cloud-upload"
                      font-scale="4"
                    />
                    <div class="mb-1">
                      {{ $t('code.del') }}...
                    </div>
                    <b-progress
                      min="1"
                      max="10"
                      :value="counter"
                      variant="danger"
                      height="10px"
                      class="mx-n4 rounded-0"
                    />
                  </div>
                  <div
                    v-else
                    ref="dialog"
                    tabindex="-1"
                    role="dialog"
                    aria-modal="false"
                    aria-labelledby="form-confirm-label"
                    class="text-center p-3"
                  >
                    <p>
                      <strong id="form-confirm-label">{{
                        $t("code.title")
                      }}</strong>
                    </p>
                    <div class="d-flex">
                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="outline-success"
                        @click="onOK"
                      >
                        OK
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="ml-2"
                        @click="onCancel"
                      >
                        {{ $t("dataGeneric.cancel") }}
                      </b-button>
                    </div>
                  </div>
                </template>
              </b-overlay>
            </b-list-group>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>

    <!-- RECURSOS -->
    <b-card>
      <b-row class="mt-2 align-items-center">
        <h4>{{ $t('resourceVid') }}</h4>
      </b-row>
      <b-row class="align-items-center">
        <b-col
          class="p-0"
          lg="1"
          md="3"
          cols="3"
        >
          <b-button
            pill
            variant="outline-secondary"
            @click="scrollLeft()"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="25"
            />
          </b-button>
        </b-col>
        <b-col
          lg="10"
          md="10"
          cols="10"
        >
          <b-row class="scroll">
            <!-- d-flex flex-column -->
            <div
              v-for="i in resources"
              :key="i.node.resource.id"
              class="m-1"
            >
              <div
                v-if="i.node.resource.type == 'VID' &&
                  i.node.resource.bucketUrl.includes('jpg') != true &&
                  i.node.resource.bucketUrl.includes('png') != true
                "
              >
                <video
                  :id="i.node.resource.id"
                  :style="i.node.resource.id == viewResource.resource.id
                    ? 'border: 2px solid; width: 15vw; height: 8vw; margin-bottom: 10px'
                    : 'width: 15vw; height: 8vw; margin-bottom: 10px'
                  "
                  class="cursor-pointer video-js vjs-default-skin resourceVideo"
                  :poster="i.node.resource.bucketUrl.trim() != ''
                    ? i.node.resource.bucketUrl.includes('mp3')
                      ? require('@/assets/images/icons/audio.svg')
                      : ''
                    : i.node.resource.directUrl.includes('mp3')
                      ? require('@/assets/images/icons/audio.svg')
                      : ''
                  "
                  @loadeddata="frameVideo(i.node.resource.id)"
                  @click="selectResource(i)"
                >
                  <!-- v-if="i.node.resource.videotype == 'BUK'" -->
                  <source
                    :src="i.node.resource.bucketUrl.trim() != ''
                      ? i.node.resource.bucketUrl
                      : i.node.resource.directUrl
                    "
                    :type="i.node.resource.bucketUrl.trim() != ''
                      ? i.node.resource.bucketUrl.includes('mp4')
                        ? 'video/mp4'
                        : 'application/x-mpegURL'
                      : i.node.resource.directUrl.includes('mp4')
                        ? 'video/mp4'
                        : 'application/x-mpegURL'
                    "
                  >
                </video>
                <div id="acortarPadre">
                  <div
                    id="acortar"
                    :class="i.node.resource.id == viewResource.resource.id
                      ? 'text-primary'
                      : ''
                    "
                  >
                    <small>

                      {{ i.node.resource.name }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </b-col>
        <b-col
          lg="1"
          md="3"
          cols="3"
        >
          <b-button
            pill
            variant="outline-secondary"
            @click="scrollRight()"
          >
            <feather-icon
              icon="ArrowRightIcon"
              size="25"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import {
  removeIntResources, isEmpty, messageError, isUserInGroup, showToast, formateDateTime,
} from '@/store/functions'

import {
  BRow,
  BCol,
  BButton,
  BCard,
  BIcon,
  BProgress,
  BListGroupItem,
  BListGroup,
  BOverlay,
  BFormTimepicker,
  BFormDatepicker,
  VBTooltip,
} from 'bootstrap-vue'
import axios from '@axios'

import Ripple from 'vue-ripple-directive'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getUserData } from '@/auth/utils'
import * as constants from '@core/utils/constants'
import { ValidationProvider, extend } from 'vee-validate'
import i18n from '@/libs/i18n'

const audioSvg = require('@/assets/images/icons/audio.svg')

extend('handlerLiveEventTime', {
  params: ['liveEventDate'],
  validate(value, { liveEventDate }) {
    const now = new Date()

    const selectedDate = new Date(liveEventDate)

    if (selectedDate.toDateString() === now.toDateString()) {
      const currentSeconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds()

      const res = value.split(':')
      const inputHours = parseInt(res[0], 10) * 3600
      const inputMinutes = parseInt(res[1], 10) * 60
      const inputSeconds = res[2] ? parseInt(res[2], 10) : 0
      const inputTimeInSeconds = inputHours + inputMinutes + inputSeconds

      const minimumTimeInSeconds = currentSeconds + 1

      if (inputTimeInSeconds <= minimumTimeInSeconds) return i18n.t('valueSuCu')
    }

    return true
  },
  message: i18n.t('valueSuCu'),
})

export default {
  components: {
    BOverlay,
    BListGroup,
    BIcon,
    BProgress,
    BListGroupItem,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormTimepicker,
    BFormDatepicker,
    ValidationProvider,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    msg: Boolean,
  },
  data() {
    return {
      response: null,
      lleno: false,
      interactivities: [],
      headers: {},
      resources: null,
      viewResource: null,
      id: null,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      video: null,
      file: null,
      initial: null,
      resourceEnd: null,
      isSponsor: false,
      userData: getUserData(),
      showData: false,
      show: false,
      isLiveContent: false,
      showDateTimePicker: false,
      liveEventTime: null,
      liveEventDate: new Date(),
      formattedLiveEventDateTime: null,
      liveEventInteractivity: null,
      locale: navigator.language || 'es-ES',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hasLiveEvents: false,
    }
  },
  computed: {
    minimumLiveEventTime() {
      const now = new Date()
      now.setMinutes(now.getMinutes() + 1)
      return now.toTimeString().slice(0, 5) // Extract "HH:mm" format
    },
    minimumLiveEventDate() {
      // Get the current date and time, add 2 minutes
      const now = new Date()
      now.setMinutes(now.getMinutes() + 1)
      return now.toISOString().split('T')[0] // Extract "HH:mm" format
    },
    timeLabels() {
      const labels = this.$i18n.t('timePicker', { returnObjects: true })
      return labels
    },
    dateLabels() {
      const labels = this.$i18n.t('datePicker', { returnObjects: true })
      return labels
    },
  },
  watch: {
    showDateTimePicker() {
      const now = new Date()
      this.liveEventDate = now

      now.setMinutes(now.getMinutes() + 1)
      this.liveEventTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:00`
    },
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }

    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.hasLiveEvents = this.userData.profile.client.hasLiveEvents
    this.getData()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    getData() {
      const { headers } = this
      axios
        .post('', {
          query: `
         query {
          allContents(id:"${this.$route.params.id}"){
            totalCount
            edges {
              node {
                creator {
                    id
                }
                id
                name
                description
                type
                contentResources{
                  edges {
                    node {
                      id
                      isInitial
                       resource{
                        id
                        name
                        videotype
                        type
                        directUrl
                        bucketUrl
                        file{
                          fileUrl
                        }
                        resourceInteractivity{
                        edges {
                          node {
                            id
                            name
                            second
                            time
                            hour
                            date
                            secondFile
                            resourceEnd {
                              id
                              name
                            }
                          }
                        }
                      }
                      resource2Interactivity{
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      }
                    }
                  }
                }                
              }
            }
          }
        }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.response = res.data.data.allContents.edges[0].node
          this.isLiveContent = this.response.type === 'DIR'

          // Sponsor role. Uncomment it to restrict access again
          /* let authorized = false
          if (this.isSponsor) {
            if (this.response.creator !== null) {
              if (this.response.creator.id === this.userData.id) authorized = true
            }
            if (!authorized) {
              this.$router.push({ name: 'misc-not-authorized' })
            }
          } */
          try {
            let id = null
            const resource = []
            this.response.contentResources.edges.forEach(element => {
              if (id == null) {
                id = element.node.resource.id
                if (element.node.isInitial === true) resource.unshift(element)
                else resource.push(element)
              } else if (id !== element.node.resource.id) {
                if (element.node.isInitial === true) resource.unshift(element)
                else resource.push(element)
              }
              if (element.node.isInitial === true) {
                this.viewResource = element.node
                this.getInteractivities(this.viewResource.resource.id)
                try {
                  this.file = this.viewResource.resource.file.fileUrl
                  this.initial = this.viewResource.resource.id
                } catch (error) {
                  this.file = this.viewResource.resource.directUrl
                }
              }
            })

            this.resources = resource.slice()

            if (this.video) {
              if (this.file != null && this.file.length !== 0) {
                if (this.file.includes('m3u8')) {
                  this.video.src({
                    type: 'application/x-mpegURL',
                    src: this.file,
                  })
                } else if (this.file.includes('mp3')) {
                  this.video.poster(
                    audioSvg,
                  )
                  this.video.src({
                    type: 'audio/mp3',
                    src: this.file,
                  })
                } else {
                  this.video.src({
                    type: 'video/mp4',
                    src: this.file,
                  })
                }
              }
            }
            // Sponsor role. Uncomment it to restrict access again
            /* if (!authorized && this.isSponsor) this.showData = false
            else */ this.showData = true // Remove this
          } catch (error) {
            console.log(error)
          }

          if (res.data.data.allContents.totalCount > 0) this.lleno = true
        })
    },
    getInteractivities(idResource) {
      const { headers } = this
      this.show = !this.show
      axios
        .post('', {
          query: `
          {
          allResources(id:"${idResource}") {
            edges {
              node {
                id
                resourceInteractivity(content:"${this.$route.params.id}") {
                  edges {
                    node {
                      id
                      name
                      description
                      second
                      time
                      hour
                      date
                      secondFile
                      timeType
                      resourceEnd {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }          `,
        }, { headers }).then(result => {
          messageError(result, this)
          this.interactivities = result.data.data.allResources.edges[0].node.resourceInteractivity.edges
          this.show = !this.show
        }).catch(() => {
          this.show = !this.show
        })
    },
    routeToView(subsid) {
      this.$router.push({
        name: 'interactivities-view',
        params: { id: subsid },
      })
    },
    routeToEditView(subsid) {
      this.$router.push({
        name: 'interactivities-edit',
        params: { id: subsid },
      })
    },
    createInteractivities() {
      this.$router.push({
        name: 'interactivities-create',
      })
    },

    scrollRight() {
      const row = document.getElementsByClassName('scroll')
      // eslint-disable-next-line operator-assignment
      row[0].scrollLeft = row[0].scrollLeft + 100
    },
    scrollLeft() {
      const row = document.getElementsByClassName('scroll')
      // eslint-disable-next-line operator-assignment
      row[0].scrollLeft = row[0].scrollLeft - 100
    },
    frameVideo(id) {
      try {
        const video = document.getElementById(id)

        video.currentTime = video.duration / 2
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    selectResource(element) {
      this.viewResource = element.node
      this.getInteractivities(this.viewResource.resource.id)
      try {
        this.file = this.viewResource.resource.file.fileUrl
      } catch (error) {
        this.file = this.viewResource.resource.directUrl
      }
      if (this.video) {
        if (this.file != null && this.file.length !== 0) {
          if (this.file.includes('m3u8')) {
            this.video.src({
              type: 'application/x-mpegURL',
              src: this.file,
            })
          } else if (this.file.includes('mp3')) {
            this.video.poster(audioSvg)
            this.video.src({
              type: 'audio/mp3',
              src: this.file,
            })
          } else {
            this.video.poster('')
            this.video.src({
              type: 'video/mp4',
              src: this.file,
            })
          }
        }
      }

      const videos = document.getElementsByClassName('resourceVideo')
      videos.forEach(e => {
        e.style = 'border: 0px; width: 15vw; height: 8vw; margin-bottom: 10px'
      })
      const seleccionado = document.getElementById(element.node.resource.id)
      seleccionado.style = 'border: 2px solid; width: 15vw; height: 8vw; margin-bottom: 10px'
    },
    deleteInteractivitie(node) {
      this.id = node.id
      this.resourceEnd = isEmpty(node.resourceEnd) ? null : node.resourceEnd.id

      this.processing = false
      this.busy = true
    },
    onCancel() {
      this.busy = false
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },

    onOK() {
      this.counter = 1
      this.processing = true
      // Simulate an async request
      this.clearInterval()
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter += 1
        } else {
          this.clearInterval()
          this.$nextTick(() => {
            axios
              .post('', {
                query: `
                    mutation{
                    deleteInteractivities(id:"${this.id}"){
                      found
                      deletedId
                    }
                  }
                `,
              })
              .then(res => {
                messageError(res, this)

                if (!isEmpty(this.resourceEnd)) {
                  removeIntResources(this.$route.params.id, this.resourceEnd).then(() => {
                    this.getData()
                  }).catch(err => {
                    console.log(err)
                  })
                } else {
                  this.getData()
                }
              })

            this.busy = false
            this.processing = false
          })
        }
      }, 350)
    },
    editInteractivitie(id) {
      this.$router.push({
        name: 'interactivities-edit',
        params: {
          id,
          content: this.$route.params.id,
        },
      })
    },
    interactivitieContent() {
      const contentId = this.$route.params.id
      let resourceStart = null
      try {
        resourceStart = this.viewResource.resource.id
        // eslint-disable-next-line no-empty
      } catch (error) { }
      this.$router.push({
        name: 'interactivities-create',
        params: { id: contentId, resource: resourceStart },
      })
    },
    videoMaker() {
      this.video = videojs('demo')

      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })

      if (this.viewResource.resource.resourceInteractivity.edges.length !== 0) {
        try {
          this.video.markers.removeAll()
          this.viewResource.resource.resourceInteractivity.edges.forEach(
            element => {
              this.video.markers.add([
                {
                  time: element.node.second,
                  text: element.node.name,
                  overlayText:
                    `${this.$t('interactivities.subtitle')
                    }: ${element.node.name}`,
                  duration: element.node.time,
                },
              ])
            },
          )
          // eslint-disable-next-line no-empty
        } catch (e) { }
      } else {
        try {
          this.video.markers.removeAll()
          // eslint-disable-next-line no-empty
        } catch (error) { }
      }

      try {
        this.video.markers({
          markerStyle: {
            width: '7px',
            'border-radius': '30%',
            'background-color': 'red',
          },
          markerTip: {
            display: true,
            text(marker) {
              return marker.text
            },
            time(marker) {
              return marker.time
            },
          },
          breakOverlay: {
            display: true,
            // No se puede poner para que dure lo mismo que dura la interactividad porque se solapa con el siguiente Overlay
            displayTime: 3,
            style: {
              width: '100%',
              height: '20%',
              'background-color': 'rgba(0,0,0,0.7)',
              color: 'white',
              'font-size': '17px',
            },
            text(marker) {
              return marker.overlayText
            },
          },
          // eslint-disable-next-line no-unused-vars
          onMarkerClick(marker) {
            // console.log("Click: " + marker.time);
          },
          // eslint-disable-next-line no-unused-vars
          onMarkerReached(marker) {
            // console.log("Reached: " + marker.time);
          },
          // markers: this.mark(),
        })
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    markInter(tiempo) {
      this.video.markers.getMarkers()

      const vjsMarker = document.getElementsByClassName('vjs-marker ')

      vjsMarker.forEach(element => {
        if (element.attributes[1].value === tiempo) {
          element.click()
        }
      })
    },
    cleanResources() {
      const eliminar = []
      this.resources.forEach(element => {
        if (!element.node.isInitial) {
          if (element.node.resource.resource2Interactivity.edges.length === 0) {
            eliminar.push(element.node.id)
          }
        }
      })
      if (eliminar.length !== 0) {
        let query = 'mutation{'
        eliminar.forEach((element, i) => {
          query += `d${i}: deleteContentsResource(id:"${element}") {
          found
            deletedId
          }`
        })
        query += '}'

        axios
          .post('', {
            query,
          })
          .then(result => {
            messageError(result, this)

            this.getData()
          })
          .catch(() => { })
      } else {
        this.getData()
      }
    },
    liveEventClicked(interactivity, timeSelect = false) {
      this.liveEventInteractivity = interactivity
      if (timeSelect) {
        this.showDateTimePicker = true
      } else {
        this.InteractivityLiveEventConfirmation(true)
      }
    },
    InteractivityLiveEventConfirmation(isInmediate = false) {
      this.show = true
      const dateTime = isInmediate ? '' : this.setLiveEventTime()
      this.$swal({
        title: this.$t('code.title'),
        text: isInmediate ? this.$t('interactivities.inmediateLiveEventConfirmText') : this.$t('interactivities.liveEventConfirmText', { date: formateDateTime(dateTime) }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('interactivities.liveEventConfirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.sendInteractivityLiveEvent(dateTime)
        } else {
          this.show = false
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async sendInteractivityLiveEvent(dateTime) {
      if (!this.hasLiveEvents) return

      const endpoint = dateTime === '' ? 'innmediate_live_events' : 'scheduled_live_events'
      const liveEventData = {
        user_id: this.userData.id,
        content_id: this.$route.params.id,
        event_type: 'INTERACTIVITY',
        payload: { id: this.liveEventInteractivity.id },
        duration: this.liveEventInteractivity.second,
        ...(dateTime !== '' && { start_at: this.convertISOToUTC(dateTime) }),
      }

      await axios.post(`https://xjhhxqvwcy3vylq5zbtynjbcge0rkkex.lambda-url.eu-west-1.on.aws/${endpoint}`,
        liveEventData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: false,
        }).then(res => {
        this.show = false

        this.response = res.data
        showToast(this.$t('interactivities.liveEventSuccess'), 1, this)
      }).catch(err => {
        this.show = false

        console.error(err)
        showToast(this.$t('interactivities.liveEventError'), 2, this)
      })
    },
    setLiveEventTime() {
      const selectedDate = new Date(this.liveEventDate)
      // Combine current date with selected time
      const [hours, minutes, seconds] = this.liveEventTime.split(':').map(Number)
      selectedDate.setHours(hours)
      selectedDate.setMinutes(minutes)
      selectedDate.setSeconds(seconds)
      this.showDateTimePicker = false

      // Set formatted time using the method
      return this.liveEventDateTimeToISO(selectedDate)
    },
    liveEventDateTimeToISO(date) {
      const pad = num => String(num).padStart(2, '0')

      // Build the formatted string
      const year = date.getFullYear()
      const month = pad(date.getMonth() + 1)
      const day = pad(date.getDate())
      const hours = pad(date.getHours())
      const minutes = pad(date.getMinutes())
      const seconds = pad(date.getSeconds())
      const milliseconds = String(date.getMilliseconds()).padStart(3, '0')

      // Calculate timezone offset in hours and minutes
      const offsetMinutes = date.getTimezoneOffset()
      const offsetSign = offsetMinutes > 0 ? '-' : '+'
      const absOffsetMinutes = Math.abs(offsetMinutes)
      const offsetHours = pad(Math.floor(absOffsetMinutes / 60))
      const offsetMins = pad(absOffsetMinutes % 60)

      // Format timezone offset as "+HH:MM" or "-HH:MM"
      const timezoneOffset = `${offsetSign}${offsetHours}:${offsetMins}`

      // Format as "2024-12-30T07:58:46.579+02:00"
      const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneOffset}`
      return formattedTime
    },
    convertISOToUTC(isoString) {
      const date = new Date(isoString) // Parse the ISO string
      const year = date.getUTCFullYear()
      const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are 0-indexed
      const day = String(date.getUTCDate()).padStart(2, '0')
      const hours = String(date.getUTCHours()).padStart(2, '0')
      const minutes = String(date.getUTCMinutes()).padStart(2, '0')
      const seconds = String(date.getUTCSeconds()).padStart(2, '0')
      const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0')

      // Format as "2025-01-08T10:15:30.123Z"
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`
    },

  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

.immediate-event-button {
  padding-top: 1px;
  padding-bottom: 1px;
  height: 20px;
}

#interactivitiesScreen .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#interactivitiesScreen .row {
  padding: 1.3rem;
}

#interactivitiesScreen .row .col {
  font-size: 1.285rem !important;
}

#interactivitiesScreen .card-title {
  font-weight: 500;
}

#interactivitiesScreen .scroll {
  max-width: 100%;
  flex-flow: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

#interactivitiesScreen .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

#interactivitiesScreen ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#interactivitiesScreen .tamanio {
  width: 45vw;
  height: 30vw;
}

#interactivitiesScreen .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}

#interactivitiesScreen .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#interactivitiesScreen .event:hover {
  color: rgb(251, 255, 0);
  cursor: pointer;
}

#interactivitiesScreen #acortar {
  width: 300px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#interactivitiesScreen #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}
</style>
