<template>
  <section id="platform-settings-tokens">
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("Tokens") }}</h1>
        <h5 class="text-primary">
          {{ $t("infoTokens") }}
        </h5>
      </b-col>
      <b-col class="element-end">
        <b-button
          variant="success"
          @click="$refs['superuser'].show()"
        >
          {{ $t('dataGeneric.create') }}
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col>
          <b-form-input
            v-model="search"
            :placeholder="$t('platformSearch')"
            @change="searching"
          />
        </b-col>
        <b-col sm="5">
          <b-overlay
            variant="transparent"
            :show="loading"
            :opacity="1.00"
            :blur="'2px'"
            rounded="sm"
          >
            <v-select
              id="platSelect"
              v-model="clientSelected"
              :no-options-text="$t('platformFilter')"
              :searchable="!loading"
              :search-input="clientName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('platformFilter')"
              :options="platformOptions"
              :open-on-focus="false"
              @search="delayMethod"
            />
          </b-overlay>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-table
            ref="tokensListTable"
            :items="allTokens"
            :fields="[
              { key: 'key', label: 'Token' },
              { key: 'user', label: $t('User') },
              { key: 'actions', label: $t('resources.tableHeader.actions') },
            ]"
            responsive="sm"
            :busy="show"
            show-empty
            :empty-text="$t('emptyText')"
            primary-key="key"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>{{ $t('load') }}...</strong>
              </div>
            </template>
            <!-- Column: key -->
            <template #cell(key)="data">
              <div class="text-nowrap">
                <b-row no-gutters>
                  <b-col
                    class="d-flex align-items-center"
                    style="flex-grow: 1; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 100%;"
                  >
                    <span
                      class="align-text-top"
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                    >
                      {{ data.item.node.key }}
                    </span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center ml-2"
                    style="min-width: 60px;"
                  >
                    <b-button
                      v-clipboard:copy="data.item.node.key"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-b-tooltip.hover.top="$t('dataGeneric.copy')+' token'"
                      variant="primary"
                      size="sm"
                    >
                      <feather-icon
                        icon="CopyIcon"
                      />
                      {{ $t('dataGeneric.copy') }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </template>

            <!-- Column: User -->
            <template #cell(user)="data">
              <div>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.node.user.username }}
                </span>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="text-nowrap d-flex pl-2">
                <feather-icon
                  v-b-tooltip.hover.top="$t('dataGeneric.delete')+' token'"

                  icon="Trash2Icon"
                  class="text-danger mr-2 cursor-pointer"
                  size="18"
                  @click="deleteTokens(data.item.node.id)"
                />
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            @input="getTokens"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="superuser"
      ref="superuser"
      hide-footer
      size="lg"
      title="Seleccionar plataforma"
    >
      <div class="d-block text-center">
        <platform-selector @client="getPlatform" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['superuser'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
    <b-modal
      id="admin-user"
      ref="admin-user"
      hide-footer
      size="xl"
      title="Seleccionar usuario"
    >
      <div class="d-block text-center">
        <b-tabs
          pills
          lazy
        >
          <b-tab active>
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>{{ $t("code.admin") }}</span>
            </template>
            <admin-user-selector
              :platform-id="platformId"
              @selectUser="getAdmin"
              @back="getBack"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="UsersIcon" />
              <span>{{ $t("fileNameUserRegistered") }}</span>
            </template>
            <final-user-selector-platform
              :platform-id="platformId"
              @back="getBack"
              @finalUser="getAdmin"
            />
          </b-tab>
        </b-tabs>
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['admin-user'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTabs,
  BTab,
  BSpinner,
  VBTooltip,
  BTable,
  BFormInput,
  BButton,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast, b64ToUtf8 } from '@/store/functions'
import PlatformSelector from '@/views/common/PlatformSelector.vue'
import AdminUserSelector from '@/views/common/AdminUserSelector.vue'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import FinalUserSelectorPlatform from '../common/FinalUserSelectorPlatform.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BSpinner,
    PlatformSelector,
    BTabs,
    BTab,
    BPagination,
    BFormInput,
    FinalUserSelectorPlatform,
    AdminUserSelector,
    BButton,
    BOverlay,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      allTokens: [],
      currentPage: 1,
      show: false,
      rows: 0,
      perPage: 20,
      platformId: null,
      userId: null,
      search: '',
      platformsFetched: [],
      platformOptions: [],
      clientName: null,
      clientId: null,
      loading: false,
      typingTimer: null,
      doneTypingInterval: 500,
      clientSelected: getUserData().profile.client.name,
    }
  },
  watch: {
    clientSelected(newValue) {
      this.clientId = newValue ? b64ToUtf8(this.clientSelected.value) : null
      this.getTokens()
    },
  },
  mounted() {
    const userData = getUserData()
    if (!userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    this.clientId = b64ToUtf8(userData.profile.client.id)
    this.getTokens()
  },
  methods: {
    getBack() {
      this.$refs.superuser.show()
      this.$refs['admin-user'].hide()
    },
    searching() {
      this.currentPage = 1
      this.getTokens()
    },
    getAdmin(data) {
      this.userId = data
      this.$refs['admin-user'].hide()
      this.generateTokens()
    },
    getPlatform(data) {
      this.platformId = data.id
      this.$refs.superuser.hide()
      this.$refs['admin-user'].show()
    },
    getTokens() {
      this.show = true

      const query = `
          {
            allTokens(
                first:${this.perPage},
                offset:${this.perPage * (this.currentPage - 1)},
                search:"${this.search}",
                ${this.clientId ? `client : "${this.clientId.split(':')[1]}"` : ''}
                orderBy:"-created"
                ) {
              totalCount
              edges {
                node {
                  id
                  key
                  user {
                    id
                    username
                  }
                }
              }
            }
          }
        `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        this.rows = res.data.data.allTokens.totalCount
        this.allTokens = res.data.data.allTokens.edges
        this.show = false
      })
    },
    generateTokens() {
      this.show = true
      const query = `
          mutation($user: ID!){
            generateTokens(input:{user:$user}){
              token{
                key
              }
            }
          }
        `
      axios.post('', {
        query,
        variables: {
          user: this.userId,
        },
      }).then(res => {
        messageError(res, this)
        showToast(this.$t('code.updateData'), 1, this)

        this.getTokens()
      }).catch(err => {
        console.log(err)
        showToast(this.$t('code.updateDataError'), 2, this)
        this.show = false
      })
    },
    deleteTokens(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteToken'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.show = true
          axios
            .post('', {
              query: `
                    mutation($id: ID!){
                      deleteTokens(id:$id){
                            found
                            deletedId
                        }
                    }
                    `,
              variables: {
                id,
              },
            })
            .then(res => {
              messageError(res, this)
              showToast(this.$t('code.updateData'), 1, this)
              this.getTokens()
            })
            .catch(err => {
              console.log(err)
              showToast(this.$t('code.updateDataError'), 2, this)
              this.show = false
            })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.tCopy'),
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.errorCopy'),
          icon: 'BellIcon',
        },
      })
    },
    fetchPlatforms() {
      this.loading = true
      axios
        .post('', {
          query: `
         query {
                allClients(
                  name_Icontains: "${this.clientName}",                 
                  ){
                edges{                  
                    node{
                      name
                      id
                      displayName
                      clientId
                    }
                  }
                  }
                }
        `,
        })
        .then(response => {
          messageError(response, this)
          this.loading = false
          const clients = response.data.data.allClients.edges

          this.platformOptions = []
          clients.forEach(element => {
            this.platformOptions.push({ label: element.node.name, value: element.node.id })
          })
        }).catch(err => {
          console.log(err)
        })
    },
    delayMethod() {
      /* IMPORTANT! Do not change the event, you must access target.value to get the input value, and not from keyup.  */
      // eslint-disable-next-line no-restricted-globals
      this.clientName = event.target.value
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => {
        if (this.clientName && this.clientName.length >= 3) this.fetchPlatforms() // Envuelve la llamada dentro de una función de flecha
      }, this.doneTypingInterval)
    },
  },
}
</script>

<style lang="scss">
.element-end{
  text-align: end;
  align-self: center;

}
</style>
