<template>
  <div>
    <b-row class="mb-5">
      <b-col
        md="8"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('Statistics') }}</h1>
        <h5 class="text-primary">
          {{ $t('statisticsPlatform') }}
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-card-header>
            <b-card-title>
              <h3>{{ $t('metricsUsers') }}</h3>
              <h6 class="text-primary">
                {{ $t('metricUserPlatform') }}
              </h6>
            </b-card-title>
            <div class="d-flex">
              <b-button
                class="m-1"
                :variant="totalsPeriod ? 'primary' : 'outline-primary'"
                size="sm"
                @click="setTotalUser"
              >
                {{ $t('total') }}
              </b-button>
              <b-button
                class="m-1"
                :variant="periodUser == 'hour' ? 'primary' : 'outline-primary'"
                size="sm"
                @click="setPeriodUser('hour')"
              >
                {{ $t('today') }}
              </b-button>
              <b-button
                class="m-1"
                :variant="periodUser == 'yesterday' ? 'primary' : 'outline-primary'
                "
                size="sm"
                @click="setPeriodUser('yesterday')"
              >
                {{ $t('yesterday') }}
              </b-button>

              <b-button
                class="m-1"
                :variant="periodUser == '7days' ? 'primary' : 'outline-primary'"
                size="sm"
                @click="setPeriodUser('7days')"
              >
                7 {{ $t('days') }}
              </b-button>
              <b-button
                class="m-1"
                :variant="periodUser == '30days' ? 'primary' : 'outline-primary'
                "
                size="sm"
                @click="setPeriodUser('30days')"
              >
                30 {{ $t('days') }}
              </b-button>
              <b-button
                class="m-1"
                :variant="periodUser == '60days' ? 'primary' : 'outline-primary'
                "
                size="sm"
                @click="setPeriodUser('60days')"
              >
                60 {{ $t('days') }}
              </b-button>
              <div style="align-self: center;">
                <div>
                  <feather-icon
                    class="text-primary cursor-pointer ml-2"
                    icon="CalendarIcon"
                    size="15"
                    @click="clickCalendar('timeUser')"
                  />
                  <flat-pickr
                    id="timeUser"
                    v-model="timeUser"
                    style=" visibility: hidden;
                    width: 0;
                    height: 0;"
                    :config="{
                      enableTime: true,
                      mode: 'range',
                      enableSeconds: true,
                      time_24hr: true,
                      dateFormat: 'Y-m-d H:i:S'
                    }"
                  />
                </div>
              </div>
            </div>
          </b-card-header>
          <b-card-body>
            <users
              v-if="!vacio"
              :period="periodUser"
              :api-key="api_key"
              :statistics-url="statisticsUrl"
              :app-id="app_id"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showCat"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                <h3>
                  {{ $t('mostCateg') }}
                  <span class="text-primary ml-2">TOTAL:
                    <strong class="text-primary ml-2">
                      {{ countCat }}</strong></span>
                </h3>
                <h6 class="text-primary">
                  {{ $t('visCatg') }}
                </h6>
              </b-card-title>
              <div class="d-flex">
                <b-button
                  class="m-1"
                  :variant="periodCat == 'hour' ? 'primary' : 'outline-primary'"
                  size="sm"
                  @click="periodCat = 'hour'"
                >
                  {{ $t('today') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodCat == 'yesterday' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodCat = 'yesterday'"
                >
                  {{ $t('yesterday') }}
                </b-button>

                <b-button
                  class="m-1"
                  :variant="periodCat == '7days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodCat = '7days'"
                >
                  7 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodCat == '30days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodCat = '30days'"
                >
                  30 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodCat == '60days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodCat = '60days'"
                >
                  60 {{ $t('days') }}
                </b-button>
                <div style="align-self: center;">
                  <div>
                    <feather-icon
                      class="text-primary cursor-pointer ml-2"
                      icon="CalendarIcon"
                      size="15"
                      @click="clickCalendar('timeCat')"
                    />
                    <flat-pickr
                      id="timeCat"
                      v-model="timeCat"
                      style=" visibility: hidden;
                    width: 0;
                    height: 0;"
                      :config="{
                        enableTime: true,
                        mode: 'range',
                        enableSeconds: true, time_24hr: true,
                        dateFormat: 'Y-m-d H:i:S'
                      }"
                    />
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-table
                    ref="tableCat"
                    responsive
                    sticky-header="500px"
                    :no-border-collapse="true"
                    :items="itemsCat"
                    :empty-text="$t('dataNotFound')"
                    :fields="columnsCat"
                    @sort-changed="onSortChangedCategory"
                  >
                    <template #cell(name)="data">
                      <div class="text-nowrap">
                        <b-row>
                          <b-col md="2">
                            <b-img
                              :src="buildImageUrl(data.item.imageUrl)"
                              class="tableImage"
                              @error="errorImg"
                            />
                          </b-col>
                          <b-col id="acortarPadre">
                            <div
                              id="acortar"
                              style="align-self:center;"
                              class="m-1"
                            >
                              <h5 id="acortar">
                                {{ data.item.name }}
                              </h5>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <template #cell(isActive)="data">
                      <div class="text-nowrap">
                        <b-badge
                          :variant="data.item.isActive ? 'success' : 'danger'"
                          class="badge-glow"
                        >
                          <span v-if="data.item.isActive">{{ $t('slider.activeSlider') }}</span>
                          <!-- <span v-if="data.item.status == 'YEW'">PENDIENTE</span> -->
                          <span v-else>{{ $t('slider.inactive') }}</span>
                        </b-badge>
                      </div>
                    </template>
                    <template #cell(c)="data">
                      <div class="text-nowrap">
                        <h3>{{ data.item.c }}</h3>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-pagination
                    v-model="currentPageCat"
                    :total-rows="rowsCat"
                    :per-page="perPage"
                    align="right"
                    class="my-0"
                    @input="onSortChangedCategory(null)"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showCont"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                <h3>
                  {{ $t('mostCont') }}
                  <span class="text-primary ml-2">TOTAL:
                    <strong class="text-primary ml-2">
                      {{ countCont }}</strong></span>
                </h3>
                <h6 class="text-primary">
                  {{ $t('visCont') }}
                </h6>
              </b-card-title>

              <div class="d-flex">
                <!-- TODO: Filtrado por tipo de contenido y categoría -->
                <!-- <div class="align-self-center mr-2">
                  <b-form-select  v-model="selected" :options="typeOptions">
                  </b-form-select>
                </div> -->
                <b-button
                  class="m-1"
                  :variant="periodCont == 'hour' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodCont = 'hour'"
                >
                  {{ $t('today') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodCont == 'yesterday' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodCont = 'yesterday'"
                >
                  {{ $t('yesterday') }}
                </b-button>

                <b-button
                  class="m-1"
                  :variant="periodCont == '7days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodCont = '7days'"
                >
                  7 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodCont == '30days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodCont = '30days'"
                >
                  30 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodCont == '60days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodCont = '60days'"
                >
                  60 {{ $t('days') }}
                </b-button>
                <div style="align-self: center;">
                  <div>
                    <feather-icon
                      class="text-primary cursor-pointer ml-2"
                      icon="CalendarIcon"
                      size="15"
                      @click="clickCalendar('timeCont')"
                    />
                    <flat-pickr
                      id="timeCont"
                      v-model="timeCont"
                      style=" visibility: hidden;
                    width: 0;
                    height: 0;"
                      :config="{
                        enableTime: true,
                        mode: 'range',
                        enableSeconds: true, time_24hr: true,
                        dateFormat: 'Y-m-d H:i:S'
                      }"
                    />
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-table
                    ref="table"
                    responsive
                    sticky-header="500px"
                    :no-border-collapse="true"
                    :items="itemsCont"
                    :empty-text="$t('dataNotFound')"
                    :fields="columnsCont"
                    @sort-changed="onSortChangedContent"
                  >
                    <template #cell(name)="data">
                      <div class="text-nowrap">
                        <b-row>
                          <b-col md="2">
                            <b-img
                              :src="buildImageUrl(data.item.imageUrl)"
                              class="tableImage"
                              @error="errorImg"
                            />
                          </b-col>
                          <b-col id="acortarPadre">
                            <div
                              id="acortar"
                              style="align-self:center;"
                              class="m-1"
                            >
                              <h5 id="acortar">
                                {{ data.item.name }}
                              </h5>
                              <h6
                                id="acortar"
                                style="color: gray"
                              >
                                {{ data.item.subtitle }}
                              </h6>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <div class="text-nowrap">
                        <b-badge
                          :variant="data.item.status == 'GRE'
                            ? 'success'
                            : data.item.status == 'YEW'
                              ? 'warning'
                              : 'danger'
                          "
                          class="badge-glow"
                        >
                          <span v-if="data.item.status == 'GRE'">{{ $t('slider.activeSlider') }}</span>
                          <span v-if="data.item.status == 'YEW'">{{ $t('slider.pend') }}</span>
                          <span v-if="data.item.status == 'RED'">{{ $t('slider.inactive') }}</span>
                        </b-badge>
                      </div>
                    </template>

                    <template #cell(interactive)="data">
                      <div class="text-nowrap">
                        <span>{{ data.item.interactive ? "SI" : "NO" }}</span>
                      </div>
                    </template>
                    <template #cell(c)="data">
                      <div class="text-nowrap">
                        <h3>{{ data.item.c }}</h3>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-pagination
                    v-model="currentPageCont"
                    :total-rows="rowsCont"
                    :per-page="perPage"
                    align="right"
                    class="my-0"
                    @input="onSortChangedContent(null)"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showFavCont"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                <h3>
                  {{ $t('favCont') }}
                  <span class="text-primary ml-2">TOTAL:
                    <strong class="text-primary ml-2">
                      {{ countFavCont }}</strong></span>
                </h3>
                <h6 class="text-primary">
                  {{ $t('marFav') }}
                </h6>
              </b-card-title>
              <div class="d-flex">
                <b-button
                  class="m-1"
                  :variant="periodFavCont == 'hour' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodFavCont = 'hour'"
                >
                  {{ $t('today') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodFavCont == 'yesterday' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodFavCont = 'yesterday'"
                >
                  {{ $t('yesterday') }}
                </b-button>

                <b-button
                  class="m-1"
                  :variant="periodFavCont == '7days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodFavCont = '7days'"
                >
                  7 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodFavCont == '30days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodFavCont = '30days'"
                >
                  30 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodFavCont == '60days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodFavCont = '60days'"
                >
                  60 {{ $t('days') }}
                </b-button>
                <div style="align-self: center;">
                  <div>
                    <feather-icon
                      class="text-primary cursor-pointer ml-2"
                      icon="CalendarIcon"
                      size="15"
                      @click="clickCalendar('timeFavCont')"
                    />
                    <flat-pickr
                      id="timeCont"
                      v-model="timeFavCont"
                      style=" visibility: hidden;
                    width: 0;
                    height: 0;"
                      :config="{
                        enableTime: true,
                        mode: 'range', time_24hr: true,
                        enableSeconds: true,
                        dateFormat: 'Y-m-d H:i:S'
                      }"
                    />
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-table
                    ref="table"
                    responsive
                    sticky-header="500px"
                    :no-border-collapse="true"
                    :items="itemsFavCont"
                    :empty-text="$t('dataNotFound')"
                    :fields="columnsFavCont"
                    @sort-changed="onSortChangedFavContent"
                  >
                    <template #cell(name)="data">
                      <div class="text-nowrap">
                        <b-row>
                          <b-col md="2">
                            <b-img
                              :src="buildImageUrl(data.item.imageUrl)"
                              class="tableImage"
                              @error="errorImg"
                            />
                          </b-col>
                          <b-col id="acortarPadre">
                            <div
                              id="acortar"
                              style="align-self:center;"
                              class="m-1"
                            >
                              <h5 id="acortar">
                                {{ data.item.name }}
                              </h5>
                              <h6
                                id="acortar"
                                style="color: gray"
                              >
                                {{ data.item.subtitle }}
                              </h6>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <div class="text-nowrap">
                        <b-badge
                          :variant="data.item.status == 'GRE'
                            ? 'success'
                            : data.item.status == 'YEW'
                              ? 'warning'
                              : 'danger'
                          "
                          class="badge-glow"
                        >
                          <span v-if="data.item.status == 'GRE'">{{ $t('slider.activeSlider') }}</span>
                          <span v-if="data.item.status == 'YEW'">{{ $t('slider.pend') }}</span>
                          <span v-if="data.item.status == 'RED'">{{ $t('slider.inactive') }}</span>
                        </b-badge>
                      </div>
                    </template>

                    <template #cell(interactive)="data">
                      <div class="text-nowrap">
                        <span>{{ data.item.interactive ? $t('dataGeneric.si') : $t('dataGeneric.no') }}</span>
                      </div>
                    </template>
                    <template #cell(c)="data">
                      <div class="text-nowrap">
                        <h3>{{ data.item.c }}</h3>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-pagination
                    v-model="currentPageFavCont"
                    :total-rows="rowsFavCont"
                    :per-page="perPage"
                    align="right"
                    class="my-0"
                    @input="onSortChangedFavContent(null)"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showAds"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                <h3>
                  {{ $t('advertising.externalAds') }}
                  <span class="text-primary ml-2">TOTAL:
                    <strong class="text-primary ml-2">
                      {{ countAds }}</strong></span>
                </h3>
                <h6 class="text-primary">
                  {{ $t('advertising.mostVis') }}
                </h6>
              </b-card-title>
              <div class="d-flex">
                <b-button
                  class="m-1"
                  :variant="periodAds == 'hour' ? 'primary' : 'outline-primary'"
                  size="sm"
                  @click="periodAds = 'hour'"
                >
                  {{ $t('today') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodAds == 'yesterday' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodAds = 'yesterday'"
                >
                  {{ $t('yesterday') }}
                </b-button>

                <b-button
                  class="m-1"
                  :variant="periodAds == '7days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodAds = '7days'"
                >
                  7 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodAds == '30days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodAds = '30days'"
                >
                  30 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodAds == '60days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodAds = '60days'"
                >
                  60 {{ $t('days') }}
                </b-button>
                <div style="align-self: center;">
                  <div>
                    <feather-icon
                      class="text-primary cursor-pointer ml-2"
                      icon="CalendarIcon"
                      size="15"
                      @click="clickCalendar('timeAds')"
                    />
                    <flat-pickr
                      id="timeAds"
                      v-model="timeAds"
                      style=" visibility: hidden;
                    width: 0;
                    height: 0;"
                      :config="{
                        enableTime: true, time_24hr: true,
                        mode: 'range',
                        enableSeconds: true,
                        dateFormat: 'Y-m-d H:i:S'
                      }"
                    />
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-table
                    ref="table"
                    responsive
                    sticky-header="500px"
                    :no-border-collapse="true"
                    :items="itemsAds"
                    :empty-text="$t('dataNotFound')"
                    :fields="columnsAds"
                    @sort-changed="onSortChangedAds"
                  >
                    <template #cell(name)="data">
                      <div class="text-nowrap">
                        <b-row>
                          <b-col id="acortarPadre">
                            <div
                              id="acortar"
                              style="align-self:center;"
                              class="m-1"
                            >
                              <h5 id="acortar">
                                {{ data.item.name }}
                              </h5>
                              <h6
                                id="acortar"
                                style="color: gray"
                              >
                                {{ data.item.description }}
                              </h6>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </template>

                    <template #cell(startDate)="data">
                      <div class="text-nowrap">
                        {{ fechaFormato(data.item.startDate) }}
                      </div>
                    </template>
                    <template #cell(finalDate)="data">
                      <div class="text-nowrap">
                        {{ fechaFormato(data.item.finalDate) }}
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <div class="text-nowrap">
                        <b-badge
                          :variant="data.item.isActive ? 'success' : 'danger'"
                          class="badge-glow"
                        >
                          <span v-if="data.item.isActive">{{ $t('slider.activeSlider') }}</span>
                          <span v-else>{{ $t('slider.inactive') }}</span>
                        </b-badge>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-pagination
                    v-model="currentPageAds"
                    :total-rows="rowsAds"
                    :per-page="perPage"
                    align="right"
                    class="my-0"
                    @input="onSortChangedAds(null)"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showSlide"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                <h3>
                  {{ $t('slider.mostSee') }}
                  <span class="text-primary ml-2">TOTAL:
                    <strong class="text-primary ml-2">
                      {{ countSlide }}</strong></span>
                </h3>
                <h6 class="text-primary">
                  {{ $t('slider.interac') }}
                </h6>
              </b-card-title>
              <div class="d-flex">
                <b-button
                  class="m-1"
                  :variant="periodSlide == 'hour' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodSlide = 'hour'"
                >
                  {{ $t('today') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodSlide == 'yesterday' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodSlide = 'yesterday'"
                >
                  {{ $t('yesterday') }}
                </b-button>

                <b-button
                  class="m-1"
                  :variant="periodSlide == '7days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodSlide = '7days'"
                >
                  7 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodSlide == '30days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodSlide = '30days'"
                >
                  30 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodSlide == '60days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodSlide = '60days'"
                >
                  60 {{ $t('days') }}
                </b-button>
                <div style="align-self: center;">
                  <div>
                    <feather-icon
                      class="text-primary cursor-pointer ml-2"
                      icon="CalendarIcon"
                      size="15"
                      @click="clickCalendar('timeSlide')"
                    />
                    <flat-pickr
                      id="timeSlide"
                      v-model="timeSlide"
                      style=" visibility: hidden;
                    width: 0;
                    height: 0;"
                      :config="{
                        enableTime: true,
                        mode: 'range',
                        enableSeconds: true, time_24hr: true,
                        dateFormat: 'Y-m-d H:i:S'
                      }"
                    />
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-table
                    ref="tableSlide"
                    sticky-header="500px"
                    :no-border-collapse="true"
                    :items="itemsSlide"
                    :empty-text="$t('dataNotFound')"
                    :fields="columnsSlide"
                    @sort-changed="onSortChangedSlide"
                  >
                    <template #cell(name)="data">
                      <div class="text-nowrap">
                        <b-row>
                          <b-col md="2">
                            <b-img
                              :src="buildImageUrl(data.item.imageUrl)"
                              class="tableImage"
                              style=""
                              @error="errorImg"
                            />
                          </b-col>
                          <b-col id="acortarPadre">
                            <div
                              id="acortar"
                              style="align-self:center;"
                              class="m-1"
                            >
                              <h5 id="acortar">
                                {{ data.item.name }}
                              </h5>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <template #cell(isActive)="data">
                      <div class="text-nowrap">
                        <b-badge
                          :variant="data.item.isActive ? 'success' : 'danger'"
                          class="badge-glow"
                        >
                          <span v-if="data.item.isActive">{{ $t('slider.activeSlider') }}</span>
                          <span v-else>{{ $t('slider.inactive') }}</span>
                        </b-badge>
                      </div>
                    </template>
                    <template #cell(c)="data">
                      <div class="text-nowrap">
                        <h3>{{ data.item.c }}</h3>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-pagination
                    v-model="currentPageSlide"
                    :total-rows="rowsSlide"
                    :per-page="perPage"
                    align="right"
                    class="my-0"
                    @input="onSortChangedSlide(null)"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showShowInt"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                <h3>
                  {{ $t('metricInterac') }}
                  <span class="text-primary ml-2">TOTAL:
                    <strong class="text-primary ml-2">
                      {{ countInt }}</strong></span>
                </h3>
                <h6 class="text-primary">
                  {{ $t('mostInterac') }}
                </h6>
              </b-card-title>
              <div class="d-flex">
                <b-button
                  class="m-1"
                  :variant="periodShowInt == 'hour' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodShowInt = 'hour'"
                >
                  {{ $t('today') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodShowInt == 'yesterday' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodShowInt = 'yesterday'"
                >
                  {{ $t('yesterday') }}
                </b-button>

                <b-button
                  class="m-1"
                  :variant="periodShowInt == '7days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodShowInt = '7days'"
                >
                  7 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodShowInt == '30days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodShowInt = '30days'"
                >
                  30 {{ $t('days') }}
                </b-button>
                <b-button
                  class="m-1"
                  :variant="periodShowInt == '60days' ? 'primary' : 'outline-primary'
                  "
                  size="sm"
                  @click="periodShowInt = '60days'"
                >
                  60 {{ $t('days') }}
                </b-button>
                <div style="align-self: center;">
                  <div>
                    <feather-icon
                      class="text-primary cursor-pointer ml-2"
                      icon="CalendarIcon"
                      size="15"
                      @click="clickCalendar('timeShowInt')"
                    />
                    <flat-pickr
                      id="timeShowInt"
                      v-model="timeShowInt"
                      style=" visibility: hidden;
                    width: 0;
                    height: 0;"
                      :config="{
                        enableTime: true,
                        mode: 'range', time_24hr: true,
                        enableSeconds: true,
                        dateFormat: 'Y-m-d H:i:S'
                      }"
                    />
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-table
                    ref="tableShowInt"
                    responsive
                    :no-border-collapse="true"
                    sticky-header="500px"
                    :items="itemsShowInt"
                    :empty-text="$t('dataNotFound')"
                    :fields="columnsInt"
                    @sort-changed="onSortChangedInt"
                  >
                    <template #cell(name)="data">
                      <div class="text-nowrap">
                        <b-row>
                          <b-col>
                            <h4>{{ data.item.name }}</h4>
                            <h6 style="color: gray">
                              {{ data.item.subtitle }}
                            </h6>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <template #cell(connect)="data">
                      <div class="text-nowrap">
                        <b>{{ interactvtyType[data.item.connect] }}</b>
                      </div>
                    </template>
                    <template #cell(isActive)="data">
                      <div class="text-nowrap">
                        <b-badge
                          :variant="data.item.isActive ? 'success' : 'danger'"
                          class="badge-glow"
                        >
                          <span v-if="data.item.isActive">{{ $t('slider.activeSlider') }}</span>
                          <span v-else>{{ $t('slider.inactive') }}</span>
                        </b-badge>
                      </div>
                    </template>
                    <template #cell(c)="data">
                      <div class="text-nowrap">
                        <h3>{{ data.item.c }}</h3>
                      </div>
                    </template>
                    <template #cell(p)="data">
                      <div class="text-nowrap">
                        <h3>{{ data.item.p }}</h3>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-pagination
                    v-model="currentPageShowInt"
                    :total-rows="rowsShowInt"
                    :per-page="perPage"
                    align="right"
                    class="my-0"
                    @input="onSortChangedInt(null)"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { showToast, messageError } from '@/store/functions'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BBadge,
  BCardHeader,
  BImg,
  BCardBody,
  BCardTitle,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import { getUserData } from '@/auth/utils'
import users from './usersAnalytics.vue'

const fileError = require('@/assets/images/backend/file-error.png')

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    flatPickr,
    BRow,
    BOverlay,
    BCol,
    BBadge,
    BImg,
    BCard,
    users,
    BButton,
    BTable,
    BCardHeader,
    BPagination,
    BCardBody,
    BCardTitle,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: getUserData(),

      periodCont: '30days',
      periodUser: '30days',
      periodCat: '30days',
      periodSlide: '30days',
      periodShowInt: '30days',
      periodFavCont: '30days',
      periodAds: '30days',

      timeFavCont: '',
      timeUser: '',
      timeCont: '',
      timeCat: '',
      timeSlide: '',
      timeInt: '',
      timeShowInt: '',
      timeAds: '',

      showCat: false,
      showFavCont: false,
      showSlide: false,
      showInt: false,
      showShowInt: false,
      showCont: false,
      showAds: false,

      rowsFavCont: 0,
      rowsCont: 0,
      rowsCat: 0,
      rowsSlide: 0,
      rowsInt: 0,
      rowsShowInt: 0,
      rowsAds: 0,

      countFavCont: 0,
      countCont: 0,
      countCat: 0,
      countSlide: 0,
      countInt: 0,
      countShowInt: 0,
      countAds: 0,

      currentPageCont: 1,
      currentPageCat: 1,
      currentPageSlide: 1,
      currentPageInt: 1,
      currentPageFavCont: 1,
      currentPageShowInt: 1,
      currentPageAds: 1,

      resCont: [],
      resFavCont: [],
      resCat: [],
      resSlide: [],
      resInt: [],
      resShowInt: [],
      resAds: [],

      totalCont: [],
      totalFavCont: [],
      totalCat: [],
      totalSlide: [],
      totalShowInt: [],
      totalAds: [],

      itemsCont: [],
      itemsFavCont: [],
      itemsCat: [],
      itemsSlide: [],
      itemsInt: [],
      itemsShowInt: [],
      itemsAds: [],

      columnsCont: [
        { key: 'name', label: this.$t('firstName'), sortable: false },
        { key: 'status', label: this.$t('editContent.status'), sortable: false },
        { key: 'interactive', label: this.$t('inter'), sortable: false },
        { key: 'c', label: this.$t('views'), sortable: true },
      ],
      columnsAds: [
        { key: 'name', label: this.$t('firstName'), sortable: false },
        { key: 'status', label: this.$t('editContent.status'), sortable: false },
        { key: 'startDate', label: this.$t('epg.startDate'), sortable: false },
        { key: 'finalDate', label: this.$t('epg.endDate'), sortable: false },
        { key: 'c', label: this.$t('views'), sortable: true },
      ],
      columnsFavCont: [
        { key: 'name', label: this.$t('firstName'), sortable: false },
        { key: 'status', label: this.$t('editContent.status'), sortable: false },
        { key: 'interactive', label: this.$t('inter'), sortable: false },
        { key: 'c', label: this.$t('analytics.interac'), sortable: true },
      ],
      columnsCat: [
        { key: 'name', label: this.$t('firstName'), sortable: false },
        { key: 'isActive', label: this.$t('editContent.status'), sortable: false },
        { key: 'c', label: this.$t('views'), sortable: true },
      ],
      columnsSlide: [
        { key: 'name', label: this.$t('firstName'), sortable: false },
        { key: 'isActive', label: this.$t('editContent.status'), sortable: false },
        { key: 'c', label: this.$t('views'), sortable: true },
      ],
      columnsInt: [
        { key: 'name', label: this.$t('firstName'), sortable: false },
        { key: 'isActive', label: this.$t('editContent.status'), sortable: false },
        { key: 'connect', label: this.$t('type'), sortable: false },
        { key: 'c', label: this.$t('views'), sortable: true },
        { key: 'p', label: this.$t('analytics.interac'), sortable: true },
      ],
      selected: null,
      interactvtyType: {
        GOT: 'Vídeo',
        GOF: 'Final de vídeo',
        ACR: 'ACR',
        PUS: 'Push',
        ADV: 'Banner',
        EMA: 'Email',
      },
      api_key: null,
      app_id: null,
      statisticsUrl: null,
      vacio: true,
      perPage: 20,
      year: new Date().getFullYear(),
      orderByCat: null,
      orderByCont: null,
      orderByFavCont: null,
      orderByAds: null,
      orderBySlide: null,
      orderByInt: null,
      totalsPeriod: false,
    }
  },
  watch: {
    periodCont() {
      this.clickContent()
    },
    periodFavCont() {
      this.clickFavContent()
    },
    periodCat() {
      this.clickCategory()
    },
    periodSlide() {
      this.clickSlide()
    },

    periodShowInt() {
      this.showInteractivity()
    },
    periodAds() {
      this.adsExternal()
    },

    timeUser() {
      try {
        const separacion = this.timeUser.split('to')
        const i = new Date(separacion[0])
        const f = new Date(separacion[1])
        const guardar = [i.getTime(), f.getTime()]
        if (separacion.length > 1) this.periodUser = guardar
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    timeAds() {
      try {
        const separacion = this.timeAds.split('to')
        const i = new Date(separacion[0])
        const f = new Date(separacion[1])
        const guardar = [i.getTime(), f.getTime()]
        if (separacion.length > 1) {
          this.periodAds = guardar
          this.adsExternal()
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    timeCont() {
      try {
        const separacion = this.timeCont.split('to')
        const i = new Date(separacion[0])
        const f = new Date(separacion[1])
        const guardar = [i.getTime(), f.getTime()]
        if (separacion.length > 1) {
          this.periodCont = guardar
          this.clickContent()
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    timeFavCont() {
      try {
        const separacion = this.timeFavCont.split('to')
        const i = new Date(separacion[0])
        const f = new Date(separacion[1])
        const guardar = [i.getTime(), f.getTime()]
        if (separacion.length > 1) {
          this.periodFavCont = guardar
          this.clickFavContent()
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    timeCat() {
      try {
        const separacion = this.timeCat.split('to')
        const i = new Date(separacion[0])
        const f = new Date(separacion[1])
        const guardar = [i.getTime(), f.getTime()]
        if (separacion.length > 1) {
          this.periodCat = guardar
          this.clickCategory()
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    timeSlide() {
      try {
        const separacion = this.timeSlide.split('to')
        const i = new Date(separacion[0])
        const f = new Date(separacion[1])
        const guardar = [i.getTime(), f.getTime()]
        if (separacion.length > 1) {
          this.periodSlide = guardar
          this.clickSlide()
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },

    timeShowInt() {
      try {
        const separacion = this.timeShowInt.split('to')
        const i = new Date(separacion[0])
        const f = new Date(separacion[1])
        const guardar = [i.getTime(), f.getTime()]
        if (separacion.length > 1) {
          this.periodShowInt = guardar
          this.showInteractivity()
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
  },
  mounted() {
    this.dataClient()
  },
  methods: {
    adsExternal() {
      this.showAds = true
      const options = {
        method: 'GET',
        headers: { Accept: 'application/json' },
      }

      let period = ''

      if (Array.isArray(this.periodAds)) {
        const inicio = this.periodAds[0]
        const fin = this.periodAds[1]

        period = `%5B${inicio}%2C${fin}%5D`
      } else {
        period = this.periodAds
      }

      const events = 'events'
      const event = 'ad_external'
      const segmentation = 'campaign_id'
      fetch(
        `${this.statisticsUrl}/o/analytics/events?app_id=${this.app_id}&method=${events}&api_key=${this.api_key}&event=${event}&segmentation=${segmentation}&period=${period}&preventRequestAbort=true`,
        options,
      )
        .then(response => response.json())
        .then(response => {
          this.resAds = response
          this.getAdsInfo()
        })
        .catch(() => {
          this.showAds = false
        })
    },
    clickContent() {
      this.showCont = true
      const options = {
        method: 'GET',
        headers: { Accept: 'application/json' },
      }

      let period = ''

      if (Array.isArray(this.periodCont)) {
        const inicio = this.periodCont[0]
        const fin = this.periodCont[1]

        period = `%5B${inicio}%2C${fin}%5D`
      } else {
        period = this.periodCont
      }

      const events = 'events'
      const event = 'play_content'
      const segmentation = 'content_id'
      fetch(
        `${this.statisticsUrl}/o/analytics/events?app_id=${this.app_id}&method=${events}&api_key=${this.api_key}&event=${event}&segmentation=${segmentation}&period=${period}&preventRequestAbort=true`,
        options,
      )
        .then(response => response.json())
        .then(response => {
          this.resCont = response
          this.getContentInfo()
        })
        .catch(() => { this.showCont = false })
    },
    setPeriodUser(time) {
      this.totalsPeriod = false
      this.periodUser = time
    },
    clickFavContent() {
      this.showFavCont = true
      const options = {
        method: 'GET',
        headers: { Accept: 'application/json' },
      }

      let period = ''

      if (Array.isArray(this.periodFavCont)) {
        const inicio = this.periodFavCont[0]
        const fin = this.periodFavCont[1]

        period = `%5B${inicio}%2C${fin}%5D`
      } else {
        period = this.periodFavCont
      }

      const events = 'events'
      const event = 'favorite_content'
      const segmentation = 'content_id'
      fetch(
        `${this.statisticsUrl}/o/analytics/events?app_id=${this.app_id}&method=${events}&api_key=${this.api_key}&event=${event}&segmentation=${segmentation}&period=${period}&preventRequestAbort=true`,
        options,
      )
        .then(response => response.json())
        .then(response => {
          this.resFavCont = response
          this.getFavContentInfo()
        })
        .catch(() => { this.showFavCont = false })
    },
    onSortChangedCategory(sortInfo = null) {
      let busqueda = this.totalCat.slice()
      if (sortInfo || this.orderByCat) {
        this.orderByCat = sortInfo || this.orderByCat
        switch (this.orderByCat.sortBy) {
          case 'c':
            if (!this.orderByCat.sortDesc) busqueda = busqueda.sort((a, b) => a.c - b.c).slice()
            else { busqueda = busqueda.sort((a, b) => b.c - a.c).slice() }
            break
          default:
            break
        }
      }
      busqueda = busqueda.slice(
        (this.currentPageCat - 1) * this.perPage,
        this.currentPageCat * this.perPage,
      )

      this.paginationCategory(busqueda)
    },
    clickCategory() {
      this.showCat = true
      const options = {
        method: 'GET',
        headers: { Accept: 'application/json' },
      }

      let period = ''

      if (Array.isArray(this.periodCat)) {
        const inicio = this.periodCat[0]
        const fin = this.periodCat[1]

        period = `%5B${inicio}%2C${fin}%5D`
      } else {
        period = this.periodCat
      }
      const events = 'events'
      const event = 'category_detail'
      const segmentation = 'category_id'
      fetch(
        `${this.statisticsUrl}/o/analytics/events?period=${period}&method=${events}&api_key=${this.api_key}&app_id=${this.app_id}&event=${event}&segmentation=${segmentation}&preventRequestAbort=true`,
        options,
      )
        .then(response => response.json())
        .then(response => {
          this.resCat = response
          this.getCategoryInfo()
        })
        .catch(() => { this.showCat = false })
    },
    clickSlide() {
      this.showSlide = true
      const options = {
        method: 'GET',
        headers: { Accept: 'application/json' },
      }
      let period = ''
      if (Array.isArray(this.periodSlide)) {
        const inicio = this.periodSlide[0]
        const fin = this.periodSlide[1]

        period = `%5B${inicio}%2C${fin}%5D`
      } else {
        period = this.periodSlide
      }
      const events = 'events'
      const event = 'select_slider'
      const segmentation = 'slide_id'
      fetch(
        `${this.statisticsUrl}/o/analytics/events?period=${period}&method=${events}&api_key=${this.api_key}&app_id=${this.app_id}&event=${event}&segmentation=${segmentation}&preventRequestAbort=true`,
        options,
      )
        .then(response => response.json())
        .then(response => {
          this.resSlide = response

          this.getSlideInfo()
        })
        .catch(() => { this.showSlide = false })
    },
    showInteractivity() {
      this.showShowInt = true
      const options = {
        method: 'GET',
        headers: { Accept: 'application/json' },
      }

      let period = ''

      if (Array.isArray(this.periodShowInt)) {
        const inicio = this.periodShowInt[0]
        const fin = this.periodShowInt[1]

        period = `%5B${inicio}%2C${fin}%5D`
      } else {
        period = this.periodShowInt
      }
      const events = 'events'
      const event = 'view_interactivity'
      const segmentation = 'interactivity_id'
      fetch(
        `${this.statisticsUrl}/o/analytics/events?period=${period}&method=${events}&api_key=${this.api_key}&app_id=${this.app_id}&event=${event}&segmentation=${segmentation}&preventRequestAbort=true`,
        options,
      )
        .then(response => response.json())
        .then(response => {
          this.resShowInt = response

          const eventClick = 'select_interactivity'
          fetch(
            `${this.statisticsUrl}/o/analytics/events?period=${period}&method=${events}&api_key=${this.api_key}&app_id=${this.app_id}&event=${eventClick}&segmentation=${segmentation}&preventRequestAbort=true`,
            options,
          )
            .then(res => res.json())
            .then(res => {
              this.resInt = res

              this.getShowInteractvtyInfo()
            })
            .catch(() => {
              this.showShowInt = false
            })
        })
        .catch(() => {
          this.showShowInt = false
        })
    },
    getAdsInfo() {
      this.totalAds = []
      this.countAds = 0
      this.resAds.forEach(element => {
        this.totalAds.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          c: element.c,
        })
        this.countAds += element.c
      })

      this.countAds = this.countAds
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.totalAds = this.totalAds.sort((a, b) => b.c - a.c)
      this.rowsAds = this.totalAds.length
      this.onSortChangedAds()
    },
    paginationAds(busqueda = []) {
      this.showAds = true
      if (busqueda.length > 0) {
        let query = 'query {'
        for (let index = 0; index < busqueda.length; index += 1) {
          query += `c${index}:
        allCampaigns(id:"${busqueda[index].id}") {
            edges {
              node {
                id
                name
                description
                startDate
                finalDate
                isActive
              }
            }
          }`
        }
        query += '}'
        axios
          .post('', { query })
          .then(res => {
            messageError(res, this)

            this.itemsAds = []
            for (let index = 0; index < busqueda.length; index += 1) {
              if (res.data.data[`c${index}`].edges.length !== 0) {
                const objeto = res.data.data[`c${index}`].edges[0]
                this.itemsAds.push({
                  id: objeto.node.id,
                  name: objeto.node.name,
                  description: objeto.node.description,
                  isActive: objeto.node.isActive,
                  c: busqueda[index].c,
                  startDate: objeto.node.startDate,
                  finalDate: objeto.node.finalDate,
                })
              } else {
                this.itemsAds.push({
                  id: null,
                  name: this.$t('countly.adsNotFound'),
                  description: this.$t('countly.data'),
                  isActive: null,
                  c: busqueda[index].c,
                  startDate: null,
                  finalDate: null,
                })
              }
            }
            this.showAds = false
          })
          .catch(() => {
            this.showAds = false
          })
      } else {
        this.itemsAds = []
        this.showAds = false
      }
    },
    getTotal() {
      const actualDate = new Date()
      const pastDate = new Date(actualDate)
      pastDate.setFullYear(pastDate.getFullYear() - 1)
      const guardar = [pastDate.getTime(), actualDate.getTime()]
      return guardar
    },
    setTotalUser() {
      this.totalsPeriod = !this.totalsPeriod
      this.periodUser = this.getTotal()
    },

    getContentInfo() {
      this.totalCont = []
      this.countCont = 0
      this.resCont.forEach(element => {
        this.totalCont.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          c: element.c,
        })
        this.countCont += element.c
      })
      this.countCont = this.countCont
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.totalCont = this.totalCont.sort((a, b) => b.c - a.c)
      this.rowsCont = this.totalCont.length
      this.onSortChangedContent()
    },
    onSortChangedAds(sortInfo = null) {
      let busqueda = this.totalAds.slice()
      if (sortInfo || this.orderByAds) {
        this.orderByAds = sortInfo || this.orderByAds
        switch (this.orderByAds.sortBy) {
          case 'c':
            if (!this.orderByAds.sortDesc) busqueda = busqueda.sort((a, b) => a.c - b.c).slice()
            else { busqueda = busqueda.sort((a, b) => b.c - a.c).slice() }
            break
          default:
            break
        }
      }
      busqueda = busqueda.slice(
        (this.currentPageAds - 1) * this.perPage,
        this.currentPageAds * this.perPage,
      )

      this.paginationAds(busqueda)
    },
    onSortChangedContent(sortInfo = null) {
      let busqueda = this.totalCont.slice()
      if (sortInfo || this.orderByCont) {
        this.orderByCont = sortInfo || this.orderByCont
        switch (this.orderByCont.sortBy) {
          case 'c':
            if (!this.orderByCont.sortDesc) busqueda = busqueda.sort((a, b) => a.c - b.c).slice()
            else { busqueda = busqueda.sort((a, b) => b.c - a.c).slice() }
            break
          default:
            break
        }
      }
      busqueda = busqueda.slice(
        (this.currentPageCont - 1) * this.perPage,
        this.currentPageCont * this.perPage,
      )

      this.paginationContent(busqueda)
    },
    paginationContent(busqueda = []) {
      this.showCont = true

      if (busqueda.length > 0) {
        let query = 'query {'
        for (let index = 0; index < busqueda.length; index += 1) {
          query += `c${index}:
        allContents(id:"${busqueda[index].id}") {
            edges {
              node {
                id
                name
                imageUrl
                state
                mainCategory {
                  id
                  name
                }
                contentInteractivity {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }`
        }

        query += '}'
        axios
          .post('', { query })
          .then(res => {
            messageError(res, this)

            this.itemsCont = []
            for (let index = 0; index < busqueda.length; index += 1) {
              if (res.data.data[`c${index}`].edges.length !== 0) {
                const objeto = res.data.data[`c${index}`].edges[0]
                const inter = objeto.node.contentInteractivity.edges.length !== 0

                this.itemsCont.push({
                  id: objeto.node.id,
                  name: objeto.node.name,
                  imageUrl: objeto.node.imageUrl,
                  status: objeto.node.state,
                  c: busqueda[index].c,
                  subtitle:
                    objeto.node.mainCategory != null
                      ? objeto.node.mainCategory.name
                      : '',
                  interactive: inter,
                })
              } else {
                this.itemsCont.push({
                  id: null,
                  name: this.$t('countly.contNotFound'),
                  imageUrl: null,
                  status: null,
                  c: busqueda[index].c,
                  subtitle: this.$t('countly.data'),
                  interactive: null,
                })
              }
            }
            this.showCont = false
          })
          .catch(() => {
            this.showCont = false
          })
      } else {
        this.itemsCont = []
        this.showCont = false
      }
    },
    onSortChangedFavContent(sortInfo = null) {
      let busqueda = this.totalFavCont.slice()
      if (sortInfo || this.orderByFavCont) {
        this.orderByFavCont = sortInfo || this.orderByFavCont
        switch (this.orderByFavCont.sortBy) {
          case 'c':
            if (!this.orderByFavCont.sortDesc) busqueda = busqueda.sort((a, b) => a.c - b.c).slice()
            else { busqueda = busqueda.sort((a, b) => b.c - a.c).slice() }
            break

          default:
            break
        }
      }
      busqueda = busqueda.slice(
        (this.currentPageFavCont - 1) * this.perPage,
        this.currentPageFavCont * this.perPage,
      )
      this.paginationFavContent(busqueda)
    },
    getFavContentInfo() {
      this.totalFavCont = []
      this.countFavCont = 0
      this.resFavCont.forEach(element => {
        this.totalFavCont.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          c: element.c,
        })
        this.countFavCont += element.c
      })

      this.countFavCont = this.countFavCont
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.totalFavCont = this.totalFavCont.sort((a, b) => b.c - a.c)
      this.rowsFavCont = this.totalFavCont.length
      this.onSortChangedFavContent()
    },
    paginationFavContent(busqueda = []) {
      this.showFavCont = true
      if (busqueda.length > 0) {
        let query = 'query {'
        for (let index = 0; index < busqueda.length; index += 1) {
          query += `c${index}:
        allContents(id:"${busqueda[index].id}") {
            edges {
              node {
                id
                name
                imageUrl
                state
                mainCategory {
                  id
                  name
                }
                contentInteractivity {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }`
        }

        query += '}'
        axios
          .post('', { query })
          .then(res => {
            messageError(res, this)

            this.itemsFavCont = []
            for (let index = 0; index < busqueda.length; index += 1) {
              if (res.data.data[`c${index}`].edges.length !== 0) {
                const objeto = res.data.data[`c${index}`].edges[0]
                const inter = objeto.node.contentInteractivity.edges.length !== 0

                this.itemsFavCont.push({
                  id: objeto.node.id,
                  name: objeto.node.name,
                  imageUrl: objeto.node.imageUrl,
                  status: objeto.node.state,
                  c: busqueda[index].c,
                  subtitle:
                    objeto.node.mainCategory != null
                      ? objeto.node.mainCategory.name
                      : '',
                  interactive: inter,
                })
              } else {
                this.itemsFavCont.push({
                  id: null,
                  name: this.$t('countly.contNotFound'),
                  imageUrl: null,
                  status: null,
                  c: busqueda[index].c,
                  subtitle:
                    this.$t('countly.data'),
                  interactive: null,
                })
              }
            }
            this.showFavCont = false
          })
          .catch(() => {
            this.showFavCont = false
          })
      } else {
        this.itemsFavCont = []
        this.showFavCont = false
      }
    },
    getCategoryInfo() {
      this.totalCat = []
      this.countCat = 0
      this.resCat.forEach(element => {
        this.totalCat.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          c: element.c,
        })
        this.countCat += element.c
      })

      this.countCat = this.countCat
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.totalCat = this.totalCat.sort((a, b) => b.c - a.c)
      this.rowsCat = this.totalCat.length
      this.onSortChangedCategory()
    },
    paginationCategory(busqueda = []) {
      this.showCat = true

      if (busqueda.length > 0) {
        let query = 'query {'
        for (let index = 0; index < busqueda.length; index += 1) {
          query += `c${index}:
        allCategories(id:"${busqueda[index].id}") {
            edges {
              node {
                id
                name
                imageUrl
                isActive

              }
            }
          }`
        }
        query += '}'
        axios
          .post('', { query })
          .then(res => {
            messageError(res, this)

            this.itemsCat = []
            for (let index = 0; index < busqueda.length; index += 1) {
              if (res.data.data[`c${index}`].edges.length !== 0) {
                const objeto = res.data.data[`c${index}`].edges[0]

                this.itemsCat.push({
                  id: objeto.node.id,
                  name: objeto.node.name,
                  imageUrl: objeto.node.imageUrl,
                  isActive: objeto.node.isActive,
                  c: busqueda[index].c,
                })
              } else {
                this.itemsCat.push({
                  id: null,
                  name: this.$t('countly.catNotFound'),
                  imageUrl: null,
                  isActive: null,
                  c: busqueda[index].c,
                })
              }
            }
            this.showCat = false
          })
          .catch(() => {
            this.showCat = false
          })
      } else {
        this.itemsCat = []
        this.showCat = false
      }
    },
    getSlideInfo() {
      this.totalSlide = []
      this.countSlide = 0
      this.resSlide.forEach(element => {
        this.totalSlide.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          c: element.c,
        })
        this.countSlide += element.c
      })
      this.countSlide = this.countSlide
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.totalSlide = this.totalSlide.sort((a, b) => b.c - a.c)
      this.rowsSlide = this.totalSlide.length
      this.onSortChangedSlide()
    },

    onSortChangedSlide(sortInfo = null) {
      let busqueda = this.totalSlide.slice()
      if (sortInfo || this.orderBySlide) {
        this.orderBySlide = sortInfo || this.orderBySlide
        switch (this.orderBySlide.sortBy) {
          case 'c':
            if (!this.orderBySlide.sortDesc) busqueda = busqueda.sort((a, b) => a.c - b.c).slice()
            else { busqueda = busqueda.sort((a, b) => b.c - a.c).slice() }
            break
          default:
            break
        }
      }
      busqueda = busqueda.slice(
        (this.currentPageSlide - 1) * this.perPage,
        this.currentPageSlide * this.perPage,
      )
      this.paginationSlide(busqueda)
    },
    paginationSlide(busqueda = []) {
      this.showSlide = true

      if (busqueda.length > 0) {
        let query = 'query {'
        for (let index = 0; index < busqueda.length; index += 1) {
          query += `c${index}:
        allSlide(id:"${busqueda[index].id}") {
            edges {
              node {
                id
                name
                imageUrl
                isActive

              }
            }
          }`
        }

        query += '}'
        axios
          .post('', { query })
          .then(res => {
            messageError(res, this)

            this.itemsSlide = []
            for (let index = 0; index < busqueda.length; index += 1) {
              if (res.data.data[`c${index}`].edges.length !== 0) {
                const objeto = res.data.data[`c${index}`].edges[0]

                this.itemsSlide.push({
                  id: objeto.node.id,
                  name: objeto.node.name,
                  imageUrl: objeto.node.imageUrl,
                  isActive: objeto.node.isActive,
                  c: busqueda[index].c,
                })
              } else {
                this.itemsSlide.push({
                  id: null,
                  name: this.$t('countly.slideNotFound'),
                  imageUrl: null,
                  isActive: null,
                  c: busqueda[index].c,
                })
              }
            }

            this.showSlide = false
          })
          .catch(() => {
            this.showSlide = false
          })
      } else {
        this.itemsSlide = []
        this.showSlide = false
      }
    },

    getShowInteractvtyInfo() {
      this.totalShowInt = []
      this.countInt = 0
      const showIntMap = new Map()
      this.resShowInt.forEach(showInt => {
        // eslint-disable-next-line no-underscore-dangle
        showIntMap.set(showInt._id, {
          // eslint-disable-next-line no-underscore-dangle
          id: showInt._id,
          c: showInt.c,
          p: 0, // Predeterminado a 0
        })
      })

      // Actualizar el mapa con los valores de resInt
      this.resInt.forEach(clickInt => {
        // eslint-disable-next-line no-underscore-dangle
        if (showIntMap.has(clickInt._id)) {
          // eslint-disable-next-line no-underscore-dangle
          showIntMap.get(clickInt._id).p = clickInt.c
        } else {
          // eslint-disable-next-line no-underscore-dangle
          showIntMap.set(clickInt._id, {
            // eslint-disable-next-line no-underscore-dangle
            id: clickInt._id,
            c: 0, // Predeterminado a 0
            p: clickInt.c,
          })
        }
      })

      // Convertir el mapa en un array
      this.totalShowInt = Array.from(showIntMap.values())
      // Calcular countInt
      this.countInt = this.totalShowInt.reduce((total, item) => total + item.c, 0)

      this.countInt = this.countInt
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.totalShowInt = this.totalShowInt.sort((a, b) => b.c - a.c)
      this.rowsShowInt = this.totalShowInt.length
      this.onSortChangedInt()
    },
    paginationShowInteractvty(busqueda = []) {
      this.showShowInt = true

      if (busqueda.length > 0) {
        let query = 'query {'
        for (let index = 0; index < busqueda.length; index += 1) {
          query += `c${index}:
        allInteractivities(id:"${busqueda[index].id}") {
            edges {
              node {
               id
              name
              isActive
              connect
              content {
                id
                name
              }
            }
          }
        }`
        }

        query += '}'
        axios
          .post('', { query })
          .then(res => {
            messageError(res, this)

            this.itemsShowInt = []
            for (let index = 0; index < busqueda.length; index += 1) {
              if (res.data.data[`c${index}`].edges.length !== 0) {
                const objeto = res.data.data[`c${index}`].edges[0]

                this.itemsShowInt.push({
                  id: objeto.node.id,
                  name: objeto.node.name,
                  subtitle:
                    objeto.node.content != null ? objeto.node.content.name : '',
                  // imageUrl: objeto.node.imageUrl,
                  connect: objeto.node.connect,
                  isActive: objeto.node.isActive,
                  c: busqueda[index].c,
                  p: busqueda[index].p,
                })
              } else {
                this.itemsShowInt.push({
                  id: null,
                  name: this.$t('countly.intNotFound'),
                  subtitle: this.$t('countly.data'),
                  connect: null,
                  isActive: null,
                  c: busqueda[index].c,
                  p: busqueda[index].p,
                })
              }
            }
            this.showShowInt = false
          })
          .catch(() => {
            this.showShowInt = false
          })
      } else {
        this.itemsShowInt = []
        this.showShowInt = false
      }
    },
    onSortChangedInt(sortInfo = null) {
      let busqueda = this.totalShowInt.slice()
      if (sortInfo || this.orderByInt) {
        this.orderByInt = sortInfo || this.orderByInt
        switch (this.orderByInt.sortBy) {
          case 'c':
            if (!this.orderByInt.sortDesc) busqueda = busqueda.sort((a, b) => a.c - b.c).slice()
            else { busqueda = busqueda.sort((a, b) => b.c - a.c).slice() }
            break
          case 'p':
            if (!this.orderByInt.sortDesc) busqueda = busqueda.sort((a, b) => a.p - b.p).slice()
            else { busqueda = busqueda.sort((a, b) => b.p - a.p).slice() }
            break
          default:
            break
        }
      }
      busqueda = busqueda.slice(
        (this.currentPageInt - 1) * this.perPage,
        this.currentPageInt * this.perPage,
      )

      this.paginationShowInteractvty(busqueda)
    },
    dataClient() {
      const query = `{
          allClients(clientId:"${this.userData.profile.client.clientId}") {
          edges {
            node {
              statisticsId
              statisticsUserApiKey
              statisticsUrl
            }
          }
        }
      }`
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)

          try {
            this.statisticsUrl = result.data.data.allClients.edges[0].node.statisticsUrl
            this.app_id = result.data.data.allClients.edges[0].node.statisticsId
            this.api_key = result.data.data.allClients.edges[0].node.statisticsUserApiKey

            this.vacio = false

            if (this.app_id == null) {
              this.vacio = true
              showToast(
                this.$t('requiredAppId'),
                2, this,
              )
            }
            if (this.api_key == null) {
              this.vacio = true
              showToast(
                this.$t('requiredApiKey'),
                2, this,
              )
            }
            if (this.statisticsUrl == null) {
              this.vacio = true
              showToast(
                this.$t('requiredStatisUrl'),
                2, this,
              )
            }

            if (!this.vacio) {
              this.clickContent()
              this.clickFavContent()
              this.clickCategory()

              this.showInteractivity()
              this.clickSlide()
              this.adsExternal()
            }
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => { })
    },
    clickCalendar(id) {
      this.totalsPeriod = false

      document.getElementById(id).click()
    },
    buildImageUrl(folder = null) {
      if (folder == null) return noCover
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },

    errorImg(e) {
      e.target.src = fileError
    },

    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : '--/--/----'
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.tableImage {
  margin-right: 2rem;
  border-radius: 10px;
  width: 8rem;
  height: 5rem;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}
</style>
