<template>
  <div id="wizardChannel">
    <b-row class="mb-5">
      <b-col
        md="10"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("createChannel") }}</h1>
        <h5 class="text-primary">
          {{ $t("configChannel") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-img
          v-if="languageService"
          height="20px"
          width="30px"
          :src="languageFlags(defaultLanguage)"
          class="mr-2"
        />

        <b-button
          variant="danger"
          @click="goBack()"
        >
          {{
            $t("dataGeneric.goBack")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.save')"
        :back-button-text="$t('dataGeneric.previous')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <tab-content
          :title="$t('infoChannel')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("infoChannel") }}
                </h5>
                <small class="text-muted">
                  {{ $t("infoChan") }}
                </small>
              </b-col>
              <b-col md="12">
                <!--cambiado el idioma del input -->
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  :rules="{ required: true, min: 3 }"
                >
                  <b-form-group
                    :label="$t('dataGeneric.name')"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      maxlength="150"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('dataGeneric.name')"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12">
                <b-form-group :label="$t('dataGeneric.description')">
                  <b-form-textarea
                    v-model="description"
                    :placeholder="$t('dataGeneric.description')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('relatedTagLabel')"
                >
                  <b-form-input
                    v-model="relatedTag"
                    maxlength="256"
                    :placeholder="$t('relatedTagInfo')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- Archivos del contenido  tab -->
        <tab-content :title="$t('Files')">
          <b-row>
            <image-element-vue
              :image-origin="image"
              :md-length="5"
              :image-type="'imageData'"
              :label="$t('Image')"
              @saveImage="loadImage"
            />
            <image-element-vue
              :image-origin="icon"
              :image-type="'iconData'"
              :md-length="5"

              :label="$t('icono')"
              @saveImage="loadImage"
            />
          </b-row>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import ImageElementVue from '@/views/components/ImageElement.vue'

import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  VBTooltip,
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import { showToast, messageError, base64Encode } from '@/store/functions'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import languageFlags from '@/@core/utils/languageFlags'

// import { codeIcon } from './code'

export default {
  components: {
    BImg,
    BButton,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormTextarea,
    BRow,
    BCol,
    ImageElementVue,
    BFormGroup,
    BFormInput,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      userData: getUserData(),
      image: null,
      icon: null,
      show: false,
      name: null,
      description: null,
      relatedTag: null,
      id: null,
      defaultLanguage: '',
      languageService: false,
      parent: this.$route.params.parent,
    }
  },
  watch: {
    imageData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataSrc = value
            })
            .catch(() => {
              this.imageDataSrc = null
            })
        } else {
          this.imageDataSrc = null
        }
      }
    },
    imageDataMobile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataMobileSrc = value
            })
            .catch(() => {
              this.imageDataMobileSrc = null
            })
        } else {
          this.imageDataMobileSrc = null
        }
      }
    },
  },
  mounted() {
    try {
      this.isShop = this.userData.profile.client.isShop
      // eslint-disable-next-line no-empty
    } catch (error) { }
    this.getDefaultLanguage()
  },

  methods: {
    languageFlags,
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
               query{
               client(id:"${this.userData.profile.client.id}"){
                   defaultLanguage
                   availableLanguages
               }
           }
           `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client
          try {
            const arr = availableLanguages.split('|')
            this.languageService = arr.length > 1
            // eslint-disable-next-line no-empty
          } catch (error) { }
          this.defaultLanguage = res.data.data.client.defaultLanguage
        })
        .catch(() => {
        })
    },
    fix(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'required') {
        return this.$t('required')
      }
      if (keyName === 'min') {
        return this.$t('cont3char')
      }
      return ''
    },
    fixUrl(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'required') {
        return this.$t('required')
      }
      if (keyName === 'url') {
        return this.$t('validUrl')
      }
      return ''
    },

    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageData':
          this.image = data
          break
        case 'iconData':
          this.icon = data
          break

        default:
          break
      }
    },
    formSubmitted() {
      this.show = true
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const variables = {
        name: this.name,
        description: this.description,
        relatedTag: this.relatedTag,
        parent: this.parent,
        client: this.userData.profile.client.id,
      }
      const query = `
            mutation($name: String!, $description: String,$relatedTag: String,$parent: ID,$client: ID!){
                createExtendedTags(input:{
                name:$name,
                description:$description,
                relatedTag:$relatedTag,
                client: $client,
                parent:$parent,
                isProfilePreference:true
                }){
                    extendedTag{
                    id
                    }
                }
                }
          `
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      if (this.image) data.append('image', this.image)
      if (this.icon) data.append('icon', this.icon)

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.show = false
          window.history.back()
        })
        .catch(res => {
          console.log(res)
          showToast(this.$t('error'), 0, this)
          this.show = false
        })
    },
    goBack() {
      window.history.back()
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#wizardChannel .modal {
    overflow-y: auto;
}

/*
  Full screen Modal
  */
#wizardChannel .fullscreen-modal .modal-dialog {
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

@media (min-width: 768px) {
    #wizardChannel .fullscreen-modal .modal-dialog {
        width: 750px;
    }
}

@media (min-width: 992px) {
    #wizardChannel .fullscreen-modal .modal-dialog {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    #wizardChannel .fullscreen-modal .modal-dialog {
        width: 1170px;
    }
}
</style>
