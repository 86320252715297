<template>
  <section id="dashboard-analytics">
    <!-- TITLE -->
    <b-row class="mb-3">
      <b-col
        md="8"
        class="pb-3 pb-md-0"
      >
        <div>
          <h1>{{ $t('supplierPanel.supplierPanelTitle') }}</h1>
          <h5 class="text-primary">
            {{ $t('supplierPanel.supplierPanelSubTitle') }}
          </h5>
        </div>
      </b-col>
    </b-row>
    <!-- SECTION 1 -->
    <div id="dashboard-analytics-cardSection1">
      <b-card>
        <!-- <b-card-header>
          <div
            class="pb-1 subtitleCard"
          >
            <span id="textSubtitleCard">Subgrupo <span class="resaltar">John Doe</span></span>
          </div>
        </b-card-header> -->
        <b-card-body>
          <b-row class="h-100 justify-content-center align-items-center pt-1 pb-1">
            <b-col
              cols="2"
              class="text-center"
            >
              <img
                :src="srcImageCard"
                class="img-fluid dashboard-analytics-imgCardSection1"
                style="background-color: #31375a;"
                @error="handleImageUserError"
              >
              <!-- <img
                src="http://www.localplaner.de/img/2-small.de63ead1.png"
                class="img-fluid dashboard-analytics-imgCardSection1"
                style="background-color: #31375a;"
                @error="handleImageUserError"
              > -->
            </b-col>
            <b-col
              id="dashboard-analytics-colCentralSection1"
              cols="7"
              class="text-left"
            >
              <div id="dashboard-analytics-titleCardSection1">
                <span>
                  {{ titleCardSection1 }}
                </span>
              </div>
              <div id="dashboard-analytics-textCardSection1">
                <span
                  class="dashboard-analytics-acortarParrafo myCursorHelp"
                  :title="textCardSection1"
                >
                  {{ textCardSection1 }}
                </span>
              </div>
            </b-col>
            <b-col
              cols="3"
              class="text-left"
            >
              <div>
                <span id="dashboard-analytics-subTitleTextRightCardSection1">
                  {{ subTitleTextRightCardSection1 }}
                </span>
                <br>
                <span id="dashboard-analytics-titleTextRightCardSection1">
                  {{ titleTextRightCardSection1 }}
                </span>
              </div>
              <!-- TABLA DINAMICA - clave/valor -->
              <!-- <div class="container">
                <span class="pb-1">{{ titleTableInfoSubGroup }}</span>
                <div
                  class="list-container dashboard-analytics-custom-scrollbar mt-1"
                  style="border-radius: 5px;"
                >
                  <ul class="list-group ">
                    <li
                      v-for="(valor, clave) in listDataExtaInfo"
                      :key="clave"
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <span class="font-weight-bold">{{ clave }}:</span>
                      <span>{{ valor }}</span>
                    </li>
                  </ul>
                </div>
              </div> -->
            </b-col>
          </b-row>
        </b-card-body>
        <!-- <b-card-footer>
          <div style="border: 1px solid red; text-align: center;" class="pt-1">
            <span>HOLA</span>
          </div>
        </b-card-footer> -->
      </b-card>
    </div>
    <!-- SECTION 2 -->
    <div
      id="filtersSection2"
      class="mt-3"
    >
      <b-row class="h-100">
        <b-col cols="3">
          <!-- <div class="d-flex align-items-center justify-content-start"> -->
          <!-- <div class="d-flex"> -->
          <div>
            <!-- Titulo de filtro fechas -->
            <div class="dashboard-analytics-titleFilterSection2">
              <span>{{ titleFilterSection2 }}</span>
              <b-button
                v-if="startDate"
                variant="link"
                @click="clearDateRangeAndRefresh"
              >
                <feather-icon
                  icon="XCircleIcon"
                  size="18"
                  class="text-danger cursor-pointer ml-2"
                />
              </b-button>
            </div>
            <!-- Paquete iconos y toggle -->
            <!-- <div class="d-flex pl-1 pr-1 mb-1">
                  <div
                    title="Fecha unica"
                    class="cursor-pointer d-flex "
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="16"
                    />
                  </div>
                  <b-form-checkbox
                    v-model="isRangeMode"
                    switch
                    class="ml-1 mr-1"
                    @change="clearDateRangeAndRefresh"
                  />
                  <div
                    class="d-flex cursor-pointer"
                    title="Rango de fechas"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="16"
                    />
                    <feather-icon
                      icon="ArrowLeftIcon"
                      size="14"
                    />
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="14"
                    />
                    <feather-icon
                      icon="CalendarIcon"
                      size="16"
                    />
                  </div>
                </div> -->
            <!-- Selector de fechas -->
            <b-form-group>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="datePickerConfig"
                :placeholder="selectDateRange"
              />
            </b-form-group>
          </div>
          <!-- <div>
              <div class="dashboard-analytics-titleFilterSection2">
                <span>Filtrar por nombre</span>
              </div>
              <div class="mt-1 mb-1">
                <span>Contenidos los cuales incluyen el nombre</span>
              </div>
              <div class="d-flex">
                <div class="mr-1">
                  <b-form-input
                    v-model="nameSearch"
                    class="d-inline-block mr-1"
                    placeholder="Buscar contenido"
                  />
                </div>
                <div>
                  <b-button @click="getData">
                    <span>{{ $t('Search') }}</span>
                  </b-button>
                </div>
              </div>
            </div> -->
          <!-- </div> -->
          <!-- </div> -->
        </b-col>
        <b-col
          v-if="subgroups.length > 0"
          md="4"
        >
          <div class="dashboard-analytics-titleFilterSection2">
            <span>{{ $t('Subgroup') }}</span>
            <b-form-group>
              <v-select
                id="subgroup"
                v-model="subgroup"
                class="mt-1"
                :options="subgroups"
                label="name"
                :placeholder="$t('SinGrupoUsuario')"
                aria-autocomplete="new-password"
                autocomplete="new-password"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- SECTION 3 -->
    <div id="tableSection3">
      <!-- Añadir -hover- para efecto al pasar por encima -->
      <div class="card">
        <div class="card-body">
          <!-- FUNCIONALIDAD ACTUALIZACION:  MANUAL Y AUTOMATICA   -->
          <!-- <div class="d-flex justify-content-end">
            <div class="mr-1">
              <b-button
                variant="secondary"
                title="Actualizar tabla"
                @click="getData"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  size="18"
                  class="text-light cursor-pointer "
                />
              </b-button>
            </div>
            <div>
              <b-form-select
                v-model="selectedInterval"
                title="Actualizar tabla automáticamente"
                :options="intervalOptions"
                @change="startTimer"
              />
              <b-progress
                v-if="isProgressVisible"
                :value="progressValue"
                max="100"
                class="progress-sm"
              />
            </div>
          </div> -->
          <!-- VERSION FINAL  -->
          <div class="d-flex justify-content-between pr-2 pl-2">
            <div class="pagination-info">
              <b-spinner
                v-if="showData"
              />
            </div>
            <div class="d-flex">
              <div class="dashboard-analytics-titlePerPageSection2 pt-1 mr-1">
                <span>{{ $t('supplierPanel.show') }}</span>
              </div>
              <div>
                <b-form-select
                  v-model="perPage"
                  :options="optionsSelectItemsPerPage"
                  @change="getData"
                />
              </div>
              <div class="dashboard-analytics-titlePerPageSection2 pt-1 ml-1">
                <span>{{ $t('supplierPanel.contents') }}</span>
              </div>
            </div>
          </div>
          <!--  -->
          <b-overlay
            variant="transparent"
            :opacity="1.00"
            :blur="'0.1em'"
            rounded="lg"
            :show="showData"
          >
            <b-table
              id="dashboard-analytics-table"
              class="mt-1 dashboard-analytics-custom-scrollbar my-table"
              :no-border-collapse="true"
              :items="myData"
              :fields="columnas"
              show-empty
              striped
              thead-class="thead-light"
            >
              <!-- CABECERAS  -->
              <!-- <template #head(index)>
            <span class="dashboard-analytics-custom-header pl-1">index</span>
              </template> -->
              <!-- <template #head(id)>
                <span class="dashboard-analytics-custom-header pl-1">id</span>
              </template> -->
              <template #head(imagen)>
                <div>
                  <span class="dashboard-analytics-custom-header" />
                </div>
              </template>
              <template #head(contenido)>
                <span class="dashboard-analytics-custom-header pl-1">{{ $t('supplierPanel.content') }}</span>
              </template>
              <template #head(ads)>
                <div class="text-center">
                  <span class="dashboard-analytics-custom-header">ADs</span>
                </div>
              </template>
              <template #head(percentil_0_25)>
                <div class="text-center">
                  <span class="dashboard-analytics-custom-header">Percentil<br>0-25</span>
                </div>
              </template>
              <template #head(percentil_25_50)>
                <div class="text-center">
                  <span class="dashboard-analytics-custom-header">Percentil<br>25-50</span>
                </div>
              </template>
              <template #head(percentil_50_75)>
                <div class="text-center">
                  <span class="dashboard-analytics-custom-header">Percentil<br>50-75</span>
                </div>
              </template>
              <template #head(percentil_75_100)>
                <div class="text-center">
                  <span class="dashboard-analytics-custom-header">Percentil<br>75-100</span>
                </div>
              </template>
              <template #head(consumo)>
                <div class="text-center">
                  <span class="dashboard-analytics-custom-header">{{ $t('supplierPanel.consumption') }}</span>
                </div>
              </template>
              <!-- FILAS -->
              <!-- <template #cell(index)="data">
              <div
              class="text-center p-1"
              >
              <span class="dashboard-analytics-text-conAds">{{ data.item.index }}</span>
              </div>
            </template> -->
              <!--  <template #cell(id)="data">
                <div
                  class="text-center p-1"
                >
                  <span class="dashboard-analytics-text-conAds">{{ data.item.id }}</span>
                </div>
              </template> -->
              <!-- IMAGEN -->
              <template #cell(imagen)="data">
                <div
                  class="dashboard-analytics-imagenSection3"
                >
                  <!-- <span>{{ data.item.imagen }}</span> -->
                  <img
                    class="dashboard-analytics-imagenSection3-img"
                    :src="data.item.imagen"
                    style="border-radius: 1em;"
                    @error="handleImageError"
                  >
                </div>
                <div
                  v-if="!mostrarOchoColumnas"
                  class="text-center dashboard-analytics-contenidoTitleSection3"
                >
                  <span
                    style="color: #FFC600;"
                    class="dashboard-analytics-acortarTitulo pt-1 myCursorHelp"
                    :title="data.item.contenido.text"
                  >{{ data.item.contenido.title }}</span>
                </div>
              </template>
              <!-- CONTENIDO -->
              <template #cell(contenido)="data">
                <div class="pl-1">
                  <div
                    id="dashboard-analytics-contenidoTitleSection3"
                    class="pb-1 pt-1 myCursorHelp"
                    :title="data.item.contenido.title"
                  >
                    <span
                      class="dashboard-analytics-acortarTitulo"
                    >{{ data.item.contenido.title }}</span>
                  </div>
                  <div id="dashboard-analytics-contenidoTextSection3">
                    <span
                      class="dashboard-analytics-acortarParrafo myCursorHelp"
                      :title="data.item.contenido.text"
                    >{{ data.item.contenido.text }}</span>
                  </div>
                </div>
              </template>
              <!-- ADS -->
              <template
                #cell(ads)
              >
                <div
                  class="text-center mb-2"
                >
                  <span
                    class="dashboard-analytics-text-sinAds"
                    :class="[fontSizeClass]"
                  >{{ $t("WithoutAds") }}</span>
                </div>
                <div
                  class="text-center mt-2"
                >
                  <span
                    class="dashboard-analytics-text-conAds"
                    :class="[fontSizeClass]"
                  >{{ $t("WithAds") }}</span>
                </div>
              </template>
              <!-- PERCENTIL 0-25 -->
              <template #cell(percentil_0_25)="data">
                <div
                  class="text-center p-1"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.percentils.percentil_0_25.without))"
                    class="dashboard-analytics-text-sinAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatearNumero(data.item.percentils.percentil_0_25.without)+' '+$t('supplierPanel.views')"
                  >{{ formatoContraidoNumero(data.item.percentils.percentil_0_25.without) }}</span>
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                    :title="$t('supplierPanel.infoTitleSpanWithoutADs1')+$t('supplierPanel.noData')"
                  >{{ formatearNumero(data.item.percentils.percentil_0_25.without) }}</span>
                </div>
                <div
                  class="text-center p-1"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.percentils.percentil_0_25.with))"
                    class="dashboard-analytics-text-conAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatearNumero(data.item.percentils.percentil_0_25.with)+' '+$t('supplierPanel.views')"
                  >{{ formatoContraidoNumero(data.item.percentils.percentil_0_25.with) }}</span>
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                    :title="$t('supplierPanel.infoTitleSpanWithADs1')+$t('supplierPanel.noData')"
                  >{{ formatearNumero(data.item.percentils.percentil_0_25.with) }}</span>
                </div>
              </template>
              <!-- PERCENTIL 25-50 -->
              <template #cell(percentil_25_50)="data">
                <div
                  class="text-center p-1"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.percentils.percentil_25_50.without))"
                    class="dashboard-analytics-text-sinAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatearNumero(data.item.percentils.percentil_25_50.without)+' '+$t('supplierPanel.views')"
                  >{{ formatoContraidoNumero(data.item.percentils.percentil_25_50.without) }}</span>
                  <!-- Muestra un texto informativo de que este contenido no tiende datos de estadisticas -->
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                    :title="$t('supplierPanel.infoTitleSpanWithoutADs2')+$t('supplierPanel.noData')"
                  >{{ formatearNumero(data.item.percentils.percentil_25_50.without) }}</span>
                </div>
                <div
                  class="text-center p-1"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.percentils.percentil_25_50.with))"
                    class="dashboard-analytics-text-conAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatearNumero(data.item.percentils.percentil_25_50.with)+' '+$t('supplierPanel.views')"
                  >{{ formatoContraidoNumero(data.item.percentils.percentil_25_50.with) }}</span>
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                    :title="$t('supplierPanel.infoTitleSpanWithADs2')+$t('supplierPanel.noData')"
                  >{{ formatearNumero(data.item.percentils.percentil_25_50.with) }}</span>
                </div>
              </template>
              <!-- PERCENTIL 50-75 -->
              <template #cell(percentil_50_75)="data">
                <div
                  class="text-center p-1"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.percentils.percentil_50_75.without))"
                    class="dashboard-analytics-text-sinAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatearNumero(data.item.percentils.percentil_50_75.without)+' '+$t('supplierPanel.views')"
                  >{{ formatoContraidoNumero(data.item.percentils.percentil_50_75.without) }}</span>
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                    :title="$t('supplierPanel.infoTitleSpanWithoutADs3')+$t('supplierPanel.noData')"
                  >{{ formatearNumero(data.item.percentils.percentil_50_75.without) }}</span>
                </div>
                <div
                  class="text-center p-1"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.percentils.percentil_50_75.with))"
                    class="dashboard-analytics-text-conAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatearNumero(data.item.percentils.percentil_50_75.with)+' '+$t('supplierPanel.views')"
                  >{{ formatoContraidoNumero(data.item.percentils.percentil_50_75.with) }}</span>
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                    :title="$t('supplierPanel.infoTitleSpanWithADs3')+$t('supplierPanel.noData')"
                  >{{ formatearNumero(data.item.percentils.percentil_50_75.with) }}</span>
                </div>
              </template>
              <!-- PERCENTIL 75-100 -->
              <template #cell(percentil_75_100)="data">
                <div
                  class="text-center p-1"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.percentils.percentil_75_100.without))"
                    class="dashboard-analytics-text-sinAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatearNumero(data.item.percentils.percentil_75_100.without)+' '+$t('supplierPanel.views')"
                  >{{ formatoContraidoNumero(data.item.percentils.percentil_75_100.without) }}</span>
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                    :title="$t('supplierPanel.infoTitleSpanWithoutADs4')+$t('supplierPanel.noData')"
                  >{{ formatearNumero(data.item.percentils.percentil_75_100.without) }}</span>
                </div>
                <div
                  class="text-center p-1"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.percentils.percentil_75_100.with))"
                    class="dashboard-analytics-text-conAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatearNumero(data.item.percentils.percentil_75_100.with)+' '+$t('supplierPanel.views')"
                  >{{ formatoContraidoNumero(data.item.percentils.percentil_75_100.with) }}</span>
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                    :title="$t('supplierPanel.infoTitleSpanWithADs4')+$t('supplierPanel.noData')"
                  >{{ formatearNumero(data.item.percentils.percentil_75_100.with) }}</span>
                </div>
              </template>
              <!-- CONSUMO (min) -->
              <template #cell(consumo)="data">
                <div
                  class="text-center mb-2"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.consumo.without))"
                    class="dashboard-analytics-text-sinAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatTimeTitle(data.item.consumo.without)"
                  >{{ formatTime(data.item.consumo.without) }}</span>
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                  >{{ formatearNumero(data.item.consumo.without) }}</span>
                </div>
                <div
                  class="text-center mt-2"
                >
                  <span
                    v-if="!Number.isNaN(parseInt(data.item.consumo.with))"
                    class="dashboard-analytics-text-conAds myCursorHelp"
                    :class="[fontSizeClass]"
                    :title="formatTimeTitle(data.item.consumo.with)"
                  >{{ formatTime(data.item.consumo.with) }}</span>
                  <span
                    v-else
                    class="dashboard-analytics-text-noData myCursorHelp"
                  >{{ formatearNumero(data.item.consumo.with) }}</span>
                </div>
              </template>
            </b-table>
          </b-overlay>
          <!-- PAGINATION -->
          <div class="d-flex justify-content-between pr-2 pl-2">
            <div>
              <div class="pagination-info mt-2">
                <span class="dashboard-analytics-text-info-pagination">
                  {{ $t('supplierPanel.show') }} {{ startItem }} {{ $t('supplierPanel.to') }} {{ endItem }} {{ $t('supplierPanel.of') }} {{ totalItems }} {{ $t('supplierPanel.entries') }}
                </span>
              </div>
            </div>
            <b-pagination
              v-if="totalItems > perPage"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              class="mt-1"
              first-number
              last-number
              @input="paginationF"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BTable,
  BButton,
  BPagination,
  BOverlay,
  BSpinner,
  BCard,
  BCardBody,
  // BFormCheckbox,
  BFormSelect,
  // BProgress,
  // BFormInput,

} from 'bootstrap-vue'
import axios from '@axios'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import { getUserData } from '@/auth/utils'
import { getSubgroups, utf8ToB64 } from '@/store/functions'
import vSelect from 'vue-select'

// Esta imagen aparece cuando un contenido no tiene o no carga la imagen
const noCover = require('@/assets/images/backend/nocover.jpeg')
const noImgUser = require('@/assets/images/backend/user.svg')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    BSpinner,
    BCard,
    BCardBody,
    // BFormCheckbox,
    BFormSelect,
    // BProgress,
    // BFormInput,
    vSelect,
  },
  data() {
    return {
      // listIDs: ['52319', '48062', '48073'],
      userId: null,
      client: null,
      listIDs: [],
      showData: false,
      isRangeMode: false, // Estado que indica si estamos en modo rango
      // Section1
      titleTableInfoSubGroup: this.$t('supplierPanel.titleTableInfoSubGroup'),
      titleCardSection1: '',
      textCardSection1: this.$t('supplierPanel.noDescription'),
      srcImageCard: noImgUser,
      subTitleTextRightCardSection1: this.$t('supplierPanel.subTitleTextRightCardSection1'),
      titleTextRightCardSection1: '',
      // Section 2
      titleFilterSection2: this.$t('supplierPanel.titleFilterSection2'),
      selectDateRange: this.$t('supplierPanel.selectDateRange'),
      labelFilterDateRangeSection2: 'Selecciona un rango de fechas',
      rangeDate: null, // Almacena el rango seleccionado
      startDate: null, // Variable para la fecha de inicio
      endDate: null, // Variable para la fecha de fin
      selectedInterval: null, // Valor del intervalo seleccionado
      progressValue: 100, // Valor inicial de la barra de progreso
      isProgressVisible: false, // Controla si se muestra la barra de progreso
      progressInterval: null, // Intervalo para la barra de progreso
      intervalOptions: [ // Opciones para el selector
        { value: null, text: 'OFF' },
        /* { value: 5000, text: '5 seconds' }, */
        { value: 10000, text: '10 seconds' },
        { value: 15000, text: '15 seconds' },
        { value: 30000, text: '30 seconds' },
        { value: 60000, text: '1 minute' },
      ],
      optionsSelectItemsPerPage: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      // Section 3
      columns: [
        /*          { key: 'index' }, */
        /* { key: 'id' }, */
        /* { key: 'imagen', thStyle: { minWidth: '100px', width: '20%' } }, */
        { key: 'imagen' },
        { key: 'contenido', thStyle: { minWidth: '100px', width: '20%' } },
        { key: 'ads' },
        { key: 'percentil_0_25' },
        { key: 'percentil_25_50' },
        { key: 'percentil_50_75' },
        { key: 'percentil_75_100' },
        { key: 'consumo' },
      ],
      myData: [],
      // Section 4
      currentPage: 1, // Página actual
      perPage: 10,
      totalItems: 0, // Total de elementos
      nameSearch: '',
      // Objeto de clave-valor
      listDataExtaInfo: {
        Retribucion: 'Uno',
        Edad: 30,
        Profesión: 'Ingeniero',
        Ciudad: 'Madrid',
        Email: 'juan.perez@example.com',
      },
      mostrarOchoColumnas: true,
      subgroups: [],
      subgroup: null,
    }
  },
  computed: {
    datePickerConfig() {
      return {
        // mode: this.isRangeMode ? 'range' : 'single', // Cambia el modo según el estado
        mode: 'range',
        maxDate: new Date(),
        onChange: this.handleDateChange,
      }
    },
    // Calcula el número de item inicial (x1)
    startItem() {
      return (this.currentPage - 1) * this.perPage + 1
    },
    // Calcula el número de item final (x2)
    endItem() {
      return Math.min(this.currentPage * this.perPage, this.totalItems)
    },
    columnas() {
      if (this.mostrarOchoColumnas) {
        return [
          { key: 'imagen', thStyle: { minWidth: '100px', width: '20%' } },
          { key: 'contenido', thStyle: { minWidth: '100px', width: '20%' } },
          { key: 'ads' },
          { key: 'percentil_0_25' },
          { key: 'percentil_25_50' },
          { key: 'percentil_50_75' },
          { key: 'percentil_75_100' },
          { key: 'consumo' },
        ]
      }
      return [
        { key: 'imagen', thStyle: { minWidth: '75px', width: '15%' } },
        { key: 'ads' },
        { key: 'percentil_0_25' },
        { key: 'percentil_25_50' },
        { key: 'percentil_50_75' },
        { key: 'percentil_75_100' },
        { key: 'consumo' },
      ]
    },
    fontSizeClass() {
      return this.mostrarOchoColumnas ? 'font-size-big-view' : 'font-size-small-view'
    },

  },
  watch: {
    subgroup() {
      this.getData()
    },
  },
  async mounted() {
    try {
      // Llama a la función que ajusta el número de columnas al montar el componente
      this.checkScreenWidth()
      // Añade un event listener para el cambio de tamaño de pantalla
      window.addEventListener('resize', this.checkScreenWidth)
    } catch (error) {
      console.error(error)
    }
    try {
      // Pasamos una lista de IDs | Son contenidos de los cuales obtendremos su => INFORMACION (Nombre , descripcion , foto ) => DATOS ANALITICOS
      this.getData()
    } catch (error) { console.error(error) }
    const userData = getUserData()
    if (userData.profile.client.hasSubgroups && !userData.profile.subgroup) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
    }
  },
  beforeDestroy() {
    // Limpiar los temporizadores al destruir el componente
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
    }
    // Elimina el event listener cuando el componente se destruya
    window.removeEventListener('resize', this.checkScreenWidth)
  },
  methods: {
    checkScreenWidth() {
      try {
        // Cambia el valor de mostrarCincoColumnas basado en el ancho de la pantalla
        const anchoPantalla = window.innerWidth
        // console.log('anchoPantalla ', anchoPantalla)
        if (anchoPantalla >= 1400) { // Umbral para una pantalla de portátil (1024px o más)
          this.mostrarOchoColumnas = true
        } else {
          this.mostrarOchoColumnas = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    // Función para generar lista de clave-valor desde un objeto JSON
    generateListFromJson(jsonData) {
      this.listDataExtaInfo = { ...jsonData } // Asignar el objeto JSON a listaDatos
    },
    // Reemplaza la imagen fallida con otra imagen predeterminada
    handleImageError(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = noCover
    },
    handleImageUserError(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = noImgUser
    },
    startTimer() {
      // Limpiar cualquier temporizador anterior
      if (this.progressInterval) {
        clearInterval(this.progressInterval)
      }

      // Ocultar barra de progreso si se selecciona "OFF"
      if (!this.selectedInterval) {
        this.isProgressVisible = false
        return
      }

      // Reiniciar y mostrar la barra de progreso
      this.isProgressVisible = false // Ocultarla temporalmente para reinicio completo
      this.$nextTick(() => {
        // Esperar a que `isProgressVisible` reactive
        this.isProgressVisible = true
        this.startProgress()
      })
    },

    startProgress() {
      this.progressValue = 100 // Iniciar la barra de progreso al 100%
      const intervalTime = this.selectedInterval / 100 // Definir pasos de la barra

      // Comenzar el intervalo para decrementar la barra
      this.progressInterval = setInterval(async () => {
        this.progressValue -= 1 // Decremento de la barra de progreso

        if (this.progressValue <= 0) {
          // Detener el temporizador de progreso al llegar a 0
          clearInterval(this.progressInterval)

          // Ejecutar la función deseada y reiniciar después de un delay
          this.getData()

          // Reiniciar la barra de progreso después de un delay de 1 segundo
          setTimeout(() => {
            this.startTimer() // Reiniciar el ciclo de la barra de progreso
          }, 1000) // Delay de 1 segundo antes de reiniciar
        }
      }, intervalTime)
    },

    // Realiza la peticion solicitando los datos de metricas de X ids y devuelve una lista de objetos la cual esta compuesta por:
    //  content_id:string | with_ads:{ percetiles y consumo } | without_ads:{ percetiles y consumo }
    // TODO => Mejorar la impemenacion de la API
    async getDataAnalytics(listIds) {
      let listAnalytics = []
      if (listIds.length) {
        const url = new URL('https://api.eu-central-1.aws.tinybird.co/v0/pipes/content_statistics_api.json')
        // Obtiene la informacion de los IDs pasados
        url.searchParams.append('content_ids', listIds)
        const dataUser = getUserData()
        // console.log('this.dataUser => ', dataUser.profile.client.name)
        // TODO => PETE AQUI NO TRAE EL CLIENTE EN SUPER ADMIN
        try {
          if (dataUser.profile.client.name) {
            url.searchParams.append('client_id', dataUser.profile.client.name)
          }
        } catch (error) {
          console.error(error)
        }

        // TODO => Añadir append del cliente - ID de perfil - (Mejora para mayor segmentacion)
        // Añade a la pericion filtro de fecha si este campo esta relleno
        // Formato de fecha: yyyy-mm-dd
        if (this.startDate && this.endDate) {
          url.searchParams.append('since', this.startDate)
          url.searchParams.append('until', this.endDate)
        } else if (this.startDate && !this.endDate) {
          url.searchParams.append('since', this.startDate)
          url.searchParams.append('until', this.startDate)
        }
        const result = await fetch(url, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN_ANALYTICS}`,
          },
        })
          .then(r => r.json())
          .then(r => r)
          .catch(e => e.toString())

        const { data } = result
        // Se realiza ya que los datos se obtienen como String
        try {
          if (data) {
          // Crear un nuevo array con los datos modificados
            listAnalytics = data.map(element => ({
              ...element, // Copia todas las propiedades del objeto original
              with_ads: JSON.parse(element.with_ads),
              without_ads: JSON.parse(element.without_ads),
            }))
          }
        } catch (error) {
          console.error(error)
        }
      }
      return listAnalytics
    },
    // V1 - Datos de contenidos obtenidos por el paso de una lista
    // Formatear los datos analiticos del contendio
    formatObjetAnalyticsOptimized(data, dataContent) {
      // Aseguramos que la lista de los contenidos y la de estadisticas tienen el mismo orden para mostrarse
      let dataContentOrdenado
      try {
        dataContentOrdenado = this.ordenarPorLista(dataContent, data)
        // console.log(' DATOS ORDENADOS: ', dataContentOrdenado)
      } catch (error) {
        console.error('Error | ordenarPorLista => ', error)
      }
      const result = dataContentOrdenado.map((element, index) => {
        let infoContent = { imagen: '', title: '', text: '' }
        // console.log('formatObjetAnalyticsOptimized | dataContent[index]', dataContent[index].node)
        if (dataContent[index]) {
          let imageCont = noCover
          let noDescription = this.$t('supplierPanel.noDescription')
          // No tiene imagen
          if (dataContent[index].node.imageUrl) {
            imageCont = dataContent[index].node.imageUrl
          }
          // No tiene descripcion
          if (dataContent[index].node.description) {
            noDescription = dataContent[index].node.description
          }

          infoContent = {
            id: dataContent[index].node.id,
            imagen: imageCont,
            title: dataContent[index].node.name,
            text: noDescription,
          }
        }
        const percentilesWithAds = element.with_ads
        const percentilesWithoutAds = element.without_ads
        // Asigena el consumo obtenido (en segundos y lo pasa a minutos)
        let consumoWithAds
        let consumoWithoutAds
        try {
          if (element.with_ads.consumption) {
            consumoWithAds = element.with_ads.consumption
            // console.log('consumoWithAds => ', consumoWithAds)
          }
        // eslint-disable-next-line no-empty
        } catch (error) {}
        try {
          if (element.without_ads.consumption) {
            consumoWithoutAds = element.without_ads.consumption
            // console.log('consumoWithoutAds => ', consumoWithoutAds)
          }
        } catch (error) {
          // console.error('ERROR - consumoWithoutAds => ', error)
        }

        // Grupos de percentiles con solo un valor
        // Rangos de percentiles
        const percentilGroups = {
          percentil_0_25: 25,
          percentil_25_50: 50,
          percentil_50_75: 75,
          percentil_75_100: 100,
        }

        let percentils
        try {
          percentils = Object.fromEntries(
            Object.entries(percentilGroups).map(([key, percentile]) => [
              key,
              {
                with: parseInt(percentilesWithAds[`percentil_${percentile}`], 10),
                without: parseInt(percentilesWithoutAds[`percentil_${percentile}`], 10),
              },
            ]),
          )
        } catch (error) {
          // Si no hay percentiles, añade valores vacios mostraria en la tabla: ---
          if (!percentils) {
            percentils = {
              percentil_0_25: { with: '', without: '' },
              percentil_25_50: { with: '', without: '' },
              percentil_50_75: { with: '', without: '' },
              percentil_75_100: { with: '', without: '' },
            }
            consumoWithAds = ''
            consumoWithoutAds = ''
          }
        }
        // Devuelve el objeto formado, datos a representar en la tabla
        return {
          index,
          id: infoContent.id,
          percentils,
          imagen: infoContent.imagen,
          contenido: { title: infoContent.title, text: infoContent.text },
          consumo: { with: consumoWithAds, without: consumoWithoutAds },
        }
      })

      return result
    },
    // ***********************************************
    // V2 - LISTA DE IDS OBTENIDA DE LA API ALLCONTENTS
    // ***********************************************
    ordenarPorLista(lista1, lista2) {
      // Validar que ambas listas sean arreglos
      if (!Array.isArray(lista1) || !Array.isArray(lista2)) {
        console.error('Ambos parámetros deben ser arreglos.')
        return []
      }

      // Filtrar elementos nulos o indefinidos de ambas listas
      const lista1Filtrada = lista1.filter(obj => obj !== null && obj !== undefined)
      const lista2Filtrada = lista2.filter(obj => obj !== null && obj !== undefined)

      // Validar que lista1 no esté vacía
      if (lista1Filtrada.length === 0) {
        // console.warn('lista1 está vacía después del filtrado. Se devolverá lista2 vacía.')
        return []
      }

      // Crear un objeto de mapeo de los content_id en lista2 filtrada
      const mapaObjetos = Object.fromEntries(
        lista2Filtrada.map(objeto => [objeto.content_id, objeto]),
      )

      // Crear el resultado asegurando que la longitud sea igual a lista1
      const resultado = lista1Filtrada.map(obj => {
        const encontrado = mapaObjetos[obj.node.id] // Busca el objeto correspondiente en mapaObjetos
        return encontrado || { content_id: obj.node.id, ...Object.keys(lista2Filtrada[0] || {}).reduce((acc, key) => ({ ...acc, [key]: null }), {}) } // Crea un objeto nuevo con content_id
      })

      // Asegurarse de que todos los content_id de lista2 se incluyan
      const contentIdsEnResultado = new Set(resultado.map(item => item.content_id))
      const contenidoFaltante = lista2Filtrada.filter(obj => !contentIdsEnResultado.has(obj.content_id))

      // Añadir los elementos faltantes como null al resultado usando concat
      const resultadoCompleto = resultado.concat(
        contenidoFaltante.map(obj => ({
          content_id: obj.content_id,
          ...Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: null }), {}),
        })),
      )
      return resultadoCompleto
    },
    async showDataAnalytics(listIDs, dataContent) {
      // console.log(' showDataAnalytics - listIDs: ', listIDs)
      // console.log(' showDataAnalytics - dataContent: ', dataContent)
      try {
        let dataAPIanalytics
        let formatV2
        // console.log('listIDs => ', listIDs)
        try {
          dataAPIanalytics = await this.getDataAnalytics(listIDs) // Datos obtenidos de la API Analitica
          // console.log(' showDataAnalytics - DATOS API ANALITICA: ', dataAPIanalytics)
        } catch (error) {
          console.error('Error - getDataAnalytics', error)
        }
        // dataAPIanalytics =>  Datos obtenidos de la API de analitica
        // dataContent =>       Datos obtenidos de la API de contenidos
        try {
          formatV2 = this.formatObjetAnalyticsOptimized(dataAPIanalytics, dataContent) // Formatear los datos para mostrarlos en la tabla
        } catch (error) {
          console.error('Error -  formatObjetAnalyticsOptimized ', error)
        }
        this.myData.length = 0 // Vaciar la lista
        this.myData = formatV2 // Asignar datos formateados a tabla
        // console.log('myData | Asignar datos formateados a tabla => ', this.myData)
        // V1 - ITEMS TOTALES SE OBTIENE DE LA LISTA MY DATA
        // this.totalItems = this.myData.length // Numero de elementos totales para la paginacion
        // V2 - ITEMS TOTALES SE OBTEIENE DE LA CONSULTA
        // console.log('totalItems => ', this.totalItems)
      } catch (error) {
        console.error('Error - showDataAnalytics: ', error)
      }
    },
    // ***********************************************
    // FUNCIONES GENERACION DE QUERYS
    // ***********************************************

    // Genera Querys dinamicas pasandole una lista de IDs
    generarQueries(ids) {
      return ids.map(id => `
        query {
          ID${id}: allContents(id:"${id}") {
            totalCount
            edgeCount
            edges {
              node {
                id
                name
                imageUrl
                description
              }
            }
          }
        }
      `)
    },
    // Pagina en funcion del numero de elementos a mostrar por página | Filtra por nombre ( Por defecto aparece sin filtro )
    queryAllContents() {
      return `{
        allContents(first:${this.perPage},
            offset:${this.perPage * (this.currentPage - 1)},
            name:"${this.nameSearch}",
            orderBy:"name",
            client:"${getUserData().profile.client.id}",
           ${this.subgroup ? `subgroup:"${utf8ToB64(`id:${this.subgroup.id}`)}",` : ''}
            ){
          totalCount
          edgeCount
          edges {
            node {
              id
              name
              imageUrl
              description
            }
          }
        }
      }`
    },
    // Obtener la info de un perfil concreto
    queryInfoProfile() {
      return `{
        allProfiles(id:"${this.userId}") {
          edges {
            node {
              id
              client{
                name
              }
              username
              firstName
              lastName
              avatar
              description
              subgroup{
                name
                extraData
              }
            }
          }
    }
        }`
    },
    // Obener Conenidos de una lista de IDs pasada como parametro
    async getContentsByIDList(ids) {
      const queries = this.generarQueries(ids)

      // Usar Promise.allSettled para manejar todas las promesas
      const resultados = await Promise.allSettled(
        queries.map(query => axios.post('', { query })
          .then(response => {
            // Extrae la información de node en base a la estructura de la respuesta
            const { data } = response.data
            const idKey = Object.keys(data)[0] // La key dinámica es el id (ej., 'id1', 'id2', etc.)
            return data[idKey]?.edges[0]?.node || null // Extrae `node`, devuelve null si no hay edges
          })),
      )

      // Mapea los resultados a su ID correspondiente
      return resultados.map((resultado, index) => {
        if (resultado.status === 'fulfilled') {
          return resultado.value // Devuelve el valor si la promesa fue exitosa
        }
        console.error(`Error en la consulta para el ID ${ids[index]}:`, resultado.reason)
        return null // Devuelve null si la promesa fue rechazada
      })
    },
    // Obtiene todos los contenidos del usuario
    async getContents() {
      // console.log('nameSearch => ', this.nameSearch)
      const query = this.queryAllContents()
      const res = await axios.post('', { query })
      // console.log('respuesta peticion API contenidos: ', res.data.data)
      // console.log('RES => ', res.data.data.allContents.totalCount)
      this.totalItems = res.data.data.allContents.totalCount
      // console.log('currentPage => ', this.currentPage)
      return res.data.data.allContents.edges
    },
    async getDataCardProfile() {
      const query = this.queryInfoProfile()
      const res = await axios.post('', { query })
      // console.log('res => ', res)
      return res.data.data.allProfiles.edges[0].node
    },
    // Controla si no hay numero muestra: --- | Si hay numero lo formatea de esta forma: 1.000.000 (Incluyendole los puntos)
    formatearNumero(numero) {
      // Si no hay datos a mos trar muestra un texto informativo
      if (Number.isNaN(parseInt(numero, 10))) {
        return '---'
      }
      // Convertir el número a una cadena y usar expresión regular para agregar puntos como separadores de miles
      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatoContraidoNumero(numero) {
      if (Number.isNaN(parseInt(numero, 10))) {
        return '---'
      }
      // console.log('Numero', numero, ' xxxx => ', numero >= 1000000000000)
      // Lógica de formateo para billones, millones y miles
      if (numero >= 1000000000000) {
        // Si el número es mayor o igual a 1 billón (1.000.000.000.000)
        const result = numero / 1000000000000
        return result % 1 === 0 ? `${result} bill.` : `${result.toFixed(1)} bill.` // Mostrar sin decimales si es entero
      } if (numero >= 1000000) {
        // Si el número es mayor o igual a 1 millón (1.000.000)
        const result = numero / 1000000
        return result % 1 === 0 ? `${result} mill.` : `${result.toFixed(1)} mill.` // Mostrar sin decimales si es entero
      } if (numero >= 1000) {
        // Si el número es mayor o igual a mil (1.000)
        const result = numero / 1000
        return result % 1 === 0 ? `${result}k` : `${result.toFixed(1)}k` // Mostrar sin decimales si es entero
      }
      // Si el número es menor que mil
      return numero.toString() // Mostrar el número original
    },
    /* formatTimeTitle(seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const remainingSeconds = seconds % 60
      return `${hours} h ${minutes} min ${remainingSeconds} s`
    }, */
    formatTimeTitle(seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const remainingSeconds = seconds % 60

      // Devuelve las partes no vacías, concatenadas con espacio
      return [
        hours && `${hours} h`,
        minutes && `${minutes} min`,
        remainingSeconds && `${remainingSeconds} s`,
      ]
        .filter(Boolean) // Filtra los valores falsy
        .join(' ') // Une los elementos restantes con un espacio
    },
    // Obtiene las fechas marcadas en el rango de fecha
    async handleDateChange(selectedDates) {
      const wordToCheck = 'to'
      const dateValue = this.rangeDate // Suponiendo que `rangeDate` es el valor seleccionado
      try {
      // Comprobar si se seleccionó un rango de fechas
        const isRangeSelected = dateValue.toLowerCase().includes(wordToCheck.toLowerCase())

        if (isRangeSelected) {
        // Si se seleccionó un rango, dividirlo
          const parts = dateValue.split(/\bto\b/).map(part => part.trim()).filter(part => part)

          // eslint-disable-next-line prefer-destructuring
          this.startDate = parts[0] // Almacenar la fecha de inicio
          // eslint-disable-next-line prefer-destructuring
          this.endDate = parts[1] // Almacenar la fecha de fin
        } else {
        // Si no se seleccionó un rango, almacenar la única fecha seleccionada
          this.startDate = dateValue // Almacenar la fecha seleccionada
          this.endDate = null // No hay fecha de fin en este caso
        }
        if (selectedDates.length === 2) {
          console.log('Segundo clic detectado:', selectedDates[1])
          // Acción específica para el segundo clic
          if (this.startDate) {
            this.getData()
          }
        }
      } catch (error) {
        // console.error(error)
      }
    },
    /* detectClick (selectedDates) {
      if (selectedDates.length === 2) {
        console.log('Segundo clic detectado:', selectedDates[1])
        // Acción específica para el segundo clic
        if (this.startDate) {
          this.getData()
        }
      }
    }, */
    // Limpiar selector de rango de fecha y refrescar tabla de contenidos sin filtro
    async clearDateRangeAndRefresh() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.getData()
    },
    // *******************************************************
    // VERSION PASANDOLE LOS IDs COMO UNA LISTA
    // *******************************************************

    /* async getData() {
      console.log('Ejecutado')

      try {
        this.showData = true
        // Pasamos una lista de IDs
        const dataContent = await this.getContentsByIDList(this.listIDs) // Obtener datos de contenidos
        console.log('dataContent => ', dataContent)
        this.showDataAnalytics(this.listIDs, dataContent) // Obtener datos de contenidos y formatear la tabla para mostrar datos tanto de: CONTENIDOS y ANALITICOS
        this.showData = false
      } catch (error) { console.error(error) }
    }, */

    // Obtiene y formatea todos los dato pero sin mostrar el spinner de carga
    async getDataNoSpinner() {
      // console.log('Ejecutado')

      try {
        this.showData = false
        // Pasamos una lista de IDs
        const dataContent = await this.getContents() // Obtener datos de contenidos
        // console.log('dataContent => ', dataContent)
        this.listIDs.length = 0
        dataContent.forEach(element => {
          this.listIDs.push(element.node.id)
        })
        await this.showDataAnalytics(this.listIDs, dataContent) // Obtener datos de contenidos y formatear la tabla para mostrar datos tanto de: CONTENIDOS y ANALITICOS
        // this.showData = false
        // console.log('_______________')
      } catch (error) { console.error(error) }
    },
    // Obtiene y formatea todos los dato mostrar el spinner de carga
    async getData() {
      // Genera la Card informativa
      try {
        this.userId = getUserData()?.id
        // console.log('userId => ', this.userId)
        const info = await this.getDataCardProfile()
        // console.log('info => ', info)
        // Obtiene la Imagen | Nombre | Description
        this.getDataInformationProfile(info)
      } catch (error) {
        // console.error(error)
      }
      // console.log('titleTextRightCardSection1 => ', this.titleTextRightCardSection1)
      // Si no se ha asignado titulo del extadata se quita el subtitulo de este
      if (!this.titleTextRightCardSection1) {
        this.subTitleTextRightCardSection1 = ''
      }
      // -----
      try {
        this.showData = true
        // Pasamos una lista de IDs
        const dataContent = await this.getContents() // Obtener datos de contenidos
        // console.log('dataContent => ', dataContent)
        this.listIDs.length = 0
        dataContent.forEach(element => {
          this.listIDs.push(element.node.id)
        })
        // console.log('this.listIDs => ', this.listIDs)
        await this.showDataAnalytics(this.listIDs, dataContent) // Obtener datos de contenidos y formatear la tabla para mostrar datos tanto de: CONTENIDOS y ANALITICOS
        this.showData = false
        // console.log('_______________')
      } catch (error) { console.error(error) }

      /* try {
        console.log('this.currentPage => ', this.currentPage)
        console.log('this.perPage => ', this.perPage)
        this.addParamsToUrl()
      } catch (error) {
        console.error(error)
      } */
    },
    getDataInformationProfile(dataProfile) {
      // Obtener info del Usuario de la Api
      // console.log('INFO => ', dataProfile)
      // Comprueba que el subgrupo no se nulo
      // let subGroupName
      let extraDataName = ''
      let dataClient
      if (dataProfile.subgroup) {
        // console.log('Tengo subgrupo')
        // subGroupName = dataProfile.subgroup.name
        if (JSON.parse(dataProfile.subgroup.extraData).retribution_type) {
          extraDataName = JSON.parse(dataProfile.subgroup.extraData).retribution_type
        } else {
          this.subTitleTextRightCardSection1 = ''
          extraDataName = ''
        }
        // console.log('extraDataName => ', extraDataName)
      } else {
        // console.log(' NO Tengo subgrupo')
        this.subTitleTextRightCardSection1 = ''
      }
      if (dataProfile.client) {
        dataClient = dataProfile.client
      }
      const result = {
        imgSrc: dataProfile.avatar,
        name: `${dataProfile.firstName} ${dataProfile.lastName}`,
        description: dataProfile.description,
        // TODO AÑADIR compensation: dataProfile.x,
        compensation: extraDataName,
        client: dataClient,
      }
      if (result) {
        this.titleCardSection1 = result.name
        if (result.description) {
          this.textCardSection1 = result.description
        }
        if (result.imgSrc) {
          this.srcImageCard = result.imgSrc
        }
        if (result.compensation) {
          this.titleTextRightCardSection1 = result.compensation
        }
        this.client = result.client
      }
    },
    formatTime(inputSeconds) {
      const remainingSeconds = inputSeconds

      // Calcular horas si hay suficientes segundos para al menos una hora
      if (remainingSeconds >= 3600) {
        const hours = Math.floor(remainingSeconds / 3600)
        return `${hours} h`
      }

      // Calcular minutos si hay suficientes segundos para al menos un minuto
      if (remainingSeconds >= 60) {
        const minutes = Math.floor(remainingSeconds / 60)
        return `${minutes} min`
      }

      // Si quedan solo segundos, los mostramos
      return `${remainingSeconds} s`
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'instant', // Desplazamiento suave
      })
    },
    paginationF() {
      this.scrollToTop()
      this.getData()
    },
  },
}
</script>
<style lang="scss"  >
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.dashboard-analytics-custom-header {
  color: white;
  text-transform: none;
  font-size: 13px;
}
.dashboard-analytics-acortarParrafo {
  display: block;
  display: -webkit-box;
  /* height: 559px; */
  font-size: 14px;
  line-height: 1.4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-analytics-acortarTitulo {
  display: block;
  display: -webkit-box;
  /* height: 559px; */
  font-size: 14px;
  line-height: 1.4;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-analytics-acortarTexto{
  display: block;
  display: -webkit-box;
  line-height: 1.2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
// Section 1
#dashboard-analytics-cardSection1{
 // background-color: #FFC600;
  background-color: #283046;

  color: #ffffff;
  // padding: 1.5em;
  border-radius: 0.5em;
}
#dashboard-analytics-colCentralSection1{
  padding-right: 160px;
}

.dashboard-analytics-imgCardSection1{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.68);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.68);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.68);
}
#dashboard-analytics-titleCardSection1{
  font-size: 22px;
  font-weight: bolder;
  margin-bottom: 0.5em;
}
#dashboard-analytics-textCardSection1{
  font-size: 12px;
  color: #bebebe
}
#dashboard-analytics-subTitleTextRightCardSection1{
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}
#dashboard-analytics-titleTextRightCardSection1{
  font-size: 24px;
  font-weight: bolder;
  text-align: left;
  color: #7367f0;

}
.subtitleCard{
  text-align: center;
  border-bottom: 0.5px solid #64646450;
}
#textSubtitleCard{
  color: #fefefe;
  font-size: 12px;
}
#textSubtitleCard .resaltar {
  color: #7367f0; /* Cambia este color al que desees */
  font-weight: bold; /* Puedes agregar otros estilos si lo deseas */
}
// Section 2
.dashboard-analytics-titleFilterSection2{
  color: #ffffff;
  margin-bottom: 1em;
}
.dashboard-analytics-titlePerPageSection2{
  color: #bebebe;
  margin-bottom: 1em;
}
// Section 3
#dashboard-analytics-table{
  color: #ffffff;

}
#dashboard-analytics-contenidoTitleSection3{
  font-size: 16px;
  font-weight: bold;
  color: #FFC600;

}
#dashboard-analytics-contenidoTextSection3{
font-size: 12px;
color: rgb(156, 156, 156);
}
.dashboard-analytics-imagenSection3{
  width: 200px; /* Ancho deseado */
  aspect-ratio: 16 / 9; /* Mantener la relación de aspecto 16:9 */
  overflow: hidden; /* Evitar que la imagen se desborde */
  text-align: center;
}
.dashboard-analytics-imagenSection3-img{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegurarse de que la imagen cubra el contenedor */
}
.dashboard-analytics-text-sinAds{
  color: #ffdb58;
  font-size: 16px;
  font-weight: bold;
}
.dashboard-analytics-text-noData{
  color: #bebebe;
  font-size: 14px;
  font-weight: 100;
}
.dashboard-analytics-text-conAds{
  color: #FFC600;
  font-size: 16px;
  font-weight: bold;
}

.dashboard-analytics-text-info-pagination{
  color: #8f93a0;
}

.dashboard-analytics-custom-scrollbar {
  /* Define el estilo del contenedor de la tabla */
  max-height: 650px; /* Altura máxima del área de desplazamiento */
  overflow-y: auto;
  background-color: #161d31; /* Fondo de la tabla */
}

/* Estilos de scrollbar para navegadores WebKit (Chrome, Safari, Edge) */
.dashboard-analytics-custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

/* Estilo del track (fondo de la barra) */
.dashboard-analytics-custom-scrollbar::-webkit-scrollbar-track {
  background-color: #161d31; /* Fondo de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado */
  opacity: 0.6; /* Baja la opacidad del track */
}

/* Estilo de la barra (thumb) */
.dashboard-analytics-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #7367f0; /* Color de la barra */
  border-radius: 10px; /* Borde redondeado */
  opacity: 0.8; /* Baja la opacidad de la barra */
}

/* Efecto al pasar el cursor por encima de la barra */
.dashboard-analytics-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #5f50e3; /* Color de la barra al pasar el cursor */
  opacity: 1; /* Poner la opacidad al 100% cuando se pasa el cursor */
}

/* Eliminar las flechas del scroll */
.dashboard-analytics-custom-scrollbar::-webkit-scrollbar-button {
  display: none; /* Eliminar las flechas del scroll */
}

/* Estilos específicos para Firefox */
.dashboard-analytics-custom-scrollbar {
  scrollbar-width: thin; /* Ancho de la barra en Firefox */
  scrollbar-color: #7367f0 #161d31; /* Color del scrollbar (barra y fondo) en Firefox */
}

/*  */

/* Estilos para hacer la lista scrolleable */
.list-container {
  max-height: 100px; /* Altura máxima de 200px */
  overflow-y: auto; /* Scroll vertical automático si es necesario */
}

.container {
  max-width: 600px;
}

.font-weight-bold {
  font-weight: bold;
}
.font-size-small-view{
  font-size: 13px;
}
.font-size-big-view{
  font-size: 16px;
}
.table th, .table td{
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.myCursorHelp{
cursor: help;
}
</style>
