<template>
  <b-row class="match-height ">
    <b-col v-if="qrLink">
      <b-card id="vincular">
        <b-card-header class="d-flex justify-content-start mb-1">
          <span class="card-title">{{ $t('twoFactor.vincular') }} </span>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-3 with-vertical-line"
            >
              <h5 class="text-center mb-3">
                {{ $t('twoFactor.qrAuth.title') }}
              </h5>
              <div class="qr-scanner-container">
                <div class="qr-scanner">
                  <vue-qr
                    :text="qrLink"
                    :size="200"
                    :margin="10"
                  />
                </div>
                <small class="text-muted d-block mt-2 text-center">
                  {{ $t('twoFactor.qrAuth.helper') }}
                </small>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col>
      <b-card id="desvincular">
        <b-card-header class="d-flex justify-content-start mb-1">
          <span class="card-title">{{ $t('twoFactor.desvincular') }}</span>
        </b-card-header>
        <b-card-body>
          <div>
            <!--   <b-alert
              show
              variant="warning"
              class="p-3 alert-message"
            >
              <div
                class="mb-1"
                style="text-align: center; text-align: -webkit-center;"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="50"
                />
              </div>
              <strong class="message">{{ $t('twoFactor.messageSure') }}</strong>
            </b-alert> -->
            <b-button
              variant="outline-warning"
              style="background-color: #534647 !important; width:100%"
              disabled
            >
              <div
                class="mb-1"
                style="text-align: center; text-align: -webkit-center;"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="50"
                />
              </div>
              <strong class="message">{{ $t('twoFactor.messageSure') }}</strong>
            </b-button>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="mb-1 mb-sm-0  mt-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              variant="danger"
              @click="unlink()"
            >
              {{ $t('twoFactor.reiniciar') }}
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  VBTooltip,
  BCardHeader,
  BCardBody,
  BButton,
  // BCardFooter,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive' // Notification
// eslint-disable-next-line import/no-extraneous-dependencies
import VueQr from 'vue-qr'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BCard,
    VueQr,
    BCardHeader,
    BCardBody,
    // BCardFooter,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      qrLink: '',
      isSuperuser: getUserData().isSuperuser,
      userId: this.$route.params?.id,
    }
  },
  mounted() {
    if (!this.isSuperuser) this.getQRUrl()
  },

  methods: {
    getQRUrl() {
      const query = `mutation{
            initUser2fa {
              url
            }
          }`
      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.qrLink = result.data.data.initUser2fa.url
        }).catch(() => {
          showToast(this.$t('twoFactor.qrAuth.error'), 2, this)
        })
    },
    unlink() {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('twoFactor.qrAuth.sure?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yDesv'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const query = `mutation {
        cleanTfaSecret${this.isSuperuser ? `(userId:"${this.userId}")` : ''}{
          success
          message
        }
      }
      `
          axios
            .post('', { query }).then(res => {
              messageError(res, this)

              const { success, message } = res.data.data.cleanTfaSecret
              showToast(this.$t(message), success ? 1 : 2, this)
              this.$router.push({ name: 'auth-login' })
            }).catch(() => {

            })
        }
      }).catch(() => {

      })
    },
  },
}
</script>
<style lang="scss">
#vincular .qr-scanner-container {
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

#vincular .qr-scanner {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

#desvincular .message {
  text-align: justify;
  color: white;
  line-height: 1.5;
  font-size: 1rem;
}

#desvincular .alert-message {
  color: white;
  border-radius: 8px;
  text-align: justify;
}
</style>
