<template>
  <section id="interactivitySelector">
    <b-row
      class="m-2"
    >
      <b-col style="text-align: start;">
        <h1>{{ $t('interactivities.selectInt') }}</h1>
        <h5 class="text-primary">
          {{ $t('interactivities.automation') }}
        </h5>
      </b-col>
      <b-col style="text-align: end;">
        <b-button

          variant="success"
          :disabled="selectedInteractivity ? false : true"
          @click="seleccionar"
        >
          {{ $t('select') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="m-2">
      <b-col style="text-align: start;">
        <b-form-input
          v-model="searchInput"
          :placeholder="emptySearch"
          autofocus
          autocomplete="off"
          @change="searching()"
        />
      </b-col>
      <b-col style="justify-items: end;">
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="allInteractivity"
        />
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="loading"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row>
        <b-col>
          <b-table
            selectable
            select-mode="range"
            fixed
            responsive
            hover
            :sticky-header="true"
            :items="response"
            :fields="tableColumns"
            class="custom-table-height"
            @row-selected="onRowSelected"
          >
            <template #cell(name)="data">
              <b-row class="justify-content-center">
                <p>{{ data.item.node.name }}</p>
              </b-row>
            </template>
            <template #cell(description)="data">
              <b-row class="justify-content-center">
                <p>{{ data.item.node.description }}</p>
              </b-row>
            </template>
            <template #cell(type)="data">
              <b-row class="justify-content-center">
                <p class="mr-2">
                  {{ resolveType(data).type }}
                </p>
                <feather-icon
                  color="white"
                  :icon="resolveType(data).icon"
                  size="20"
                />
              </b-row>
            </template>
            <template #cell(image)="data">
              <b-row class="justify-content-center">
                <b-img
                  :src="buildImageUrlBucket(data)"
                  class="tableImage"
                />
              </b-row>
            </template>
            <template #cell(contentName)="data">
              <b-row class="justify-content-center">
                <strong>
                  <b-link
                    style="color:white; text-decoration: underline;"
                    class="cursor-pointer"
                    :href="'/contents/contents/edit/' + data.item.node.content.id"
                    target="_blank"
                  >
                    {{ data.item.node.content.name }}
                  </b-link>
                </strong>
              </b-row>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BTable,
  BPagination,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BImg,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'
import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BTable,
    BImg,
    BLink,
    BOverlay,
    BPagination,
    BFormInput,

  },
  props: {
    code: {
      type: String,
      default: '',
    },
    seleccionado: { type: Object, default: null },
  },
  data() {
    return {
      selected: null,
      selectedInteractivity: null,
      headers: {},
      userData: getUserData(),
      response: null,
      loading: false,
      currentPage: 1,
      perPage: 15,
      rows: null,
      searchInput: '',
      emptySearch: this.$t('interactivities.searchThreeCaracInt'),
      interactivityType: {
        VID: { type: this.$t('videoInt'), icon: 'VideoIcon' },
        ENDVID: { type: this.$t('interactivities.fVideo'), icon: 'VideoOffIcon' },
        TEST: { type: this.$t('interactivities.quest'), icon: 'FileTextIcon' },
        INFO: { type: this.$t('interactivities.info'), icon: 'InfoIcon' },
        DIRECTBUY: { type: this.$t('interactivities.shop'), icon: 'ShoppingBagIcon' },
        CART: { type: this.$t('interactivities.add'), icon: 'ShoppingCartIcon' },
        AD: { type: this.$t('interactivities.ad'), icon: 'FilmIcon' },
        DEEPL: { type: 'DEEPLINK', icon: 'ExternalLinkIcon' },
        PUSH: { type: 'PUSH', icon: 'SendIcon' },
        EMAIL: { type: 'E-MAIL', icon: 'MailIcon' },
        BANNER: { type: 'BANNER', icon: 'MonitorIcon' },
        NOTYPE: { type: '', icon: '' },
      },
      tableColumns: [
        { key: 'name', label: this.$t('dataGeneric.name') },
        { key: 'description', label: this.$t('dataGeneric.description') },
        { key: 'type', label: this.$t('interactivities.type') },
        { key: 'image', label: this.$t('Image') },
        { key: 'contentName', label: this.$t('content') },
      ],
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      // eslint-disable-next-line
        console.log(error)
    }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.allInteractivity()
  },
  methods: {
    searching() {
      if (this.searchInput.length >= 3) {
        this.currentPage = 1
        this.allInteractivity()
      }
      if (this.searchInput.length < 3) {
        this.data = []
      }
      if (this.searchInput.length === 0) {
        this.allInteractivity()
      }
    },
    seleccionar() {
      this.$emit('confirm-selection', this.selectedInteractivity)
    },
    onRowSelected(data) {
      this.selectedInteractivity = data[0].node
    },
    allInteractivity() {
      this.loading = true
      const { headers } = this
      if (this.seleccionado != null) {
        this.selected = this.seleccionado
      }
      axios
        .post('', {
          query: `
            query{
            allInteractivities(
              name:"${this.searchInput}",
              client:"${getUserData().profile.client.id}",
              first:${this.perPage},
              offset:${this.perPage * (this.currentPage - 1)},  
            ){
              totalCount
              edges {
                node {
                  id
                  name
                  description
                  image
                  connect
                  isActive
                  isVisible
                  hasLottie
                  lottieDesign
                  second
                  date
                  hour
                  timeType
                  secondFile
                  time
                  typePush
                  image
                  fileMail
                  resourceStart{
                    id
                    type
                    videotype
                  }
                  resourceEnd{
                    id
                    type
                    videotype
                  }
                  content{
                    id
                    name
                  }
                  messagePush
                  subjectMail
                  bodyMail
                  subgroup{
                    id
                    name
                  }
                }
              }
            }
          }
          `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.response = res.data.data.allInteractivities.edges
          this.rows = res.data.data.allInteractivities.totalCount
          if (this.response.length === 0) { this.emptySearch = this.$t('interactivities.searchThreeCaracInt') }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.error(error)
        })
    },
    buildImageUrlBucket(data) {
      return data.item.node.image ? data.item.node.image : noCover
    },
    resolveType(data) {
      const connectTypeMap = {
        EMA: this.interactivityType.EMAIL,
        GOF: this.interactivityType.ENDVID,
        ADV: this.interactivityType.AD,
        PUS: this.interactivityType.PUSH,
      }

      const resourceEndTypeMap = {
        TST: this.interactivityType.TEST,
        PLN: this.interactivityType.INFO,
        BUY: this.interactivityType.DIRECTBUY,
        CAR: this.interactivityType.CART,
        A2B: this.interactivityType.DIRECTBUY,
        A2C: this.interactivityType.CART,
        DEE: this.interactivityType.DEEPL,
      }

      const { connect, resourceEnd } = data.item.node
      if (connectTypeMap[connect]) return connectTypeMap[connect]

      if (connect === 'GOT' && resourceEnd) {
        if (resourceEndTypeMap[resourceEnd.type]) {
          return resourceEndTypeMap[resourceEnd.type]
        }

        if (resourceEnd.type === 'VID') {
          return resourceEnd.videoType === 'IMG' ? this.interactivityType.BANNER : this.interactivityType.VID
        }
      }

      return this.interactivityType.NOTYPE
    },
  },
}
</script>

  <style scoped>

  .td {
    width: 50px;
    height: 100px;
  }

  .folder {
    min-height: 150px;
    height: 300px;
    cursor: pointer;
  }

  .dropContainer {
    border: 3px dashed;
  }

  .card-width {
    width: 200px;
  }

  .list-group-item {
    transition: all 1s;
  }

  option:checked {
    background-color: #ffffff !important;
  }

  .tableImage {
    margin-right: 2rem;
    border-radius: 10px;
    width: 8rem;
  }
  .custom-table-height {
    max-height: 600px;
    overflow-y: auto;
  }

  .custom-table-height table {
    height: 100%;
  }

  .scroll-list {
    overflow: hidden;
    overflow-y: scroll;
    height: 333px;
    margin-right: 20px;
  }

  .noBorder {
    border-bottom: 0px;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  </style>
