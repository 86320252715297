<template>
  <section id="edit-vendor-shop">
    <b-tabs
      v-if="mostrarDatos"
      pills
    >
      <!-- DATA VENDOR -->
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t("contents.categoryData") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showData"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t("contents.categoryData") }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateVendorData">
                        <!-- form input -->
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('nameShop') + ' *'"
                            >
                              <b-form-input
                                v-model="vendorName"
                                :placeholder="$t('nameShop')"
                                maxlength="150"
                                class="mr-1"
                                minlength="3"
                                required
                              />
                            </b-form-group>
                          </b-col>
                          <b-col v-if="language == base">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('firstName') + ' *'"
                            >
                              <b-form-input
                                v-model="name"
                                :placeholder="$t('firstName')"
                                maxlength="150"
                                class="mr-1"
                                minlength="3"
                                required
                              />
                            </b-form-group>
                          </b-col>
                          <b-col v-if="language == base">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('administrators.lname')"
                            >
                              <b-form-input
                                v-model="lastName"
                                :placeholder="$t('administrators.lname')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row
                          v-if="language == base"
                          class="align-items-center"
                        >
                          <b-col class="mr-1">
                            <b-form-group :label="$t('finalUsers.Phone')">
                              <b-form-input
                                id="phone"
                                v-model="phone"
                                maxlength="9"
                                value="phone"
                                type="text"
                                :placeholder="$t('finalUsers.Phone')"
                                @input="validationPhone(phone)"
                              />
                              <small class="text-danger">{{ msgPhone }}</small>
                            </b-form-group>
                          </b-col>
                          <b-col class="mr-1">
                            <b-form-group :label="'E-mail'">
                              <b-form-input
                                id="email"
                                v-model="email"
                                maxlength="100"
                                value="email"
                                type="email"
                                placeholder="E-mail"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col class="mr-1">
                            <b-form-group :label="'NIF/ CIF/ NIE'">
                              <b-form-input
                                id="nif"
                                v-model="nif"
                                type="text"
                                maxlength="9"
                                value="nif"
                                placeholder="nif/ cif/ nie"
                                @input="validationDni(nif)"
                              />
                              <small class="text-danger">{{ msgDni }}</small>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row
                          v-if="language == base"
                          class="align-items-center"
                        >
                          <b-col
                            md="5"
                            class="mr-1"
                          >
                            <b-form-group :label="$t('externalRef')">
                              <b-form-input
                                id="reference"
                                v-model="reference"
                                maxlength="150"
                                value="reference"
                                :placeholder="$t('externalRefVend')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            md="5"
                            class="mr-1"
                          >
                            <b-form-group :label="'Packlink api key '">
                              <b-form-input
                                id="packlinkApiKey"
                                v-model="packlinkApiKey"
                                maxlength="150"
                                value="packlinkApiKey"
                                placeholder="Packlink api key  "
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isActive"
                              switch
                            >
                              {{ $t('dataGeneric.activeQ') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-form-row>
                        <b-button
                          type="submit"
                          variant="success"
                          :disabled="!nifValidation & !boolPhone ? false : ''"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <!-- ADDRESS VENDOR -->
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="MapPinIcon" />
          <span>{{ $t('locationVend') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showData"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t('locationVend') }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateAddressData">
                        <!-- form input -->
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('finalUsers.Address')"
                            >
                              <b-form-input
                                v-model="address"
                                :placeholder="$t('finalUsers.Address')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('localidad')"
                            >
                              <b-form-input
                                v-model="city"
                                :placeholder="$t('localidad')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('finalUsers.Province')"
                            >
                              <b-form-input
                                v-model="province"
                                :placeholder="$t('finalUsers.Province')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row class="align-items-center">
                          <b-col class="mr-1">
                            <b-form-group :label="$t('finalUsers.Code')">
                              <b-form-input
                                id="postalCode"
                                v-model="postalCode"
                                maxlength="5"
                                value="postalCode"
                                type="text"
                                :placeholder="$t('finalUsers.Code')"
                                @input="validationPostal(postalCode)"
                              />
                              <small class="text-danger">{{ msgPostal }}</small>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="align-items-center">
                          <b-col class="mr-1">
                            <b-form-group :label="$t('finalUsers.Country')">
                              <b-form-select
                                v-model="country"
                                class="mb-3"
                              >
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  {{ $t('selectCountry') }}
                                </b-form-select-option>
                                <b-form-select-option
                                  v-for="coun in allCountry"
                                  :key="coun.node.id"
                                  :value="coun.node.id"
                                >
                                  {{ coun.node.country }}
                                </b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-button
                          type="submit"
                          variant="success"
                          :disabled="boolPostal"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <!-- ARCHIVOS DEL VENDOR -->
      <b-tab>
        <template #title>
          <feather-icon icon="FilmIcon" />
          <span>{{ $t("contents.categoryFiles") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showFiles"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>
                    {{
                      $t("contents.categoryFiles")
                    }}
                  </b-card-title>
                  <b-button
                    variant="success"
                    @click="updateVendorFiles"
                  >
                    {{
                      $t("dataGeneric.save")
                    }}
                  </b-button>
                </b-card-header>
                <b-row>
                  <b-col
                    lg="11"
                    class="ml-2 mb-2"
                  >
                    <b-form>
                      <!-- form input -->
                      <b-form-group
                        class="mb-2 mr-1"
                        :label="$t('resources.originMedia')"
                      >
                        <b-form-select
                          v-model="mediaOriginSelected"
                          class="mr-1"
                          :options="mediaOriginOptions"
                          @change="onChange()"
                        />
                      </b-form-group>
                      <b-form-group class="m-1">
                        <label><strong>TV/Web</strong></label>
                        <b-row
                          style="justify-content: space-around;;"
                          align-v="stretch"
                        >
                          <image-element-vue
                            :image-origin="mediaOriginSelected == 'BUK' ? imageVendorSelected : imageVendorUrl"
                            :media-origin-selected="mediaOriginSelected"
                            :image-type="'imageVendor' + language"
                            :is-required="true"
                            :can-delete="true"
                            :label="$t('contents.imgInternal')"
                            @saveImage="loadImage"
                            @deleteImage="deleteImage"
                          />
                          <image-element-vue
                            :image-origin="mediaOriginSelected == 'BUK' ? bannerSelected : bannerUrl"
                            :media-origin-selected="mediaOriginSelected"
                            :image-type="'banner' + language"
                            :can-delete="true"
                            :label="$t('banner')"
                            :label-info="$t('vendorWebBannerSize')"
                            @saveImage="loadImage"
                            @deleteImage="deleteImage"
                          />
                          <b-col cols="3" />
                        </b-row>
                      </b-form-group>
                      <b-form-group class="m-1">
                        <label><strong>{{ $t('Movil') }}</strong></label>
                        <b-row
                          style="justify-content: space-around;;"
                          align-v="stretch"
                        >
                          <image-element-vue
                            :image-origin="mediaOriginSelected == 'BUK' ? bannerMobileSelected : bannerMobileUrl"
                            :media-origin-selected="mediaOriginSelected"
                            :image-type="'bannerMobile' + language"
                            :can-delete="true"
                            :label="$t('bannerMobile')"
                            :label-info="$t('vendorMobileBannerSize')"
                            @saveImage="loadImage"
                            @deleteImage="deleteImage"
                          />
                          <b-col cols="3" />

                          <b-col cols="3" />
                        </b-row>
                      </b-form-group>
                    </b-form>
                  </b-col>
                </b-row>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <!-- PRICE DATA VENDOR -->
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>{{ $t('Datos económicos') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showBank"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t('Datos bancarios') }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateBankData">
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('Nombre del banco') + ' *'"
                            >
                              <b-form-input
                                v-model="bankName"
                                required
                                :placeholder="$t('Nombre del banco')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('IBAN')"
                            >
                              <b-form-input
                                v-model="iban"
                                required
                                :placeholder="$t('IBAN')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('userExternal')"
                            >
                              <b-form-input
                                v-model="stripeUser"
                                :placeholder="$t('userExternal')"
                                maxlength="150"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-button
                          type="submit"
                          variant="success"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
            <b-overlay
              variant="transparent"
              :show="showVat"
            >
              <b-card class="mt-1">
                <b-card-header>
                  <b-card-title>{{ $t('impuestos') }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateVatData">
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('valor') + ' *'"
                            >
                              <b-form-input
                                v-model="commissionValue"
                                required
                                :placeholder="$t('valor')"
                                type="number"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col class="d-none">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('Tipo de comisión')"
                            >
                              <b-form-select
                                v-model="commissionType"
                                :options="[
                                  { value: 'NON', text: $t('Ninguno') },
                                  { value: 'FLA', text: $t('fija') },
                                  { value: 'PER', text: $t('Porcentaje') },
                                ]"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="align-items-center">
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('Impuestos') + ' *'"
                            >
                              <b-form-input
                                v-model="vatNumber"
                                required
                                :placeholder="$t('Impuestos')"
                                type="number"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isFeatured"
                              switch
                            >
                              {{ $t('Es presentado') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-form-row>

                        <b-button
                          type="submit"
                          variant="success"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>

import {
  showToast, messageError, phoneNumber, identityDocument, postal, camelToSnake,
  camelToKebab,
} from '@/store/functions'
import {
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormSelectOption,
  BFormGroup,
  BFormRow,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BTabs, BTab,
  BCardHeader,
  BCardBody,
  VBTooltip,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    BCol,
    BCard,
    BButton,
    BFormSelect,
    BForm,
    BFormCheckbox,
    BFormInput,
    ImageElementVue,
    BFormGroup,
    BFormSelectOption,
    BFormRow,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      viewName: this.$t('editVend'),
      viewDetail: this.$t('confVend'),
      vendorName: null,
      lastName: null,
      phone: null,
      email: null,
      nif: null,
      bankName: null,
      iban: null,
      bannerMobile: null,
      banner: null,
      stripeUser: null,
      address: null,
      province: null,
      city: null,
      packlinkApiKey: '',
      postalCode: null,
      country: null,
      allCountry: [],
      isActive: false,
      commissionValue: 0,
      imageVendor: null,
      commissionType: 'NON',
      isFeatured: false,
      vatNumber: 21,
      userData: getUserData(),
      id: this.$route.params.id,
      idSelectCard: null,
      imageVendorUrl: null,
      bannerUrl: null,
      bannerMobileUrl: null,
      imageVendorSelected: null,
      bannerSelected: null,
      bannerMobileSelected: null,
      name: '',
      imageClicked: null,
      reference: '',
      mediaOriginSelected: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      mostrarDatos: false,
      showData: false,
      showFiles: false,
      showDesing: false,
      showDesingTit: false,
      showBank: false,
      showVat: false,
      msgDni: '',
      msgPhone: '',
      msgPostal: '',
      nifValidation: false,
      boolPhone: false,
      boolPostal: false,

    }
  },
  mounted() {
    this.countryList()
    this.fetch()
  },
  methods: {
    validationDni(dni) {
      identityDocument(dni).then(result => {
        this.nifValidation = result
        this.msgDni = ''
      }).catch(err => {
        this.nifValidation = true
        this.msgDni = err
      })
    },
    validationPhone(phone) {
      phoneNumber(phone).then(result => {
        this.boolPhone = result
        this.msgPhone = ''
      }).catch(err => {
        this.boolPhone = true
        this.msgPhone = err
      })
    },
    validationPostal(post) {
      postal(post).then(result => {
        this.boolPostal = result
        this.msgPostal = ''
      }).catch(err => {
        this.boolPostal = true
        this.msgPostal = err
      })
    },
    countryList() {
      const query = `
            {
                allCountry {
                edges {
                    node {
                    id
                    country
                    }
                }
            }
        }  
        `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)

          this.allCountry = result.data.data.allCountry.edges
        })
        .catch(err => { console.log(err) })
    },
    showModalCard(type) {
      this.$refs.modalCard.show()
      switch (type) {
        case 'imageCatTV':
          this.idSelectCard = this.cardSelected

          break
        case 'imageContTV':
          this.idSelectCard = this.cardMobileSelected

          break

        default:
          break
      }
      this.typeCard = type
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case `imageVendor${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageVendor = data
          else this.imageVendorUrl = data
          break
        case `banner${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.banner = data
          else this.bannerUrl = data
          break
        case `bannerMobile${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.bannerMobile = data
          else this.bannerMobileUrl = data
          break
        default:
          break
      }
    },
    fetch() {
      const query = `
            query{        
                allVendors(id:"${this.id}") {                
                    edges {
                        node {
                            id
                            name
                            lastName
                            vendorName(lang:"${this.language}")
                            phone
                            email
                            nif
                            mediaLocation
                            bannerUrl(lang:"${this.language}")
                            bannerMobileUrl(lang:"${this.language}")
                            imageVendorUrl(lang:"${this.language}")
                            bankName
                            iban
                            isActive
                            reference
                            packlinkApiKey
                            stripeUser
                            commissionValue
                            commissionType
                            isFeatured 
                            vatNumber
                            address
                            province
                            city
                            postalCode
                            
                            country {
                            id
                            country
                            
                            }
                        
                    }
                }
            }
        } `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const datos = res.data.data.allVendors.edges[0].node
        this.name = datos.name
        this.vendorName = datos.vendorName
        this.lastName = datos.lastName
        this.phone = datos.phone
        this.email = datos.email
        this.nif = datos.nif
        this.bankName = datos.bankName
        this.iban = datos.iban
        this.stripeUser = datos.stripeUser
        this.isActive = datos.isActive
        this.commissionValue = datos.commissionValue
        this.commissionType = datos.commissionType
        this.isFeatured = datos.isFeatured
        this.reference = datos.reference
        this.packlinkApiKey = datos.packlinkApiKey

        this.vatNumber = datos.vatNumber

        this.address = datos.address
        this.province = datos.province
        this.city = datos.city
        this.postalCode = datos.postalCode
        this.country = datos.country != null ? datos.country.id : null
        this.mediaOriginSelected = datos.mediaLocation

        if (this.mediaOriginSelected === 'DIR') {
          this.imageVendorUrl = datos.imageVendorUrl
          this.bannerUrl = datos.bannerUrl
          this.bannerMobileUrl = datos.bannerMobileUrl
        } else {
          this.imageVendorSelected = datos.imageVendorUrl
          this.bannerSelected = datos.bannerUrl
          this.bannerMobileSelected = datos.bannerMobileUrl
        }
        this.$emit('return', {
          name: this.vendorName, viewName: this.viewName, viewDetail: this.viewDetail,
        })
        this.mostrarDatos = true
      }).catch(err => {
        console.log(err)
      })
    },
    updateVendorData(event) {
      event.preventDefault()

      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.showData = true

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      let objectVariables = []
      objectVariables = [
        { title: `vendorName${this.language}`, value: this.vendorName, type: 'String' },
        { title: 'name', value: this.name, type: 'String' },
        { title: 'lastName', value: this.lastName, type: 'String' },
        { title: 'reference', value: this.reference, type: 'String!' },
        { title: 'nif', value: this.nif, type: 'String!' },
        { title: 'email', value: this.email, type: 'String!' },
        { title: 'isActive', value: this.isActive, type: 'Boolean!' },
        { title: 'phone', value: this.phone, type: 'String' },
        { title: 'packlinkApiKey', value: this.packlinkApiKey, type: 'String' },
      ]

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateVendors(id: "${this.id}", input:{\n
      `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        vendor{
              id
              name
              isActive
            }
        }
      }`
      query = mutation + query

      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, { headers }, config)

        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('success'),
            1, this,
          )
          this.showData = false
          // this.getCategoryInfo();
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    updateAddressData(event) {
      this.showData = true
      event.preventDefault()

      axios
        .post('', {

          query: `
            mutation{
                updateVendors(id: "${this.id}",input:{
                    address: "${this.address}",
                    city: "${this.city}",
                    province: "${this.province}",
                    postalCode: "${this.postalCode}",
                    country: "${this.country}",
                }
                
                ){
                    vendor{
                        id
                        name
                       
                        isActive                
                }
                }
            }
            `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('success'),
            1, this,
          )
          this.showData = false
          // this.getCategoryInfo();
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    updateBankData(event) {
      this.showBank = true
      event.preventDefault()

      axios
        .post('', {

          query: `
              mutation{
                  updateVendors(id: "${this.id}",input:{
                      bankName: "${this.bankName}",
                      iban: "${this.iban}",
                      stripeUser: "${this.stripeUser}",                          
                      
                  }
                  
                  ){
                      vendor{
                          id
                          name
                         
                          isActive                
                  }
                  }
              }
              `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('success'),
            1, this,
          )
          this.showBank = false
          // this.getCategoryInfo();
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showBank = false
        })
    },
    updateVatData(event) {
      this.showVat = true
      event.preventDefault()

      axios
        .post('', {

          query: `
              mutation{
                  updateVendors(id: "${this.id}",input:{
                      commissionType: ${this.commissionType},
                      commissionValue: ${this.commissionValue},                          
                      vatNumber: ${this.vatNumber},                          
                      isFeatured: ${this.isFeatured}                    
                      
                  }
                  
                  ){
                      vendor{
                          id
                          name
                         
                          isActive                
                  }
                  }
              }
              `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('success'),
            1, this,
          )
          this.showVat = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showVat = false
        })
    },

    onChange() {
      if (this.mediaOriginSelected === 'BUK') {
        this.imageClicked = ''
        this.vendorImage = null
        this.mobileImageCategory = null
        this.backgroundCategory = null
        this.bannerUrl = null
        this.bannerMobileUrl = null

        this.vendorImageSelected = false
        this.mobileImageCategorySelected = false
        this.backgroundCategorySelected = false
      } else {
        this.imageClicked = ''
        this.vendorImage = null
        this.mobileImageCategory = null
        this.backgroundCategory = null
        this.banner = null
        this.bannerMobile = null

        this.vendorImageSelected = false
        this.mobileImageCategorySelected = false
        this.backgroundCategorySelected = false
      }
    },
    processImageData(data) {
      switch (this.imageClicked) {
        case 'fileInput':
          this.vendorImage = data
          this.imageClicked = ''
          break
        case 'fileInputMob':
          this.mobileImageCategory = data
          this.imageClicked = ''
          break

        case 'fileInputBck':
          this.backgroundCategory = data
          this.imageClicked = ''
          break

        default:
          break
      }
    },
    updateVendorFiles(event) {
      this.showFiles = true
      const data = new FormData()

      event.preventDefault()
      if (this.mediaOriginSelected === 'DIR') {
        let objectVariables = []
        objectVariables = [
          { title: `imageVendorUrl${this.language}`, value: this.imageVendorUrl, type: 'String' },
          { title: `bannerUrl${this.language}`, value: this.bannerUrl, type: 'String' },
          { title: `bannerMobileUrl${this.language}`, value: this.bannerMobileUrl, type: 'String' },
        ]

        const headers = {
          'Accept-Language': camelToKebab(this.language),
          'Content-Language': camelToKebab(this.language),
        }

        const config = {
          timeout: 1000 * 60 * 30,
        }

        const variables = {}
        let mutation = 'mutation('
        let query = `{updateVendors(id:"${this.id}",input:{\n
          mediaLocation: DIR,\n`
        objectVariables.forEach(element => {
          variables[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          query += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        query += `}){
          vendor{
            id
            name            
          }
        }
      }`
        query = mutation + query
        data.append('query', query)
        data.append('variables', JSON.stringify(variables))

        axios
          .post('', data, { headers }, config)
          .then(res => {
            messageError(res, this)

            showToast(
              this.$t('success'),
              1, this,
            )
            this.showFiles = false
          })
          .catch(() => {
            this.showFiles = false

            showToast(this.$t('error'), 2, this)
          })
        this.showFiles = false
      } else {
        this.processBucketFiles()
      }
    },
    processBucketFiles() {
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
            mutation{
                updateVendors(id:"${this.id}",input:{
                    mediaLocation: BUK
                }
                ){
                    vendor{
                        id
                        name                
                    }
                    }
                }
            `
      data.append('query', query)

      if (this.imageVendor) data.append(`vendor_image_${camelToSnake(this.language)}`, this.imageVendor)
      if (this.banner) data.append(`banner_${camelToSnake(this.language)}`, this.banner)
      if (this.bannerMobile) data.append(`banner_mobile_${camelToSnake(this.language)}`, this.bannerMobile)

      axios.post('', data, config).then(createResponse => {
        messageError(createResponse, this)
        this.showFiles = false
        showToast(this.$t('code.updateData'), 1, this)
      }).catch(err => {
        console.log(err)
        this.showFiles = false
        showToast(this.$t('code.updateDataError'), 2, this)
      })
    },
    deleteImage(type) {
      const headers = {
        'Accept-Language': camelToKebab(this.language),
        'Content-Language': camelToKebab(this.language),
      }
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const { id } = this.$route.params

            const data = new FormData()
            if (this.mediaOriginSelected === 'BUK') {
              const query = `
                mutation{
                  updateVendors(id:"${id}",input:{
                    mediaLocation: BUK
                  }){
                        vendor{
                          id
                          name
                        }
                      }
                    }
              `
              data.append('query', query)

              switch (type) {
                case `imageVendor${this.language}`:
                  this.imageVendorSelected = null
                  data.append('deleteFile', `vendor_image_${camelToSnake(this.language)}`)

                  break
                case `banner${this.language}`:
                  this.bannerSelected = null
                  data.append('deleteFile', `banner_${camelToSnake(this.language)}`)

                  break
                case `bannerMobile${this.language}`:
                  this.bannerMobileSelected = null
                  data.append('deleteFile', `banner_mobile_${camelToSnake(this.language)}`)

                  break

                default:
                  break
              }
              axios.post('', data, { headers }, config).then(createResponse => {
                messageError(createResponse, this)
                this.showFiles = false

                showToast(this.$t('success'), 1, this)
              }).catch(() => {
                this.showFiles = false

                showToast(this.$t('code.updateDataError'), 2, this)
              })
            } else {
              let objectVariables = []

              switch (type) {
                case `imageVendor${this.language}`:
                  this.imageVendorUrl = ''
                  objectVariables = [
                    { title: `imageVendorUrl${this.language}`, value: this.imageVendorUrl, type: 'String' },
                  ]

                  break
                case `banner${this.language}`:
                  this.bannerUrl = ''
                  objectVariables = [
                    { title: `bannerUrl${this.language}`, value: this.bannerUrl, type: 'String' },
                  ]

                  break
                case `bannerMobile${this.language}`:
                  this.bannerMobileUrl = ''
                  objectVariables = [
                    { title: `bannerMobileUrl${this.language}`, value: this.bannerMobileUrl, type: 'String' },
                  ]
                  break

                default:
                  break
              }

              const variables = {}
              let mutation = 'mutation('
              let query = `{updateVendors(id:"${id}",input:{\n
              mediaLocation: DIR,\n`
              objectVariables.forEach(element => {
                variables[element.title] = element.value
                mutation += `$${element.title}:${element.type},\n`
                query += `${element.title}:$${element.title},\n`
              })
              mutation += ')'
              query += `}){
                  vendor{
                    id
                    name            
                  }
                }
              }`
              query = mutation + query
              data.append('query', query)
              data.append('variables', JSON.stringify(variables))

              axios
                .post('', data, { headers }, config)
                .then(res => {
                  messageError(res, this)

                  this.showFiles = false

                  showToast(this.$t('code.updateData'), 1, this)
                })
                .catch(error => {
                  console.log(error)
                  this.showFiles = false
                  showToast(this.$t('code.updateDataError'), 2, this)
                })
            }
            /* this.deleteFile.push(type)
            switch (type) {
              case `imageVendor${this.language}`:
                this.imageVendorUrl = null
                this.imageVendorSelected = false

                break
              case `banner${this.language}`:
                this.bannerSelected = null
                this.bannerUrl = false

                break
              case `bannerMobile${this.language}`:
                this.bannerMobileUrl = null
                this.bannerMobileSelected = false
                break
              default:
                break
            } */
          }
        })
        .catch(() => { })
    },
    modalTags() {
      if (this.selected.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
      {
        allTags(name:"${this.selected[this.selected.length - 1]}") {
          edges {
            node {
              id
              name
              createdAt

            }
          }
        }
      }
    `,
          })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.allTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selected[this.selected.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })

            if (crear) {
              /* AQUÍ CREA EL TAG */

              axios
                .post('', {
                  query: `
          mutation{
            createTag(input:{name:"${this.selected[this.selected.length - 1]
}"}){
                tag{
                    id
                    name
                }
            }
        }
        `,
                })
                .then(res => {
                  messageError(res, this)

                  this.idTags.push(res.data.data.createTag.tag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
              // console.log(this.idTags);
            }
          })
          .catch(() => { })
      } else {
        // console.log(this.selected);
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selected.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    getTags() {
      axios
        .post('', {
          query: `
      {
        allTags {
          edges {
            node {
              id
              name
              createdAt

            }
          }
        }
      }
    `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allTags.edges.forEach(element => {
            this.option.push({
              title: element.node.name,
              value: element.node.id,
            })
          })
        })
        .catch(() => { })
    },
    newTag(data) {
      this.option.push({
        title: data.name,
        value: data.id,
      })
      this.selected.push({
        title: data.name,
        value: data.id,
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "vue-context/src/sass/vue-context";

#edit-vendor-shop .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#edit-vendor-shop #add-new-category-sidebar {
  #edit-vendor-shop .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#edit-vendor-shop .base-image-input {
  display: block;
  /* width: 200px;
  height: 200px; */
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#edit-vendor-shop .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#edit-vendor-shop .placeholder:hover {
  background: #e0e0e0;
}

#edit-vendor-shop .file-input {
  display: none;
}

#edit-vendor-shop .final-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

#edit-vendor-shop .tamanio {
  width: 50%;
}
</style>
