<template>
  <section id="payPurchases">
    <b-row class="mt-2 align-items-center">
      <b-col>
        <b-form-input
          v-model="searchUser"
          :placeholder="$t('subscriptions.search')"
          autocomplete="new-password"
          @change="searchMethod"
        />
      </b-col>
      <b-col>
        <b-form-select
          v-model="typeSelected"
          :options="type"
          @change="searchMethod"
        />
      </b-col>
      <b-col>
        <b-form-input
          v-model="searchNombre"
          autocomplete="new-password"
          :placeholder="$t('purchases.search')"
          @change="searchMethod"
        />
      </b-col>

      <b-col v-if="!IsManual">
        <b-form-select
          v-model="payTypeSelected"
          :options="optionsPayType"
          @change="searchMethod"
        />
      </b-col>
      <b-col>
        <b-form-select
          v-model="isActive"
          :options="optionsPayActive"
          @change="searchMethod"
        />
      </b-col>

      <b-col>
        <b-dropdown
          right
          variant="primary"
          class="mr-1"
        >
          <template #button-content>
            <feather-icon
              icon="FileTextIcon"
              class="mr-50"
              size="20"
            />
            {{ $t("purchases.export") }}
          </template>
          <b-dropdown-item @click="exportTable('CSV')">
            CSV
          </b-dropdown-item>
          <b-dropdown-item @click="exportTable('XLS')">
            XLS
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col style="text-align: end;">
        <b-button
          variant="success"
          @click="$refs['modal'].show()"
        >
          <span class="text-nowrap">
            <feather-icon
              icon="FilePlusIcon"
              size="18"
            />
            {{ $t('crearPagoUsuario') }}
          </span>
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="isBusy"
    >
      <b-table
        id="pagos"
        class="tablePagos mt-2"
        responsive
        :selectable="isSuperuser"
        select-mode="single"
        :no-border-collapse="true"
        :busy="isBusy"
        :sticky-header="true"
        :items="purchasesList"
        :fields="tableColumns"
        @row-selected="onRowSelected"
      >
        <template #table-busy>
          <div
            v-if="!exporting"
            class="text-center text-danger my-2"
          >
            <b-spinner class="align-middle mr-1" />
            <strong>{{ $t('load') }}...</strong>
          </div>
          <div
            v-else
            class="text-center my-2"
          >
            <div class="text-center">
              <feather-icon
                icon="ClockIcon"
                size="30"
              />
              <p id="cancel-label">
                {{ $t('exporting') }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(User)="data">
          <p>{{ cleanUsername(data.item.node.user.username) }}</p>
        </template>
        <template #cell(Tipo)="data">
          <p>{{ type[data.item.node.type] }}</p>
        </template>
        <template #cell(Nombre)="data">
          <p v-if="data.item.node.content">
            {{ data.item.node.content.name }}
          </p>
          <p v-if="data.item.node.category">
            {{ data.item.node.category.name }}
          </p>
        </template>
        <template
          v-if="!IsManual"
          #cell(price)="data"
        >
          <p>{{ data.item.node.price }}</p>
        </template>
        <template #cell(payType)="data">
          <p>{{ optionsPayType[data.item.node.payType] }}</p>
        </template>
        <template #cell(FechaCompra)="data">
          <p>{{ limpiarFecha(data.item.node.createdAt) }}</p>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="data.item.node.isActive ? 'success' : 'danger'">
            {{ data.item.node.isActive ? $t('slider.activeSlider') : $t('slider.inactive') }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              dropleft
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="17"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click.prevent="onRowSelected([...data.item], 'edit')">
                <div class="d-flex">
                  <feather-icon
                    icon="Edit2Icon"
                    class=" mr-2"
                    size="18"
                  />
                  {{ $t('dataGeneric.edit') }}
                </div>
              </b-dropdown-item>.
              <b-dropdown-item
                v-if="isSuperuser"
                @click.prevent="onRowSelected([...data.item], 'delete')"
              >
                <div class="d-flex">
                  <feather-icon
                    icon="Trash2Icon"
                    class=" mr-2"
                    size="18"
                  />
                  {{ $t('dataGeneric.delete') }}
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="rows > perPage"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="lg"
        @input="fetchData()"
      />
    </b-overlay>
    <b-modal
      ref="modal"
      centered
      size="lg"
      :title="$t('paidContModal')"
      @hidden="resetModal"
      @ok="validationForm"
    >
      <validation-observer
        ref="paidContent"
        tag="form"
      >
        <b-overlay
          variant="transparent"
          :show="show"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-row>
            <b-col>
              <b-form-group :label="$t('methodPayment')">
                <b-form-select
                  v-model="payType"
                  :options="opPayType"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('elementoComprado')">
                <b-form-select
                  v-model="purchaseType"
                  :options="opTypePurch"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                v-if="purchaseType == 'CNT'"
                v-slot="{ errors }"
                name="content"
                rules="required"
              >
                <b-form-group
                  :label="$t('content') + '*'"
                  label-for="content"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="content"
                      v-model="contentPaid"
                      :placeholder="$t('contents.content')"
                      :state="errors.length > 0 ? false : null"
                      maxlength="150"
                      required
                      disabled
                    />
                    <b-button
                      class="ml-2"
                      variant="primary"
                      @click="$refs.ConModal.show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-else
                v-slot="{ errors }"
                name="category"
                rules="required"
              >
                <b-form-group
                  :label="$t('zones.category') + '*'"
                  label-for="category"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="category"
                      v-model="categoryPaid"
                      :placeholder="$t('zones.category')"
                      :state="errors.length > 0 ? false : null"
                      maxlength="150"
                      required
                      disabled
                    />
                    <b-button
                      class="ml-2"
                      variant="primary"
                      @click="$refs.CatModal.show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="User"
                rules="required"
              >
                <b-form-group
                  :label="$t('User') + '*'"
                  label-for="User"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="User"
                      v-model="userPaid"
                      :placeholder="$t('User')"
                      :state="errors.length > 0 ? false : null"
                      maxlength="150"
                      required
                      disabled
                    />
                    <b-button
                      class="ml-2"
                      variant="primary"
                      @click="$refs.userModal.show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="idPago"
                rules="required"
              >
                <b-form-group
                  :label="$t('idPago') + '*'"
                  label-for="idPago"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="idPago"
                    v-model="idPayment"
                    :placeholder="$t('idPago')"
                    :state="errors.length > 0 ? false : null"
                    maxlength="150"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="price"
                rules="required"
              >
                <b-form-group
                  :label="$t('purchases.price') + '*'"
                  label-for="price"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="price"
                    v-model="price"
                    type="number"
                    :placeholder="$t('purchases.price')"
                    :state="errors.length > 0 ? false : null"
                    maxlength="10"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('idInApp')"
                label-for="idInAppPurchase"
                :invalid-feedback="$t('required')"
              >
                <b-form-input
                  id="idInAppPurchase"
                  v-model="idInAppPurchase"
                  :placeholder="$t('idInApp')"
                  maxlength="150"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('nombreAppTV')"
                label-for="appTv"
                :invalid-feedback="$t('required')"
              >
                <b-form-input
                  id="appTv"
                  v-model="appTv"
                  :placeholder="$t('nombreAppTV')"
                  maxlength="150"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('tokenPaid')"
                label-for="tokenPaid"
              >
                <b-form-textarea
                  id="tokenPaid"
                  v-model="tokenPaid"
                  :placeholder="$t('tokenPaid')"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('dataGeneric.activeQ')"
                label-for="isActiveSave"
              >
                <b-form-checkbox
                  id="isActiveSave"
                  v-model="isActiveSave"
                  switch
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          {{ $t('dataGeneric.cancel') }}
        </b-button>
        <b-button
          variant="success"
          class="ml-1"
          @click="ok()"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      ref="ConModal"
      size="xl"
      hide-footer
      :title="$t('select')"
    >
      <div class="d-block text-center">
        <content-selector
          :is-premium="true"
          @info="getContent"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.ConModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="CatModal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="[
            { key: 'image', label: $t('contents.category') },
            { key: 'name', label: $t('firstName') },
            { key: 'type', label: $t('type') },
          ]"
          :is-premium="true"
          @confirm-selection="getCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.CatModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="userModal"
      size="xl"
      hide-footer
      :title="$t('shopOrder')"
    >
      <div class="d-block ">
        <final-user-selector @finalUser="getFinalUser" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs.userModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

import {
  BFormSelect,
  BPagination,
  BCol,
  BRow,
  BDropdown,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
  BDropdownItem,
  BFormGroup,
  BBadge,
  BOverlay,
  BTable,
  BFormInput,
  BButton,
  BModal,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, showToast, cleanUsername, isEmpty,
} from '@/store/functions'
import xlsExport from 'xlsexport'
import ContentSelector from '@/views/common/ContentSelector.vue'
import CategorySelector from '@/views/common/CategorySelector.vue'
import FinalUserSelector from '@/views/common/FinalUserSelector.vue'

export default {
  components: {
    BFormInput,
    BTable,
    BFormSelect,
    BOverlay,
    BPagination,
    BBadge,
    FinalUserSelector,
    BFormCheckbox,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BCol,
    BRow,
    BFormGroup,
    BModal,
    BButton,
    ValidationProvider,
    ValidationObserver,
    ContentSelector,
    CategorySelector,
  },
  props: {
    IsManual: Boolean,
  },
  data() {
    return {
      required,
      currentParentFolder: null,
      perPage: 15,
      currentPage: 1,
      price: 0,
      contentPaid: null,
      idPayment: '',
      idPurchase: null,
      tokenPaid: '',
      rows: null,
      userPaid: null,
      show: false,
      isActiveSave: true,
      purchasesList: null,
      payType: 'STR',
      categoryPaid: '',
      purchaseType: 'CNT',
      appTv: '',
      devicePaid: '',
      idInAppPurchase: '',
      optionsPayType: {
        NULL: this.$t('subscriptions.type'),
        GOW: 'Google',
        APP: 'Apple',
        STR: 'Stripe',
        EPO: 'Epoch',
        AMZ: 'Amazon',
        // MAN: this.$t('manualCreat'),
        COD: this.$t('Código'),
      },
      opPayType: {
        GOW: 'Google',
        APP: 'Apple',
        STR: 'Stripe',
        EPO: 'Epoch',
        AMZ: 'Amazon',
        MAN: this.$t('manualCreat'),
        COD: this.$t('Código'),
      },
      optionsPayActive: {
        NULL: this.$t('filterByPaid'),
        TRUE: this.$t('paidAct'),
        FALSE: this.$t('paidDes'),
      },
      isActive: 'NULL',
      array: [],
      type: {
        NULL: this.$t('purchases.type'),
        CNT: this.$t('purchases.content'),
        CAT: this.$t('purchases.category'),
      },
      opTypePurch: {
        CNT: this.$t('purchases.content'),
        CAT: this.$t('purchases.category'),
      },
      tableColumns: [
        { key: 'User', sortable: false, label: this.$t('User') },
        { key: 'Tipo', sortable: false, label: this.$t('code.type') },
        { key: 'Nombre', sortable: false, label: this.$t('dataGeneric.name') },
        { key: 'price', sortable: false, label: this.$t('purchases.price') },
        { key: 'payType', sortable: false, label: this.$t('purchases.payType') },
        { key: 'FechaCompra', sortable: false, label: this.$t('purchases.FechaCompra') },
        { key: 'status', sortable: false, label: this.$t('code.status') },
      ],
      diffSubs: [{ value: null, text: this.$t('subscriptions.type') }],
      searchUser: '',
      searchNombre: '',
      isBusy: false,
      payTypeSelected: 'NULL',
      typeSelected: 'NULL',
      subTypeSelected: null,
      userData: getUserData(),
      exporting: false,
      isSuperuser: true,
      dataToExport: [],

    }
  },

  mounted() {
    this.isSuperuser = this.userData.isSuperuser

    this.fetchData()
  },
  methods: {
    searchMethod() {
      this.currentPage = 1
      this.fetchData()
    },
    resetModal() {
      this.payType = 'MAN'
      this.idPurchase = null
      this.purchaseType = 'CNT'
      this.contentPaid = null
      this.contentId = null
      this.categoryPaid = null
      this.categoryId = null
      this.userPaid = null
      this.userId = null
      this.idPayment = null
      this.price = 0
      this.idInAppPurchase = null
      this.tokenPaid = null
      this.appTv = null
      this.isActiveSave = true
    },
    exportTable(type) {
      let clientId = ''
      try {
        // eslint-disable-next-line prefer-destructuring
        clientId = atob(this.userData.profile.client.id).split(':')[1]
        // eslint-disable-next-line no-empty
      } catch (error) { }
      this.dataToExport = []
      this.exporting = !this.exporting
      this.isBusy = !this.isBusy

      this.exportData('', type, clientId)
    },
    exportData(cursor = '', typeExport, clientId) {
      const query = `query{
            allPaycontent(
              client_Id:"${clientId}",
              after:"${cursor}"
              ${this.typeSelected !== 'NULL' ? `type:"${this.typeSelected}"` : ''}
              ${this.payTypeSelected !== 'NULL' ? `payType:"${this.payTypeSelected}"` : ''}
              search_Name:"${this.searchNombre}",
              search_User:"${this.searchUser}",
              ${this.isActive !== 'NULL' ? `isActive:${this.isActive === 'TRUE'}` : ''}
              ${this.IsManual ? 'payType:"MAN"' : ''}
              ){
              totalCount
              pageInfo {
                endCursor
                hasNextPage
              }
              edges{
                node {
                  id
                  type
                  payType
                  idInapppurchase
                  createdAt
                  price
                  package
                  idInapppurchase
                  receipt
                  isActive
                  payId
                  content {
                    id
                    name
                  }
                  category {
                    id
                    name
                  }
                   user {
                    id
                    username
                      client {
                        id
                        name
                      }
                  }
                }
              }
            }
          }`
      axios
        .post('', {
          query,
        })
        .then(response => {
          messageError(response, this)
          const info = response.data.data.allPaycontent
          const page = info.pageInfo
          const paySubscriptions = info.edges
          const nodes = paySubscriptions.map(item => item.node)
          this.dataToExport = [...this.dataToExport, ...nodes]
          if (page.hasNextPage) {
            this.exportData(page.endCursor, typeExport, clientId)
          } else {
            const dataTable = this.dataToExport.map(item => {
              let name = ''
              if (item.content) { name = item.content.name }
              if (item.category) { name = item.category.name }
              const commonData = {
                [this.$t('User')]: item.user.username,
                [this.$t('code.type')]: this.type[item.type],
                [this.$t('name')]: name,
                [this.$t('purchases.payType')]: this.optionsPayType[item.payType],
                [this.$t('datePurchase')]: this.limpiarFecha(item.createdAt),
              }
              return !this.IsManual
                ? {
                  ...commonData,
                  [this.$t('purchases.price')]: item.price,
                }
                : commonData
            })
            const f = new Date()
            const m = f.getMonth() + 1
            const name = `${this.$t('Payments')}_${f.getFullYear()}${m < 10 ? (`0${m}`) : `${m}`}${f.getDate()}`

            if (!isEmpty(this.dataToExport)) {
              // eslint-disable-next-line new-cap
              const xls = new xlsExport(dataTable, this.$t('Payments'))
              if (typeExport === 'XLS') { xls.exportToXLS(`${name}.xls`) } else { xls.exportToCSV(`${name}.csv`) }
            }
            this.exporting = !this.exporting
            this.isBusy = !this.isBusy
          }
        }).catch(err => {
          console.log(err)
          showToast(this.$t('subtitles.translationError'), 0, this)
        })
    },
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      return new Promise(resolve => {
        this.$refs.paidContent.validate().then(success => {
          if (success) {
            resolve(true)
            this.handleOk()
          }
        })
      })
    },
    getContent(item) {
      const [contentId, contentPaid] = item
      this.contentId = contentId
      this.contentPaid = contentPaid
      this.$refs.ConModal.hide()
    },
    getFinalUser(item) {
      const { id, username } = item
      this.userId = id
      this.userPaid = username
      this.$refs.userModal.hide()
    },
    getCategory(item) {
      const { id, name } = item.node
      this.categoryPaid = name
      this.categoryId = id
      this.$refs.CatModal.hide()
    },
    handleOk() {
      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      let query = ''
      if (this.idPurchase) {
        query = `mutation(
        $id: ID!,
        $user: ID!,
        $type: PayContentTypeInput,
        $payType: PayContentPayTypeInput,
        $content: ID,
        $category: ID,
        $price: Float,
        $payId: String!,
        $receipt: String!,
        $package: String!
        $idInapppurchase: String!,
        $isActive: Boolean
        ){
          updatePayContent(id:$id,input:{type:$type,payType:$payType,user:$user,
            content:$content,category:$category,price:$price,payId:$payId,
            receipt:$receipt,package:$package,idInapppurchase:$idInapppurchase,
            isActive:$isActive}){
            payContent{
              id
            }
          }
        }`
      } else {
        query = `mutation(
        $user: ID!,
        $type: PayContentTypeInput,
        $payType: PayContentPayTypeInput,
        $content: ID,
        $category: ID,
        $price: Float,
        $payId: String!,
        $receipt: String!,
        $package: String!
        $idInapppurchase: String!,
        $isActive: Boolean
        ){
          createPayContent(input:{
            type:$type,payType:$payType,user:$user,
            content:$content,category:$category,price:$price,payId:$payId,
            receipt:$receipt,package:$package,idInapppurchase:$idInapppurchase,
            isActive:$isActive
          }){
              payContent{
              id
            }
          }
        }
          `
      }

      const variables = {
        id: this.idPurchase,
        user: this.userId,
        type: this.purchaseType,
        payType: this.payType,
        content: this.contentId,
        category: this.categoryId,
        price: this.price,
        payId: this.idPayment,
        receipt: this.tokenPaid,
        package: this.appTv,
        idInapppurchase: this.idInAppPurchase,
        isActive: this.isActiveSave,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      axios
        .post('', data, config)
        .then(res => {
          this.show = false
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$refs.modal.hide()
          this.fetchData()
        }).catch(() => {
          this.show = false
          showToast(this.$t('error'), 0, this)
        })
    },
    filterData(data) {
      let valueData = data
      try {
        if (data != null) {
          if (this.searchUser !== null) {
            valueData = this.purchasesList.filter(item => item.node.user.username
              .toLowerCase()
              .includes(this.searchUser.toLowerCase()))
          }
          if (this.searchNombre !== null) {
            valueData = this.purchasesList.filter(item => {
              if (item.node.content) {
                return item.node.content.name
                  .toLowerCase()
                  .includes(this.searchNombre.toLowerCase())
              }
              return item.node.category.name
                .toLowerCase()
                .includes(this.searchNombre.toLowerCase())
            })
          }
          if (this.typeSelected !== 'NULL') {
            valueData = valueData.filter(
              item => item.node.type === this.typeSelected,
            )
          }
          if (this.payTypeSelected !== 'NULL') {
            valueData = valueData.filter(
              item => item.node.payType === this.payTypeSelected,
            )
          }
          if (this.isActive !== 'NULL') {
            const bol = this.isActive === 'TRUE'
            valueData = valueData.filter(
              item => item.node.isActive === bol,
            )
          }
          return valueData
        }
        return []
      } catch (error) {
        console.log(error)
      }
      return []
    },
    fetchData() {
      this.isBusy = !this.isBusy
      const { id } = this.userData.profile.client
      let clientId = ''
      try {
        // eslint-disable-next-line prefer-destructuring
        clientId = atob(id).split(':')[1]
        // eslint-disable-next-line no-empty
      } catch (error) { }
      axios
        .post('', {
          query: `
         query{
            allPaycontent(
              client_Id:"${clientId}",
              first:${this.perPage},
              ${this.typeSelected !== 'NULL' ? `type:"${this.typeSelected}"` : ''}
              ${this.payTypeSelected !== 'NULL' ? `payType:"${this.payTypeSelected}"` : ''}
              offset:${this.perPage * (this.currentPage - 1)},
              search_Name:"${this.searchNombre}",
              search_User:"${this.searchUser}",
              ${this.isActive !== 'NULL'
    ? `isActive:${this.isActive === 'TRUE'}` : ''}
              ${this.IsManual ? 'payType:"MAN"' : ''}
              ){
              totalCount
              edges{
                node {
                  id
                  type
                  payType
                  idInapppurchase
                  createdAt
                  price
                  package
                  idInapppurchase
                  receipt
                  isActive
                  payId
                  content {
                    id
                    name
                  }
                  category {
                    id
                    name
                  }
                   user {
                    id
                    username
                      client {
                        id
                        name
                      }
                  }
                }
              }
            }
          }
        `,
        })
        .then(response => {
          messageError(response, this)
          this.purchasesList = response.data.data.allPaycontent.edges
          this.rows = response.data.data.allPaycontent.totalCount
          if (this.IsManual) {
            this.tableColumns = [
              { key: 'User', sortable: false, label: this.$t('User') },
              { key: 'Tipo', sortable: false, label: this.$t('code.type') },
              { key: 'Nombre', sortable: false, label: this.$t('dataGeneric.name') },
              { key: 'payType', sortable: false, label: this.$t('purchases.payType') },
              { key: 'FechaCompra', sortable: false, label: this.$t('purchases.FechaCompra') },
              { key: 'status', sortable: false, label: this.$t('code.status') },

            ]
            this.tableColumns = [
              ...this.tableColumns,
              { key: 'actions', label: this.$t('message.tableHeader.action') },
            ]
          } else {
            this.tableColumns = [
              { key: 'User', sortable: false, label: this.$t('User') },
              { key: 'Tipo', sortable: false, label: this.$t('code.type') },
              { key: 'Nombre', sortable: false, label: this.$t('dataGeneric.name') },
              { key: 'price', sortable: false, label: this.$t('purchases.price') },
              { key: 'payType', sortable: false, label: this.$t('purchases.payType') },
              { key: 'FechaCompra', sortable: false, label: this.$t('purchases.FechaCompra') },
              { key: 'status', sortable: false, label: this.$t('code.status') },
            ]
          }
          if (this.isSuperuser) {
            this.tableColumns = [
              ...this.tableColumns,
              { key: 'actions', label: this.$t('message.tableHeader.action') },
            ]
            this.opPayType = {
              GOW: 'Google',
              APP: 'Apple',
              STR: 'Stripe',
              EPO: 'Epoch',
              AMZ: 'Amazon',
              MAN: this.$t('manualCreat'),
              COD: this.$t('Código'),
            }
          } else {
            this.opPayType = {
              MAN: this.$t('manualCreat'),
            }
          }
        })

      this.isBusy = !this.isBusy
    },
    onRowSelected(data, action) {
      if (data[0]) {
        const {
          id, type, payId, receipt, payType, content, category, user, idInapppurchase, price, isActive,
        } = data[0].node
        const appTv = data[0].node.package
        this.idPurchase = id
        this.payType = payType
        this.purchaseType = type
        this.contentPaid = content?.name
        this.contentId = content?.id
        this.categoryPaid = category?.name
        this.categoryId = category?.id
        this.appTv = appTv
        this.userPaid = user?.username
        this.userId = user?.id
        this.idPayment = payId
        this.price = price
        this.idInAppPurchase = idInapppurchase
        this.tokenPaid = receipt
        this.isActiveSave = isActive

        if (action === 'edit') {
          this.$refs.modal.show()
        } else if (action === 'delete') {
          this.$swal({
            title: this.$t('code.title'),
            text: this.$t('rem'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('code.confirm'),
            cancelButtonText: this.$t('dataGeneric.cancel'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              const query = `
            mutation {
              deletePayContent(id:${this.idPurchase}) {
                found
              }
            }
          `
              axios
                .post('', { query })
                .then(res => {
                  if (res.data.data.deletePayContent.found) {
                    showToast(this.$t('success'), 1, this)
                    this.fetchData()
                  }
                })
                .catch(() => {
                  showToast(this.$t('error'), 2, this)
                })
            }
          })
        }
      }
    },

    limpiarFecha(fecha) {
      const purchaDate = new Date(fecha)
      const newDate = purchaDate.toLocaleDateString('en-GB')
      return newDate
    },

    cleanUsername,
  },
}

</script>

<style scoped>
#payPurchases #avatar {
  width: 10rem;
  height: 5.5rem;
}

#payPurchases .td {
  width: 50px;
  height: 100px;
}

#payPurchases .res {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

#payPurchases .dropContainer {
  border: 3px dashed;
}

#payPurchases .card-width {
  width: 200px;
}

#payPurchases .list-group-item {
  transition: all 1s;
}

#payPurchases .tablePagos {
  height: 400px;
}
</style>
