<template>
  <div id="orders">
    <b-row class="mb-5">
      <b-col>
        <h1>{{ $t('Orders') }}</h1>
        <h5 class="text-primary">
          {{ $t('shopOrder') }}
        </h5>
      </b-col>
      <b-col>
        <div
          v-if="isFinal"
          class="mt-2  text-right"
        >
          <b-button
            variant="danger"
            @click="$router.go(-1)"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="!isVendor"
        class="align-items-center d-flex m-1"
      >
        <b-form-input
          id="search"
          v-model="search"
          type="text"
          name="search"
          :placeholder="$t('Search')"
          class="searchCategories mr-2"
          @keyup="onKeyUp($event)"
          @focus="searching"
        />
        <b-button
          v-if="seeker"
          variant="info"
          class="mr-1"
          @click.stop.prevent="onKeyUp($event, true)"
        >
          <feather-icon
            icon="SearchIcon"
            size="18"
          />
        </b-button>
        <b-button
          v-if="seeker"
          variant="danger"
          @click="cleanSearch"
        >
          {{ $t('dataGeneric.cancel') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-breadcrumb>
          <b-breadcrumb-item
            v-for="(brc, index) in navItems"
            :key="brc.text + '_' + brc.id"
            :text="brc.text"
            :active="index === navItems.length - 1 ? true : false"
            @click="getElement(brc.id)"
          />
        </b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <div class="d-flex align-items-center">
        <div
          v-if="!isFinal"
          class="ml-1 d-flex align-items-center"
        >
          <flat-pickr
            id="pickr"
            v-model="filterDate"
            class="form-control mr-2"
            :placeholder="$t('filtDate')"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d'
            }"
          />

          <a
            class="input-button"
            title="toggle"
            @click="clickPickr()"
          >
            <feather-icon
              icon="CalendarIcon"
              size="22"
              class="mr-1"
            />
          </a>

          <a
            v-if="filterDate.length != 0"
            class="input-button"
            title="clear"
            @click="filterDate = ''"
          >
            <feather-icon
              icon="XIcon"
              size="22"
            />
          </a>
        </div>
        <div class="ml-1">
          <b-form-select
            v-model="filterIsSpecial"
            :options="{
              null: $t('filterBySpecial'),
              true: $t('specialOrder'),
              false: $t('ordersSinSpecial'),
            }"
          />
        </div>
        <div class="ml-1">
          <b-form-select
            v-model="filterVendorPaid"
            :options="optionsPaid"
          />
        </div>
        <!-- FILTER Status -->
        <div class="ml-1">
          <b-form-select v-model="filterStatus">
            <b-form-select-option :value="null">
              {{ $t('subscriptions.status') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="a in Object.keys(status)"
              :key="a"
              :value="a"
            >
              {{ status[a] }}
            </b-form-select-option>
          </b-form-select>
        </div>
        <div
          v-if="!isVendor"
          class="ml-1"
        >
          <b-form-select v-model="vendor">
            <b-form-select-option :value="null">
              {{ $t('filterVend') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="coun in allVendors"
              :key="coun.node.id"
              :value="coun.node.id"
            >
              {{ coun.node.vendorName }}
            </b-form-select-option>
          </b-form-select>
        </div>
        <div
          v-if="!isVendor"
          class="ml-1 mr-1"
        >
          <b-button
            v-b-modal.paid-vendor
            variant="success"
            @click="getOrdersToPaid"
          >
            <span class="text-nowrap">{{ vendor ? $t('paidVe') : $t('paidOr') }}</span>
          </b-button>
        </div>
      </div>
    </b-row>

    <b-row>
      <b-col v-if="!isFinal">
        <b-table
          ref="refInvoiceListTable"
          :items="allOrder"
          :busy="isBusy"
          responsive
          :fields="tableColumns"
          primary-key="id"
          select-mode="single"
          show-empty
          hover
          :empty-text="$t('emptyText')"
          selectable
          class="cursor-pointer"
          @row-selected="clickRow"
          @sort-changed="onSortChanged"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>{{ $t('load') }}...</strong>
            </div>
          </template>
          <template #cell(order_id)="data">
            <div class="d-flex justify-content-between">
              {{ data.item.node.orderId }}
              <b-badge
                v-if="data.item.node.status == 'A_7'"
                variant="info"
                class="ml-1 mr-1"
                style="height: max-content;"
              >
                {{ $t('pendingDelivery') }}
              </b-badge>
              <b-badge
                v-if="data.item.node.status == 'A_10'"
                variant="success"
                class="ml-1 mr-1"
                style="height: max-content;"
              >
                {{ $t('transito') }}
              </b-badge>
              <b-badge
                v-if="data.item.node.status == 'A_14'"
                variant="danger"
                class="ml-1 mr-1"
                style="height: max-content;"
              >
                {{ $t('errorPacklink') }}
              </b-badge>
              <b-badge
                v-if="data.item.node.isSpecial"
                variant="warning"
                class="mr-1"
                style="height: max-content;"
              >
                {{ $t('special') }}
              </b-badge>
            </div>
          </template>
          <template #cell(customer)="data">
            <div>
              <b-link
                :to="{
                  name: 'final-users-edit',
                  params: { id: data.item.node.customer.id }
                }"
                class="font-weight-bold d-block text-nowrap"
                style="max-width: fit-content;"
              >
                {{ cleanUsername(data.item.node.customer.username) }}
              </b-link>
            </div>
          </template>

          <template #cell(status)="data">
            <div>
              {{ status[data.item.node.status] }}
            </div>
          </template>
          <template #cell(vendor)="data">
            <div>
              {{ data.item.node.vendor != null ? data.item.node.vendor.vendorName : '' }}
            </div>
          </template>

          <template #cell(created)="data">
            <div>
              {{ fechaFormato(data.item.node.created) }}
            </div>
          </template>
          <template #cell(paymentDate)="data">
            <div>
              {{ fechaFormato(data.item.node.paymentDate) }}
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col v-else>
        <b-table
          ref="refInvoiceListTable"
          :items="childOrders"
          :busy="isBusy"
          responsive
          :fields="tableColumnsFinalOrders"
          primary-key="id"
          select-mode="single"
          show-empty
          style="height:500px"
          empty-text="No se han encontrado datos"
          @sort-changed="onSortChanged"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>{{ $t('load') }}...</strong>
            </div>
          </template>
          <template #cell(order_id)="data">
            <div class="d-flex justify-content-between">
              {{ data.item.node.orderId }}
              <b-badge
                v-if="data.item.node.status == 'A_7'"
                variant="info"
                class="ml-1 mr-1"
                style="height: max-content;"
              >
                {{ $t('pendingDelivery') }}
              </b-badge>
              <b-badge
                v-if="data.item.node.status == 'A_10'"
                variant="success"
                class="ml-1 mr-1"
                style="height: max-content;"
              >
                {{ $t('transito') }}
              </b-badge>
              <b-badge
                v-if="data.item.node.status == 'A_14'"
                variant="danger"
                class="ml-1 mr-1"
                style="height: max-content;"
              >
                {{ $t('errorPacklink') }}
              </b-badge>
              <b-badge
                v-if="data.item.node.isSpecial"
                variant="warning"
                class="mr-1"
                style="height: max-content;"
              >
                {{ $t('special') }}
              </b-badge>
            </div>
          </template>
          <template #cell(chargeToken)="data">
            <small>
              {{ data.item.node.chargeToken }}
            </small>
          </template>
          <template #cell(customer)="data">
            <div>
              <b-link
                :to="{
                  name: 'final-users-edit',
                  params: { id: data.item.node.customer.id }
                }"
                class="font-weight-bold d-block text-nowrap"
                style="max-width: fit-content;"
              >
                {{ cleanUsername(data.item.node.customer.username) }}
              </b-link>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="d-flex justify-content-between align-items-center">
              <span>
                {{ status[data.item.node.status] }}
              </span>
              <b-dropdown
                v-if="showDropdown(data.item.node)"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    color="white"
                    icon="ChevronDownIcon"
                    size="23"
                  />
                </template>
                <div v-if="data.item.node.isSpecial && data.item.node.shippingCost == 0">
                  <b-dropdown-item @click.prevent="specialOrder(data.item.node, true)">
                    <feather-icon
                      class="text-success mr-25"
                      icon="ShoppingCartIcon"
                      size="18"
                    />
                    {{ $t('completeOrder') }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="data.item.node.status != 'A_14'"
                    @click.prevent="specialOrder(data.item.node, false)"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      class="text-danger mr-25"
                      size="18"
                    />
                    {{ $t('cancelOrder') }}
                  </b-dropdown-item>
                </div>
                <div v-if="data.item.node.status == 'A_7'">
                  <b-dropdown-item @click.prevent="changeStatusOrder(data.item.node, 'A_10')">
                    <feather-icon
                      icon="TruckIcon"
                      class="text-info mr-25"
                      size="18"
                    />
                    {{ $t('transito') }}
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="specialOrder(data.item.node, false)">
                    <feather-icon
                      icon="XCircleIcon"
                      class="text-danger mr-25"
                      size="18"
                    />
                    {{ $t('cancelOrder') }}
                  </b-dropdown-item>
                </div>
                <b-dropdown-item
                  v-if="data.item.node.status == 'A_10'"
                  @click.prevent="changeStatusOrder(data.item.node, 'A_11')"
                >
                  <feather-icon
                    icon="ThumbsUpIcon"
                    class="text-success mr-25"
                    size="18"
                  />
                  {{ $t('endOrd') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.node.status == 'A_10'"
                  @click.prevent="specialOrder(data.item.node, false)"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    class="text-danger mr-25"
                    size="18"
                  />
                  {{ $t('cancelOrder') }}
                </b-dropdown-item>
                <div v-if="data.item.node.status == 'A_14'">
                  <b-dropdown-item @click.prevent="specialOrder(data.item.node, false)">
                    <feather-icon
                      icon="XCircleIcon"
                      class="text-danger mr-25"
                      size="18"
                    />
                    {{ $t('cancelOrder') }}
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="changeStatusOrder(data.item.node, 'A_8')">
                    <feather-icon
                      icon="DollarSignIcon"
                      class="text-warning mr-25"
                      size="18"
                    />
                    {{ $t('Devolver') }}
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="changeStatusOrder(data.item.node, 'A_10', true)">
                    <feather-icon
                      icon="TruckIcon"
                      class="text-info mr-25"
                      size="18"
                    />
                    {{ $t('transito') }}
                  </b-dropdown-item>
                </div>
              </b-dropdown>
              <div v-else>
                <b-dropdown
                  v-if="data.item.node.status !== 'A_6' && data.item.node.status !== 'A_11'"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      color="white"
                      icon="ChevronDownIcon"
                      size="23"
                    />
                  </template>
                  <b-dropdown-item @click.prevent="specialOrder(data.item.node, false)">
                    <feather-icon
                      icon="XCircleIcon"
                      class="text-danger mr-25"
                      size="18"
                    />
                    {{ $t('cancelOrder') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <template #cell(vendor)="data">
            <div>
              {{ data.item.node.vendor == null ? "" : data.item.node.vendor.vendorName }}
            </div>
          </template>
          <template #cell(totalAmount)="data">
            <div>
              {{ format(data.item.node.totalAmount) }} {{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(refunds)="data">
            <div>
              {{ format(data.item.node.totalRefunded) }} {{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(created)="data">
            <div>
              {{ fechaFormato(data.item.node.created) }}
            </div>
          </template>
          <template #cell(paymentDate)="data">
            <div>
              {{ fechaFormato(data.item.node.paymentDate) }}
            </div>
          </template>
          <template #cell(packlink)="data">
            <small>
              <b-link
                :href="'https://pro.packlink.es/private/shipments/' + data.item.node.shippingId + '/create/payment'"
                target="_blank"
                style="max-width: fit-content;"
              >
                {{ data.item.node.shippingId }}
              </b-link>
            </small>
          </template>
          <template #cell(show_details)="data">
            <b-button
              size="sm"
              :variant="data.detailsShowing ? 'danger' : 'success'"
              class="cursor-pointer"
              :disabled="data.item.node.products.edges.length == 0"
              @click="data.toggleDetails"
            >
              {{ data.detailsShowing ? $t('table.hidden') : $t('table.Show') }} {{ $t('productos') }}
            </b-button>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row class="mb-1">
                <b-col>
                  <h6>{{ $t('firstName') }}</h6>
                </b-col>
                <b-col>
                  <h6>{{ $t('quantity') }}</h6>
                </b-col>
                <b-col>
                  <h6>{{ $t('purchases.price') }}</h6>
                </b-col>
              </b-row>
              <b-row
                v-for="prod in row.item.node.products.edges"
                :key="prod.node.id"
                class="mb-1"
              >
                <b-col>{{ prod.node.itemName }}</b-col>
                <b-col>{{ prod.node.itemQuantity }} </b-col>
                <b-col>{{ prod.node.itemPrice }} {{ currencyType[currency] }}</b-col>
              </b-row>
              <b-row>
                <b-button
                  size="sm"
                  variant="danger"
                  @click="row.toggleDetails"
                >
                  {{ $t('dataGeneric.close') }}
                </b-button>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex ">
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="nextPage()"
        />
      </b-col>
    </b-row>
    <b-modal
      id="paid-vendor"
      ref="paid-vendor"
      scrollable
      modal-class="paid-vendor"
      size="xl"
      :title="$t('paidVe')"
      @ok="stripeWithdrawal"
    >
      <div>
        <b-row>
          <b-col>
            <h5 v-if="!isEmpty(vendorData)">
              {{ $t('paidOrderToVend') }} {{ vendorData.vendorName }}
            </h5>
            <h5 v-else>
              {{ $t('ordersToPaid') }}
            </h5>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2">
          <b-col>
            <b-table
              ref="refInvoiceListTable"
              :items="paidOrders"
              :busy="busyPaid"
              responsive
              :fields="[
                {
                  key: 'orderId', label: $t('idOrder')
                },
                { key: 'customer', label: $t('Cliente') },
                { key: 'status', label: $t('editContent.status') },
                { key: 'vendors', label: $t('Vendedor') },
                { key: 'paymentDate', label: $t('fechaPago') },
                {
                  key: 'totalAmount',
                  label: $t('totalOrder')
                },
                {
                  key: 'shippingCost',
                  label: $t('shippingCosts')
                },
                {
                  key: 'feeDetails',
                  label: $t('stripeTax')
                },
                {
                  key: 'totals',
                  label: $t('totalPaid')
                },
                { key: 'show_details', label: $t('Productos') },
                { key: 'paid', label: $t('Pagar') },]
              "
              primary-key="id"
              select-mode="single"
              show-empty
              :empty-text="$t('emptyText')"
              @sort-changed="onSortChanged"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>{{ $t('load') }}...</strong>
                </div>
              </template>
              <template #cell(orderId)="data">
                <div class="d-flex justify-content-between">
                  {{ data.item.node.orderId }}
                  <b-badge
                    v-if="data.item.node.status == 'A_7'"
                    variant="info"
                    class="ml-1 mr-1"
                  >
                    {{ $t('pendingDelivery') }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.node.status == 'A_10'"
                    variant="success"
                    class="ml-1 mr-1"
                  >
                    {{ $t('transito') }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.node.status == 'A_14'"
                    variant="danger"
                    class="ml-1 mr-1"
                  >
                    {{ $t('errorPacklink') }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.node.isSpecial"
                    variant="warning"
                    class=""
                  >
                    {{ $t('special') }}
                  </b-badge>
                </div>
              </template>
              <template #cell(customer)="data">
                <div>
                  <b-link
                    :to="{
                      name: 'final-users-edit',
                      params: { id: data.item.node.customer.id }
                    }"
                    class="font-weight-bold d-block text-nowrap"
                    style="max-width: fit-content;"
                  >
                    {{ cleanUsername(data.item.node.customer.username) }}
                  </b-link>
                </div>
              </template>
              <template #cell(chargeToken)="data">
                <div>
                  {{ data.item.node.chargeToken }}
                </div>
              </template>
              <template #cell(status)="data">
                <div>
                  {{ status[data.item.node.status] }}
                </div>
              </template>
              <template #cell(vendors)="data">
                <div>
                  {{ !isEmpty(data.item.node.vendor) ? data.item.node.vendor.vendorName : '' }}
                </div>
              </template>
              <template #cell(paymentDate)="data">
                <div>
                  {{ fechaFormato(data.item.node.paymentDate) }}
                </div>
              </template>
              <template #cell(shippingCost)="data">
                <div>
                  {{ format(data.item.node.shippingCost) }} {{ currencyType[currency] }}
                </div>
              </template>
              <template #cell(feeDetails)="data">
                <div>
                  {{ format(data.item.node.feeDetails) }} {{ data.item.node.feeDetails > 0 ? currencyType[currency] : ''
                  }}
                </div>
              </template>
              <template #cell(totalAmount)="data">
                <div>
                  {{ format(data.item.node.totalAmount) }} {{ currencyType[currency] }}
                </div>
              </template>
              <template #cell(totals)="data">
                <div>
                  <h5>
                    {{ format(getTotalPrice(data.item.node)) }} {{ currencyType[currency] }}
                  </h5>
                </div>
              </template>

              <template #cell(show_details)="data">
                <b-button
                  size="sm"
                  :variant="data.detailsShowing ? 'danger' : 'success'"
                  class="cursor-pointer"
                  :disabled="data.item.node.products.edges.length == 0"
                  @click="data.toggleDetails"
                >
                  {{ data.detailsShowing ? $t('table.hidden') : $t('table.Show') }} {{ $t('Productos') }}
                </b-button>
              </template>
              <template #row-details="row">
                <b-card title="Productos">
                  <b-row class="mb-1">
                    <b-col>
                      <h6>{{ $t('firstName') }}</h6>
                    </b-col>
                    <b-col>
                      <h6>{{ $t('quantity') }}</h6>
                    </b-col>
                    <b-col>
                      <h6>{{ $t('purchases.price') }}</h6>
                    </b-col>
                  </b-row>
                  <b-row
                    v-for="prod in row.item.node.products.edges"
                    :key="prod.node.id"
                    class="mb-1"
                  >
                    <b-col>{{ prod.node.itemName }}</b-col>
                    <b-col>{{ prod.node.itemQuantity }} </b-col>
                    <b-col>{{ prod.node.itemPrice }} {{ currencyType[currency] }}</b-col>
                  </b-row>
                  <b-row>
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="row.toggleDetails"
                    >
                      {{ $t('dataGeneric.close') }}
                    </b-button>
                  </b-row>
                </b-card>
              </template>
              <template #cell(paid)="data">
                <div class="d-flex align-items-center">
                  <b-button
                    size="sm"
                    variant="success"
                    :disabled="vendorPaid(data.item.node)"
                    @click="stripeWithdrawal(paidOrders = [data.item])"
                  >
                    {{ $t('Pagar') }}
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex ">
            <b-pagination
              v-if="totalPaid > nPaid"
              v-model="pageList"
              :total-rows="totalPaid"
              :per-page="nPaid"
              size="lg"
              @input="getOrdersToPaid()"
            />
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          {{ $t('dataGeneric.cancel') }}
        </b-button>
        <b-button
          v-if="vendor"
          variant="success"
          :disabled="paidOrders.length == 0"
          @click="ok()"
        >
          {{ $t('paidAllOrder') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BSpinner,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BBadge,
  BLink,
  BFormSelectOption,
  BPagination,
  BModal,
  BBreadcrumbItem,
  BBreadcrumb,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, cleanUsername, utf8ToB64, isEmpty, showToast, getVendorProfileData,
} from '@/store/functions'

import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import statusOrder from '@core/utils/statusOrder'
import { getUserData } from '@/auth/utils'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BSpinner,
    BModal,
    BButton,
    BTable,
    BFormSelectOption,
    BLink,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBreadcrumbItem,
    BBreadcrumb,
    BBadge,
    flatPickr,
    BPagination,

  },
  directives: {
    Ripple,
  },
  props: {
    idFinalOrder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      seeker: false,
      search: '',
      filterVendorPaid: null,
      filterIsSpecial: null,
      optionsPaid: {
        null: this.$t('filterByPaide'),
        true: this.$t('paidVend'),
        false: this.$t('No pagados'),
      },
      userData: getUserData(),
      optionStatus: {},
      filterStatus: null,
      paidOrders: [],
      vendorData: [],
      orders: null,
      isEmpty,
      isBusy: false,
      allOrder: [],
      childOrders: [],
      vendor: null,
      allVendors: [],
      filterDate: '',
      currency: null,
      isFinal: false,
      myId: null,
      orphan: false,
      tableColumns: [
        { key: 'order_id', label: this.$t('idOrder'), sortable: true },
        { key: 'customer', label: this.$t('Cliente') },
        { key: 'status', label: this.$t('editContent.status') },
        { key: 'vendor', label: this.$t('Vendedor') },
        { key: 'created', label: this.$t('subscriptions.createdAt'), sortable: true },
        { key: 'paymentDate', label: this.$t('fechaPago'), sortable: true },
      ],
      tableColumnsFinalOrders: [
        { key: 'order_id', label: this.$t('orderId'), sortable: true },
        { key: 'customer', label: this.$t('Cliente') },
        { key: 'chargeToken', label: this.$t('idPaid') },
        { key: 'status', label: this.$t('editContent.status') },
        { key: 'vendor', label: this.$t('Vendedor') },
        { key: 'totalAmount', label: this.$t('totalPaidment') },
        { key: 'created', label: this.$t('subscriptions.createdAt'), sortable: true },
        { key: 'paymentDate', label: this.$t('fechaPago'), sortable: true },
        { key: 'packlink', label: 'Packlink' },
        { key: 'show_details', label: this.$t('Productos') },
      ],
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
        isInternal: false,
      },
      methodPayment: {
        STR: 'Stripe',
        APP: 'Apple InAppPurchase',
        GOW: 'Google InAppPurchase',
      },
      status: statusOrder,
      rows: 0,
      nPaid: 10,
      totalPaid: 0,
      perPage: 20,
      currentPage: 1,
      busyPaid: false,
      pageList: 1,
      brItems: [
        {
          id: null,
          text: this.$t('contents.start'),
        },
      ],
      navItems: [
        {
          id: null,
          text: this.$t('contents.start'),
        },
      ],
      orderBy: '-created',
      updateFee: 0,
      isVendor: false,
    }
  },
  watch: {
    filterVendorPaid() {
      this.currentPage = 1
      this.nextPage()
    },
    filterStatus() {
      this.currentPage = 1
      this.nextPage()
    },
    filterIsSpecial() {
      this.currentPage = 1
      this.nextPage()
    },
    filterDate() {
      this.currentPage = 1
      this.fetchData()
    },
    async vendor() {
      if (this.vendor) {
        if (this.isVendor) {
          await getVendorProfileData(this.userData.profile.id).then(result => {
            if (result) {
              this.vendorData.push(result)
            }
          })
        } else this.vendorData = this.allVendors.filter(a => a.node.id === this.vendor)[0].node
      } else this.vendorData = []
      this.currentPage = 1
      this.nextPage()
    },
  },
  async mounted() {
    this.getPayMethod()
    this.isVendor = this.userData.profile.isVendor
    if (this.isVendor) {
      await getVendorProfileData(this.userData.profile.id).then(result => {
        if (result) {
          this.vendor = result.id
        }
      })
      this.tableColumnsFinalOrders = this.tableColumnsFinalOrders.filter(item => item.key !== 'vendor')
      this.tableColumns = this.tableColumns.filter(item => item.key !== 'vendor')
    } else {
      this.getVendor()
    }
    if (this.idFinalOrder) {
      this.isFinal = true
      this.finalOrders()
    } else {
      this.fetchData()
    }
  },
  methods: {
    getTotalPrice(item) {
      const total = item.totalAmount
      const fee = item.feeDetails > 0 ? item.feeDetails : 0
      const { shippingCost } = item
      return total - fee - shippingCost
    },
    feeDetails(orders) {
      let query = 'query{'
      const position = []
      orders.forEach((element, i) => {
        console.log(element.node)
        const { stripeSecretKey } = element.node.client
        const { chargeToken } = element.node
        if (!isEmpty(chargeToken) && !isEmpty(stripeSecretKey)) {
          query += `or_${i + 1}:
           feeDetailsStripe(user:"${stripeSecretKey}",paymentId:"${chargeToken}")
              {
               status
             }`
          position.push(i)
        } else {
          this.paidOrders[i].node.feeDetails = isEmpty(chargeToken) ? this.$t('NoIdPaid') : this.$t('NotFoundKey')
        }
      })
      console.log(this.paidOrders)
      query += '}'
      if (position.length > 0) {
        axios
          .post('',
            { query }).then(response => {
            messageError(response, this)

            position.forEach(n => {
              const { status } = response.data.data[`or_${n + 1}`]
              const fee = JSON.parse(status).fee / 100
              this.paidOrders[n].node.feeDetails = fee
            })
            this.busyPaid = !this.busyPaid
          }).catch(err => {
            console.log(err)
          })
      } else this.busyPaid = !this.busyPaid
    },
    onSortChanged(sortInfo) {
      this.orderBy = sortInfo.sortDesc ? sortInfo.sortBy : `-${sortInfo.sortBy}`
      this.nextPage()
    },
    cleanSearch() {
      document.getElementById('search').classList.remove('searching')
      this.seeker = false
      this.search = ''
      this.nextPage()
    },
    onKeyUp(event, click = false) {
      const keycode = event.keyCode || event.which
      if (keycode === 13 || click) {
        this.currentPage = 1
        this.nextPage()
      }
    },
    searching() {
      document.getElementById('search').classList.add('searching')
      this.seeker = true
    },
    changeStatusOrder(node, status, packlink = false) {
      let text = ''
      switch (status) {
        case 'A_10':
          text = this.$t('orderBeInTransit')
          break
        case 'A_11':
          text = this.$t('finishOrder')

          break
        case 'A_8':
          text = this.$t('indicateAsReturnedOrder')

          break

        default:
          break
      }
      let name = ''
      let id = ''
      this.$swal({
        title: this.$t('code.title'),
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (packlink) {
            this.$swal({
              title: this.$t('changeOrderStatus'),
              html: `<h3 style="color: #545454;">${this.$t('indicateIdOrderAndNameCarrier')}</h3><br/><div >
          <input id="name_packlink" autocapitalize="off" style="color: #545454;"  class="swal2-input" placeholder="${this.$t('nameCarrier')}" type="text" style="display: flex;"/>
          <input id="id_packlink" autocapitalize="off" style="color: #545454;"  class="swal2-input" placeholder="${this.$t('idPackLink')}" type="text" style="display: flex;"/>
          </div>`,

              preConfirm: () => {
                name = document.getElementById('name_packlink').value
                id = document.getElementById('id_packlink').value

                if (name === null || name === '' || name === undefined) {
                  this.$swal.showValidationMessage(
                    this.$t('errorIntroduceName'),
                  )
                  document.getElementById('name_packlink').classList.add('swal2-inputerror')
                } else {
                  document.getElementById('name_packlink').classList.remove('swal2-inputerror')

                  if (id === null || id === '' || id === undefined) {
                    this.$swal.showValidationMessage(
                      this.$t('errorIntroduceId'),
                    )
                    document.getElementById('id_packlink').classList.add('swal2-inputerror')
                  } else {
                    document.getElementById('id_packlink').classList.remove('swal2-inputerror')
                  }
                }
              },
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,

              showCancelButton: true,
              confirmButtonText: this.$t('dataGeneric.save'),
              cancelButtonText: this.$t('dataGeneric.cancel'),
              showLoaderOnConfirm: true,
            }).then(res => {
              if (res.isConfirmed) {
                const query = `mutation{
                  updateOrder(id:"${node.id}",input:{status:${status},shippingId:"${id}",shippingName:"${name}"}){
                    order{
                      id
                      status
                      
                    }
                  }
                }`
                axios
                  .post('', { query })
                  .then(r => {
                    messageError(r, this)

                    showToast(this.$t('success'), 1, this)
                    if (this.idFinalOrder) {
                      this.isFinal = true
                      this.finalOrders()
                    } else {
                      this.fetchData()
                    }
                  })
                  .catch(() => {
                    showToast(this.$t('error'), 2, this)
                  })
              }
            })
          } else {
            const query = `mutation{
                 updateOrder(id:"${node.id}",input:{status:${status}}){
                   order{
                     id
                     status
                     
                   }
                 }
               }`

            axios
              .post('', { query })
              .then(r => {
                messageError(r, this)

                showToast(this.$t('success'), 1, this)
                if (this.idFinalOrder) {
                  this.isFinal = true
                  this.finalOrders()
                } else {
                  this.fetchData()
                }
              })
              .catch(() => {
                showToast(this.$t('error'), 2, this)
              })
          }
        }
      }).catch(() => {

      })
    },
    showDropdown(node) {
      let show = false
      if (node.isSpecial && node.shippingCost === 0) {
        show = true
      }

      if (node.status === 'A_7') {
        show = true
      }
      if (node.status === 'A_10') {
        show = true
      }
      if (node.status === 'A_14') {
        show = true
      }

      return show
    },
    nextPage() {
      if (this.idFinalOrder) {
        this.isFinal = true
        if (this.vendor) this.getVendorOrder()
        else this.finalOrders()
      } else if (this.vendor) {
        this.isFinal = true
        this.getVendorOrder()
      } else {
        this.isFinal = false
        this.fetchData()
      }
    },
    vendorPaid(data) {
      if (isEmpty(data.vendor) === false) {
        return false
      }
      return true
    },
    ok() {

    },
    format(num) {
      if (num === null) return 0
      if (typeof num === 'string') return num

      const isDecimal = num % 1 !== 0
      const formattedNum = num.toLocaleString(undefined, { minimumFractionDigits: isDecimal ? 2 : 0, maximumFractionDigits: 2 })

      return formattedNum
    },
    getElement(id = null) {
      if (id != null) {
        this.$router.push({ name: 'subOrders', params: { idFinalOrder: id } })
      } else {
        this.$router.push({ name: 'orders' })
      }
    },
    clickRow(data) {
      const { id } = data[0].node
      this.myId = id
      if (id != null) {
        this.$router.push({ name: 'subOrders', params: { idFinalOrder: id } })
      } else {
        this.$router.push({ name: 'orders' })
      }
    },

    cleanUsername,
    fetchData() {
      this.isBusy = !this.isBusy
      const query = `
            {
                allOrder(
                        orderBy:"${this.orderBy}",
                        first:${this.perPage},
                        ${this.vendor != null ? `vendor:"${utf8ToB64(`id:${this.vendor}`)}"` : ''}
                        offset:${this.perPage * (this.currentPage - 1)},
                        orderId:"${this.search}",
                        parent_Isnull:true,
                        ${!isEmpty(this.filterStatus) ? `status:"${this.filterStatus.split('_')[1]}",` : ''}
                        ${!isEmpty(this.filterVendorPaid) ? `isVendorPaid:${this.filterVendorPaid}` : ''}
                        ${!isEmpty(this.filterIsSpecial) ? `isSpecial:${this.filterIsSpecial}` : ''}                        
                        ${this.filterDate !== '' ? `created:"${this.filterDate}"` : ''}
                        client: "${this.userData.profile.client.id}") {
                        totalCount
                        edges {
                        node {
                            id
                            isVendorPaid
                            totalAmount
                            totalRefunded
                            shippingCost
                            shippingId
                            chargeToken
                            status
                            orderId
                            isSpecial
                            updated
                            products {
                                edges {
                                node {
                                    id
                                    itemId
                                    itemName
                                    itemModel
                                    itemPrice
                                    itemBasePrice
                                    itemQuantity
                                    itemTaxId
                                    itemImage
                                    itemVariantId
                                    
                                }
                            }
                        }
                        client {
                          id
                          name
                        }
                        vendor {
                            id
                            vendorName
                            name
                            lastName
                            vendorImage
                            imageVendorUrl
                            
                        }
                        customer {
                        id
                        username
                        }
                        created
                        paymentDate
                        }
                    }
                    }
                }
            `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)

          this.allOrder = result.data.data.allOrder.edges
          this.rows = result.data.data.allOrder.totalCount

          this.orphan = true
          this.isBusy = !this.isBusy
        })
        .catch(err => { console.log(err) })
    },

    finalOrders() {
      this.isBusy = !this.isBusy
      axios
        .post('', {
          query: `{
                    allOrder(
                        id:"${this.idFinalOrder}"
                            orderBy:"${this.orderBy}",
                            first:${this.perPage},
                            orderId:"${this.search}",
                            ${this.vendor != null ? `vendor:"${utf8ToB64(`id:${this.vendor}`)}"` : ''}
                            offset:${this.perPage * (this.currentPage - 1)}, 
                            ${!isEmpty(this.filterStatus) ? `status:"${this.filterStatus.split('_')[1]}",` : ''}
                            ${!isEmpty(this.filterVendorPaid) ? `isVendorPaid:${this.filterVendorPaid}` : ''}                           
                            ${!isEmpty(this.filterIsSpecial) ? `isSpecial:${this.filterIsSpecial}` : ''}                        
                            client: "${this.userData.profile.client.id}") {
                            totalCount
                            edges {
                            node {
                                orderId
                                isSpecial
                                id
                                shippingCost
                                isVendorPaid
                                shippingId
                                status
                                totalAmount
                                totalRefunded
                                chargeToken
                                updated
                                created
                                paymentDate
                                childOrders(orderBy:"${this.orderBy}") {
                                    edges {
                                        node {  
                                            id
                                            orderId
                                            isSpecial
                                            isVendorPaid
                                            created
                                            paymentDate
                                            status
                                            shippingCost
                                            shippingId
                                            totalAmount
                                            totalRefunded
                                            chargeToken
                                            products { 
                                                edges {
                                                    node {
                                                        id
                                                        itemId
                                                        itemName
                                                        itemModel
                                                        itemPrice
                                                        itemBasePrice
                                                        itemQuantity
                                                        itemTaxId
                                                        itemImage
                                                        itemVariantId
                                                        
                                                    }
                                                }
                                            }
                                            vendor {
                                                id
                                                vendorName 
                                                }
                                            customer{
                                                username
                                                }
                                          orderVendorPay{
                                              edges{
                                                  node{
                                                      id
                                                  }
                                              }
                                          }
                                        }                                            
                                      }
                                    }
                                    orderVendorPay{
                                                edges{
                                                    node{
                                                        id
                                                    }
                                                }
                                            }
                                products {
                                    edges {
                                    node {
                                        id
                                        itemId
                                        itemName
                                        itemModel
                                        itemPrice
                                        itemBasePrice
                                        itemQuantity
                                        itemTaxId
                                        itemImage
                                        itemVariantId
                                        
                                        }
                                    }
                                }
                                client {
                                    id
                                    name
                                }
                                vendor {
                                    id
                                    vendorName                                                               
                                }
                                customer {
                                    id
                                    username
                                }
                                
                                }
                            }
                            }
                        }`,
        })
        .then(response => {
          messageError(response, this)

          this.rows = response.data.data.allOrder.totalCount
          if (this.rows !== 0) {
            const order = response.data.data.allOrder.edges[0].node
            this.brItems = [
              {
                id: null,
                text: this.$t('contents.start'),
              },
            ]
            this.navItems = [
              {
                id: null,
                text: this.$t('contents.start'),
              },
            ]
            this.brItems.push({
              text: order.orderId,
              id: order.id,
            })
            this.navItems = this.brItems.slice()
            this.childOrders = response.data.data.allOrder.edges[0].node.childOrders.edges
            const orderResponse = response.data.data.allOrder.edges

            if (this.childOrders.length === 0) {
              this.childOrders = orderResponse
              this.orphan = true
            } else {
              this.orphan = false
            }
          } else {
            this.childOrders = []
          }
          this.isBusy = !this.isBusy
        })
        .catch(err => {
          console.log(err)
          this.overlay = false
          this.isBusy = !this.isBusy
        })
    },
    getOrdersToPaid() {
      this.busyPaid = !this.busyPaid
      const query = `{
        allOrder(
          ${this.vendor ? `vendor:"${utf8ToB64(`id:${this.vendor}`)}",` : ''}
          first:${this.nPaid},
          offset:${this.nPaid * (this.pageList - 1)},
          isVendorPaid:false,
          status:"11"
          ) {
                  totalCount
                    edges {
                    node {                        
                              id
                              orderId
                              isSpecial
                              isVendorPaid
                              shippingCost
                              shippingId
                              status
                              totalAmount
                              totalRefunded
                              chargeToken
                              updated
                              created
                              paymentDate
                              client{
                                stripeSecretKey

                              }
                              customer{
                                username
                              }
                            vendor {
                                id
                                vendorName
                              }
                              orderVendorPay {
                                edges {
                                  node {
                                    id
                                  }
                                }
                              }
                            products { 
                                edges {
                                    node {
                                        id
                                        itemId
                                        itemName
                                        itemModel
                                        itemPrice
                                        itemBasePrice
                                        itemQuantity
                                        itemTaxId
                                        itemImage
                                        itemVariantId

                                    }
                                }
                            }
                            }
                        }
                        }
                    }
                    
               `
      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.totalPaid = result.data.data.allOrder.totalCount
          this.paidOrders = result.data.data.allOrder.edges
          this.feeDetails(this.paidOrders)
        }).catch(err => {
          console.log(err)
        })
    },
    getVendorOrder() {
      this.isBusy = !this.isBusy
      const query = `{
                allVendors(id:"${this.vendor}") {
                    edges {
                    node {
                        id
                        vendorName
                        cartVendor(orderBy:"${this.orderBy}",
                        first:${this.perPage},
                        offset:${this.perPage * (this.currentPage - 1)},
                        ${!this.orphan && this.idFinalOrder ? `parentOrder:"${utf8ToB64(`id:${this.idFinalOrder}`)}",` : ''}
                        ${!isEmpty(this.filterStatus) ? `status:"${this.filterStatus.split('_')[1]}",` : ''}
                        ${!isEmpty(this.filterVendorPaid) ? `isVendorPaid:${this.filterVendorPaid}` : ''}
                        ${!isEmpty(this.filterIsSpecial) ? `isSpecial:${this.filterIsSpecial}` : ''}
                        ){
                        totalCount
                        edges {
                            node {
                              id
                              orderId
                              isSpecial
                              isVendorPaid
                              shippingCost
                              shippingId
                              status
                              totalAmount
                              totalRefunded
                              chargeToken
                              updated
                              created
                              paymentDate
                              client{
                                stripeSecretKey

                              }
                              customer{
                                username
                              }
                            vendor {
                                id
                                vendorName
                              }
                              orderVendorPay {
                                edges {
                                  node {
                                    id
                                  }
                                }
                              }
                            products { 
                                edges {
                                    node {
                                        id
                                        itemId
                                        itemName
                                        itemModel
                                        itemPrice
                                        itemBasePrice
                                        itemQuantity
                                        itemTaxId
                                        itemImage
                                        itemVariantId

                                    }
                                }
                            }
                            }
                        }
                        }
                    }
                    }
                }
                }`
      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.childOrders = result.data.data.allVendors.edges[0].node.cartVendor.edges
          this.rows = result.data.data.allVendors.edges[0].node.cartVendor.totalCount

          this.isBusy = !this.isBusy
        }).catch(err => {
          console.log(err)
          this.isBusy = !this.isBusy
        })
    },
    getVendor() {
      const query = `{
            allVendors(client:"${this.userData.profile.client.id}",order:true,${this.idFinalOrder ? `parentOrder:"${this.idFinalOrder}"` : ''}) {
                totalCount
                edges {
                    node {
                        id
                        name
                        vendorName                        
                        cartVendor {
                        edges {
                        node {
                          id
                          orderId
                          isSpecial                            
                        }
                      }
                    }                    
                    client {
                        id
                        name
                    }
                  }
                }
              }
            }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.allVendors = result.data.data.allVendors.edges
        }).catch(err => {
          console.log(err)
        })
    },
    clickPickr() {
      document.getElementById('pickr').click()
    },

    createWithdrawal(vendor, paymentId, commission, amount, reason) {
      try {
        const client = this.userData.profile.client.id
        const creator = this.userData.id

        const data = new FormData()
        const config = {
          timeout: 1000 * 60 * 30,
        }

        const orders = []

        this.paidOrders.forEach(element => {
          orders.push(element.node.id)
        })
        let query = 'mutation($date: DateTime!,$reason: String){'
        query += `createWithdrawal(input:{order:[${orders}],vendor:"${vendor}",
                  amount:${amount},commission:${commission}, status:COM, reasonCommission:$reason,
                  withdrawalCreator:"${creator}",paymentId:"${paymentId}",client:"${client}",
                  date:$date}){
                    withdrawal{
                      id
                    }
                }`
        query += '}'
        const variables = {
          date: new Date(),
          reason,
        }

        data.append('variables', JSON.stringify(variables))
        data.append('query', query)

        axios
          .post('', data, config).then(result => {
            messageError(result, this)

            showToast(this.$t('success'), 1, this)
            if (this.idFinalOrder) {
              this.isFinal = true
              this.finalOrders()
            } else {
              this.fetchData()
            }
          }).catch(err => {
            console.log(err)
            showToast(this.$t('error'), 2, this)
            if (this.idFinalOrder) {
              this.isFinal = true
              this.finalOrders()
            } else {
              this.fetchData()
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    getPayMethod() {
      axios
        .post('', {
          query: `
              query{
                client(id:"${this.userData.profile.client.id}"){
                  externalPayMethod
                  currency
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.currency = res.data.data.client.currency
        })
        .catch(() => {
        })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    getImage(data) {
      let path = '@/assets/images/backend/nocover.jpeg'
      if (data.length !== 0) {
        data.forEach(element => {
          path = element.http_path
        })
      }
      return path
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl(folder) {
      return folder.imageVendorUrl == null || folder.imageVendorUrl.length === 0
        ? noCover
        : folder.imageVendorUrl
    },
    stripeWithdrawal() {
      this.$refs['paid-vendor'].hide()
      let vendor = null
      let amount = 0
      let error = false
      let products = '<ul class="scroll-list">'

      this.paidOrders.forEach(element => {
        if (!isEmpty(element.node.vendor)) {
          vendor = element.node.vendor.id
        } else {
          showToast(this.$t('errorPaymentOrderNotSeller'), 2, this)
          error = true
          return
        }
        amount += this.getTotalPrice(element.node)
        element.node.products.edges.forEach(prod => {
          products += `<ol>${prod.node.itemName} - ${prod.node.itemPrice} ${this.currencyType[this.currency]} x ${prod.node.itemQuantity}</ol><br/>`
        })
      })

      products += '</ul>'
      const showAmount = amount.toFixed(2)
      let commission = 0
      let reason = ''
      if (!error) {
        this.$swal({
          title: this.$t('paidVe'),
          html: `<h3>${this.$t('surepaymentvend')} <b>${this.format(showAmount)}</b> ${this.currencyType[this.currency]}?</h3><br/>${products
          }<div >
          <input id="commission" autocapitalize="off" step="0.01" class="swal2-input" placeholder="${this.$t('applyCommision')}" type="number" style="display: flex;"/>
          <textarea id="reason_commission" class="swal2-textarea" placeholder="${this.$t('reasonCommision')}" style="display: block;"></textarea>
          </div>`,
          icon: 'warning',
          width: '800px',
          didOpen: () => {
            const input = document.getElementById('commission')

            input.oninput = () => {
              const b = this.$swal.getHtmlContainer().querySelector('b')
              commission = parseFloat(input.value)
              let suma = 0
              if (commission > showAmount) {
                suma = 0
              }
              suma = (showAmount - (commission >= 0 ? commission : 0)).toFixed(2)
              b.textContent = suma
              amount = suma
            }
          },
          preConfirm: result => {
            reason = document.getElementById('reason_commission').value
            if (parseFloat(result) < 0) {
              this.$swal.showValidationMessage(
                this.$t('errorMay0'),
              )
            }
            const b = this.$swal.getHtmlContainer().querySelector('b')
            if (parseFloat(b.textContent) <= 0) {
              this.$swal.showValidationMessage(
                this.$t('errorImporte'),
              )
            }
          },
          showCancelButton: true,
          confirmButtonText: this.$t('yNext'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          // cancelButtonText: "Indicar url",
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            const query = `{
                 withdrawalStripePaid(vendor:"${vendor}",totalAmount:"${amount * 100}") {
                     status
                 }
             }`
            axios
              .post('', {
                query,
              }).then(r => {
                messageError(r, this)

                const paymentID = JSON.parse(r.data.data.withdrawalStripePaid.status)
                if (paymentID) {
                  let query2 = 'mutation{'
                  this.paidOrders.forEach((element, i) => {
                    query2 += `
                                     w${i + 1}: updateOrder(id:"${element.node.id}",input:{isVendorPaid:true}) {
                                         order{
                                         id
                                         status
                                         }
                                     }
                                 `
                  })
                  query2 += '}'
                  axios
                    .post('', {
                      query: query2,
                    }).then(res => {
                      messageError(res, this)

                      this.createWithdrawal(vendor, paymentID.id, commission, amount, reason)
                    }).catch(err => {
                      console.log(err)
                      this.createWithdrawal(vendor, paymentID.id, commission, amount, reason)
                    })
                } else showToast(this.$t('somethingWrong'), 2, this)
              }).catch(err => {
                console.log(err)
              })
          }
        }).catch(() => {

        })
      }
    },
    specialOrder(node, next) {
      if (next) {
        this.$swal({
          title: this.$t('indicateShippingCosts'),
          input: 'number',
          html: this.$t('orderTotalCost', { totalAmount: node.totalAmount, currencyType: this.currencyType[this.currency] }),
          inputPlaceholder: this.$t('shippingCosts'),
          didOpen: () => {
            const input = this.$swal.getInput()
            input.oninput = () => {
              const b = this.$swal.getHtmlContainer().querySelector('b')
              const val = parseFloat(input.value)
              const suma = node.totalAmount + (val >= 0 ? val : 0)
              b.textContent = suma
            }
          },
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: 'off',
            step: '0.01',
          },

          preConfirm: result => {
            if (result === null || result === '' || result === undefined) {
              this.$swal.showValidationMessage(
                this.$t('mustEnterAmount'),
              )
            } else if (parseFloat(result) <= 0) {
              this.$swal.showValidationMessage(
                this.$t('mustEnterAmountGreaterZero'),
              )
            }
          },
          showCancelButton: true,
          confirmButtonText: this.$t('dataGeneric.save'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.isConfirmed) {
            const query = `mutation{
                 updateOrder(id:"${node.id}",input:{status:A_5,shippingCost:${result.value},totalAmount:${parseFloat(result.value) + parseFloat(node.totalAmount)}}){
                   order{
                     id
                     status
                     
                   }
                 }
               }`
            axios
              .post('', { query })
              .then(r => {
                messageError(r, this)

                showToast(this.$t('success'), 1, this)
                if (this.idFinalOrder) {
                  this.isFinal = true
                  this.finalOrders()
                } else {
                  this.fetchData()
                }
              })
              .catch(() => {
                showToast(this.$t('error'), 2, this)
              })
          }
        })
      } else {
        this.$swal({
          title: this.$t('code.title'),
          text: this.$t('continueCancelOrder'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('yNext'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            const query = `mutation{
                updateOrder(id:"${node.id}",input:{status:A_6,isSpecial:false}){
                  order{
                    id
                    status
                    
                  }
                }
              }`
            axios
              .post('', { query })
              .then(r => {
                messageError(r, this)

                showToast(this.$t('cancelledOrder'), 1, this)
                if (this.idFinalOrder) {
                  this.isFinal = true
                  this.finalOrders()
                } else {
                  this.fetchData()
                }
              })
              .catch(() => {
                showToast(this.$t('error'), 2, this)
              })
          }
        }).catch(() => {

        })
      }
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#orders .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#orders .cardContent img {
  height: 11vw;
  object-fit: cover;
  /*width: 100%;
        height:  100%;
        object-fit: cover;*/
}

#orders .card-body {
  padding: 1rem;
}

#orders .card-body h4 {
  font-size: 1rem !important;
}

#orders .card-header {
  padding: 1rem;
}

#orders .card-header h4 {
  font-size: 1rem !important;
}

#orders .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;

  justify-content: space-between;
}

#orders #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#orders #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#orders .swal-wide {
  width: 850px !important;
}

.scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  // max-height: 150px;
}

#orders .searchCategories {
  width: 130px;
  box-sizing: border-box;
  font-size: 18px;
  background-image: url('~@/assets/images/icons/searchIcon.png');
  background-position: 10px 8px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

#orders .searchCategories:active {
  cursor: pointer;
}

#orders .searching {
  width: 100%;

}
</style>
