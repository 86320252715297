<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
      <h1 v-if="!superUser">
        {{ displayName }}
      </h1>
      <b-button v-else variant="primary" @click="login()">
        <div class="d-flex align-items-center">
          <b>
            {{ displayName }}
          </b>
          <feather-icon icon="ChevronDownIcon" class="ml-50" size="15" />
        </div>
      </b-button>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- IDIOMAS -->
      <!-- <locale /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <!-- <feather-icon class="cursor-pointer" icon="MonitorIcon" size="21" @click="preview()" /> -->
      <user-dropdown />
    </b-navbar-nav>
    <b-modal id="modal-xl" ref="my-modal" hide-footer size="lg" :title="$t('selectPlatf')">
      <div class="d-block text-center">
        <platform-selector @client="loginPlatform" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton, BModal,
} from 'bootstrap-vue'
import PlatformSelector from '@/views/common/PlatformSelector.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData, setUserData } from '@/auth/utils'
import { mapState } from 'vuex'
import UserDropdown from './components/UserDropdown.vue'
import locale from './components/Locale.vue'
import { isEmpty } from '@/store/functions'
export default {
  components: {
    BLink,
    BButton,
    locale,
    // Navbar Components
    BNavbarNav,
    BModal,
    PlatformSelector,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      name: null,
      displayName: null,
      firstName: null,
      superUser: false,
    }
  },
  computed: {
    ...mapState('verticalMenu', ['changeName']),
  },
  watch: {
    changeName() {
      this.displayName = this.changeName
    },
  },

  mounted() {
    this.displayName = this.changeName
    this.getGeneralData()
  },

  methods: {
    preview() {
      const url = `${window.location.protocol
        }//${window.location.hostname
        }/preview/browser/${this.name
        }/`
      window.open(url, '_blank')
    },
    login() {
      this.$refs['my-modal'].show()
    },

    loginPlatform(data) {
      this.$refs['my-modal'].hide()

      const dataSaved = getUserData()
      const ability = {
        ability: [
          {
            action: 'manage',
            subject: 'all',
          },
        ],
      }
      if (dataSaved.profile) {
        dataSaved.profile.client = data
      }
      const userData = { ...dataSaved, ...ability }
      setUserData(userData)
      let name = ""

      name = userData?.profile?.client?.name
      this.$ability.update(userData.ability)
      this.getGeneralData()
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `Cambio de plataforma`,
          icon: 'CoffeeIcon',
          variant: 'success',
          text: `Se ha cambiado a la plataforma ${name}`,
        },
      })

      if (this.$route.name === 'dashboard') {
        this.$router.go()
      } else {
        this.$router.push({ name: 'dashboard' })
      }

    },
    getGeneralData() {
      try {
        const userData = getUserData()
        this.superUser = userData.isSuperuser
        this.name = userData?.profile?.client?.name
        this.displayName=!isEmpty(userData.profile.client.displayName)?userData.profile.client.displayName:userData.profile.client.name
      } catch (error) {
        this.$router.push({ name: 'auth-login' })
      }
    },
  },
}
</script>
<style lang="scss">
.main-menu.menu-light .navigation>li.sidebar-group-active>a {
  background: transparent !important;
  border-radius: 6px;
}
</style>
