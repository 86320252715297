// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/no-cycle
import { utf8ToB64, b64ToUtf8 } from '@/store/functions'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style

export const getUserData = () => {
  try {
    return localStorage.getItem('userData') ? JSON.parse(b64ToUtf8(localStorage.getItem('userData'))) : null
  } catch (error) {
    // console.log(error)
  }
  return null
}

export const setUserData = userData => localStorage.setItem('userData', utf8ToB64(JSON.stringify(userData)))
export const clientNode = () => `
                        id
                        clientId
                        name
                        type
                        displayName
                        bucketType
                        liveType
                        hasProfiles
                        logo
                        isElearningPlatform
                        hasMyChannel
                        hasReels
                        isPromotionalCodes
                        createdAt
                        isInteractivity
                        isShop
                        hasAdSlides
                        hasAdCuePoints
                        isBuy
                        isFavorite
                        isSecondScreenInteractivity
                        isPushInteractivity
                        isBlockInteractivity
                        isCountlyActive
                        isFastChannel
                        isVirtualUser
                        isClose
                        isDownload
                        isParentalControl
                        isAds
                        isLinkCategories
                        isLive
                        isTranscoding
                        webUrl
                        currency
                        defaultLanguage
                        geoipbloking
                        hasDisplayAds
                        hasSubgroups
                        languageSegmentation
                        isHbbtv
                        hasLiveEvents
                        `

export const isUserLoggedIn = () => getUserData() && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}
