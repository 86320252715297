<template>
  <section id="ads-edit">
    <b-row class="mb-5">
      <b-col md="11">
        <h1>{{ $t("settingTabs.ads") }}</h1>
        <h5 class="text-primary">
          {{ $t("advertising.EditAd") }}
        </h5>
      </b-col>
      <b-row class="d-flex justify-content-end">
        <b-col>
          <b-button
            variant="danger"
            @click="routeToEditView()"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </b-col>
      </b-row>
    </b-row>

    <b-tabs
      v-if="mostrarDatos"
      pills
    >
      <!-- Resource -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>{{ $t("editContent.title2") }}</span>
        </template>
        <b-row>
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showDatos"
            >
              <b-card>
                <b-card-header class="head">
                  <b-card-title>{{ $t("code.data") }}</b-card-title>
                  <h6 class="text-primary ">
                    {{ $t('adsInfo') }}
                  </h6>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col class="ml-2 mb-2">
                      <b-form @submit.prevent="updateDatos">
                        <!-- Title -->
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('dataGeneric.name')">
                              <b-form-input
                                v-model="name"
                                autocomplete="new-password"
                                :placeholder="$t('dataGeneric.name')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              :label="$t('code.momento')"
                              label-for="type"
                            >
                              <b-form-select
                                id="type"
                                v-model="type"
                                :options="typeOptions"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('code.activeAds')">
                              <b-form-checkbox
                                v-model="isActive"
                                :disabled="disabled"
                                switch
                              >
                                <b-badge
                                  v-if="disabled"
                                  class="badge-light-warning "
                                >
                                  <h6 class="text-warning mb-0 ">
                                    {{ $t('activateCamp') }}
                                  </h6>
                                </b-badge>
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('viewAds')">
                              <h6 class="text-muted mb-0 ">
                                {{ $t('vis', { nDisplay: nDisplay }) }}
                              </h6>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('dataGeneric.description')">
                              <b-form-textarea
                                id="textarea"
                                v-model="description"
                                :placeholder="$t('dataGeneric.description')"
                                rows="3"
                                max-rows="6"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('code.impresiones')">
                              <b-form-input
                                v-model="prints"
                                type="number"
                                :min="0"
                                autocomplete="new-password"
                                :placeholder="$t('code.nVis')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('code.seg')">
                              <b-form-input
                                v-model="timeout"
                                type="number"
                                :min="0"
                                autocomplete="new-password"
                                :placeholder="$t('code.seg2')"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-col>
                          <b-button
                            type="submit"
                            variant="success"
                          >
                            {{
                              $t("dataGeneric.save")
                            }}
                          </b-button>
                        </b-col>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="TvIcon" />
          <span>{{ $t('code.content') }}</span>
        </template>
        <b-row>
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showContent"
            >
              <b-card>
                <b-card-header class="head">
                  <b-card-title>{{ $t("code.content") }}</b-card-title>
                  <h6 class="text-primary ">
                    {{ $t('code.content') }}
                  </h6>
                </b-card-header>
                <b-card-body>
                  <b-row class="infoContent mb-4">
                    <b-col>
                      <b-form @submit.prevent="updateContent">
                        <div class="m-2 mb-sm-2">
                          <b-form-group :label="$t('dataGeneric.name')">
                            <b-row @click="showModal()">
                              <b-col md="10">
                                <b-form-input
                                  v-model="contentName"
                                  autocomplete="new-password"
                                  :placeholder="$t('content')"
                                  disabled
                                />
                              </b-col>
                              <b-col>
                                <b-button @click="showModal()">
                                  {{
                                    $t("content")
                                  }}
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form-group>
                          <div v-if="contentName">
                            <video
                              id="demo"
                              :key="contentId"
                              ref="video"
                              width="500"
                              height="305"
                              controls
                              :poster="videotype == 'AUR'
                                ? require('@/assets/images/icons/audio.svg')
                                : ''
                              "
                              class="
                                                    video-js
                                                    vjs-default-skin vjs-big-play-centered
                                                    tamanio
                                                  "
                              data-setup="{&quot;liveui&quot;: true}"
                              @loadeddata="videoMaker()"
                              @loadstart="videoMaker()"
                            >
                              <source
                                v-if="directUrl != '' && directUrl != null"
                                :src="directUrl"
                                :type="videotype == 'AUR'
                                  ? 'audio/mp3'
                                  : 'application/x-mpegURL'
                                "
                              >
                              <source
                                v-if="bucketUrl != '' && bucketUrl != null"
                                :src="bucketUrl"
                                :type="videotype == 'AUR' ? 'audio/mp3' : 'video/mp4'
                                "
                              >
                            </video>
                          </div>
                        </div>
                        <b-col>
                          <b-button
                            type="submit"
                            variant="success"
                          >
                            {{
                              $t("dataGeneric.save")
                            }}
                          </b-button>
                        </b-col>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>{{ $t('code.C&C') }}</span>
        </template>
        <!-- CATEGORÍAS -->
        <b-row>
          <b-col>
            <b-overlay
              no-center
              :show="contentSelected.length > 0"
              variant="transparent"
              rounded="sm"
            >
              <template #overlay>
                <b-icon
                  icon="stopwatch"
                  variant="info"
                  scale="2"
                  shift-v="8"
                  shift-h="8"
                  class="position-absolute"
                  style="top: 0; right: 0"
                />
              </template>
              <b-card>
                <b-card-header class="head d-flex flex-row">
                  <div>
                    <b-card-title>{{ $t('Category') }}</b-card-title>
                    <h6 class="text-primary ">
                      {{ $t("code.categ") }}
                    </h6>
                  </div>

                  <h3>
                    <b-badge
                      v-if="requiredField"
                      variant="danger"
                    >
                      <feather-icon
                        icon="AlertOctagonIcon"
                        size="50"
                        style="vertical-align: baseline;"
                      />
                      {{ $t('needAdd') }}
                    </b-badge>
                  </h3>
                </b-card-header>
                <b-card-body>
                  <b-form @submit.prevent>
                    <b-row class="mb-2 mt-2">
                      <!-- people group 1 -->
                      <b-col md="6">
                        <b-row>
                          <b-col
                            md="5"
                            class="d-flex align-items-center"
                          >
                            <h6 class="text-primary font-weight-bold mb-0 ml-2 ">
                              {{ $t("code.allC") }}
                            </h6>
                            <feather-icon
                              v-show="resCategories.length > 0"
                              icon="PlusCircleIcon"
                              size="20"
                              class="text-success cursor-pointer ml-1"
                              @click="AddCat()"
                            />
                          </b-col>
                          <b-col md="5">
                            <b-form-input
                              v-model="searchCategories"
                              :placeholder="$t('slider.searchThreeCaracCat')"
                              @input="getCategoriesThreeCharact"
                            />
                          </b-col>
                        </b-row>

                        <b-form-select
                          v-model="checkResults"
                          tag="ul"
                          multiple
                          :select-size="5"
                          class="scroll-list list-group list-group-flush  mt-2 border-0"
                        >
                          <b-form-select-option
                            v-for="c in resCategories"
                            :key="c.node.id"
                            tag="li"
                            :value="c"
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div class="ml-25 ">
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ c.node.name }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                          <b-form-select-option
                            v-if="resCategories.length == 0"
                            :value="null"
                            disabled
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div
                                v-if="searchCategories.length <= 2"
                                class="ml-25 "
                              >
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ threeCharSearch }}
                                </b-card-text>
                              </div>
                              <div
                                v-if="searchCategories.length >= 3"
                                class="text-left mt-2 ml-2"
                              >
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ $t('subscriptions.notFound') }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <!--categorías restringidas -->
                      <b-col md="6">
                        <b-row>
                          <b-col
                            md="4"
                            class="d-flex align-items-center"
                          >
                            <h6 class="text-primary font-weight-bold mb-0 mt-0">
                              {{ $t('subscriptions.selectedCategory') }}
                            </h6>
                            <feather-icon
                              v-show="resultCategorySelected.length > 0"
                              icon="XCircleIcon"
                              size="20"
                              class="text-danger cursor-pointer ml-1"
                              @click="QuitarCat()"
                            />
                          </b-col>
                          <b-col md="5">
                            <b-form-input
                              v-model="searchCatSelected"
                              :placeholder="$t('slider.searchThreeCaracCat')"
                              @input="getCategoriesSelected"
                            />
                          </b-col>
                        </b-row>

                        <b-form-select
                          v-model="checkRemove"
                          tag="ul"
                          multiple
                          :select-size="5"
                          class="scroll-list list-group list-group-flush mt-2 border-0"
                        >
                          <b-form-select-option
                            v-for="catSus in resultCategorySelected"
                            :key="catSus.node.id"
                            tag="li"
                            :value="catSus"
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div class="ml-25 ">
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ catSus.node.name }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
        <!-- CONTENIDOS -->
        <b-row>
          <b-col>
            <b-card>
              <b-overlay
                no-center
                :show="categorySelected.length > 0"
                variant="transparent"
                rounded="sm"
              >
                <template #overlay>
                  <b-icon
                    icon="stopwatch"
                    variant="info"
                    scale="2"
                    shift-v="8"
                    shift-h="8"
                    class="position-absolute"
                    style="top: 0; right: 0"
                  />
                </template>
                <b-card-header class="head">
                  <b-card-title>{{ $t('Contents') }}</b-card-title>
                  <h6 class="text-primary ">
                    {{ $t("code.contCatg") }}
                  </h6>
                </b-card-header>
                <b-card-body>
                  <b-form @submit.prevent>
                    <b-row class="m-2">
                      <!-- people group 1 -->
                      <b-col md="6">
                        <b-row>
                          <b-col md="5">
                            <h6 class="text-primary font-weight-bold mb-2">
                              {{ $t("code.allCon") }}
                              <feather-icon
                                v-show="resContents.length > 0"
                                icon="PlusCircleIcon"
                                size="20"
                                class="text-success cursor-pointer ml-1"
                                @click="AddCont()"
                              />
                            </h6>
                          </b-col>
                          <b-col md="5">
                            <b-form-input
                              v-model="searchContents"
                              minlength="3"
                              :placeholder="$t('slider.searchThreeCaracContent')"
                              @input="getContentsThreeCharact"
                            />
                          </b-col>
                        </b-row>

                        <b-form-select
                          v-model="checkContResults"
                          tag="ul"
                          multiple
                          :select-size="5"
                          class="scroll-list list-group list-group-flush  mt-2 border-0"
                        >
                          <b-form-select-option
                            v-for="c in resContents"
                            :key="c.node.id"
                            tag="li"
                            :value="c"
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div class="ml-25 ">
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ c.node.name }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                          <b-form-select-option
                            v-if="resContents.length == 0"
                            :value="null"
                            disabled
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div
                                v-if="searchContents.length <= 2"
                                class="ml-25 "
                              >
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ threeCharSearch }}
                                </b-card-text>
                              </div>
                              <div
                                v-if="searchContents.length >= 3"
                                class="text-left mt-2 ml-2"
                              >
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ $t('subscriptions.notFound') }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <!-- people group 2 -->
                      <b-col md="6">
                        <b-row>
                          <b-col
                            md="5"
                            class="d-flex align-items-center"
                          >
                            <h6 class="text-primary font-weight-bold mb-0 mt-0">
                              {{ $t('selcCont') }}
                            </h6>
                            <feather-icon
                              v-show="resultContentsSelected.length > 0"
                              icon="XCircleIcon"
                              size="20"
                              class="text-danger cursor-pointer ml-1"
                              @click="QuitarCont()"
                            />
                          </b-col>
                          <b-col md="5">
                            <b-form-input
                              v-model="searchContSelected"
                              :placeholder="$t('slider.searchThreeCaracContent')"
                              @input="getContentsSelected"
                            />
                          </b-col>
                        </b-row>
                        <b-form-select
                          v-model="checkContRemove"
                          tag="ul"
                          multiple
                          :select-size="5"
                          class="scroll-list list-group list-group-flush mt-2 border-0"
                        >
                          <b-form-select-option
                            v-for="cont in resultContentsSelected"
                            :key="cont.node.id"
                            tag="li"
                            :value="cont"
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div class="ml-25 ">
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ cont.node.name }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-overlay>
              <b-button
                class="m-1"
                variant="success"
                @click="update"
              >
                {{
                  $t("dataGeneric.save")
                }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.content')"
    >
      <div class="d-block text-center">
        <content-selector
          :sponsor="isSponsor"
          @data="SelectContentId"
          @url="SelectcontentName"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import {
  BFormTextarea,
  BButton,
  BRow,
  BBadge,
  BIcon,
  BCol,
  BFormSelectOption,
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BTabs,
  BTab,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  isUserInGroup, messageError, showToast, utf8ToB64,
} from '@/store/functions'

import { getUserData } from '@/auth/utils'

import * as constants from '@core/utils/constants'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import ContentSelector from '@/views/common/ContentSelector.vue'

export default {
  components: {
    BFormTextarea,
    BFormSelectOption,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    ContentSelector,
    BIcon,
    BFormSelect,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      video: null,
      ads: null,
      name: null,
      description: null,
      type: 'PRE',
      campaing: null,
      content: null,
      categories: [],
      isSponsor: false,
      contents: [],
      prints: null,
      timeout: null,
      isActive: null,
      nDisplay: 0,
      showDatos: false,
      showContent: false,
      contentName: null,
      contentId: null,
      typeOptions: [
        { value: 'PRE', text: 'Preroll' },
        { value: 'MID', text: 'Midroll' },
        { value: 'POS', text: 'Postroll' },
      ],
      videotype: null,
      directUrl: null,
      mostrarDatos: false,
      disabled: false,
      file: null,
      bucketUrl: null,
      userData: getUserData(),

      categorySelected: [],
      contentSelected: [],
      allCats: [],
      allConts: [],
      checkResults: [],
      checkRemove: [],
      checkContResults: [],
      checkContRemove: [],
      resultContentsSelected: [],
      resultCategorySelected: [],
      resCategories: [],
      resContents: [],
      threeCharSearch: this.$t('slider.searchThreeCaracDefault'),
      showContents: false,
      showCategories: false,
      searchCategories: '',
      searchContents: '',
      searchContSelected: '',
      searchCatSelected: '',
      requiredField: false,
    }
  },
  watch: {
    requiredField() {
      if (!this.isSponsor) {
        this.requiredField = false
      }
    },
    resCategories() {
      if (this.resCategories.length !== 0) {
        if (this.searchCategories.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    resContents() {
      if (this.resContents.length !== 0) {
        if (this.searchContents.length <= 2) {
          this.resContents = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracContent')
        }
      }
    },
    searchContents(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.searchContents.length <= 2) {
          this.resContents = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracContent')
        }
      }
    },
    searchCategories(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.searchCategories.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    categorySelected() {
      if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
        this.requiredField = true
      } else {
        this.requiredField = false
      }
    },
    contentSelected() {
      if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
        this.requiredField = true
      } else {
        this.requiredField = false
      }
    },
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    this.getData()
  },
  methods: {
    update() {
      if (this.isSponsor && this.contentSelected.length === 0 && this.categorySelected.length === 0) {
        showToast(this.$t('isReq'), 0, this)
      } else {
        this.updateContents()
        this.updateCategories()
      }
    },
    getData() {
      const { id } = this.$route.params
      axios
        .post('', {
          query: `
           query{
            allAds(id:"${id}",client:"${this.userData.profile.client.id}"){
              edges {
                node {
                  id
                  creator {
                    id
                  }
                  name
                  description
                  type                  
                  campaing{
                    name
                  }
                  content {
                    id
                    name
                    resources{
                      edges {
                        node {
                          videotype
                          directUrl
                          bucketUrl
                          file {                           
                            media
                          }
                        }
                      }
                    }
                  }
                  categories${this.isSponsor ? `(filter_Sponsor:"${this.userData.id}")` : ''}{
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }                  
                  contents ${this.isSponsor ? `(creator:"${utf8ToB64(`id:${this.userData.id}`)}")` : ''}{
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  prints
                  nDisplay
                  timeout
                  isActive                  
                }
              }
            }              
          }          
        `,
        })
        .then(result => {
          messageError(result, this)

          try {
            this.ads = result.data.data.allAds.edges[0].node
            // Sponsor role can access every content, but cannot delete it. Uncomment it to restrict access again
            /* let authorized = false
            if (this.isSponsor) {
              if (this.ads.creator !== null) {
                if (this.ads.creator.id === this.userData.id) authorized = true
              }
              if (!authorized) {
                this.$router.push({ name: 'misc-not-authorized' })
              }
            } */
            this.name = this.ads.name
            this.description = this.ads.description
            this.nDisplay = this.ads.nDisplay
            this.type = this.ads.type
            this.campaing = this.ads.campaing
            this.content = this.ads.content
            if (this.content) this.contentName = this.content.name
            if (this.contentName !== null) this.getContentId(this.content.id)
            this.categorySelected = this.ads.categories.edges
            this.contentSelected = this.ads.contents.edges
            if (this.contentSelected.length === 0) this.contentSelected = []
            if (this.categorySelected.length === 0) this.categorySelected = []
            this.getCategoriesSelected()
            this.getContentsSelected()

            if (this.isSponsor && this.contentSelected.length === 0 && this.categorySelected.length === 0) {
              this.disabled = true
            } else {
              this.disabled = false
            }
            this.prints = this.ads.prints
            this.timeout = this.ads.timeout
            this.isActive = this.ads.isActive
            // Sponsor role can access every content, but cannot delete it. Uncomment it to restrict access again
            /* if (!authorized && this.isSponsor) this.mostrarDatos = false
            else */ this.mostrarDatos = true
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => { })
    },
    routeToEditView() {
      window.history.back()
    },
    getContentId(id) {
      axios
        .post('', {
          query: `
            query{
              allContents(id:"${id}",client:"${this.userData.profile.client.id}"){
                edges {
                  node {
                    id
                  contentResources(isInitial:true){
                    edges {
                      node {
                        id
                        resource{
                          directUrl
                          videotype
                          bucketUrl
                          file{
                            media
                            fileUrl
                            
                          }
                        }
                      }
                    }
                  }
                  }
                }
              }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          try {
            const response = result.data.data.allContents.edges[0].node
            this.contentId = result.data.data.allContents.edges[0].node.id
            this.videotype = response.contentResources.edges[0].node.resource.videotype
            this.directUrl = response.contentResources.edges[0].node.resource.directUrl
            this.file = response.contentResources.edges[0].node.resource.file
            this.bucketUrl = response.contentResources.edges[0].node.resource.bucketUrl
            this.$refs.video.load()
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => { })
    },
    SelectContentId(data) {
      this.contentId = data
      this.getContentId(data)
      this.hideModal()
    },
    SelectcontentName(data) {
      this.contentName = data
      this.hideModal()
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    updateDatos() {
      const { id } = this.$route.params
      this.showDatos = true

      axios
        .post('', {
          query: `
          mutation{
            updateAds(id:"${id}",input:{
              name:"${this.name}",            
              description:"${this.description}",
              isActive:${this.isActive},
              prints:${this.prints},              
              timeout:${this.timeout},
              type:${this.type},
            }){
              ad
              {                      
                name
                client{
                  id
                  name
                }
                
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.showDatos = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
          this.showDatos = false
        })
    },
    getContents() {
      this.showContents = true
      axios
        .post('', {
          query: `
           query{
            contentsUnlimited(client:"${this.userData.profile.client.id}",name:"${this.searchContents}"
            ${this.isSponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}
            ){
                edges {
                    node {
                    id
                    name                    
                    }
                }
            }                
        }   
        `,
        })
        .then(response => {
          messageError(response, this)

          const arrayTotal = response.data.data.contentsUnlimited.edges
          this.contents = response.data.data.contentsUnlimited.edges
          const arrayselec = []

          for (let i = 0; i < arrayTotal.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.contentSelected.length; j += 1) {
              if (this.contentSelected[j].node.id === arrayTotal[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              arrayselec.push(arrayTotal[i])
            }
          }
          this.resContents = arrayselec.slice()
          this.allConts = arrayselec.slice()
          this.showContents = false
        })
    },
    getCategories() {
      this.showCategories = true

      axios
        .post('', {
          query: `
           query{
            allCategories(client:"${this.userData.profile.client.id}",name:"${this.searchCategories}"
            ${this.isSponsor ? `,filter_Sponsor:"${this.userData.id}"` : ''} 
            ){
                edges {
                    node {
                    id
                    name                    
                    }
                }
            }                
        }   
        `,
        })
        .then(response => {
          messageError(response, this)

          const arr2 = response.data.data.allCategories.edges
          const array = []
          for (let i = 0; i < arr2.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.categorySelected.length; j += 1) {
              if (this.categorySelected[j].node.id === arr2[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              array.push(arr2[i])
            }
          }
          this.categories = arr2.slice()

          this.resCategories = array.slice()
          this.allCats = array.slice()

          this.emptySearchCat = this.$t('resultNotFound')
          this.showCategories = false
        })
    },
    existe(id, selected) {
      let bool = false
      for (let j = 0; j < selected.length; j += 1) {
        if (selected[j].node.id === id) {
          bool = true
        }
      }
      return bool
    },
    updateArrayData(array, type) {
      const { id } = this.$route.params

      axios
        .post('', {
          query: `
          mutation{
            updateAds(id:"${id}",input:{
              ${type === 0 ? `categories: [${array}],` : `contents: [${array}],`}                  
                   
            }){
              ad
              {
                name
                categories{
                  edges{
                    node{
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          if (this.isSponsor && this.contentSelected.length === 0 && this.categorySelected.length === 0) {
            this.disabled = true
          } else {
            this.disabled = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    updateCategories() {
      const { id } = this.$route.params
      this.showCategories = true

      axios
        .post('', {
          query: `
          mutation{
            updateAds(id:"${id}",input:{
            categories: [], 
              client:"${this.userData.profile.client.id}",
            }){
              ad
              {                                 
                name
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.updateArrayData(this.getIdArray(this.categorySelected), 0)
          showToast(this.$t('code.updateData'), 1, this)
          this.showCategories = false
        })
        .catch(err => {
          console.log(err)

          showToast(this.$t('code.updateDataError'), 0, this)
          this.showCategories = false
        })
    },
    updateContents() {
      const { id } = this.$route.params
      this.showContents = true
      axios
        .post('', {
          query: `
          mutation{
            updateAds(id:"${id}",input:{
              contents: [],     
            }){
              ad
              {                      
                name
                client{
                  id
                  name
                }
                
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.updateArrayData(this.getIdArray(this.contentSelected), 1)

          showToast(this.$t('code.updateData'), 1, this)
          this.showContents = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
          this.showContents = false
        })
    },
    updateContent() {
      const { id } = this.$route.params
      this.showContent = true
      axios
        .post('', {
          query: `
          mutation{
            updateAds(id:"${id}",input:{
              content:"${this.contentId}",     
            }){
              ad
              {                      
                name
                client{
                  id
                  name
                }
                
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.showContent = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
          this.showContent = false
        })
    },
    QuitarCont() {
      if (this.checkContRemove.length !== 0) {
        this.allConts = [...this.checkContRemove, ...this.allConts]
        this.checkContRemove.forEach(element => {
          const idx = this.contentSelected.indexOf(element)
          if (idx !== -1) {
            this.contentSelected.splice(idx, 1)
          }
        })
        this.checkContRemove = []
        this.searchContentsByName()
        this.getContentsSelected()
      } else {
        this.allConts = [...this.resultContentsSelected, ...this.allConts]

        this.resultContentsSelected.forEach(element => {
          const idx = this.contentSelected.indexOf(element)
          if (idx !== -1) {
            this.contentSelected.splice(idx, 1)
          }
        })
        this.resultContentsSelected = []
        this.searchContentsByName()
      }
    },
    getContentsSelected() {
      this.resultContentsSelected = this.contentSelected.filter(item => item.node.name.toLowerCase().includes(this.searchContSelected.toLowerCase()))
    },
    QuitarCat() {
      if (this.checkRemove.length !== 0) {
        this.allCats = [...this.checkRemove, ...this.allCats]
        this.checkRemove.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.checkRemove = []
        this.searchByName()
        this.getCategoriesSelected()
      } else {
        this.allCats = [...this.resultCategorySelected, ...this.allCats]

        this.resultCategorySelected.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.resultCategorySelected = []
        this.searchByName()
      }
    },
    AddCat() {
      if (this.checkResults.length !== 0) {
        this.categorySelected = [...this.categorySelected, ...this.checkResults]
        this.getCategoriesSelected()
        this.checkResults.forEach(element => {
          // document.getElementById(element.node.id + '_res').classList.toggle("active");
          const idx = this.resCategories.indexOf(element)
          if (idx !== -1) {
            this.resCategories.splice(idx, 1)
          }
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })
        this.checkResults = []
        this.searchByName()
      } else {
        this.categorySelected = [...this.categorySelected, ...this.resCategories]
        this.getCategoriesSelected()

        this.resCategories.forEach(element => {
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })

        this.resCategories = []

        this.searchByName()
      }
    },
    getCategoriesSelected() {
      this.resultCategorySelected = this.categorySelected.filter(item => item.node.name.toLowerCase().includes(this.searchCatSelected.toLowerCase()))
    },
    getCategoriesThreeCharact() {
      if (this.searchCategories.trim().length >= 3) {
        this.getCategories()
      }
    },
    searchByName() {
      this.resCategories = this.allCats.filter(item => item.node.name.toLowerCase().includes(this.searchCategories.toLowerCase()))
      this.resCategories.sort((firstParams, secondParams) => firstParams.node.name.localeCompare(secondParams.node.name))
    },
    AddCont() {
      if (this.checkContResults.length !== 0) {
        this.contentSelected = [...this.contentSelected, ...this.checkContResults]
        this.getContentsSelected()
        this.checkContResults.forEach(element => {
          const idx = this.resContents.indexOf(element)
          if (idx !== -1) {
            this.resContents.splice(idx, 1)
          }
          this.allConts = this.allConts.filter(e => element.node.id !== e.node.id)
        })
        this.checkContResults = []
        this.searchContentsByName()
      } else {
        this.contentSelected = [...this.contentSelected, ...this.resContents]
        this.getContentsSelected()
        this.resContents.forEach(element => {
          this.allConts = this.allConts.filter(e => element.node.id !== e.node.id)
        })
        this.resContents = []
        this.searchContentsByName()
      }
    },
    getContentsThreeCharact() {
      if (this.searchContents.trim().length >= 3) {
        this.getContents()
      }
    },
    searchContentsByName() {
      this.resContents = this.allConts.filter(item => item.node.name.toLowerCase().includes(this.searchContents.toLowerCase()))
      this.resContents.sort((firstParams, secondParams) => firstParams.node.name.localeCompare(secondParams.node.name))
    },

    videoMaker() {
      this.video = videojs('demo')
      this.video.on('ready', function () {
        try {
          this.video.vhs = null
        // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },

    beforeDestroy() {
      if (this.video) this.video.dispose()
    },
  },
}
</script>
<style lang="scss" scoped>
#ads-edit .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#ads-edit .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#ads-edit .card-body h4 {
  font-size: 1.286rem !important;
}

#ads-edit .tamanio {
  width: 45vw;
  height: 30vw;
}

.head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
