<template>
  <section id="interactivities">
    <b-tabs
      v-if="showData"
      pills
      lazy
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t("DataInt") }}</span>
        </template>
        <!-- DATOS BÁSICOS -->
        <b-row>
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showOne"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t('DataInt') }}</b-card-title>
                  <b-row>
                    <b-col>
                      <b-button
                        class="save-button"
                        variant="success"
                        @click="validationInfo"
                      >
                        {{ $t("dataGeneric.save") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <b-form>
                    <validation-observer ref="infoForm">
                      <b-row>
                        <image-element-vue
                          v-if="connect != 'GOF' && connect != 'ADV'"
                          :image-origin="image"
                          :image-type="'image' + language"
                          :can-delete="true"
                          :label-info="$t('infoImage.int')"
                          :label="$t('Image') + ' ' + $t('miniature')"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />

                        <b-col>
                          <!-- Title -->
                          <b-row>
                            <b-col md="10">
                              <b-form-group :label="$t('interactivities.name')">
                                <b-form-input
                                  id="name"
                                  v-model="name"
                                  :placeholder="$t('interactivities.name')"
                                  maxlength="150"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col v-if="language == base">
                              <b-form-group :label="$t('interactivities.active')">
                                <b-form-checkbox
                                  v-model="isActive"
                                  switch
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row v-if="connect != 'GOF' && connect != 'ADV'">
                            <b-col>
                              <!-- Description -->
                              <b-form-group :label="$t('interactivities.sub')">
                                <b-form-textarea
                                  id="description"
                                  v-model="description"
                                  :placeholder="$t('dataGeneric.description')"
                                  rows="4"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row v-if="language == base && exceptionPlatform()">
                            <b-col md="2">
                              <b-form-group :label="$t('hasLottie')">
                                <b-form-checkbox
                                  v-model="hasLottie"
                                  switch
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="10">
                              <b-form-group
                                v-if="hasLottie"
                                :label="$t('lottieDesign')"
                              >
                                <b-form-select
                                  v-model="lottieDesign"
                                  :options="[
                                    { value: 'vertical_derecha', text: $t('vertical_derecha') },
                                    { value: 'horizontal_derecha', text: $t('horizontal_derecha') },
                                  ]"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="2">
                              <b-form-group :label="$t('hasPublishingDates')">
                                <b-form-checkbox
                                  v-model="hasPublishingDates"
                                  switch
                                />
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                v-if="hasPublishingDates"
                                class="mb-2 mr-1"
                              >
                                <label class="mb-1">
                                  {{ $t('contents.datePublication') }}
                                  <feather-icon
                                    v-if="publishDate"
                                    class="text-danger cursor-pointer ml-1"
                                    icon="XOctagonIcon"
                                    size="15"
                                    @click="publishDate = null"
                                  />
                                </label>
                                <flat-pickr
                                  v-model="publishDate"
                                  class="form-control"
                                  :placeholder="$t('contents.datePublication')"
                                  :config="{
                                    enableTime: true,
                                    enableSeconds: true,
                                    dateFormat: 'Y-m-d H:i:S',
                                    minDate: 'today'
                                  }"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                v-if="hasPublishingDates"
                                class="mb-2 mr-1"
                              >
                                <label class="mb-1">
                                  {{ $t('contents.dateFinishing') }}
                                  <feather-icon
                                    v-if="expirationDate"
                                    class="text-danger cursor-pointer ml-1"
                                    icon="XOctagonIcon"
                                    size="15"
                                    @click="expirationDate = null"
                                  />
                                </label>
                                <flat-pickr
                                  v-model="expirationDate"
                                  class="form-control"
                                  :placeholder="$t('contents.dateFinishing')"
                                  :config="{
                                    enableTime: true,
                                    enableSeconds: true,
                                    dateFormat: 'Y-m-d H:i:S',
                                    minDate: publishDate
                                  }"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          v-if="subgroups"
                          md="4"
                        >
                          <b-form-group
                            v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                            :label="$t('Subgroup')"
                          >
                            <v-select
                              id="subgroup"
                              v-model="subgroup"
                              :options="subgroups"
                              label="name"
                              :placeholder="$t('SinGrupoUsuario')"
                              aria-autocomplete="new-password"
                              autocomplete="new-password"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="language == base && connect != 'GOF' && hasLiveEvents && isLiveContent">
                        <b-col>
                          <b-form-group
                            class="mb-2 mr-1"
                            :label="$t('startType')"
                          >
                            <b-form-select
                              v-model="timeType"
                              :options="[
                                { value: 'REL', text: $t('interactivities.rel') },
                                { value: 'LIV', text: $t('interactivities.live') },
                              ]"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="language == base">
                        <b-col>
                          <!-- FECHA/TIEMPO -->
                          <div v-if="connect != 'GOF' && timeType !== 'LIV'">
                            <b-form-group
                              v-if="timeType == 'ABS'"
                              class="mb-1 mb-sm-3"
                              :label="$t('interactivities.dateIn')"
                            >
                              <b-form-datepicker
                                v-model="date"
                                class="mb-2"
                                :min="min"
                                locale="en"
                              />
                              <b-form-timepicker
                                v-model="hour"
                                class="mb-2"
                                locale="en"
                              />
                            </b-form-group>
                            <!-- TODO: Sacar tiempo del recurso -->
                            <b-form-group
                              v-else
                              class="mb-1 mb-sm-3"
                              :label="$t('interactivities.second')"
                            >
                              {{ secondS }}'s
                              <validation-provider
                                v-slot="{ errors }"
                                name="second"
                                rules="timeDuration"
                              >
                                <b-form-timepicker
                                  id="second"
                                  v-model="second"
                                  show-seconds
                                  :state="errors.length > 0 ? false : null"
                                  locale="es"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </div>

                          <b-form-group
                            v-if="connect != 'GOF'"
                            class="mb-1 mb-sm-3"
                            :label="$t('interactivities.dur')"
                          >
                            {{ timeSeconds }}'s
                            <validation-provider
                              v-slot="{ errors }"
                              name="time"
                              rules="timeDuration"
                            >
                              <b-form-timepicker
                                id="time"
                                v-model="time"
                                show-seconds
                                :state="errors.length > 0 ? false : null"
                                locale="es"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group
                            v-if="(connect == 'GOF' || tipo == 'VID') && !imageInclude"
                            class="mb-1 mb-sm-3"
                            :label="$t('interactivities.segVid')"
                          >
                            {{ secondFileS }}'s

                            <b-form-timepicker
                              id="secondFile"
                              v-model="secondFile"
                              show-seconds
                              locale="es"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col v-if="connect == 'EMA'">
                          <b-form-group>
                            <b-row class="mb-1">
                              <b-col>
                                <b-form-group :label="$t('interactivities.asunto')">
                                  <b-form-input
                                    v-model="subjectMail"
                                    :placeholder="$t('subject')"
                                    maxlength="250"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col>
                                <b-form-group :label="$t('interactivities.message')">
                                  <quill-editor
                                    id="quil-content"
                                    v-model="bodyMail"
                                    :placeholder="$t('interactivities.bodyMess')"
                                    :options="snowOptions"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col>
                                <b-form-checkbox
                                  v-model="pdf"
                                  switch
                                >
                                  {{ $t("interactivities.adjuntar") }}
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col md="10">
                                <b-form-file
                                  v-if="pdf"
                                  v-model="fileMail"
                                  accept=".pdf"
                                />
                              </b-col>
                              <b-col style="align-self: center;">
                                <a
                                  v-if="archivo"
                                  :href="archivo"
                                  target="_blank"
                                >
                                  {{ $t("interactivities.pdf") }}</a>
                                <feather-icon
                                  v-if="archivo"
                                  class="ml-2 text-danger cursor-pointer"
                                  icon="XOctagonIcon"
                                  size="15"
                                  @click="deleteFile()"
                                />
                              </b-col>
                            </b-row>
                          </b-form-group>
                        </b-col>
                        <b-col v-else-if="connect == 'PUS'">
                          <b-form-group>
                            <b-row class="mb-1">
                              <b-col>
                                <b-form-group label="URL Push">
                                  <b-form-input
                                    id="url"
                                    v-model="urlPush"
                                    placeholder="URL push"
                                    maxlength="150"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col>
                                <b-form-group :label="$t('interactivities.message')">
                                  <b-form-input
                                    id="messagePush"
                                    v-model="messagePush"
                                    :placeholder="$t('interactivities.messagePush')"
                                    maxlength="150"
                                    class="mr-1"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row
                              v-if="language == base"
                              class="mb-1"
                            >
                              <b-col>
                                <b-form-group :label="$t('typeSend')">
                                  <b-form-checkbox
                                    v-model="isAuto"
                                    switch
                                  >
                                    {{
                                      $t("interactivities.auto")
                                    }}
                                  </b-form-checkbox>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-form-group>
                        </b-col>

                        <b-col v-else>
                          <b-form-group
                            v-if="language == base"
                            :label="$t('interactResource')"
                          >
                            <b-row>
                              <b-col
                                md="10"
                                @click="showModal"
                              >
                                <b-form-input
                                  id="resourceName"
                                  v-model="resourceNameEnd"
                                  class="cursor-pointer"
                                  disabled
                                />
                              </b-col>
                              <b-col>
                                <div @click="showModal">
                                  <b-button @click="showModal">
                                    {{ $t("interactivities.eleRec") }}
                                  </b-button>
                                </div>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="m-1">
                                <small>{{ resourceNameEnd }}</small>
                              </b-col>
                            </b-row>

                            <div class="mb-1 mb-sm-3">
                              <img
                                v-if="imageInclude"
                                :src="urlEnd"
                                width="450"
                                height="150"
                                alt=""
                              >
                              <audio
                                v-else-if="videotypeEnd == 'AUR'"
                                :key="resourceIdEnd"
                                controls
                              >
                                <source
                                  :src="urlEnd"
                                  type="audio/mp3"
                                >
                              </audio>
                              <video
                                v-else-if="urlEnd"
                                id="demo"
                                width="500"
                                height="305"
                                controls
                                class="
                                  video-js
                                  vjs-default-skin vjs-big-play-centered
                                  tamanio
                                "
                                data-setup="{&quot;liveui&quot;: true}"
                                @loadeddata="videoMaker()"
                                @loadstart="videoMaker()"
                              >
                                <source
                                  :src="urlEnd"
                                  :type="urlEnd.toLowerCase().includes('.mp4') ? 'video/mp4' : 'application/x-mpegURL'"
                                >

                              </video>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </validation-observer>
                  </b-form>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <!-- GEOBLOQUEO -->
      <b-tab v-if="geoipbloking && language == base">
        <template #title>
          <feather-icon icon="MapIcon" />
          <span>{{ $t('zones.title') }}</span>
        </template>
        <b-card>
          <geoblocking :type-element="'INT'" />
        </b-card>
      </b-tab>
    </b-tabs>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('editContent.recSelect')"
    >
      <div class="d-block text-center">
        <resources-selector
          :msg="tipo"
          :sponsor="isSponsor"
          :image="imageInclude"
          @data="seleccionarRecurso"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import geoblocking from '@/views/components/geoblocking.vue'

import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import i18n from '@/libs/i18n'

import {
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BTab, BTabs,
  BFormInput,
  BButton,
  BFormGroup,
  BCard,
  BFormFile,
  BCardHeader,
  BCardBody,
  BFormTextarea,
  BFormDatepicker,
  BFormTimepicker,
  BOverlay,
  BFormSelect,
  BCardTitle,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ResourcesSelector from '@/views/common/ResourcesSelector.vue'
import { quillEditor } from 'vue-quill-editor'
import {
  base64Encode, utf8ToB64, showToast, isEmpty, camelToSnake, messageError, getSubgroups, checkPermissions, isUserInGroup,
} from '@/store/functions'

import * as constants from '@core/utils/constants'
import { getUserData } from '@/auth/utils'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Quill from 'quill'
import ImageElementVue from '@/views/components/ImageElement.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

extend('timeDuration', value => {
  const res = value.split(':')
  const hora = res[0] * 3600
  const minuto = res[1] * 60
  const seg = res[2]
  const tiempo = parseInt(hora, 10) + parseInt(minuto, 10) + parseInt(seg, 10)

  if (tiempo >= 10) {
    return true
  }
  return i18n.t('valueSu10')
})

const alignClass = Quill.import('attributors/style/align')
const backgroundClass = Quill.import('attributors/style/background')
const colorClass = Quill.import('attributors/style/color')
const directionClass = Quill.import('attributors/style/direction')
const fontClass = Quill.import('attributors/style/font')
const sizeClass = Quill.import('attributors/style/size')
Quill.register(alignClass, true)
Quill.register(backgroundClass, true)
Quill.register(colorClass, true)
Quill.register(directionClass, true)
Quill.register(fontClass, true)
Quill.register(sizeClass, true)
export default {
  components: {
    BOverlay,
    quillEditor,
    BCardHeader,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BCardTitle,
    BFormTextarea,
    ResourcesSelector,
    BFormSelect,
    BFormDatepicker,
    geoblocking,
    BFormTimepicker,
    ImageElementVue,
    BFormGroup,
    BForm,
    BRow,
    BTab,
    BTabs,
    BCol,
    BFormCheckbox,
    BFormInput,
    BButton,
    BCard,
    BFormFile,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    msg: Boolean,
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      geoipbloking: false,
      hasLottie: false,
      lottieDesign: 'vertical_derecha',
      viewName: this.$t('interactivities.title'),
      viewDetail: this.$t('interactivities.editar'),
      video: null,
      durationMedia: null,
      response: null,
      showData: false,
      vacio: false,
      description: null,
      name: '',
      connect: null,
      date: null,
      time: null,
      hour: null,
      second: null,
      secondS: null,
      bodyMail: null,
      subjectMail: null,
      isAuto: null,
      headers: {},
      urlPush: null,
      messagePush: null,
      typePush: null,
      content: null,
      resourceStart: null,
      resourceEnd: null,
      isVisible: false,
      isActive: true,
      tipo: null,
      videotype: null,
      resourceName: null,
      timeType: null,
      resourceId: null,
      bucketUrl: null,
      directUrl: null,
      videotypeEnd: null,
      secondFile: null,
      secondFileS: null,
      resourceNameEnd: null,
      resourceIdEnd: null,
      bucketUrlEnd: null,
      isSponsor: false,
      userData: getUserData(),
      urlEnd: null,
      timeSeconds: null,
      directUrlEnd: null,
      image: null,
      imageBol: null,
      imageShow: null,
      imageSrc: null,
      imageInclude: false,
      pdf: false,
      fileMail: null,
      archivo: null,
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: this.$t('interactivities.body'),
      },
      snowOptions: {
        theme: 'snow',
      },
      showOne: false,
      showTwo: false,
      min: null,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      hasLiveEvents: false,
      isLiveContent: false,
      hasPublishingDates: false,
      expirationDate: null,
      publishDate: null,
      checkPermissions,
    }
  },
  watch: {
    pdf() {
      if (!this.pdf) {
        if (this.response.node.fileMail !== '') {
          this.deleteFile()
        }
      }
    },
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageBol = true
              this.imageSrc = value
            })
            .catch(() => {
              this.imageSrc = null
            })
        } else {
          this.imageSrc = null
        }
      }
    },
    second() {
      this.secondS = this.timeToSecond(this.second)
    },
    time() {
      this.timeSeconds = this.timeToSecond(this.time)
    },
    secondFile() {
      // this.secondFileS = this.timeToSecond(this.secondFile);
      const res = this.secondFile.split(':')
      const hora = res[0] * 3600
      const minuto = res[1] * 60
      const seg = res[2]
      const tiempo = parseInt(hora, 10) + parseInt(minuto, 10) + parseInt(seg, 10)
      this.secondFileS = tiempo
    },
  },
  async mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.fechaDir()
    this.geoipbloking = this.userData?.profile?.client?.geoipbloking
    this.hasSubgroups = this.userData.profile.client.hasSubgroups
    this.hasLiveEvents = this.userData.profile.client.hasLiveEvents

    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
    }

    await this.getData()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    exceptionPlatform() {
      const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
      if (mode !== 'production') {
        return true
      }
      return this.isPlatformAvailable(this.userData.profile.client.name)
    },
    isPlatformAvailable(idPlatform) {
      const allowedPlatforms = [
        'fcbarcelona', 'fcbvideo', 'psoeplay', 'breakonsponsorship', 'breakon',
        'juanamartin', 'dipucordoba', 'jcf', 'cca', 'figc', 'madcup', 'mecctv',
        'smartplatino', 'genial_art', 'canalsur', 'valladolid', 'emiral', 'nwm',
        'dipubadajoz', 'camaramalaga', 'canalextremadura', 'sierranieves',
      ]

      // Verifica si la plataforma está en el array
      return allowedPlatforms.includes(idPlatform)
    },
    fechaDir() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      this.min = new Date(today)
    },
    timeToSecond(value) {
      const res = value.split(':')
      const hora = res[0] * 3600
      const minuto = res[1] * 60
      const seg = res[2]
      const tiempo = parseInt(hora, 10) + parseInt(minuto, 10) + parseInt(seg, 10)
      return tiempo < 10 ? 10 : tiempo
    },
    async getData() {
      const { id } = this.$route.params
      const { headers } = this

      await axios
        .post('', {
          query: `
          query{
            allInteractivities(id: "${id}"){
             edges {
              node {
                  id
                  name(lang:"${this.language}")
                  description(lang:"${this.language}")
                  connect
                  image(lang:"${this.language}")
                  second
                  date
                  hour
                  time
                  secondFile
                  timeType
                  lottieDesign
                  hasLottie
                  publishDate
                  expirationDate
                  #Mail
                  fileMail(lang:"${this.language}")
                  bodyMail(lang:"${this.language}")
                  subjectMail(lang:"${this.language}")

                  #PUSH
                  isAuto
                  urlPush(lang:"${this.language}")
                  messagePush(lang:"${this.language}")
                  typePush
                  content {
                    id
                    name
                    duration
                    type
                    creator {
                      id
                      
                    }
                  }
                  resourceStart {
                    id
                    type
                    name
                    videotype
                    directUrl
                    bucketUrl
                    file{
                      fileUrl
                    }
                  }
                  resourceEnd {
                    id
                    name
                    type
                    videotype
                    directUrl
                    bucketUrl
                    file{
                      fileUrl
                    }
                  }
                  isVisible
                  isActive
                  subgroup{
                    id
                    name
                  }

              }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          const [firstEdge] = res.data.data.allInteractivities.edges
          this.response = firstEdge
          this.name = this.response.node.name
          this.timeType = this.response.node.timeType
          this.resourceEnd = this.response.node.resourceEnd
          this.durationMedia = this.response.node.content.duration
          this.lottieDesign = this.response.node.lottieDesign ? this.response.node.lottieDesign : 'vertical_derecha'
          this.hasLottie = this.response.node.hasLottie
          this.isLiveContent = this.response.node.content.type === 'DIR'
          this.publishDate = this.response.node.publishDate
          this.expirationDate = this.response.node.expirationDate
          this.hasPublishingDates = this.publishDate != null || this.expirationDate != null

          try {
            this.resourceIdEnd = this.resourceEnd.id
            this.videotypeEnd = this.resourceEnd.videotype
            this.resourceNameEnd = this.resourceEnd.name
            this.bucketUrlEnd = this.resourceEnd.bucketUrl
            this.directUrlEnd = this.resourceEnd.directUrl
            this.urlEnd = isEmpty(this.directUrlEnd) ? this.bucketUrlEnd : this.directUrlEnd
            this.tipo = this.resourceEnd.type

            if (this.urlEnd.toLowerCase().includes('.jpg') || this.urlEnd.toLowerCase().includes('.png')) {
              this.imageInclude = true
            } else {
              this.imageInclude = false
            }
            // eslint-disable-next-line no-empty
          } catch (error) { }

          this.image = this.response.node.image

          this.description = this.response.node.description
          this.connect = this.response.node.connect

          this.date = this.response.node.date
          this.time = this.secondsToHour(this.response.node.time)
          this.timeSeconds = this.response.node.time
          this.secondFile = this.secondsToHour(this.response.node.secondFile)
          this.secondFileS = this.response.node.secondFile
          this.hour = this.response.node.hour
          this.secondS = this.response.node.second
          this.second = this.secondsToHour(this.response.node.second)

          this.pdf = this.response.node.fileMail !== ''
          this.archivo = this.response.node.fileMail
          this.bodyMail = this.response.node.bodyMail
          this.subjectMail = this.response.node.subjectMail

          this.isAuto = this.response.node.isAuto
          this.urlPush = this.response.node.urlPush
          this.messagePush = this.response.node.messagePush
          this.typePush = this.response.node.typePush

          this.content = this.response.node.content
          this.resourceStart = this.response.node.resourceStart
          this.isVisible = this.response.node.isVisible
          this.isActive = this.response.node.isActive

          this.resourceId = this.resourceStart.id
          this.videotype = this.resourceStart.videotype
          this.resourceName = this.resourceStart.name
          this.bucketUrl = this.resourceStart.bucketUrl
          this.directUrl = this.resourceStart.directUrl

          this.subgroup = this.response.node.subgroup

          // Sponsor role can access every content, but cannot delete it. Uncomment it to restrict access again
          /* let authorized = false
          if (this.isSponsor) {
            if (this.content.creator !== null) {
              if (this.content.creator.id === this.userData.id) authorized = true
            }
            if (!authorized) {
              this.$router.push({ name: 'misc-not-authorized' })
            }
          }

          if (!authorized && this.isSponsor) this.showData = false
          else */ this.showData = true

          this.$emit('return', { name: this.name, viewName: this.viewName, viewDetail: this.viewDetail })
        })
    },

    goToBackPage() {
      window.history.back()
    },
    chooseImage() {
      document.getElementById('fileInput').click()
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    seleccionarRecurso(data) {
      this.hideModal()
      const [resourceIdEnd, resourceNameEnd, urlEnd] = data
      this.resourceNameEnd = resourceNameEnd
      this.resourceIdEnd = resourceIdEnd
      try {
        this.urlEnd = urlEnd
        if (this.urlEnd.toLowerCase().includes('.jpg') || this.urlEnd.toLowerCase().includes('.png')) {
          this.imageInclude = true
        } else {
          this.imageInclude = false
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }

      if (this.video) {
        if (!isEmpty(this.urlEnd)) {
          if (this.urlEnd.toLowerCase().includes('.m3u8')) {
            this.video.src({
              type: 'application/x-mpegURL',
              src: this.urlEnd,
            })
          } else {
            this.video.src({
              type: 'video/mp4',
              src: this.urlEnd,
            })
          }
        }
      }

      showToast(this.$t('code.rec'), 1, this)
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case `image${this.language}`:
          this.image = data
          break

        default:
          break
      }
    },
    updateInfo() {
      this.showOne = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const { headers } = this

      const data = new FormData()

      const dateIn = this.hasPublishingDates ? this.fechaFormato(this.publishDate) : null
      const dateFin = this.hasPublishingDates ? this.fechaFormato(this.expirationDate) : null

      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: this.name, type: 'String' },
        { title: `description${this.language}`, value: this.description, type: 'String' },
        { title: `bodyMail${this.language}`, value: this.bodyMail, type: 'String' },
        { title: `messagePush${this.language}`, value: this.messagePush, type: 'String' },
        { title: `subjectMail${this.language}`, value: this.subjectMail, type: 'String' },
        { title: `urlPush${this.language}`, value: this.urlPush, type: 'String' },

      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'isAuto', value: this.isAuto, type: 'Boolean' },
          { title: 'isActive', value: this.isActive, type: 'Boolean' },
          { title: 'resourceEnd', value: this.resourceIdEnd, type: 'ID' },
          { title: 'secondFile', value: this.secondFileS, type: 'Int' },
          { title: 'time', value: this.timeSeconds, type: 'Int' },
          { title: 'second', value: this.secondS, type: 'Int' },
          { title: 'date', value: this.date, type: 'Date' },
          { title: 'hour', value: this.hour, type: 'Time' },
          { title: 'timeType', value: this.timeType, type: 'InteractivityTimeTypeInput' },
          { title: 'hasLottie', value: this.hasLottie, type: 'Boolean' },
          { title: 'lottieDesign', value: this.lottieDesign, type: 'String' },
          { title: 'subgroup', value: this.subgroup?.id, type: 'ID' },
          { title: 'publishDate', value: dateIn, type: 'DateTime' },
          { title: 'expirationDate', value: dateFin, type: 'DateTime' },
        )
      }

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateInteractivities(id:"${this.$route.params.id}",input:{\n
       `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        interactivity{
          name
              description
              isActive
              second
              time
              subgroup{
                id
                name
              }
            }
        }
      }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      if (this.resourceIdEnd != null) {
        if (this.image) data.append(`image_${camelToSnake(this.language)}`, this.image)

        const resource = utf8ToB64(`id:${this.resourceIdEnd}`)

        const { content } = this.$route.params
        axios
          .post('', {
            query: `
          {
            allContents(id:"${content}"){
            edges {
              node {
                id
                contentResources (resource:"${resource}"){
                  totalCount
                  edges {
                    node {
                      id
                      content
                      resource {
                        id
                      }

                    }
                  }
                }
              }
            }
            }
          }
        `,
          }, { headers })
          .then(result => {
            messageError(result, this)

            if (
              result.data.data.allContents.edges[0].node.contentResources
                .totalCount === 0
            ) {
              axios
                .post('', {
                  query: `
                  mutation{
                  createContentsResource(input:{content:"${content}",resource:"${this.resourceIdEnd}"}){
                    contentResource2{
                      id
                      content
                      resource{
                        id
                        name
                      }
                    }
                  }
                }
                `,
                }, { headers })
                .then(response => {
                  messageError(response, this)

                  axios.post('', data, { headers }, config).then(res => {
                    messageError(res, this)

                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Form Submitted',
                        icon: 'EditIcon',
                        variant: 'success',
                      },
                    })
                    if (res.status === 200) {
                      this.show = false

                      this.$router.push({
                        name: 'interactivities-video',
                        params: { id: content },
                      })
                    }

                    this.show = false
                  })
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                })
            } else {
              axios
                .post('', data, { headers }, config)
                .then(response => {
                  messageError(response, this)

                  if (response.status === 200) showToast(this.$t('code.updateData'), 1, this)
                  else showToast(this.$t('code.updateDataError'), 2, this)

                  this.showOne = false
                })
                .catch(() => {
                  showToast(this.$t('code.updateDataError'), 2, this)
                  this.showOne = false
                })
            }
          })
          .catch(() => { })
      } else {
        if (this.image) data.append(`image_${camelToSnake(this.language)}`, this.image)

        if (this.pdf) data.append(`file_mail_${camelToSnake(this.language)}`, this.fileMail)

        axios
          .post('', data, { headers }, config)
          .then(response => {
            messageError(response, this)

            if (response.status === 200) showToast(this.$t('code.updateData'), 1, this)
            else showToast(this.$t('code.updateDataError'), 2, this)

            this.showOne = false
          })
          .catch(() => {
            showToast(this.$t('code.updateDataError'), 2, this)
            this.showOne = false
          })
      }
    },

    videoMaker() {
      this.video = videojs('demo')

      // eslint-disable-next-line func-names
      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },

    validationInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoForm.validate().then(success => {
          if (success) {
            resolve(true)

            this.updateInfo()
          } else {
            reject()
          }
        })
      })
    },
    secondsToHour(segundosP) {
      const segundos = Math.round(segundosP % 0x3c).toString()
      const horas = Math.floor(segundosP / 0xe10).toString()
      const minutos = (Math.floor(segundosP / 0x3c) % 0x3c).toString()
      return `${horas}:${minutos}:${segundos}`
    },
    deleteFile() {
      const { headers } = this

      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.showOne = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const query = `
              mutation{
                updateInteractivities(id: "${this.$route.params.id}",input:{
                  isAuto:${this.isAuto}
                }){
                  interactivity{
                    name
                    description
                    isActive
                    second
                    time
                  }
                }
              }
              `

            data.append('query', query)
            data.append('deleteFile', `file_mail_${camelToSnake(this.language)}`)

            axios
              .post('', data, { headers }, config)
              .then(response => {
                messageError(response, this)

                if (response.status === 200) showToast(this.$t('code.updateData'), 1, this)
                else showToast(this.$t('code.updateDataError'), 2, this)
                this.getData()
                this.showOne = false
              })
              .catch(() => {
                showToast(this.$t('code.updateDataError'), 2, this)
                this.showOne = false
              })
          } else {
            this.pdf = true
          }
        })
        .catch(() => { })
    },
    deleteImage() {
      const { headers } = this
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.showOne = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const query = `
              mutation{
                updateInteractivities(id: "${this.$route.params.id}",input:{
                  isAuto:${this.isAuto}
                }){
                  interactivity{
                    name
                    description
                    isActive
                    second
                    time
                  }
                }
              }
              `

            data.append('query', query)
            data.append('deleteFile', `image_${camelToSnake(this.language)}`)

            axios
              .post('', data, { headers }, config)
              .then(response => {
                messageError(response, this)

                if (response.status === 200) showToast(this.$t('code.updateData'), 1, this)
                else showToast(this.$t('code.updateDataError'), 2, this)
                this.getData()
                this.showOne = false
              })
              .catch(() => {
                showToast(this.$t('code.updateDataError'), 2, this)
                this.showOne = false
              })
          } else {
            this.pdf = true
          }
        })
        .catch(() => { })
    },
    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#interactivities .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#interactivities .card-body h4 {
  font-size: 1.286rem !important;
}

#add-new-category-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#interactivities .modal {
  overflow-y: auto;
}

/*
Full screen Modal
*/
#interactivities .fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  #interactivities .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  #interactivities .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #interactivities .fullscreen-modal .modal-dialog {
    width: 1170px;
  }
}
</style>
